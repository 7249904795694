import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';

import {Modal, ModalProps} from '@app/components/Modal';
import {singleton as config} from '@app/config';
import {ApiKeyModal_apiKey$key} from './__generated__/ApiKeyModal_apiKey.graphql';

interface Props {
  apiKey: ApiKeyModal_apiKey$key | null,
  onHide: ModalProps["onHide"],
  show: ModalProps["show"]
}

export default function ApiKeyModal(props : Props) {
  const apiKey = useFragment(graphql`
    fragment ApiKeyModal_apiKey on ApplicationApiKey {
      clientId
      clientSecret
    }
  `, props.apiKey)

  if (!props.apiKey) return null;
  if (!apiKey) return null;

  return (
    <Modal show={props.show} onHide={props.onHide} title={`API ClientId and ClientSecret`}>
      <Modal.Body>
        <p className="help-block">
          This is the only time we will show you your client secret.
        </p>

        <p className="help-block">
          You can try out your api keys in our <a href="https://docs.criipto.com/signatures" target="_blank">API documentation</a>
          &nbsp;or our <a href={config.signaturesGraphQLEndpoint?.replace('v1/graphql', 'v1/explorer')} target="_blank">GraphQL Explorer</a>
        </p>
        <div className="form-group">
          <label className="control-label">Client ID</label>
          <input type="text" className="form-control" value={apiKey.clientId} readOnly />
        </div>
        <div className="form-group">
          <label className="control-label">Client Secret</label>
          <input type="text" className="form-control" value={apiKey.clientSecret!} readOnly />
        </div>
      </Modal.Body>
    </Modal>
  )
}