import { useSelector } from "@app/redux";

export function useVerifyAccessToken() {
  return useSelector(state => state.auth.access_token);
}

export function useGaussAccessToken() {
  return useSelector(state => state.auth.id_token);
}

export function useProfile() {
  return useSelector(state => state.auth.profile);
}