import moment from 'moment';

const PRESETS = [
    {
        preset: 'Last 7 days',
        from: moment().subtract(7, 'days').startOf('day')
    },
    {
        preset: 'Last 30 days',
        from: moment().subtract(30, 'days').startOf('day')
    },
    {
        preset: 'Last 3 months',
        from: moment().subtract(3, 'months').startOf('day')
    },
    {
        preset: 'Last 12 months',
        from: moment().subtract(12, 'months').startOf('day')
    },
    {
        preset: 'Last 24 months',
        from: moment().subtract(24, 'months').startOf('day')
    },
    {
        preset: 'Last 36 months',
        from: moment().subtract(36, 'months').startOf('day')
    }
].map(preset => ({
    ...preset,
    to: moment().endOf('day')
}));

export default PRESETS;