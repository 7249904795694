import React, {useState} from 'react';
import { useField } from 'formik';

import { Application } from '@app/models';
import { translate } from '@app/i18n';
import Button from '@app/components/Button';

function isValidURL(input: string) {
  return /^.+\:\/\/.+$/.test(input); // very rudimentary check that url must contain [before]://[after]
}

export default function ReturnURLs() {
  const [{value}, , {setValue}] = useField<Application['returnUrls']>('returnUrls');
  const [addText, setAddText] = useState('');

  const handleAddClick = (event: React.FormEvent) => {
    if (!addText || !isValidURL(addText)) return;
    setValue(value.concat(addText));
    setAddText('');
  };
  const handleKeydown = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
    }
  }
  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
      if (!addText || !isValidURL(addText)) return;
      setValue(value.concat(addText));
      setAddText('');
      return;
    }
  }

  const handleRemove = (index: number) => {
    const newValue = value.slice(0);
    newValue.splice(index, 1);
    setValue(newValue);
  }

  return (
    <div className="form-group">
      <label className="control-label">{translate('LABEL_CALLBACK_URLS')}</label>
      <div className="flex flex-row flex-wrap gap-[5px]">
        {value.map((url, index) => (
          <div className="text-xl bg-slate-200 px-3 py-2 rounded-lg" key={index}>
            {url}
            <i className="fa fa-times cursor-pointer ml-2 hover:text-primary-900" onClick={() => handleRemove(index)} />
          </div>
        ))}
      </div>
      <div className="flex flex-row mt-4">
        <input
          type="text"
          className="form-control"
          placeholder="Add callback URL"
          value={addText}
          onChange={(event) => setAddText(event.target.value)}
          onKeyDown={handleKeydown}
          onKeyUp={handleKeyUp}
        />
        <Button type="button" variant="default" autoWidth style={{margin: 0}} onClick={handleAddClick}>Add</Button>
      </div>
      {addText && !isValidURL(addText) ? (
        <small className="help-text">Not a valid URL</small>
      ) : null}
    </div>
  );
}