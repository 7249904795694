import React from 'react';
import {identity} from 'lodash';

import { translate } from '@app/i18n';
import {REL, CONTENT_TYPE} from '@app/constants';
import {useDispatch, useSelector} from '@app/redux';
import {Form, FormError, FormSuccess} from '@components/Form';
import Button from '@components/Button';
import { getHref, DanishMitIdStatus, DanishMitIdConfiguration } from '@app/models';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';
import useEnvironment from '@app/hooks/useEnvironment';
import useTenant from '@app/hooks/useTenant';

interface Props {
  status: DanishMitIdStatus,
  configuration: DanishMitIdConfiguration
}
export default function DKMitIDProviderPending(props: Props) {
  const {status} = props;
  const environment = useEnvironment();
  const tenant = useTenant();
  const cname = environment === 'PRODUCTION' ? status.productionCname : status.testCname;
  const dispatch = useDispatch();

  return (
    <Form
      key={environment}
      initialValues={{}}
      onSubmit={async (values : any) => {
        const url = getHref(tenant!, REL.DK_MITID_COMPLETE);
        await dispatchVerifyRequest(
          dispatch,
          {
            url,
            method: "POST",
            data: {
              production: environment === 'PRODUCTION'
            },
            contentType: CONTENT_TYPE.DK_MITID_COMPLETE
          },
          identity
        );
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <div className="row">
          <div className="col-sm-6">
            <h3>
              Danish MitID Application
            </h3>

            <p>
              We are setting up your domains with MitID, when you hear from us please click the button below.
            </p>

            {cname ? (
              <p>
                <strong>Domain {props.configuration.brokerLandingPageDomain} is ready</strong>
              </p>
            ) : (
              <p>
                <strong>Domain {props.configuration.brokerLandingPageDomain} is not ready</strong>
              </p>
            )}

            <FormError error={error} />
            <FormSuccess message={!isPending && isSuccess && `MitID is now ready to use`} />
            <Button className="pull-right" variant="primary" working={isPending} disabled={!cname} type="submit">Complete</Button>
          </div>
          <div className="col-sm-6">

          </div>
        </div>
      )}
    </Form>
  )
}