import React, {useCallback} from 'react';
import {identity} from 'lodash';

import {useDispatch, useSelector} from '@app/redux';
import {translate} from '@app/i18n';

import {
  NorwegianBankIdOldConfiguration,
  Tenant, DetailedIdentityProvider,
  getHref
} from '@app/models';

import {REL} from '@app/constants';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, FormError, FormSuccess} from '@components/Form';
import CertificateForm from '@components/CertificateForm';

import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import { dispatchVerifyRequest, valuesToFormData } from '@app/redux/apiSlice';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  configuration: NorwegianBankIdOldConfiguration,
  provider: DetailedIdentityProvider,
  apiRoot: Tenant
}

export interface FormValues {
  certificate?: {
    file: File,
    password: string
  }
}

export function NOBankIDOldProvider(props : Props) {
  const tenant = props.apiRoot;
  const dispatch = useDispatch();
  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";

  const saveProvider = useCallback(async (values : FormValues) => {
    let hrefUpload = getHref(tenant, REL.NO_BANKID_OLD);

    return await dispatchVerifyRequest(
      dispatch,
      {
        url: hrefUpload,
        method: 'POST',
        data: valuesToFormData({
          file: values.certificate?.file!,
          metadata: JSON.stringify({
            password: values.certificate?.password,
            production: isProduction
          }),
        })
      },
      identity
    );
  }, [tenant]);

  return (
    <Form
      key={environment}
      initialValues={{}}
      onSubmit={(values : FormValues) => {
        return saveProvider(values);
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p className="green">⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_NOBANKIDOLD_WHAT_TO_AVOID')}</em></p>
            <p className="green">❓{translate('HINT_ID_SERVICE_CONFIG_WHEN_IN_DOUBT_NOBANKIDOLD')}</p>
          </EnvironmentDialog>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} Norwegian BankID
              </h3>
              <EnvironmentTag />
              <CertificateForm
                certificate={null}
                fileFieldName="certificate.file"
                passwordFieldName="certificate.password"
                required={true}
              />
              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your norwegian bankid settings have been updated` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_CERTIFICATE', { provider: 'Norwegian BankID Old' })}</p>
              <p>{translate('INFO_NO_BANKID_OLD')}</p>
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}