/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DeleteApplicationApiKeyInput = {
    applicationId: string;
    apiKeyId: string;
};
export type DeleteApiKeyButtonMutationVariables = {
    input: DeleteApplicationApiKeyInput;
};
export type DeleteApiKeyButtonMutationResponse = {
    readonly deleteApplicationApiKey: {
        readonly application: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"ApiKeys_application">;
        };
    } | null;
};
export type DeleteApiKeyButtonMutation = {
    readonly response: DeleteApiKeyButtonMutationResponse;
    readonly variables: DeleteApiKeyButtonMutationVariables;
};



/*
mutation DeleteApiKeyButtonMutation(
  $input: DeleteApplicationApiKeyInput!
) {
  deleteApplicationApiKey(input: $input) {
    application {
      id
      ...ApiKeys_application
    }
  }
}

fragment AddApiKeyButton_application on Application {
  id
}

fragment AddApiKeyForm_application on Application {
  id
}

fragment ApiKeys_application on Application {
  apiKeys {
    id
    clientId
    mode
    note
    ...DeleteApiKeyButton_apiKey
    ...RefreshApiKeyButton_apiKey
  }
  ...AddApiKeyButton_application
  ...AddApiKeyForm_application
  ...DeleteApiKeyButton_application
  ...RefreshApiKeyButton_application
}

fragment DeleteApiKeyButton_apiKey on ApplicationApiKey {
  id
}

fragment DeleteApiKeyButton_application on Application {
  id
}

fragment RefreshApiKeyButton_apiKey on ApplicationApiKey {
  id
}

fragment RefreshApiKeyButton_application on Application {
  id
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteApiKeyButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteApplicationApiKeyOutput",
        "kind": "LinkedField",
        "name": "deleteApplicationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApiKeys_application"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteApiKeyButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteApplicationApiKeyOutput",
        "kind": "LinkedField",
        "name": "deleteApplicationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ApplicationApiKey",
                "kind": "LinkedField",
                "name": "apiKeys",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b934b67a2626eae9b3927ff261f46bb9",
    "id": null,
    "metadata": {},
    "name": "DeleteApiKeyButtonMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteApiKeyButtonMutation(\n  $input: DeleteApplicationApiKeyInput!\n) {\n  deleteApplicationApiKey(input: $input) {\n    application {\n      id\n      ...ApiKeys_application\n    }\n  }\n}\n\nfragment AddApiKeyButton_application on Application {\n  id\n}\n\nfragment AddApiKeyForm_application on Application {\n  id\n}\n\nfragment ApiKeys_application on Application {\n  apiKeys {\n    id\n    clientId\n    mode\n    note\n    ...DeleteApiKeyButton_apiKey\n    ...RefreshApiKeyButton_apiKey\n  }\n  ...AddApiKeyButton_application\n  ...AddApiKeyForm_application\n  ...DeleteApiKeyButton_application\n  ...RefreshApiKeyButton_application\n}\n\nfragment DeleteApiKeyButton_apiKey on ApplicationApiKey {\n  id\n}\n\nfragment DeleteApiKeyButton_application on Application {\n  id\n}\n\nfragment RefreshApiKeyButton_apiKey on ApplicationApiKey {\n  id\n}\n\nfragment RefreshApiKeyButton_application on Application {\n  id\n}\n"
  }
};
})();
(node as any).hash = 'b147a7aabf110d6f6303114d0f12425e';
export default node;
