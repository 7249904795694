import React, { useEffect, useReducer } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Redirect
} from "react-router-dom";
import {
  RelayEnvironmentProvider
} from 'react-relay/hooks';
import { Provider as ReduxProvider } from 'react-redux';
import {default as reduxStore} from './redux/store';
import { useSelector, useDispatch } from './redux';
import Header from './components/Header';
import {Footer} from './components/Footer';

import usePromise from './hooks/usePromise';
import RedirectScreen from '@screens/RedirectScreen';
import LogsScreen from '@screens/LogsScreen';
import DashboardScreen from '@screens/DashboardScreen';
import SignupScreen from '@screens/SignupScreen';
import {LoginScreen, LoginCallbackScreen, LoginRefreshScreen, NemIDLoginCallbackScreen} from '@screens/LoginScreen';
import OnboardingScreen from '@screens/OnboardingScreen';
import StylingScreen from '@screens/StylingScreen';
import ApplicationsScreen from '@screens/ApplicationsScreen';
import ProvidersScreen from '@screens/ProvidersScreen';
import {setTenant} from '@redux/tenantSlice';
import DomainsScreen from './screens/DomainsScreen/DomainsScreen';
import { fetchScopedClaims, fetchTenants } from './redux/discoverySlice';
import { checkSession, init as initAuth } from '@redux/authSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import RelayEnvironment from './RelayEnvironment';
import ExtensibilityScreen from "./screens/ExtensibilityScreen/ExtensibilityScreen";
import CriiptoAdminScreen from "./screens/CriiptoAdminScreen/CriiptoAdminScreen";
import usePermissions from "./hooks/usePermissions";
import useTenant from "./hooks/useTenant";
import useGaussOrganization from "./hooks/useGaussOrganization";
import {singleton as Segment} from '@app/segment';
import UsersScreen from "./screens/UsersScreen/UsersScreen";
import AnalyticsScreen from "./screens/AnalyticsScreen/AnalyticsScreen";
import TenantNavigation from "@app/components/TenantNavigation";
import InvitationAcceptScreen from "./screens/InvitationAcceptScreen";
import BillingScreen from "./screens/BillingScreen";
import ToolsScreen from "./screens/ToolsScreen";
import SupportScreen from "./screens/SupportScreen/SupportScreen";

interface RoutesProps {}

interface TenantRouteParams {
  organizationId: string
}

function TenantScreen(props: RoutesProps) {
  const dispatch = useDispatch();
  let match = useRouteMatch<TenantRouteParams>();

  useEffect(() => {
    dispatch(fetchTenants({force: false}));
    dispatch(fetchScopedClaims({force: false}));
  }, []);

  const reduxTenant = useSelector(state => state.tenant.tenant);

  const tenant = useTenant();
  const organization = useGaussOrganization();
  const permissions = usePermissions();

  useEffect(() => {
    if (!tenant || !permissions) return;
    dispatch(setTenant({tenant, organization, permissions}));
  }, [tenant, organization, permissions]);

  // Segment/Mixpanel tracking/grouping
  useEffect(() => {
    if (!tenant) return;

    Segment.group(tenant, organization);
  }, [tenant, organization]);

  const verifyState = useSelector(state => state.discovery.verify.state);
  const gaussState = useSelector(state => state.discovery.gauss.state);

  const [showNav, toggleNav] = useReducer(value => !value, false);
  const header = <Header key="header" onHamburger={toggleNav} />;
  const nav = <TenantNavigation key="nav" show={showNav} onHide={toggleNav} />;
  const footer = <Footer left={true} />;

  if (tenant == undefined || verifyState.pending) {
    return (
      <React.Fragment>
        {nav}
        <div className="sidebar-offset">
          {header}
          <div className="app-content"><i className="fa fa-spinner fa-pulse" /></div>
          {footer}
        </div>
      </React.Fragment>
    );
  }
  const error = verifyState.error || gaussState.error;
  if (error) {
    return (
      <React.Fragment>
        {nav}
        <div className="sidebar-offset">
          {header}
          <div className="app-content alert alert-danger">{error.message}</div>
          {footer}
        </div>
      </React.Fragment>
    );
  }
  if (tenant === null) return <Redirect to="/" />; // Somehow had a bad tenant link, let them start over

  /*
   * There is a render tick where we allow rendering because the tenant is present but it is not yet in the redux store
   * For that case we block further rendering shortly. TODO: remove when we introduce useTenant hook
   */
  if (!reduxTenant) {
    return (
      <React.Fragment>
        {nav}
        <div className="sidebar-offset">
          {header}
          <div className="app-content"><i className="fa fa-spinner fa-pulse" /></div>
          {footer}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {nav}
      <div className="sidebar-offset">
        {header}
        <div className="app-content">
          <Switch>
            <Route path={`${match.path}/dashboard`}>
              <DashboardScreen />
            </Route>
            <Route path={`${match.path}/onboarding`}>
              <OnboardingScreen tenant={tenant}  />
            </Route>
            <Route path={`${match.path}/applications`}>
              {permissions?.integration ? (
                <ApplicationsScreen />
              ) : (
                <Redirect to={`${match.url}/dashboard`} />
              )}
            </Route>
            <Route path={`${match.path}/styling`}>
              {permissions?.providers ? (
                <StylingScreen />
              ) : (
                <Redirect to={`${match.url}/dashboard`} />
              )}
            </Route>
            <Route path={`${match.path}/providers`}>
              {permissions?.providers ? (
                <ProvidersScreen />
              ) : (
                <Redirect to={`${match.url}/dashboard`} />
              )}
            </Route>
            <Route path={`${match.path}/domains`}>
              {(permissions?.productionDomains || permissions?.testDomains) ? (
                <DomainsScreen />
              ) : (
                <Redirect to={`${match.url}/dashboard`} />
              )}
            </Route>
            <Route path={`${match.path}/logs/:domain?`}>
              {(permissions?.productionDomains || permissions?.testDomains) ? (
                <LogsScreen />
              ) : (
                <Redirect to={`${match.url}/dashboard`} />
              )}
            </Route>
            <Route path={`${match.path}/extensibility`}>
              {permissions?.extensibility ? (
                <ExtensibilityScreen />
              ) : (
                <Redirect to={`${match.url}/dashboard`} />
              )}
            </Route>

            <Route path={`${match.path}/criipto-admin`}>
              {permissions?.criiptoAdmin ? (
                <CriiptoAdminScreen />
              ) : (
                <Redirect to={`${match.url}/dashboard`} />
              )}
            </Route>

            <Route path={`${match.path}/analytics`}>
              <AnalyticsScreen />
            </Route>

            <Route path={`${match.path}/users`}>
              <UsersScreen />
            </Route>

            <Route path={`${match.path}/billing`}>
              <BillingScreen />
            </Route>

            <Route path={`${match.path}/tools`}>
              <ToolsScreen />
            </Route>

            <Route path={`${match.path}/support`}>
              <SupportScreen />
            </Route>

            {/* 404 */}
            <Route path={match.path}>
              <Redirect to={`${match.url}/dashboard`} />
            </Route>
          </Switch>
        </div>
        {footer}
      </div>
    </React.Fragment>
  );
}

interface IsAuthenticatedProps {
  children: React.ReactChild
}
export function IsAuthenticated(props : IsAuthenticatedProps) {
  const auth = useSelector(state => state.auth.client);
  const pending = useSelector(state => state.auth.pending);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.valid);
  const location = useLocation();

  // Trigger session check on each navigation
  const [checkSessionResult, checkSessionState] = usePromise(async () => {
    return await dispatch(checkSession()).then(unwrapResult);
  }, []);

  // Segment user tracking
  const profile = checkSessionResult?.profile;
  useEffect(() => {
    if (!profile) return;
    Segment.identify(profile);
  }, [profile?.sub]);

  // If user flips to not authenticated (after having done an initial session check)
  // while on a authenticated required route, log them in
  const isRedirecting = !isAuthenticated && !checkSessionState.pending
  useEffect(() => {
    if (isRedirecting) {
      auth.loginWithRedirect({
        appState: {
          returnTo: location.pathname + location.search
        },
        response_type: 'token id_token'
      });
    }
  }, [isRedirecting]);

  if (checkSessionState.pending || isRedirecting) {
    return (
      <React.Fragment>
        <Header />
        <div className="app-content">
          <i className="fa fa-spinner fa-pulse" />
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  if (checkSessionState.error && !isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
}

export function Routes(props : RoutesProps) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(initAuth());
  }, []);

  return (
    <Switch>
      <Route path="/org/:organizationId">
        <IsAuthenticated>
          <TenantScreen {...props} />
        </IsAuthenticated>
      </Route>
      <Route path="/signup">
        <Header />
        <IsAuthenticated>
          <SignupScreen {...props} />
        </IsAuthenticated>
        <Footer />
      </Route>
      <Route path="/invitation/accept">
        <Header />
        <IsAuthenticated>
          <InvitationAcceptScreen/>
        </IsAuthenticated>
        <Footer />
      </Route>
      <Route path="/login/nemid/callback">
        <NemIDLoginCallbackScreen />
      </Route>
      <Route path="/login">
        <Header />
        <div className="app-content">
          <LoginScreen />
        </div>
        <Footer />
      </Route>
      <Route path="/callback">
        <Header />
        <div className="app-content">
          <LoginCallbackScreen {...props} />
        </div>
        <Footer />
      </Route>
      <Route path="/refreshlogin/:organizationId?">
        <Header />
        <LoginRefreshScreen />
        <Footer />
      </Route>
      <Route path="/:environment(test|production)">
        <Header />
        <RedirectScreen />
        <Footer />
      </Route>
      <Route path="/">
        <Header />
        <RedirectScreen />
        <Footer />
      </Route>
    </Switch>
  );
}

export function Router(props : RoutesProps) {
  return (
    <ReduxProvider store={reduxStore}>
      <BrowserRouter>
        <RelayEnvironmentProvider environment={RelayEnvironment}>
          <Routes {...props} />
        </RelayEnvironmentProvider>
      </BrowserRouter>
    </ReduxProvider>
  )
}

export default Router;