import React, {useEffect, useState} from 'react';
import { useField } from 'formik';

import {Application, Domain} from '@app/models';
import {useDispatch, useSelector} from '@app/redux';
import { fetchApplications } from '@app/redux/tenantSlice';
import {translate} from '@app/i18n';
import {isApplicationRealmValid2} from '@app/validators';

interface Props {
  application?: Application,
  domain: Domain | undefined,
  onValid: (valid : boolean) => void
}

export default function ApplicationRealmValidation(props : Props) {
  const {domain} = props;
  const [isValid, setValid] = useState<boolean |undefined>();
  const [{value}] = useField('realm');
  const dispatch = useDispatch();
  const tenant = useSelector(state => state.tenant.tenant)
  const applications = useSelector(state => state.tenant.applications)

  useEffect(() => {
    if (!tenant) return;
    dispatch(fetchApplications({force: false}));
  }, [tenant]);

  useEffect(() => {
    if (applications.state.pending) return;
    if (applications.state.error) return;
    if (!domain) return;

    const otherApplications = props.application ?
      applications.items!.filter((search: Application) =>
        search.realm !== props.application!.realm || search.domain.name !== domain.name) :
      applications.items!;

    setValid(isApplicationRealmValid2(domain, value, otherApplications));
  }, [value, domain, applications]);

  useEffect(() => {
    if (isValid === undefined) return;
    props.onValid(isValid);
  }, [isValid]);

  if (!value) return <React.Fragment />;
  if (!domain) return <React.Fragment />;

  if (applications.state.error) {
    return (
      <div className="alert alert-danger">
        {applications.state.error.message}
      </div>
    )
  }

  return (
    <div className="help-block">
      {(applications.state.pending || isValid === undefined) ? (
        <i className="fa fa-spinner fa-pulse" />
      ) : isValid ? (
        <i className="fa fa-check" />
      ) : (
        <React.Fragment>
          <i className="fa fa-times" />
          <span>{translate('REALM_EXISTS')}</span>
        </React.Fragment>
      )}
    </div>
  )
}