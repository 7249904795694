import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useReducer, useState } from 'react';

import { useCustomerQuery } from '@app/api/subscription';
import usePermissions from '@app/hooks/usePermissions';
import useTenant from '@app/hooks/useTenant';
import { getErrorString } from '@app/api/helper';
import {singleton as config} from '@app/config';

import Button from '@app/components/Button/Button';
import { StripeElementsProvider } from '@app/stripe';
import PaymentMethodModal from './components/PaymentMethodModal/PaymentMethodModal';

export default function PaymentMethodScreen() {
  const tenant = useTenant();
  const permissions = usePermissions();
  const customer = useCustomerQuery(tenant && permissions?.financials ? tenant : skipToken);
  const [showPaymentMethodModal, togglePaymentMethodModal] = useReducer(value => !value, false);
  const [success, setSuccess] = useState(false);

  const handleSuccess = () => {
    setSuccess(true);
    togglePaymentMethodModal();
  }

  return (
    <div className="flex flex-col gap-[16px]">
      {customer.error ? (
        <div className="alert alert-danger">
          {getErrorString(customer.error) || 'An error occurred'}
        </div>
      ) : null}
      {(!permissions?.financials || customer.isFetching) ? (
        <div><i className="fa fa-spinner fa-pulse fa-2x" /></div>
      ) : (
        <React.Fragment>
          {customer.data?.cardLast4 ? (
            <React.Fragment>
              <div>{customer.data?.cardBrand} - xxxx xxxx xxxx {customer.data?.cardLast4}</div>

              <div>
                <StripeElementsProvider>
                  <Button variant="default" onClick={togglePaymentMethodModal}>
                    {customer.data?.cardLast4 ? 'Change payment method' : 'Add payment method'}
                  </Button>
                  {tenant && (<PaymentMethodModal tenant={tenant} show={showPaymentMethodModal} onHide={togglePaymentMethodModal} onSuccess={handleSuccess} />)}
                </StripeElementsProvider>
              </div>
            </React.Fragment>
          ) : (
            <div>
              No payment method registered. Please <a href={`${config.SUBSCRIPTION_FRONTEND_URL}/${btoa(tenant!.entityId)}/plans`} className="!underline" target="_blank">sign up for a subscription.</a>
            </div>
          )}
        </React.Fragment>
      )}

      {success ? (
        <div className="alert alert-success">
          Payment method updated!
        </div>
      ) : null}
    </div>
  );
}