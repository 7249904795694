import React, { useEffect } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Link, useRouteMatch, Switch, Route, NavLink, useHistory } from "react-router-dom";

import { Application } from "@app/models";
import useTenant from '@app/hooks/useTenant';
import { EditSignaturesApplicationScreenQuery } from './__generated__/EditSignaturesApplicationScreenQuery.graphql';
import SignatureApplicationApiKeys from '../../components/ApplicationEditor/SignaturesApplicationEditor/Sections/ApiKeys';
import IdentityProvidersSection from '../../components/ApplicationEditor/Sections/IdentityProviders';
import DeleteApplicationButton from '../../components/DeleteApplicationButton/DeleteApplicationButton';
import ApplicationForm from '../../components/ApplicationForm';
import DomainField from '../../components/ApplicationEditor/DomainField';
import ApplicationNameField from '../../components/ApplicationEditor/ApplicationNameField';
import { Tag } from '@app/components/Tag';

interface Props {
  parentUrl: string,
  application: Application,
  onDelete: HeaderProps["onDelete"]
}
export default function EditSignaturesApplicationScreen(props: Props) {
  const {parentUrl, application} = props;
  const match = useRouteMatch();
  const tenant = useTenant();
  const history = useHistory();

  const data = useLazyLoadQuery<EditSignaturesApplicationScreenQuery>(
    graphql`
      query EditSignaturesApplicationScreenQuery($id: ID!) {
        tenant(id: $id) {
          applications {
            verifyApplication {
              domain
              realm
            }

            ... ApiKeys_application
          }
        }
      }
    `
  , {
    id: btoa(`Tenant:${tenant?.tenantId}`)
  });

  const existingSignatureApplication =
    data.tenant?.applications.find(search => search.verifyApplication.domain === application.domain.name && search.verifyApplication.realm === application.realm);

  useEffect(() => {
    if (!existingSignatureApplication) {
      history.push(parentUrl);
    }
  }, [parentUrl, existingSignatureApplication]);

  return (
    <React.Fragment>
      <EditSignaturesApplicationScreenHeader {...props} />
      <div className="app-tab-content">
        <Switch>
          <Route path={match.path} exact={true}>
           <div className="max-w-[750px]">
              <ApplicationForm application={application} valid={true}>
                <ApplicationNameField />
                <DomainField domain={application.domain} isCreate={false} onChange={() => {}} />
              </ApplicationForm>
            </div>
          </Route>
          <Route path={`${match.path}/e-ids`}>
            <ApplicationForm application={application} valid={true} formSuccessMessage="Application e-IDs updated">
              <IdentityProvidersSection
                isCreate={false}
                application={application}
                help={(
                  <p>
                    These e-IDs define the authentication choices a user can make when signing a document.
                  </p>
                )}
              />
            </ApplicationForm>
          </Route>
          <Route path={`${match.path}/api-keys`}>
            {existingSignatureApplication ? (
              <SignatureApplicationApiKeys application={existingSignatureApplication} />
            ) : (
              <div className="alert alert-danger">
                An error occurred. Could not find signature application in Criipto Signatures API.
              </div>
            )}
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
}

interface HeaderProps {
  parentUrl: string,
  application: Application,
  onDelete: () => void,
}
export function EditSignaturesApplicationScreenHeader(props: HeaderProps) {
  const {parentUrl, application, onDelete} = props;
  const match = useRouteMatch();
  return (
    <div className="app-content-header with-tabs">
      <div className="breadcrumb">
        <Link to={parentUrl}>Applications</Link>
        <i className="fa fa-angle-right" />
        <div>Signature Application Details</div>
      </div>

      <div className="flex justify-between">
        <div className="flex flex-row items-center gap-[10px]">
          <h1>{application?.name}</h1>
          <Tag>Criipto Signatures</Tag>
        </div>
        <DeleteApplicationButton onDelete={onDelete} application={application} autoWidth />
      </div>

      <div className="app-content-tabs">
        <ul>
          <li>
            <NavLink to={match.url} exact={true} activeClassName="active">General</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/e-ids`} activeClassName="active">e-IDs</NavLink>
          </li>
          <li>
            <NavLink to={`${match.url}/api-keys`} activeClassName="active">API</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}