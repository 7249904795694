import React, {useEffect, useRef} from 'react';
import {omit} from 'lodash';
import Tooltip from '@components/Tooltip';

import './Checkbox.scss';

interface Props extends Omit<React.HTMLProps<HTMLSpanElement>, 'onChange'> {
  id?: string
  checked: boolean
  indeterminate?: boolean,
  onChange?: (checked: boolean) => void,
  tooltip?: string
}
export default function Checkbox(props: Props) {
  const checkbox = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkbox.current) {
      checkbox.current.indeterminate = !props.checked && props.indeterminate || false;
    }
  }, [props.checked, props.indeterminate]);

  const handleClick = (event : React.MouseEvent) => {
    if (!props.onChange) return;
    props.onChange(!checkbox.current?.checked);
  };

  const rendered = (
    <span className="criipto-checkbox" {...omit(props, 'id', 'checked', 'indeterminate', 'onChange')} onClick={handleClick}>
      <input type="checkbox" id={props.id} checked={props.checked} ref={checkbox} readOnly />

      <span className="checkbox__control">
        {props.checked ? (
          <i className="fas fa-check" />
        ) : props.indeterminate ? (
          <i className="fas fa-minus" />
        ) : null}
      </span>
    </span>
  );

  if (props.tooltip) {
    return (
      <Tooltip id={`${props.id}_tooltip`} tooltip={props.tooltip}>
        {rendered}
      </Tooltip>
    );
  }
  return rendered;
}