import React, {useState} from 'react';
import cx from 'classnames';

import { getErrorString } from '@app/api/helper';
import { usePlansQuery, Currency, Period, PricedPlanRendition } from '@app/api/subscription';
import Button from '@app/components/Button/Button';

export type PlanChoice = {plan: PricedPlanRendition, currency: Currency, period: Period};
interface Props {
  onSelect: (choice: PlanChoice) => void,
  selected?: PlanChoice
}

export default function PlanSelection(props: Props) {
  const {onSelect, selected} = props;

  const plansResponse = usePlansQuery();
  const [currency, setCurrency] = useState<Currency>(selected?.currency ?? 'dkk');
  const [period, setPeriod] = useState<Period>(selected?.period ?? 'month');
  const [selectedPlan, setPlan] = useState<PricedPlanRendition | null>(selected?.plan ?? null);

  if (plansResponse.isLoading) return null;
  if (plansResponse.error) return <div className="alert alert-danger">{getErrorString(plansResponse.error)}</div>;

  const defaultPeriodClass = "flex items-center justify-center py-2 px-8 cursor-pointer"
  const selectedPeriodClass = "bg-primary-900 text-white";
  const plans = plansResponse.data!.filter(p => "volume" in p) as PricedPlanRendition[];

  const handlePlan = (plan: PricedPlanRendition) => {
    setPlan(plan);
    onSelect({plan, currency, period});
  };

  const handlePeriod = (period: Period) => {
    setPeriod(period);
    if (selectedPlan) onSelect({plan: selectedPlan, currency, period});
  }

  const handleCurrency = (currency: Currency) => {
    setCurrency(currency);
    if (selectedPlan) onSelect({plan: selectedPlan, currency, period});
  }

  return (
    <React.Fragment>
      <div className="flex flex-row justify-between">
        <div className="hidden lg:block">&nbsp;</div>
        <div className="rounded-full flex flex-row border border-primary-900 ">
          <div className={cx(defaultPeriodClass, 'rounded-l-full', {[selectedPeriodClass]: period === 'month'})} onClick={() => handlePeriod('month')}>
            Monthly
          </div>
          <div className={cx(defaultPeriodClass, 'rounded-r-full', {[selectedPeriodClass]: period === 'year'})} onClick={() => handlePeriod('year')}>
            Annual - 10% off
          </div>
        </div>
        <div>
          <select className="form-control" value={currency} onChange={(event) => handleCurrency(event.target.value as Currency)}>
            <option value="dkk">Prices in DKK</option>
            <option value="eur">Prices in EUR</option>
          </select>
        </div>
      </div>
      <div className="mt-16 mb-16 flex flex-row gap-[20px]">
        {plans.map(plan => (
          <div key={plan.id} className="flex-1 rounded-lg border border-primary-900 gap-[16px] flex flex-col cursor-pointer" style={{background: '#F2F1ED'}} onClick={() => handlePlan(plan)}>
            <h2 className="border-b border-primary-900 border-gray-600 text-3xl p-6 text-center font-bold">{plan.name}</h2>
            <h3 className="text-4xl text-center">
              {currency.toUpperCase()} <strong>{plan[period][currency].price.toLocaleString()}</strong> per {period}
            </h3>
            <p className="!m-0 text-2xl px-6">
              {plan.volume.toLocaleString()} logins or native signatures monthly
            </p>
            <p className="!m-0 text-xl px-6">
              {currency.toUpperCase()} {plan[period][currency].unitprice} per transaction if you use more than {plan.volume.toLocaleString()} transactions.
            </p>
            <a className="!m-0 text-lg px-6" href="https://www.criipto.com/pricing#fees-block" target="_blank">Fees for underlying national/bank e-ID billed separately</a>

            {plan === selectedPlan ? <i className="fa fa-check self-center justify-self-center" /> : (
              <Button variant="default" className="ml-6 mr-6 mb-6 self-center max-w-[200px]" autoWidth>
                Choose
              </Button>
            )}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}