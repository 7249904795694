import { createSlice, createAsyncThunk, ActionReducerMapBuilder, unwrapResult } from '@reduxjs/toolkit';

import {ApiRoot, deserializer, ScopedClaims, GaussOrganization, Tenant, getHref, getTemplateHref, ErrorData, Tenants, ClaimScope } from '@app/models';
import {REL, GAUSS_REL} from '@app/constants';
import {singleton as config} from '@app/config';
import {
  ApiRequestState, defaultApiRequestState,
  pendingApiRequestState, successApiRequestState, errorApiRequestState,
  dispatchVerifyRequest, dispatchGaussRequest
} from '@redux/apiSlice';
import {RootState} from './store';

type Deferred<T> = {
  promise: Promise<T>,
  requestId: string,
  resolve: (value: T) => void,
  reject: (error: Error) => void
}

function defer<T>(requestId : string) : Deferred<T> {
  const deferred : Partial<Deferred<T>> = {
    requestId
  };
  deferred.promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });
  return deferred as Deferred<T>;
}

interface DiscoveryState {
  verify: {
    root?: ApiRoot
    deferredRoot?: Deferred<ApiRoot>,
    tenants?: Tenant[]
    deferredTenants?: Deferred<Tenant[]>
    state: ApiRequestState
  },
  gauss: {
    root?: ApiRoot,
    deferredRoot?: Deferred<ApiRoot>,
    scopedClaims?: ScopedClaims,
    verifyScopedClaims?: ScopedClaims,
    subscriptionScopedClaims?: ScopedClaims,
    deferredScopedClaims?: Deferred<[ScopedClaims, ScopedClaims]>
    organizations?: GaussOrganization[],
    state: ApiRequestState
  },
};
type CacheArgs = {
  force?: boolean
}

export class NoScopedClaimsError extends Error {
  constructor(message?: string) {
    super(message); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

export const discoverVerify = createAsyncThunk<ApiRoot, CacheArgs, {state: RootState}>(
  'discovery/discoverVerify',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const {verify} = state.discovery;

    if (!args.force && verify.root) {
      return verify.root;
    }

    if (!args.force && verify.deferredRoot && verify.deferredRoot.requestId !== thunkAPI.requestId) {
      return verify.deferredRoot.promise;
    }

    return await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'GET',
        url: `${config.apiBase}/`
      },
      deserializer(ApiRoot)
    );
  }
);

export const fetchTenants = createAsyncThunk<Tenant[], CacheArgs, {state: RootState}>(
  'discovery/fetchTenants',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const {verify} = state.discovery;

    if (verify.tenants && !args.force) {
      return verify.tenants;
    }

    if (!args.force && verify.deferredTenants && verify.deferredTenants.requestId !== thunkAPI.requestId) {
      return verify.deferredTenants.promise;
    }

    const root = await thunkAPI.dispatch(discoverVerify(args)).then(unwrapResult);

    const tenants = await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'GET',
        url: getHref(root, REL.TENANTS)
      },
      deserializer(Tenants)
    );

    return tenants.tenants;
  }
);

export const discoverGauss = createAsyncThunk<ApiRoot, CacheArgs, {state: RootState}>(
  'discovery/discoverGauss',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const {gauss} = state.discovery;

    if (gauss.root && !args.force) {
      return gauss.root;
    }

    if (!args.force && gauss.deferredRoot && gauss.deferredRoot.requestId !== thunkAPI.requestId) {
      return gauss.deferredRoot.promise;
    }

    return await dispatchGaussRequest(
      thunkAPI.dispatch,
      {
        method: 'GET',
        url: `${config.gaussBase}/`
      },
      deserializer(ApiRoot)
    );
  }
);

export const fetchScopedClaims = createAsyncThunk<[ScopedClaims, ScopedClaims], CacheArgs, {state: RootState}>(
  'discovery/fetchScopedClaims',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const {gauss} = state.discovery;

    if (gauss.verifyScopedClaims && gauss.subscriptionScopedClaims && !args.force) {
      return [gauss.verifyScopedClaims, gauss.subscriptionScopedClaims];
    }

    if (!args.force && gauss.deferredScopedClaims && gauss.deferredScopedClaims.requestId !== thunkAPI.requestId) {
      return gauss.deferredScopedClaims.promise;
    }

    const root = await thunkAPI.dispatch(discoverGauss(args)).then(unwrapResult);
    const verifyHref = getTemplateHref(root, GAUSS_REL.SCOPED_CLAIMS, {
      "application": btoa(config.gaussAppId)
    }, true);

    if (!verifyHref) {
      return thunkAPI.rejectWithValue(new NoScopedClaimsError("No scoped claims found in gauss root"));
    }

    const subscriptionHref = getTemplateHref(root, GAUSS_REL.SCOPED_CLAIMS, {
      "application": btoa(config.subscriptionGaussAppId)
    }, false)!;

    const [verifyClaims, subscriptionClaims] = await Promise.all([
      dispatchGaussRequest(
        thunkAPI.dispatch,
        {
          method: 'GET',
          url: verifyHref
        },
        deserializer(ScopedClaims)
      ),
      dispatchGaussRequest(
        thunkAPI.dispatch,
        {
          method: 'GET',
          url: subscriptionHref
        },
        deserializer(ScopedClaims)
      )
    ]);

    return [verifyClaims, subscriptionClaims];
  }
);

export const fetchOrganizations = createAsyncThunk<GaussOrganization[], CacheArgs, {state: RootState}>(
  'discovery/fetchOrganizations',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const {gauss} = state.discovery;

    if (gauss.organizations && !args.force) {
      return gauss.organizations;
    }

    const tenants = await thunkAPI.dispatch(fetchTenants(args)).then(unwrapResult);
    if (!tenants.length) return [];

    const scopedClaims = await thunkAPI.dispatch(fetchScopedClaims(args)).then(unwrapResult).catch<undefined>(err => {
      if (err instanceof NoScopedClaimsError) {
        return undefined;
      }
      throw err;
    });

    if (!scopedClaims || !scopedClaims[0]) return [];

    let scopes = scopedClaims[0].claimScopes;
    let scopeByEntityId = scopes.reduce((acc: {[key: string]: ClaimScope}, scope) => {
      acc[scope.organization.entityIdentifier] = scope;
      return acc;
    }, {});

    return tenants?.map((tenant) => {
      let claimScope = scopeByEntityId[tenant.entityId];
      claimScope.organization.userClaims = claimScope.userClaims;
      return claimScope.organization;
    });
  }
);

export const initialState: DiscoveryState = {
  verify: {
    state: {
      ...defaultApiRequestState,
      pending: false
    }
  },
  gauss: {
    state: {
      ...defaultApiRequestState,
      pending: false
    }
  }
};
export const DiscoverySlice = createSlice({
  name: 'discovery',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    resetDiscovery: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    addRootHandling<typeof discoverVerify>(builder, discoverVerify, 'verify');
    addRootHandling<typeof discoverGauss>(builder, discoverGauss, 'gauss');
    addAsyncHandling<typeof fetchOrganizations>(builder, fetchOrganizations, 'gauss', 'organizations');

    builder.addCase(fetchTenants.pending, (state, {meta}) => {
      return {
        ...state,
        verify: {
          ...state.verify,
          deferredTenants: state.verify.deferredTenants || defer<Tenant[]>(meta.requestId),
          state: pendingApiRequestState
        }
      };
    })
    builder.addCase(fetchTenants.fulfilled, (state, { payload }) => {
      if (state.verify.deferredTenants) state.verify.deferredTenants.resolve(payload);
      return {
        ...state,
        verify: {
          ...state.verify,
          tenants: payload,
          deferredTenants: undefined,
          state: successApiRequestState
        }
      };
    });
    builder.addCase(fetchTenants.rejected, (state, { error }) => {
      if (state.verify.deferredTenants) state.verify.deferredTenants.reject(new Error(error.message));
      return {
        ...state,
        verify: {
          ...state.verify,
          root: undefined,
          deferredTenants: undefined,
          state: errorApiRequestState(new Error(error.message))
        }
      };
    });

    builder.addCase(fetchScopedClaims.pending, (state, {meta}) => {
      return {
        ...state,
        gauss: {
          ...state.gauss,
          deferredScopedClaims: state.gauss.deferredScopedClaims || defer<[ScopedClaims, ScopedClaims]>(meta.requestId),
          state: pendingApiRequestState
        }
      };
    })
    builder.addCase(fetchScopedClaims.fulfilled, (state, { payload }) => {
      if (state.gauss.deferredScopedClaims) state.gauss.deferredScopedClaims.resolve(payload);
      return {
        ...state,
        gauss: {
          ...state.gauss,
          scopedClaims: payload[0],
          verifyScopedClaims: payload[0],
          subscriptionScopedClaims: payload[1],
          deferredScopedClaims: undefined,
          state: successApiRequestState
        }
      };
    });
    builder.addCase(fetchScopedClaims.rejected, (state, { error, payload }) => {
      let handledError = (error.message === 'Rejected') ? payload as Error : new Error(error.message);
      if (state.gauss.deferredScopedClaims) state.gauss.deferredScopedClaims.reject(handledError);
      return {
        ...state,
        gauss: {
          ...state.gauss,
          root: undefined,
          deferredScopedClaims: undefined,
          state: errorApiRequestState(handledError)
        }
      };
    });
  }
});

function addRootHandling<A extends typeof discoverVerify | typeof discoverGauss>(
  builder : ActionReducerMapBuilder<DiscoveryState>,
  action : A,
  key: keyof DiscoveryState
) {
  builder.addCase(action.pending, (state, {meta}) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        deferredRoot: state[key].deferredRoot || defer<ApiRoot>(meta.requestId),
        state: pendingApiRequestState
      }
    };
  })
  builder.addCase(action.fulfilled, (state, { payload }) => {
    if (state[key].deferredRoot) state[key].deferredRoot?.resolve(payload);

    return {
      ...state,
      [key]: {
        ...state[key],
        root: payload,
        deferredRoot: undefined,
        state: successApiRequestState
      }
    };
  });
  builder.addCase(action.rejected, (state, { error }) => {
    if (state[key].deferredRoot) state[key].deferredRoot?.reject(new Error(error.message));
    return {
      ...state,
      [key]: {
        ...state[key],
        root: undefined,
        deferredRoot: undefined,
        state: errorApiRequestState(new Error(error.message))
      }
    };
  });
}

function addAsyncHandling<A extends typeof fetchScopedClaims | typeof fetchTenants | typeof fetchOrganizations>(
  builder : ActionReducerMapBuilder<DiscoveryState>,
  action : A,
  key: keyof DiscoveryState,
  dataKey: string
) {
  builder.addCase(action.pending, (state) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        state: pendingApiRequestState
      }
    };
  })
  builder.addCase(action.fulfilled, (state, { payload }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        [dataKey]: payload,
        state: successApiRequestState
      }
    };
  });
  builder.addCase(action.rejected, (state, { error }) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        [dataKey]: undefined,
        state: errorApiRequestState(new Error(error.message))
      }
    };
  });
}

export const { resetDiscovery } = DiscoverySlice.actions;
export default DiscoverySlice;