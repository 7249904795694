import React, {useCallback, useEffect} from 'react';
import {identity} from 'lodash';;

import {useSelector, useDispatch} from '@app/redux';
import {fetchDomains, updateProviderSettings} from '@redux/tenantSlice';
import {translate} from '@app/i18n';

import {
  GermanSofortConfiguration,
  Tenant, DetailedIdentityProvider
} from '@app/models';

import {REL, CONTENT_TYPE} from '@app/constants';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess} from '@components/Form';

import EnvironmentDialog from '../EnvironmentDialog';
import { unwrapResult } from '@reduxjs/toolkit';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  configuration: GermanSofortConfiguration,
  provider: DetailedIdentityProvider,
  apiRoot: Tenant
}

export interface FormValues extends GermanSofortConfiguration {
  userId : string
  projectId : string
  projectPassword : string
}

export function DESofortProvider(props : Props) {
  const tenant = props.apiRoot;
  const {configuration} = props;

  const initialValues : FormValues = {
    userId: configuration?.userId,
    projectId: configuration?.projectId,
    projectPassword: '',
    deserialize: (data) => data
  };

  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";
  const dispatch = useDispatch();
  const domains = useSelector(state => state.tenant.domains);

  useEffect(() => {
    dispatch(fetchDomains({force: false}));
  }, []);

  const saveProvider = async (values : FormValues) => {
    return dispatch(updateProviderSettings({
      rel: 'DE_SOFORT_SETTINGS',
      values
    })).then(unwrapResult);
  };

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return saveProvider(values);
      }}
      data-test-id="form"
    >
      {({isPending, isSuccess, error}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p className="green">⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_DE_SOFORT_WHAT_TO_AVOID')}</em></p>
          </EnvironmentDialog>
          <EnvironmentDialog environment="PRODUCTION" heading={(
            <React.Fragment>
              <h1>
                <strong>{translate('INFO_DE_SOFORT_PROJECT')}</strong>
              </h1>
              <br />
              <h3>{translate('INFO_DE_SOFORT_CALLBACK_URL_PROD')}</h3>
            </React.Fragment>
          )}>
            {domains.items?.filter(domain => domain.production).map(domain => (
              <p className="green" key={domain.name}>
                https://{domain.name}/DESofort/Callback?state=-USER_VARIABLE_0_URLENCODE-
              </p>
            ))}
          </EnvironmentDialog>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} German Sofort Identification
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label={translate('LABEL_DE_SOFORT_USERID')}
                name="userId"
                placeholder={translate('LABEL_DE_SOFORT_USERID')}
              />
              <InputField
                type="text"
                label={translate('LABEL_DE_SOFORT_PROJECTID')}
                name="projectId"
                placeholder={translate('LABEL_DE_SOFORT_PROJECTID')}
              />
              <InputField
                type="password"
                label={translate('LABEL_DE_SOFORT_PROJECTPASSWORD')}
                name="projectPassword"
                placeholder={translate('LABEL_DE_SOFORT_PROJECTPASSWORD')}
              />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your german sofort settings have been updated`} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_DE_SOFORT_PROJECT')}</p>
              <p>{translate(isProduction ? 'INFO_DE_SOFORT_CALLBACK_URL_PROD': 'INFO_DE_SOFORT_CALLBACK_URL_TEST')}</p>
              {domains.items?.filter(domain => domain.production === isProduction).map(domain => (
                <p key={domain.name}>
                  https://{domain.name}/DESofort/Callback?state=-USER_VARIABLE_0_URLENCODE-
                </p>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}
