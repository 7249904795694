import React from 'react';
import { useField } from 'formik';

import {translate} from '@app/i18n';

interface Props {
  disabled?: boolean
}
export default function VatField(props : Props) {
  const [{value}, , {setValue}] = useField('vatIdentifier');

  const handleChange = (key : string, event : React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [key]: event.target.value});
  };

  return (
    <div className="form-group">
      <label className="control-label">{translate('LABEL_DK_MITID_VAT_IDENTIFIER')}</label>
      <div className="vat-identifier">
        <input type="text" className="form-control" value={value?.vatCountryCode || ''} onChange={(event) => handleChange('vatCountryCode', event)}  placeholder="Country code" minLength={2} maxLength={2} required disabled={props.disabled} />
        <input type="text" className="form-control" value={value?.vatNumber || ''} onChange={(event) => handleChange('vatNumber', event)} placeholder="VAT Number" required disabled={props.disabled} />
      </div>
    </div>
  )
}