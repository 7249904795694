import React, { useEffect } from 'react';

import {Domain, DomainConfiguration} from '@app/models';
import {Switch, Form, FormError, FormSuccess} from '@components/Form';
import { translate } from '@app/i18n';
import Button, { AnchorButton } from '@components/Button';
import { useDispatch } from '@app/redux';
import {fetchDomainConfiguration, updateDomainConfiguration} from '@redux/tenantSlice';
import { FormikValues } from 'formik';
import usePromise from '@app/hooks/usePromise';
import { unwrapResult } from '@reduxjs/toolkit';

interface Props {
  domain: Domain
}

export default function DomainSSOSection(props : Props) {
  const dispatch = useDispatch();
  const handleSubmit = async (values : FormikValues) => {
    await dispatch(updateDomainConfiguration([props.domain, values as DomainConfiguration]));
  };

  const [, fetchDomainState] = usePromise(async () => {
    await dispatch(fetchDomainConfiguration({
      cache: true,
      domain: props.domain
    })).then(unwrapResult);
  }, []);

  if (!props.domain.configuration?.sso) return <i className="fa fa-spinner fa-pulse fa-2x" />;

  return (
    <React.Fragment>
      <Form initialValues={props.domain.configuration.sso} onSubmit={handleSubmit} data-test-id="form" key="domain_sso_editor">
        {({isPending, error, isSuccess}) => (
          <React.Fragment>
            <Switch<DomainConfiguration["sso"]> name="enableForOAuth2" label={translate('INFO_DOMAIN_SSO_enableForOAuth2')} />
            <Switch<DomainConfiguration["sso"]> name="enableForWSFederation" label={translate('INFO_DOMAIN_SSO_enableForWSFederation')} />

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={'SSO settings updated'} />
            )}

            <div className="button-group flex-start" style={{marginTop: '20px'}}>
              <AnchorButton variant="default" autoWidth href="https://docs.criipto.com/verify/guides/sso/" target="_blank">
                Learn more about SSO
              </AnchorButton>
              <Button variant="primary" type="submit" working={isPending} autoWidth>
                Save SSO settings
              </Button>
            </div>
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  )
}

DomainSSOSection.Help = <p>{translate('INFO_DOMAIN_SSO')}</p>;