/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ApiKeyModal_apiKey = {
    readonly clientId: string;
    readonly clientSecret: string | null;
    readonly " $refType": "ApiKeyModal_apiKey";
};
export type ApiKeyModal_apiKey$data = ApiKeyModal_apiKey;
export type ApiKeyModal_apiKey$key = {
    readonly " $data"?: ApiKeyModal_apiKey$data;
    readonly " $fragmentRefs": FragmentRefs<"ApiKeyModal_apiKey">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApiKeyModal_apiKey",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clientSecret",
      "storageKey": null
    }
  ],
  "type": "ApplicationApiKey",
  "abstractKey": null
};
(node as any).hash = 'bd017f691493bb4d99f120719517e4b8';
export default node;
