import React from 'react';

import {
  SelectedGaussApplicationRoleGrant, SelectedGaussServicePermission,
  SelectedGaussApplicationRole, GaussApplicationRole,
  GaussServicePermission
} from '@app/models';

interface PermissionsProps {
  applicationRoleGrants: SelectedGaussApplicationRoleGrant[]
  servicePermissions: SelectedGaussServicePermission[]
  selectable: boolean
  onSelect?: (item: GaussApplicationRole | GaussServicePermission, selected: boolean) => void
}

export default function Permissions(props: PermissionsProps) {
  const {
    applicationRoleGrants,
    servicePermissions,
    selectable,
    onSelect
  } = props;
  return (
    <React.Fragment>
      {applicationRoleGrants.map(roleGrant => (
        <React.Fragment key={roleGrant.id}>
          {roleGrant.applicationRoles.map(role => (
            <label className="font-normal cursor-pointer m-0">
              <input
                type="checkbox"
                className="mr-[5px]"
                disabled={!selectable}
                checked={(role as SelectedGaussApplicationRole).selected}
                onChange={(event) => onSelect ? onSelect(role, event.target.checked) : null}
              />
              {role.displayName}
            </label>
          ))}
        </React.Fragment>
      ))}
      {servicePermissions.map(permission => (
        <label className="font-normal cursor-pointer m-0">
          <input
            type="checkbox"
            className="mr-[5px]"
            disabled={!selectable}
            checked={(permission as SelectedGaussServicePermission).selected}
            onChange={(event) => onSelect ? onSelect(permission, event.target.checked) : null}
          />
          {permission.displayName}
        </label>
      ))}
    </React.Fragment>
  );
}