import _ from 'lodash';

import { deserializer,  DomainAvailability, getTemplateHref } from '@app/models';
import { REL, RESERVED_DOMAINS } from '@app/constants';
import { singleton as config } from '@app/config';
import { default as reduxStore } from '@app/redux/store';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';
import { discoverVerify } from '@app/redux/discoverySlice';
import { unwrapResult } from '@reduxjs/toolkit';

export class DomainRegistration {
  canonicalize(name: string) {
    if (name) {
      return name
        .trim()
        .toLowerCase()
        .replace(/\s/g, "-")
        .replace(/[^a-z0-9\-]/g, "");
    }

    return null;
  }

  async checkAvailability(domainName: string): Promise<DomainAvailability> {
    if (!domainName || RESERVED_DOMAINS.includes(domainName)) {
      throw new Error("Invalid domain name");
    }

    await reduxStore.dispatch(discoverVerify({force: false})).then(unwrapResult);
    const verifyRoot = reduxStore.getState().discovery.verify.root;

    return await dispatchVerifyRequest(
      reduxStore.dispatch,
      {
        method: 'GET',
        url: getTemplateHref(verifyRoot!, REL.DNS_AVAILABLE, {
          domain: domainName
        })!
      },
      deserializer(DomainAvailability)
    );
  }

  findAvailable(name : string, attempt : number = 0) : Promise<string> {
    let domainName = `${this.canonicalize(name)}-test`;
    if (attempt > 0) domainName = `${domainName}-${attempt}`;
    domainName = `${domainName}.${config.easyIdDns}`;

    if (attempt > 10) throw new Error('Unable to find unique domain name');

    return this.checkAvailability(domainName).then(available => {
      if (available.available) return domainName;
      return this.findAvailable(name, attempt + 1);
    }).catch((err : any) => {
      return this.findAvailable(name, attempt + 1);
    });
  }
}

export default DomainRegistration;