import {TENANT_ID_PREFIX, PERMISSION_CLAIM_TYPE, ROLE, ROLE_CLAIM_TYPE, PERMISSION} from './constants';
import _, { replace } from 'lodash';

import {deserializer, serializer, multiDeserializer, propMultiDeserializer} from './models/utils';
export {deserializer, serializer, multiDeserializer, propMultiDeserializer};
import {IdentityProvider, DetailedIdentityProvider} from './providers';
export {IdentityProvider, DetailedIdentityProvider};

interface WithLinksRendition {
  links: LinkRendition[]
}
interface WithLinkTemplatesRendition extends WithLinksRendition {
  linkTemplates: LinkRendition[]
}
export type LinkRendition = {
  rel: string;
  href: string;
}

export function getOptionalHref(on: WithLinksRendition | Linked, rel: string) : string | null {
  return _.find(on.links, x => x.rel === rel)?.href || null;
}

export function getHref<T extends boolean>(on: WithLinksRendition | Linked, rel: string) : string {
  const href = _.find(on.links, x => x.rel === rel)?.href;
  if (!href) {
    throw new Error(`Unexpected empty href for ${rel} on ${on}`);
  }
  return href;
}

export function getTemplateHref(on: WithLinkTemplatesRendition | ApiRoot, rel: string, replacements: {[key: string]: string}, allowEmpty: boolean = false) : string | null {
  const directHref = getOptionalHref(on, rel);
  if (directHref) {
    return directHref;
  }

  let href = _.find(on.linkTemplates, x => x.rel === rel)?.href;
  if (href) {
    for (const key of Object.keys(replacements)) {
      href = href.replace("{" + key + "}", replacements[key]);
    }
    return href;
  }

  if (allowEmpty === false) {
    throw new Error(`Unexpected empty href for ${rel} on ${on}`);
  }

  return null;
}

export abstract class Linked {
  links: Link[] = [];

  deserialize(data: any) {
    this.links = data.links
      ? multiDeserializer(Link)(data.links)
      : [];
    return this;
  }

  getHref(rel: string) {
    return getHref(this, rel);
  }
}

export class Link {
  rel: string;
  href: string;

  deserialize(data: LinkRendition): this {
    this.rel = data.rel;
    this.href = data.href;
    return this;
  }
}

export class RestorationBundle<T> {
  public error: ErrorData;

  constructor(public state: string, public key: string, public data: T) { }
}

export class ErrorData {
  message: string | undefined;

  constructor(message?: string) {
    this.message = message;
  }

  deserialize(data: any): this {
    this.message = data.message;
    return this;
  }
}

export class subscribeStatusMessage {
  message: string | undefined;

  constructor(message?: string) {
    this.message = message;
  }

  deserialize(data: any): this {
    this.message = data.message;
    return this;
  }
}

declare interface Window { analytics: any; }

export class Billable {
  billable: boolean;
  verifyBillable: boolean;
  signaturesBillable: boolean;

  deserialize(data: any) {
    this.billable = data.billable;
    this.verifyBillable = data.verifyBillable;
    this.signaturesBillable = data.signaturesBillable;
    return this;
  }
}

export interface BackofficeEnvironmentRendition {
  danishCprServices: {
    pocesAddressEnabled: boolean
    companySignatoryEnabled: boolean
  }
}
export class Backoffice {
  billable: boolean;
  verifyBillable: boolean;
  verifyBillableUntil: string;
  signaturesBillable: boolean;
  signaturesBillableUntil: string;
  test: BackofficeEnvironmentRendition
  prod: BackofficeEnvironmentRendition
}

export class Dashboard {
  keenProjectId: string;
  keenReadKey: string;

  deserialize(data: any) {
    this.keenProjectId = data.keenProjectId;
    this.keenReadKey = data.keenReadKey;
    return this;
  }
}

export class IpLookup {
  actual: string;
  expected: string;

  matches() {
    return this.actual === this.expected;
  }

  deserialize(data: any): this {
    this.actual = data.actual;
    this.expected = data.expected;
    return this;
  }
}
export class Domain extends Linked {
  name: string;
  production: boolean;
  domain: string;
  companyName: string;

  configuration?: DomainConfiguration | undefined;
  tokenSigningKeysList?: TokenSigningKeysList | undefined;

  constructor(name?: string, production?: boolean, domain?: string, companyName?: string) {
    super();
    this.name = name || '';
    this.production = production || false;
    this.domain = domain || '';
    this.companyName = companyName || '';
  }

  deserialize(data: any) {
    super.deserialize(data);
    this.name = data.name;
    this.production = data.production;
    this.domain = data.domain;
    this.companyName = data.companyName;
    return this;
  }

  clone(data? : Partial<Domain>) {
    const clone = new Domain();
    clone.name = this.name;
    clone.production = this.production;
    clone.links = this.links;
    clone.configuration = this.configuration;
    clone.tokenSigningKeysList = this.tokenSigningKeysList;

    if (data) {
      for (let [key, value] of Object.entries(data)) {
        (clone as any)[key] = value;
      }
    }
    return clone;
  }
}


class GaussApplication extends Linked {
  entityIdentifier: string;
  id: string;
  name: string;

  deserialize(data: any) {
    super.deserialize(data);

    this.entityIdentifier = data.entityIdentifier;
    this.id = data.id;
    this.name = data.name;
    return this;
  }
}

export class GaussOrganization extends Linked {
  entityIdentifier: string;
  id: string;
  name: string;

  userClaims: UserClaim[] = [];

  getTenantId() {
    return btoa(TENANT_ID_PREFIX + this.id);
  }

  deserialize(data: any): this {
    super.deserialize(data);

    this.entityIdentifier = data.entityIdentifier;
    this.id = data.id;
    this.name = data.name;
    return this;
  }

  hasUserRoleClaim(role: string): boolean {
    return this.userClaims &&
      this.userClaims.length !== 0 &&
      this.userClaims.filter(claim => claim.type === "role" && claim.value === role).length !== 0;
  }
}

export interface GaussServicePermission {
  displayName: string
  id: string
}
export interface GaussApplicationRole {
  applicationId: string
  description: string
  displayName: string
  role: string
  roleId: string
}
export interface GaussApplicationRoleGrant {
  id: string
  applicationEntityId: string
  applicationName: string
  applicationRoles: GaussApplicationRole[]
  subscriberId: string
}

export interface GaussUserApplicationRole {
  id: string,
  applicationRoles: {
    applicationId: string
    roleId: string
    links: LinkRendition[]
  }[]
}

export interface GaussOrganizationPendingUser {
  name: string
  email: string
  invitedAt: string
  applicationRoleGrants: GaussApplicationRoleGrant[]
  servicePermissions: GaussServicePermission[]
  links: LinkRendition[]
}

export interface GaussOrganizationUser {
  name: string;
  email: string;
  loginId: string;
  links: LinkRendition[],
  roles: GaussUserApplicationRole[]
  servicePermissions: (GaussServicePermission & {links: LinkRendition[]})[]
}

export type SelectedGaussApplicationRole = GaussApplicationRole & {selected: boolean}
export type SelectedGaussApplicationRoleGrant = GaussApplicationRoleGrant & {applicationRoles: SelectedGaussApplicationRole[]}
export type SelectedGaussServicePermission = GaussServicePermission & {selected: boolean}

export interface GaussInvitationRequest {
  returnUrl: string
  name: string
  contactEmail: string
  emailTemplate: {
    subject: string
    bodyTemplate: string
  }
  invitationCompletionFlow: 'linkInEmail'
  applications: GaussApplicationRoleGrant[]
  servicePermissions: GaussServicePermission[]
}

export const USER_ROLES = ['Owner', 'Admin', 'Developer', 'Custom'] as const;
export type UserRole = typeof USER_ROLES[number];
export type UserRolePermissionMap = {
  [key in UserRole]: '__ALL__' | '__CUSTOM__' | {roles: string[], servicePermissions: string[]}
}
export const ROLE_DESCRIPTION : {[key in UserRole]: string} = {
  Owner: 'Has access to everything, including billing settings',
  Admin: 'Can manage users and all settings except billing',
  Developer: 'Can manage domains, applications and styling but not users or billing',
  Custom: 'Set custom permissions'
};
export const ROLE_TO_PERMISSIONS : UserRolePermissionMap = {
  'Owner': '__ALL__',
  'Admin': {
    roles: [
      'customization-preview',
      'configuration-management',
      'private-key-management',
      'customization',
      'reporting'
      // No financials
    ],
    servicePermissions: [
      '854f67627ecb4d10beedeff43edc9f2a', // User administration
    ]
  },
  'Developer': {
    roles: [
      'customization-preview',
      'configuration-management',
      'private-key-management',
      'customization',
      'reporting'
    ],
    servicePermissions: []
    // No financials no user administration
  },
  'Custom': '__CUSTOM__'
}

export function roleGrantsToRoles(applicationRoleGrants: GaussApplicationRoleGrant[]) {
  return applicationRoleGrants.reduce((memo, roleGrant) => memo.concat(roleGrant.applicationRoles), [] as GaussApplicationRole[]);
}

export function permissionsToRole(
  userPermissions: {roles: string[], servicePermissions: string[]},
  allPermissions: {applicationRoleGrants: GaussApplicationRoleGrant[], servicePermissions: GaussServicePermission[]}
) : UserRole {
  const allRoles = roleGrantsToRoles(allPermissions.applicationRoleGrants).map(r => r.role);

  if (allRoles.every(s => userPermissions.roles.includes(s)) &&
    allPermissions.servicePermissions.every(s => userPermissions.servicePermissions.includes(s.id))) return 'Owner';

  for (const role of USER_ROLES) {
    if (role === 'Owner' || role === 'Custom') continue;
    const set = ROLE_TO_PERMISSIONS[role as UserRole];
    if (set === '__ALL__' || set === '__CUSTOM__') continue;

    const roles = set.roles;
    const servicePermissions = set.servicePermissions;

    if (roles.every(s => userPermissions.roles.includes(s)) &&
      servicePermissions.every(s => userPermissions.servicePermissions.includes(s))) return role;
  }

  return 'Custom';
}

export class statusPageComponents {
  $$hashKey: string;
  created_at: string;
  description: string;
  group: Boolean;
  group_id: string;
  id: string;
  name: string;
  only_show_if_degraded: boolean;
  page_id: string;
  position: number;
  showcase: true
  status: string;
  updated_at: string;

  deserialize(data: any): this {
    this.$$hashKey = data.$$hashKey;
    this.created_at = data.created_at;
    this.description = data.description;
    this.group = data.group;
    this.group_id = data.group_id;
    this.id = data.id;
    this.name = data.name;
    this.only_show_if_degraded = data.only_show_if_degraded;
    this.page_id = data.page_id;
    this.position = data.position;
    this.showcase = data.showcase;
    this.status = data.status;
    this.updated_at = data.updated_at;
    return this;
  }
}

export class UserClaim {
  type: string;
  value: string | any;

  deserialize(data: any): this {
    this.type = data.type;
    this.value = data.value;
    return this;
  }
}

export class ClaimScope extends Linked {
  organization: GaussOrganization;
  userClaims: UserClaim[] = [];
  permissions: IPermissions;

  getClaims(type: string) {
    return this.userClaims.filter(x => x.type === type);
  }

  getPermissions(): IPermissions {
    let permissions = this.getClaims(PERMISSION_CLAIM_TYPE)
      , roles = this.getClaims(ROLE_CLAIM_TYPE)
      , can = (x: string) => roles.some(role => role.value === x);

    return {
      testDomains: can(ROLE.TEST_DOMAINS),
      productionDomains: can(ROLE.PRODUCTION_DOMAINS),
      providers: can(ROLE.ID_ADMIN),
      integration: can(ROLE.APP_ADMIN) || can(ROLE.APP_DEV),
      users: permissions.some(x => x.value === PERMISSION.USER_ADMIN),
      extensibility: can(ROLE.APP_ADMIN) || can(ROLE.APP_DEV),
      criiptoAdmin: false, // Ammended by JWT inspection in routes,
      reporting: false, // Ammended in routes
      financials: false // Ammended in routes
    };
  }

  deserialize(data: any): this {
    super.deserialize(data);

    this.organization = new GaussOrganization().deserialize(data.organization);
    this.userClaims = data.userClaims.map(deserializer(UserClaim));
    this.permissions = this.getPermissions();
    return this;
  }
}

export type Environment = "PRODUCTION" | "TEST"

export class ProductionMode {
  enabled: boolean
}

export interface IPermissions {
  testDomains: boolean;
  productionDomains: boolean;
  providers: boolean;
  integration: boolean;
  users: boolean | undefined;
  extensibility: boolean;
  criiptoAdmin: boolean;
  reporting: boolean | undefined;
  financials: boolean | undefined;
}

export class ScopedClaims {
  application: GaussApplication;
  claimScopes: ClaimScope[] = [];

  deserialize(data: any): this {
    this.application = new GaussApplication().deserialize(data.application);
    this.claimScopes = data.claimScopes.map(deserializer(ClaimScope));
    return this;
  }
}

export class ApiRoot extends Linked {
  linkTemplates: Link[] = [];

  deserialize(data: any): this {
    super.deserialize(data);
    this.linkTemplates = data.linkTemplates
      ? data.linkTemplates.map(deserializer(Link))
      : [];
    return this;
  }

  getTemplateHref(rel: string, replacements: {}) {
    return getTemplateHref(this, rel, replacements);
  }
}

export class Tenant extends ApiRoot {
  tenantId: string;
  entityId: string;

  deserialize(data: any): this {
    super.deserialize(data);
    this.tenantId = data.tenantId;
    this.entityId = data.entityId;
    return this;
  }

  getTemplateHref(rel : string, params?: {}) {
    params = {
      ...params,
      tenantId: this.tenantId
    };

    return super.getTemplateHref(rel, params);
  }
}

export class Tenants {
  tenants: Tenant[];
  deserialize(data: any): this {
    this.tenants =
      data.tenants
        ? data.tenants.map(deserializer(Tenant))
        : [];
    return this;
  }
}

export class AccessRequest extends Linked {
  applicationId: string;
  entityIdentifier: string;
  id: string;

  deserialize(data: any): this {
    super.deserialize(data);
    this.applicationId = data.applicationId;
    this.entityIdentifier = data.entityIdentifier;
    this.id = data.id;
    return this;
  }
}

export class CommandStatus extends Linked {
  status: "Pending" | "Applied" | "Rejected";

  deserialize(data: any): this {
    super.deserialize(data);
    this.status = data.status;
    return this;
  }
}

export class AuthMethod {
  name: string;
  specificMethod: string;

  deserialize(data: any): this {
    this.name = data.name;
    this.specificMethod = data.specificMethod;
    return this;
  }
}



export const validApplicationTags = ["auth0", "signatures", "automatic"] as const;
export type ApplicationTag = typeof validApplicationTags[number];

export class EnabledState { enabled: boolean; }

export class Application extends Linked {
  name: string;
  realm: string;
  cssClass: string;
  returnUrls: string[];
  sessionLifetime: string;
  authMethods: string[];
  frameOrigins: string[];
  tags: ApplicationTag[];
  oAuth2ClientSecret: EnabledState | null;
  oAuth2Config: OAuth2Config;
  domain: Domain;
  errorStrategy: "legacy" | "protocol";
  scopeStrategy: "static" | "dynamic";

  constructor() {
    super();
    this.returnUrls = [];
    this.authMethods = [];
    this.tags = [];
    this.sessionLifetime = "1200";
    this.oAuth2Config = new OAuth2Config();
    this.errorStrategy = "protocol";
    this.realm = `urn:my:application:identifier:${_.random(1000, 9999)}`;
    this.scopeStrategy = "static";
  }

  deserialize(data: any): this {
    super.deserialize(data);
    this.name = data.name || data.realm;
    this.realm = data.realm;
    this.cssClass = data.cssClass;
    this.returnUrls = data.returnUrls;
    this.sessionLifetime = data.sessionLifetime;
    this.authMethods = data.authMethods;
    this.frameOrigins = data.frameOrigins;
    this.tags = data.tags;
    this.oAuth2ClientSecret = data.oAuth2ClientSecret || null;
    this.oAuth2Config = new OAuth2Config().deserialize(data.oAuth2Config);
    this.errorStrategy = data.errorStrategy;
    this.scopeStrategy = data.scopeStrategy || "static";
    return this;
  }

  clone(data : Partial<Application>) {
    const clone = new Application();
    clone.links = this.links;
    clone.name = this.name;
    clone.realm = this.realm;
    clone.cssClass = this.cssClass;
    clone.returnUrls = this.returnUrls;
    clone.sessionLifetime = this.sessionLifetime;
    clone.authMethods = this.authMethods;
    clone.frameOrigins = this.frameOrigins;
    clone.tags = this.tags;
    clone.oAuth2ClientSecret = this.oAuth2ClientSecret;
    clone.oAuth2Config = this.oAuth2Config;
    clone.domain = this.domain;
    clone.errorStrategy = this.errorStrategy;
    clone.scopeStrategy = this.scopeStrategy;

    for (let [key, value] of Object.entries(data)) {
      (clone as any)[key] = value;
    }
    return clone;
  }

  deserializeWithDomain(data: any, domain : Domain) {
    this.domain = domain;
    return this.deserialize(data);
  }

  hasTag(tag: ApplicationTag) {
    return _.includes(this.tags, tag);
  }

  is(application: Application) {
    return this.realm === application.realm && this.domain.name === application.domain.name;
  }

  static deserializeWithDomain(data: any, domain : Domain) : Application {
    const application = new Application();
    application.deserializeWithDomain(data, domain);
    return application;
  }

  static deserializeMultiWithDomain(data: any, prop: string, domain : Domain) : Application[] {
    return data[prop].map((item : any) => Application.deserializeWithDomain(item, domain));
  }
}

export type ApplicationValues = Omit<Application, 'deserialize' | 'deserializeWithDomain' | 'clone' | 'is' | 'hasTag' | 'getHref'>;

export class ClientSecret {
  clientSecret: string;
  deserialize(data: any): this {
    this.clientSecret = data.clientSecret;
    return this;
  }
}

export class ClientSecretMatch {
  clientSecretMatches: boolean;
  deserialize(data: any): this {
    this.clientSecretMatches = data.clientSecretMatches;
    return this;
  }
}

export class OAuth2CallbackStrategy {
  onLocationHash: boolean;
  constructor() {
    this.onLocationHash = false;
  }

  deserialize(data: any): this {
    if (!data) return this;

    this.onLocationHash = data.onLocationHash;
    return this;
  }
}

export class OAuth2Config {
  callbackStrategy: OAuth2CallbackStrategy;
  userInfoResponseStrategy: "plainJson" | "signedJwt" | "fromTokenEndpoint";
  claimPropertyFormat: "compact" | "default" | "namespaced";
  nullClaimValues: "allow" | "remove";

  constructor() {
    this.callbackStrategy = new OAuth2CallbackStrategy();
    this.userInfoResponseStrategy = "fromTokenEndpoint";
    this.claimPropertyFormat = "compact";
    this.nullClaimValues = "allow";
  }

  deserialize(data: any): this {
    if (!data) return this;

    this.callbackStrategy =
      new OAuth2CallbackStrategy().deserialize(data.callbackStrategy);
    this.userInfoResponseStrategy = data.userInfoResponseStrategy;
    this.claimPropertyFormat = data.claimPropertyFormat;
    this.nullClaimValues = data.nullClaimValues ?? "allow";

    return this;
  }
}

export class DomainAvailability {
  available: boolean;

  deserialize(data: any): this {
    this.available = data.available;
    return this;
  }
}

export class GaussOrganizationDetail extends GaussOrganization {
  contactEmail: string;
  contactName: string;

  deserialize(data: any): this {
    super.deserialize(data);

    this.contactEmail = data.contactEmail;
    this.contactName = data.contactName;
    return this;
  }
}

export class CertificateMetadata {
  password: string;
}

export class IdServiceCertificateMetadata extends CertificateMetadata {
  production: boolean;
}

export class TokenSigningKey {
  isActive: boolean;
  isExpired: boolean;
  keyVersion: string;
  validToUI: string;
  validFromUI: string;
  daysToRenwalUI: number;

  certificate = {
    name: "",
    pkcs7: "",
    validFrom: "",
    validTo: "",
  };

  deserialize(data: any) {
    if (!data) return this;
    this.isActive = data.isActive;
    this.isExpired = (new Date(data.certificate.validTo)) < new Date();
    this.keyVersion = data.keyVersion;
    this.validToUI = data.validToUI;
    this.validFromUI = data.validFromUI;
    this.daysToRenwalUI = data.daysToRenwalUI;
    this.certificate.name = data.certificate.name;
    this.certificate.validFrom = data.certificate.validFrom;
    this.certificate.validTo = data.certificate.validTo;
    this.certificate.pkcs7 = data.certificate.pkcs7;
    return this;
  }
}

export class TokenSigningKeysList extends Linked {
  tokenSigningKeys: TokenSigningKey[];

  deserialize(data: any) {
    if (!data) return this;
    super.deserialize(data);
    this.tokenSigningKeys = data.tokenSigningKeys
      ? multiDeserializer(TokenSigningKey)(data.tokenSigningKeys)
      : [];
    return this;
  }
}

export type DomainCertificate = {
  issuedAt: Date
  expiresAt: Date
  issuer: string
  subject: string
};
export class DomainConfiguration {
  sso: {
    enableForWSFederation: boolean;
    enableForOAuth2: boolean;
  }
  certificate: DomainCertificate | null
  dnsConfigured: boolean

  deserialize(data: any) {
    if (!data) return this;
    this.sso = {
      enableForWSFederation: data.singleSignOn.enableForWSFederation,
      enableForOAuth2: data.singleSignOn.enableForOAuth2
    };
    this.dnsConfigured = data.dnsConfigured;
    this.certificate = data.certificate ? {
      issuedAt: new Date(data.certificate.issuedAt),
      expiresAt: new Date(data.certificate.expiresAt),
      issuer: data.certificate.issuer,
      subject: data.certificate.subject
    } : null;
    return this;
  }

  serialize() {
    return {
      singleSignOn: {
        enableForWSFederation: this.sso.enableForWSFederation,
        enableForOAuth2: this.sso.enableForOAuth2,
      }
    };
  }
}

export class CertificateConfiguration {
  name: string;
  validFrom: string;
  validTo: string;
  pkcs7: string;

  deserialize(data: any) {
    if (!data) return this;
    this.name = data.name;
    this.validFrom = data.validFrom;
    this.validTo = data.validTo;
    this.pkcs7 = data.pkcs7;
    return this;
  }
}

export class DutchDigiDDomainConfiguration {
  domain: string;
  pkiOverheidCertificate: CertificateConfiguration;

  deserialize(data: any) {
    if (!data) return this;
    this.domain = data.domain;
    this.pkiOverheidCertificate =
      new CertificateConfiguration().deserialize(data.pkiOverheidCertificate);
    return this;
  }
}

export class SwedishBankIdConfiguration extends CertificateConfiguration { }

export class NorwegianBankIdConfiguration {
  clientId: string;
  clientSecret: string;
  requestAddress: boolean;
  requestEmail: boolean;
  requestPhone: boolean;
  requestSocialNo: boolean;

  deserialize(data: any) {
    if (!data) return this;
    this.clientId = data.clientId;
    this.clientSecret = data.clientSecret;
    this.requestAddress = data.requestAddress;
    this.requestEmail = data.requestEmail;
    this.requestPhone = data.requestPhone;
    this.requestSocialNo = data.requestSocialNo;
    return this;
  }
}

export class NorwegianBankIdOldConfiguration {
  name: string;
  version: string;

  deserialize(data: any) {
    if (!data) return this;
    this.name = data.name;
    this.version = data.version;
    return this;
  }
}

export class NorwegianVippsLoginConfiguration {
  clientId: string;
  clientSecret: string;
  socialNoRequired: boolean;

  deserialize(data: any) {
    if (!data) return this;
    this.clientId = data.clientId;
    this.clientSecret = data.clientSecret;
    this.socialNoRequired = data.socialNoRequired;
    return this;
  }
}

export type PocesCprStrategy = "askuser" | "omit";

export class DanishNemIdConfiguration {
  spIdPid: string;
  logonTo: string;
  cprStrategy: PocesCprStrategy;
  vocesCertificate: CertificateConfiguration;

  deserialize(data: any) {
    if (!data) {
      this.cprStrategy = "askuser";
      this.logonTo = "";
      this.spIdPid = "";
      this.vocesCertificate = new CertificateConfiguration();
      return this;
    }
    this.spIdPid = data.spIdPid;
    this.logonTo = data.logonTo;
    this.cprStrategy = data.cprStrategy;
    this.vocesCertificate = new CertificateConfiguration().deserialize(data.vocesCertificate);
    return this;
  }
}

export class DanishMitIdConfiguration {
  serviceProviderId: string;
  serviceProviderReference: string;
  referenceText: string;
  cprLookupStrategy: PocesCprStrategy;
  cprIsOptional: boolean;
  includePostalAddress: boolean;
  brokerLandingPageDomain: string;
  nemIdDomain: string;
  showOnNemId: boolean;

  deserialize(data: any) {
    if (!data) {
      this.cprLookupStrategy = "askuser";
      this.cprIsOptional = true;
      this.includePostalAddress = false;
      this.showOnNemId = true;
      return this;
    }

    this.serviceProviderId = data.serviceProviderId;
    this.serviceProviderReference = data.serviceProviderReference;
    this.referenceText = data.referenceText;
    this.cprLookupStrategy = data.cprLookupStrategy;
    this.cprIsOptional = data.cprIsOptional;
    this.includePostalAddress = data.includePostalAddress;
    this.brokerLandingPageDomain = data.brokerLandingPageDomain;
    this.nemIdDomain = data.nemIdDomain;
    this.showOnNemId = data.showOnNemId;
    return this;
  }
}

export class DanishMitIdStatus {
  production: string; // BMPS state
  productionServiceProviderState: string // Criipto state
  productionCname: boolean;
  test: string; // BMPS state
  testServiceProviderState: string // Criipto state
  testCname: boolean;

  deserialize(data: any) {
    if (data) {
      this.production = data.production;
      this.productionServiceProviderState = data.productionServiceProviderState;
      this.productionCname = data.productionCname;
      this.test = data.test;
      this.testServiceProviderState = data.testServiceProviderState;
      this.testCname = data.testCname;
    }
    return this;
  }
}

export class DutchDigIdConfiguration {
  domainConfigurations: DutchDigiDDomainConfiguration[];

  deserialize(data: any) {
    if (!data) {
      this.domainConfigurations = [];
      return this;
    }
    let toDomainConfiguration = function (dc: any) {
      let candidate = new DutchDigiDDomainConfiguration();
      if (!dc) { return candidate; }
      return candidate.deserialize(dc);
    }

    this.domainConfigurations = data.pkiOverheidCertificates.map(toDomainConfiguration);
    return this;
  }
}

export class DutchiDINConfiguration {
  clientId: string;
  clientSecret: string;
  deserialize(data: any) {
    if (!data) return this;
    this.clientId = data.clientId;
    this.clientSecret = data.clientSecret;
    return this;
  }
}

export class ItsmeConfiguration {
  clientId: string;
  serviceCode: string;
  requestExtraIdData: boolean;
  requestSecurityData: boolean;

  deserialize(data: any) {
    if (!data) return this;
    this.clientId = data.clientId;
    this.serviceCode = data.serviceCode;
    this.requestExtraIdData = data.requestExtraIdData;
    this.requestSecurityData = data.requestSecurityData;
    return this;
  }
}

export class BelgianeIDConfiguration {
  clientId: string;
  requestPersonalInfo: boolean;
  requestAddress: boolean;
  requestPhoto: boolean;
  requestNationalNumber: boolean;
  skipSummary: boolean;

  deserialize(data: any) {
    if (!data) return this;
    this.clientId = data.clientId;
    this.requestPersonalInfo = data.requestPersonalInfo;
    this.requestAddress = data.requestAddress;
    this.requestPhoto = data.requestPhoto;
    this.requestNationalNumber = data.requestNationalNumber;
    this.skipSummary = data.skipSummary;
    return this;
  }
}

export class GermanSofortConfiguration {
  userId: string
  projectId: string
  projectPassword: string

  deserialize(data: any) {
    if (!data) return this;
    this.userId = data.userId;
    this.projectId = data.projectId;
    this.projectPassword = data.projectPassword;
    return this;
  }
}

export type IdentityConnectionConfiguration =
  SwedishBankIdConfiguration
  | NorwegianBankIdConfiguration
  | NorwegianBankIdOldConfiguration
  | NorwegianVippsLoginConfiguration
  | DanishNemIdConfiguration
  | DanishMitIdConfiguration
  | DutchDigIdConfiguration
  | DutchiDINConfiguration
  | ItsmeConfiguration
  | BelgianeIDConfiguration
  | GermanSofortConfiguration;

export let isDkNemIdConfig = function (x: IdentityConnectionConfiguration): x is DanishNemIdConfiguration {
  return (<DanishNemIdConfiguration>x) !== undefined;
};
export let isDkMitIdConfig = function (x: IdentityConnectionConfiguration): x is DanishMitIdConfiguration {
  return (<DanishMitIdConfiguration>x) !== undefined;
};
export let isSeBankIdConfig = function (x: IdentityConnectionConfiguration): x is SwedishBankIdConfiguration {
  return (<SwedishBankIdConfiguration>x) !== undefined;
};
export let isNoBankIdConfig = function (x: IdentityConnectionConfiguration): x is NorwegianBankIdConfiguration {

  return (<NorwegianBankIdConfiguration>x) !== undefined;
};
export let isNoBankIdOldConfig = function (x: IdentityConnectionConfiguration): x is NorwegianBankIdOldConfiguration {
  return (<NorwegianBankIdOldConfiguration>x) !== undefined;
};
export let isNoVippsLoginConfig = function (x: IdentityConnectionConfiguration): x is NorwegianVippsLoginConfiguration {
  return (<NorwegianVippsLoginConfiguration>x) !== undefined;
};

export let isNlDigIdConfig = function (x: IdentityConnectionConfiguration): x is DutchDigIdConfiguration {
  return (<DutchDigIdConfiguration>x) !== undefined;
};
export let isNliDINConfig = function (x: IdentityConnectionConfiguration): x is DutchiDINConfiguration {
  return (<DutchiDINConfiguration>x) !== undefined;
};
export let isItsmeConfig = function (x: IdentityConnectionConfiguration): x is ItsmeConfiguration {
  return (<ItsmeConfiguration>x) !== undefined;
}
export let isBelgianeIDConfiguration = function (x: IdentityConnectionConfiguration): x is BelgianeIDConfiguration {
  return (<BelgianeIDConfiguration>x) !== undefined;
};
export let isGermanSofortConfiguration = function (x: IdentityConnectionConfiguration): x is GermanSofortConfiguration {
  return (<GermanSofortConfiguration>x) !== undefined;
};

export class IdentityConnection {
  swedishBankId: SwedishBankIdConfiguration;
  norwegianBankId: NorwegianBankIdConfiguration;
  norwegianBankIdOld: NorwegianBankIdOldConfiguration;
  norwegianVippsLogin: NorwegianVippsLoginConfiguration;
  danishNemId: DanishNemIdConfiguration;
  danishMitId: DanishMitIdConfiguration;
  dutchDigId: DutchDigIdConfiguration;
  dutchiDIN: DutchiDINConfiguration;
  itsme: ItsmeConfiguration;
  beeID: BelgianeIDConfiguration;
  germanSofort: GermanSofortConfiguration;

  deserialize(data: any) {
    this.swedishBankId =
      new SwedishBankIdConfiguration().deserialize(data.swedishBankId);
    this.norwegianBankId =
      new NorwegianBankIdConfiguration().deserialize(data.norwegianBankIdOidc);
    this.norwegianBankIdOld =
      new NorwegianBankIdOldConfiguration().deserialize(data.norwegianBankId);
    this.norwegianVippsLogin =
      new NorwegianVippsLoginConfiguration().deserialize(data.norwegianVippsLogin);
    this.danishNemId =
      new DanishNemIdConfiguration().deserialize(data.danishNemId);
    this.danishMitId =
      new DanishMitIdConfiguration().deserialize(data.danishMitId);
    this.dutchDigId =
      new DutchDigIdConfiguration().deserialize(data.dutchDigiD);
    this.dutchiDIN =
      new DutchiDINConfiguration().deserialize(data.dutchiDIN);
    this.itsme =
      new ItsmeConfiguration().deserialize(data.itsme);
    this.beeID =
      new BelgianeIDConfiguration().deserialize(data.belgianeID);
    this.germanSofort =
      new GermanSofortConfiguration().deserialize(data.germanSofort);
    return this;
  }
}

export class IdentityConnections {
  test: IdentityConnection;
  production: IdentityConnection;

  deserialize(data: any) {

    this.test = new IdentityConnection().deserialize(data.test);
    this.production = new IdentityConnection().deserialize(data.production);
    return this;
  }
}

export class IdServicesStyling {
  cssUrl: string;
  viewVersion: string;
  viewVersionEditable: boolean;

  deserialize(data: any) {
    this.cssUrl = data.cssUrl;
    this.viewVersion = data.viewVersion;
    this.viewVersionEditable = data.viewVersionEditable;
    return this;
  }
}

export class IdServicesStylings {
  testStyle: IdServicesStyling;
  prodStyle: IdServicesStyling;

  deserialize(data: any) {
    this.testStyle = new IdServicesStyling().deserialize(data.testStyle);
    this.prodStyle = new IdServicesStyling().deserialize(data.prodStyle);
    return this;
  }
}

export interface VatIdentifier { vatNumber : string; vatCountryCode: string }

export interface DanishMitIdApplyRendition {
  vatIdentifier : VatIdentifier
  companyName : string
  companyAlias : string
  companyType : string
  contactEmail : string
  contactName : string
  domainPrefix : string
  nemIdToken : string
  production : boolean
}
export interface DanishMitIdDomainRendition {
  domainPrefix : string
  production : boolean
}

export class LogEntry {
  cursor: string;
  timestamp: string;
  url: string;
  rawUrl: string;
  domain: string;
  method: string;
  statusCode: number;
  requestHeaders: {[key: string]: string};
  requestBody: string;
  responseHeaders: {[key: string]: string};
  responseBody: string;

  deserialize(data: any) {
    this.cursor = data.cursor;
    this.timestamp = data.timestamp;
    this.url = data.url;
    this.rawUrl = data.rawUrl;
    this.domain = data.domain;
    this.method = data.method;
    this.statusCode = data.statusCode;
    this.requestHeaders = data.requestHeaders;
    this.requestBody = data.requestBody;
    this.responseHeaders = data.responseHeaders;
    this.responseBody = data.responseBody;
    return this;
  }
}

export class LogEntryList extends Linked {
  entries: LogEntry[];

  deserialize(data: any) {
    if (!data) return this;
    super.deserialize(data);
    this.entries = data.entries
      ? multiDeserializer(LogEntry)(data.entries)
      : [];
    return this;
  }
}