import React, { useEffect } from 'react';

import Button from '@components/Button';
import { useDispatch } from '@app/redux';
import {translate} from '@app/i18n';
import {Domain, TokenSigningKey} from '@app/models';
import {REL} from '@app/constants';
import {addDomainTokenSigningKey, fetchDomainTokenSigningKeys, activateDomainTokenSigningKey} from '@redux/tenantSlice';
import usePromise from '@hooks/usePromise';

interface Props {
  domain: Domain
}

import './TokenSigningKeys.scss';

function renderDate(input: string) {
  const date = new Date(input);
  return date.getDate() + '-' + date.toLocaleString('default', { month: 'short' }) + '-' + date.getFullYear();
}

function renderDayDiff(input: string) {
  const currentDate = new Date();
  const date = new Date(input);
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round(Math.abs((currentDate.getTime() - date.getTime()) / (oneDay)));
  return diffDays;
}

export default function DomainTokenSigningKeysSection(props : Props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDomainTokenSigningKeys(props.domain));
  }, []);

  const [, addKeyState, addKeyTrigger] = usePromise(async () => {
    await dispatch(addDomainTokenSigningKey(props.domain));
  }, false);

  const [, activateKeyState, activateKeyTrigger] = usePromise<void, TokenSigningKey>(async (key) => {
    await dispatch(activateDomainTokenSigningKey([props.domain, key!]));
  }, false);

  if (!props.domain.tokenSigningKeysList) return <i className="fa fa-spinner fa-pulse fa-2x" />;

  const error = addKeyState.error || activateKeyState.error;
  const canAdd = props.domain.tokenSigningKeysList!.links.find(search => search.rel === REL.PREPARE_NEW_TOKENSIGNING_KEYS);
  const canActivate = props.domain.tokenSigningKeysList!.links.find(search => search.rel === REL.PREPARE_NEW_TOKENSIGNING_KEYS);

  return (
    <div className="token-signing-keys">
      <table>
        <thead>
          <tr>
            <th>{translate('INFO_TOKEN_SIGNING_KEY_IS_ACTIVE')}</th>
            <th>{translate('INFO_TOKEN_SIGNING_KEY_VALID_FROM')}</th>
            <th>{translate('INFO_TOKEN_SIGNING_KEY_VALID_TO')}</th>
            <th>{translate('INFO_TOKEN_SIGNING_KEY_PKCS7')}</th>
            <th>{translate('INFO_TOKEN_SIGNING_KEY_EXPIRES_IN')}</th>
          </tr>
        </thead>
        <tbody>
          {props.domain.tokenSigningKeysList.tokenSigningKeys.sort((a, b) => (b.isActive ? 1 : 0) - (a.isActive ? 1 : 0)).map((key, index) => (
            <tr key={index}>
              <td>
                {key.isActive ? 'Yes' : 'No'}
              </td>
              <td>
                {renderDate(key.certificate.validFrom)}
              </td>
              <td>
                {renderDate(key.certificate.validTo)}
              </td>
              <td>
              <div className="pkcs7">
                {translate('INFO_TOKEN_SIGNING_KEY_PKCS7')}
                <i className="fa fa-chevron-down fa-lg"></i>
                <textarea readOnly className="showPkcs7OnHover" value={key.certificate.pkcs7} />
              </div>
              </td>
              <td>
                {key.isExpired ? 'Expired' : `${renderDayDiff(key.certificate.validTo)} days`}
              </td>
              {!key.isActive && !key.isExpired && !activateKeyState.pending && canActivate && (
                <td style={{border: 0}}>
                  <Button variant="primary" type="button" className="button-small" onClick={() => activateKeyTrigger(key)}>
                    Activate
                  </Button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {error && (
        <div className="alert alert-danger" style={{marginTop: '15px'}}>
          {error.message}
        </div>
      )}
      {canAdd && (
        <div className="button-group flex-end" style={{marginTop: '15px'}}>
          <Button variant="primary" type="button" working={addKeyState.pending} onClick={() => addKeyTrigger()} autoWidth>
            Prepare new
          </Button>
        </div>
      )}
    </div>
  );
}

DomainTokenSigningKeysSection.Help = <p>{translate('INFO_TOKEN_SIGNING_KEYS_MANAGE')}</p>;