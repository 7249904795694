import React, {useCallback, useEffect} from 'react';
import {identity} from 'lodash';

import {useSelector, useDispatch} from '@app/redux';
import {fetchDomains, updateProviderSettings} from '@redux/tenantSlice';
import {translate} from '@app/i18n';

import {
  DutchiDINConfiguration,
  Tenant, DetailedIdentityProvider
} from '@app/models';

import {REL, CONTENT_TYPE} from '@app/constants';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch} from '@components/Form';

import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import { unwrapResult } from '@reduxjs/toolkit';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  configuration: DutchiDINConfiguration,
  provider: DetailedIdentityProvider
}

export interface FormValues extends DutchiDINConfiguration  {
  clientId : string
  clientSecret : string
}

export function NLiDINProvider(props : Props) {
  const {configuration} = props;
  const dispatch = useDispatch();

  const initialValues : FormValues = {
    clientId: configuration?.clientId || '',
    clientSecret: '',
    deserialize: (data) => data
  };

  const environment = useEnvironment();
  const domains = useSelector(state => state.tenant.domains?.items?.filter(d => d.production === (environment === "PRODUCTION")));

  useEffect(() => {
    dispatch(fetchDomains({force: false}));
  }, []);

  const saveProvider = async (values : FormValues) => {
    return dispatch(updateProviderSettings({
      rel: 'NL_IDIN_SETTINGS',
      values
    })).then(unwrapResult);
  };

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return saveProvider(values);
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST" />
          <EnvironmentDialog environment="PRODUCTION" heading={(
            <React.Fragment>
              <h1>
                <strong>{translate('LABEL_NL_IDIN_CLIENTID')}</strong>
              </h1>
              <br />
              <h3>{translate('INFO_NL_IDIN_CALLBACK_URL_PROD')}</h3>
            </React.Fragment>
          )}>
            {domains?.map(domain =>(
              <p className="green" key={domain.name}>
                Callback URL: https://{domain.name}/NLIdin/Callback<br />
                Cancel URL: https://{domain.name}/NLIdin/Cancel
              </p>
            ))}
          </EnvironmentDialog>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} Dutch iDIN Authentication
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label={translate('LABEL_NL_IDIN_CLIENTID')}
                name="clientId"
                placeholder={translate('LABEL_NL_IDIN_CLIENTID')}
              />
              <InputField
                type="password"
                label={translate('LABEL_NL_IDIN_CLIENTSECRET')}
                name="clientSecret"
                placeholder={translate('LABEL_NL_IDIN_CLIENTSECRET')}
              />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your dutch iDIN settings have been updated`} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_NL_IDIN_CLIENT')}</p>
              <p>{translate(environment === 'PRODUCTION' ? 'INFO_NL_IDIN_CALLBACK_URL_PROD': 'INFO_NL_IDIN_CALLBACK_URL_TEST')}</p>
              {domains?.map(domain => (
                <p key={domain.name}>
                  Callback URL: https://{domain.name}/NLIdin/Callback<br />
                  Cancel URL: https://{domain.name}/NLIdin/Cancel
                </p>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}