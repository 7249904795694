import { Application } from "@app/models"

import {Form, InputField, Switch, FormError, FormSuccess} from '@app/components/Form';
import { unwrapResult } from "@reduxjs/toolkit";
import { updateApplication } from "@app/redux/tenantSlice";
import { useDispatch } from "@app/redux";
import React from "react";
import Button from "@app/components/Button/Button";

interface Props {
  application: Application
  children: React.ReactNode
  valid: boolean
  formSuccessMessage?: string
  beforeSave?: (application: Application) => Application | Promise<Application>
  afterSave?: (application: Application) => Application | Promise<Application>,
  onSave?: (application: Application) => void
}
export default function ApplicationForm(props: Props) {
  const {application, children, beforeSave, afterSave, onSave, formSuccessMessage, valid} = props;
  const dispatch = useDispatch();

  const handleSubmit = async (application : Application) => {
    if (!valid) return Promise.reject(new Error('Invalid form fields'));

    if (beforeSave) {
      application = await beforeSave(application);
    }

    await dispatch(updateApplication(application)).then(unwrapResult);

    if (afterSave) {
      application = await afterSave(application);
    }

    if (onSave) onSave(application);
  };

  return (
    <Form initialValues={application} onSubmit={handleSubmit} data-test-id="form" key="application_form">
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          {children}
          {error && (
            <FormError error={error} />
          )}

          {isSuccess && (
            <FormSuccess message={formSuccessMessage || 'Application updated!'} />
          )}

          <Button variant="primary" type="submit" working={isPending} disabled={!valid}>
            Save
          </Button>
        </React.Fragment>
      )}
    </Form>
  );
}