import React, {useState, useEffect, useRef, useLayoutEffect, useReducer} from 'react';
import { ExtensibilityConfiguration } from '@app/redux/extensibilitySlice';

import runnerTemplate from '!!raw-loader!../template/runner.js';
import indexTemplate from '!!raw-loader!../template/index.js';
import packageTemplate from '!!raw-loader!../template/package_json.txt';
import { ConfirmModal } from '@app/components/Modal/Modal';
import Button from '@app/components/Button/Button';

interface FusebitEditorProps {
  configuration: ExtensibilityConfiguration,
  height: number
}

interface FusebitEditorContext {
  dispose: () => void,
  on: (event: string, callback: (event: any) => void) => void
  selectFile : (fileName : string) => void,
  deleteFile : (fileName : string) => void,
  getFiles: () => {[key: string]: string},
  addFile: (fileName: string) => void,
  addFileToSpecification: (fileName: string, content: string, overwrite: boolean) => void,
  setRunnerContent: (content: string) => void,
  specification: {
    location: string,
    security: {
      authentication: string
    }
  }
}

interface Fusebit {
  createEditor: (
    element: HTMLElement,
    boundaryId: string,
    functionId: string,
    account: {
      accessToken: string,
      accountId: string,
      baseUrl: string,
      subscriptionId: string
    },
    editorOptions: {
      template: any,
      editor: any
    }
  ) => Promise<FusebitEditorContext>
}

export default function FusebitEditor(props: FusebitEditorProps) {
  const {configuration} = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [showResetConfirm, toggleResetConfirm] = useReducer((value) => !value, false);
  const [editorIsDirty, setEditorIsDirty] = useState(false);
  const [editorContext, setEditorContext] = useState<FusebitEditorContext | null>(null);
  const [state, setState] = useState<{scriptState: "pending" | "loaded" | "error", editorPending: Boolean, error: Error | null}>({
    scriptState: "pending",
    editorPending: false,
    error: null
  });

  useLayoutEffect(() => {
    if (!iframeRef.current) return;
    iframeRef.current.onload = function () {
      setState({scriptState: "loaded", editorPending: true, error: null});
    };
    iframeRef.current.onerror = function (error) {
      setState({scriptState: "error", editorPending: false, error: new Error('Unable to load editor')});
    };
  }, []);

  useEffect(() => {
    if (state.scriptState !== "loaded") return;
    if (state.error) return;
    if (!iframeRef.current) return;

    setState(state => ({...state, editorPending: true}));

    const fusebit = ((iframeRef.current.contentWindow as any).fusebit as Fusebit);
    fusebit
      .createEditor(
        iframeRef.current.contentDocument!.getElementById('criipto_fusebit_container')!,
        configuration.boundaryId,
        configuration.functionId,
        {
          accountId: configuration.accountId,
          subscriptionId: configuration.subscriptionId,
          baseUrl: configuration.baseUrl,
          accessToken: configuration.accessToken,
        },
        {
          template: {
            nodejs: { // Specify the files that comprise the function
              files: {
                'package.json': packageTemplate,
                'index.js': indexTemplate
              }
            },
            metadata: {
              fusebit: {
                runner : runnerTemplate,
              }
            }
          },
          editor: {
            navigationPanel: {
              hideScheduleSettings: true
            }
          }
        }
      )
      .then((editorContext) => {
        setEditorContext(editorContext);
        setState(state => ({...state, editorPending: false, error: null}));

        editorContext.specification = {
          ...editorContext.specification,
          security: {
            ...editorContext.specification.security,
            authentication: "optional"
          }
        };
        sessionStorage.setItem(`${editorContext.specification.location}_access_token`, configuration.accessToken);

        editorContext.on('dirty-state:changed', (event) => {
          setEditorIsDirty(event.newState);
        });
      })
      .catch((error) => {
        setState(state => ({...state, editorPending: false, error}));
      });
  }, [state.scriptState]);

  const handleResetEditor = () => {
    toggleResetConfirm();

    if (!editorContext) return;
    const files = Object.keys(editorContext.getFiles());
    for (const file of files) {
      editorContext.deleteFile(file);
    }

    editorContext.addFile('index.js');
    editorContext.addFileToSpecification('index.js', indexTemplate, true);
    editorContext.addFile('package.json');
    editorContext.addFileToSpecification('package.json', packageTemplate, true);
    editorContext.setRunnerContent(runnerTemplate);
    editorContext.selectFile('index.js');
  };

  return (
    <React.Fragment>
      {(state.scriptState === "pending" || state.editorPending) ? (
        <i className="fa fa-spinner fa-pulse" />
      ) : state.error ? (
        <div className="alert alert-danger">
          {state.error.message}
        </div>
      ) : null}

      <div className="d-flex justify-content-between align-items-center" style={{marginBottom: '20px'}}>
        <p style={{margin: 0}}>
          {editorIsDirty && 'Make sure to press the save icon inside the code editor if you wish you save your unsaved changes.'}
        </p>

        <Button variant="default" autoWidth={true} onClick={() => toggleResetConfirm()}>Reset</Button>

        <ConfirmModal
          title={`Reset editor?`}
          show={showResetConfirm}
          onHide={() => toggleResetConfirm()}
          confirmVariant="danger"
          confirmText={`Reset editor`}
          onConfirm={() => handleResetEditor()}
        >
          <p>
            Heads-up: You are about to reset the editor.
          </p>
          <p>
            This will discard any changes you have made.
          </p>
        </ConfirmModal>
      </div>

      <iframe ref={iframeRef} src="/fusebit.html" style={{border: 0, width: '100%', height: '700px'}} />
    </React.Fragment>
  );
}