import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Environment, Tenant } from '../models';

interface TenantEnvironmentState {
  environment: Environment,
  isProduction: boolean // Backwards compatability
};
type EnvironmentState = {
  [key: string]: TenantEnvironmentState | undefined
}
type StorageState = {
  [key: string]: Environment
}

const storage = localStorage;

export function updateStorage(tenantId: string, environment: Environment) {
  const value = JSON.parse(storage.getItem('tenants_environment') || '{}');
  value[tenantId] = environment;
  storage.setItem('tenants_environment', JSON.stringify(value));
}
function readStorage() : StorageState {
  return JSON.parse(storage.getItem('tenants_environment') || '{}');
}

export function makeInitialState() : EnvironmentState {
  const storedState = readStorage();
  const state = Object.keys(storedState).reduce((memo, tenantId) => {
    memo[tenantId] = {
      environment: storedState[tenantId],
      isProduction: storedState[tenantId] === "PRODUCTION"
    };

    return memo;
  }, {} as EnvironmentState);

  return state;
}

export const initialState: EnvironmentState = makeInitialState();

export const environmentSlice = createSlice({
  name: 'environment',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    set: (state, action: PayloadAction<{tenant: Tenant, environment: Environment}>) => {
      const {tenant, environment} = action.payload;
      updateStorage(tenant.tenantId, environment);
      return {
        ...state,
        [tenant.tenantId]: {
          environment: environment,
          isProduction: environment === "PRODUCTION"
        }
      };
    },
    toggle: (state, action: PayloadAction<Tenant>) => {
      const tenant = action.payload;
      const environment = state[tenant.tenantId]?.environment !== "PRODUCTION" ? "PRODUCTION" : "TEST"
      storage.setItem('environment', JSON.stringify(environment));

      return {
        ...state,
        [tenant.tenantId]: {
          environment: environment,
          isProduction: environment === "PRODUCTION"
        }
      };
    }
  }
});

export const { set, toggle } = environmentSlice.actions;
export default environmentSlice;