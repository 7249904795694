import React, {useState} from 'react';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {ConfirmModal} from '@app/components/Modal';
import {DeleteApiKeyButton_apiKey$key} from './__generated__/DeleteApiKeyButton_apiKey.graphql';
import {DeleteApiKeyButton_application$key} from './__generated__/DeleteApiKeyButton_application.graphql';
import {DeleteApiKeyButtonMutation} from './__generated__/DeleteApiKeyButtonMutation.graphql';
import Button from '@app/components/Button';
import useMutation from '@app/hooks/useMutation';


interface Props {
  apiKey: DeleteApiKeyButton_apiKey$key,
  application: DeleteApiKeyButton_application$key
}

export default function DeleteApiKeyButton(props: Props) {
  const [showConfirm, setShowConfirm] = useState(false);

  const apiKey = useFragment(graphql`
    fragment DeleteApiKeyButton_apiKey on ApplicationApiKey {
      id
    }
  `, props.apiKey);

  const application = useFragment(graphql`
    fragment DeleteApiKeyButton_application on Application {
      id
    }
  `, props.application);

  const [deleteKeyMutationExecutor, deleteKeyMutationState] = useMutation<DeleteApiKeyButtonMutation>(graphql`
    mutation DeleteApiKeyButtonMutation($input: DeleteApplicationApiKeyInput!) {
      deleteApplicationApiKey(input: $input) {
        application {
          id
          ... ApiKeys_application
        }
      }
    }
  `);

  const handleClick = () => {
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    return deleteKeyMutationExecutor.executePromise({
      input: {
        applicationId: application.id,
        apiKeyId: apiKey.id
      }
    }).then(() => {}).catch(() => {
      // Handled by deleteKeyMutationState
    });
  }

  return (
    <React.Fragment>
      <Button variant="danger" className="button-small" autoWidth onClick={handleClick} data-test-id="button">
        <i className="fa fa-trash-alt" />
      </Button>
      <ConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        title={"Delete API key"}
        confirmText={"Delete API key"}
        confirmVariant="danger"
        data-test-id="confirm-modal"
      >
        Are you sure you want to delete this API key? Any calls using this API key will start to fail.

        {deleteKeyMutationState.error && (
          <div className="alert alert-danger" data-test-id="error">{deleteKeyMutationState.error.message}</div>
        )}
      </ConfirmModal>
    </React.Fragment>
  )
}