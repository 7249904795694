import React, {useState, useMemo} from 'react';

import {useSelector} from '@app/redux';
import {createWebAuth} from '@app/auth';
import Button from '@components/Button';
import {SIGNUP_CALLBACK_URL} from '@app/constants';

import './SignupBox.scss';

import google from './assets/google.png';
import microsoft from './assets/microsoft.png';

export default function SignupBox() {
  const webAuth = useMemo(() => createWebAuth(), []);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const authClient = useSelector(state => state.auth.client);
  const [working, setWorking] = useState(false);

  const handleGoogleLogin = () => {
    authClient.loginWithRedirect({
      response_type: 'token id_token',
      connection: 'google-oauth2',
      mode: 'signUp',
      redirect_uri: SIGNUP_CALLBACK_URL
    });
  };

  const handleMicrosoftLogin = () => {
    authClient.loginWithRedirect({
      response_type: 'token id_token',
      connection: 'windowslive',
      mode: 'signUp',
      redirect_uri: SIGNUP_CALLBACK_URL
    });
  };

  const handleEmailSubmit = (event : any) => {
    event.preventDefault();
    setWorking(true);

    webAuth.signup({
      email: email,
      password: password,
      username: email,
      connection: 'Username-Password-DB'
    }, (err, result) => {
      console.log(err, result);
      webAuth.login({
        username: email,
        password: password,
        realm: 'Username-Password-DB',
        redirectUri: SIGNUP_CALLBACK_URL
      }, (error, result) => {
        console.log(error, result);
      });
    });
  }

  return (
    <div className="signup-box">
      <div className="email-form">
        <form method="POST" onSubmit={handleEmailSubmit} data-test-id="email-form">
          <h3>Sign Up</h3>
          <div className="form-group">
            <label>Email</label>
            <input type="email" placeholder="Your email address" onChange={(event) => setEmail(event.target.value)} required />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" placeholder="*******" onChange={(event) => setPassword(event.target.value)} required  />
          </div>
          <p>
            By clicking any of the Sign Up buttons,<br />
            I agree to the <a href="https://criipto.com/legal/tos" target="_blank">terms of service</a>
          </p>
          <Button type="submit" variant="primary" className="button" working={working}>
            Sign Up with email
          </Button>
        </form>
      </div>
      <div className="social">
        Or:

        <button type="button" onClick={handleGoogleLogin}>
          <div className="icon"><img src={google} /></div>
          <div className="text">Sign up with Google</div>
        </button>

        <button type="button" onClick={handleMicrosoftLogin}>
          <div className="icon"><img src={microsoft} /></div>
          <div className="text">Sign up with Microsoft</div>
        </button>
      </div>
    </div>
  )
}