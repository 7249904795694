import React, {useEffect, useState} from 'react';

import {Domain} from '@app/models';
import Renderer from './Renderer';
import { PreviewItem, Styling } from '../constants';

interface Props {
  item?: PreviewItem | null,
  styling: Styling,
  domain: string,
  bodyCssClass?: string,
  language: string,
  onClose: () => void
}

export default function WindowRenderer(props : Props) {
  const {item} = props;

  const [popup, setPopup] = useState<Window | null>(null);

  useEffect(() => {
    if (item) {
      const popupRef = window.open('', 'WindowRenderer', `width=1024,height=768`)!;
      setPopup(popupRef)
      popupRef.addEventListener('unload', () => {
        props.onClose();
        //setPopup(null);
      });
    } else {
      setPopup(null);
      if (popup) {
        popup.close();
      }
    }
  }, [item]);

  if (!popup) return null;
  if (!item) return null;

  return (
    <Renderer {...props} item={item} renderRef={popup} />
  );
}