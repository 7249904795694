import React from 'react';

import {CertificateConfiguration} from '@app/models';
import { translate } from '@app/i18n';

import {InputField, FileUpload} from '@app/components/Form';

interface Props {
  certificate: CertificateConfiguration | null | undefined,
  fileFieldName: string,
  passwordFieldName: string,
  required?: boolean
}

export default function CertificateForm(props : Props) {
  const {certificate} = props;

  return (
    <React.Fragment>
      {certificate?.name && (
        <div className="form-group">
          <label className="control-label">{translate('LABEL_CERTIFICATE')}</label>
          <pre>{certificate.name}</pre>
          <label className="control-label">{translate('LABEL_CERTIFICATE_VALID_FROM')}</label>
          <pre>{certificate.validFrom}</pre>
          <label className="control-label">{translate('LABEL_CERTIFICATE_VALID_TO')}</label>
          <pre>{certificate.validTo}</pre>
        </div>
      )}

      <FileUpload name={props.fileFieldName} label={translate('LABEL_CERTIFICATE_UPLOAD')} placeholder={translate('HINT_FILE')} />
      <InputField name={props.passwordFieldName} type="password" label={translate('LABEL_CERTIFICATE_PASSWORD')} placeholder={translate('HINT_PASSWORD')} required={props.required} />
    </React.Fragment>
  )
}