import {Application, Domain} from './models';

/**
 * Validates the uniqueness of an application realm
 *
 * @param application
 * @param otherApplications - Must not include the application being validated
 */
export function isApplicationRealmValid(application : Application, otherApplications : Application[]) : boolean {
    if (!otherApplications.length) return true;
    return !otherApplications.some((search) => search.realm === application.realm && search.domain.name === application.domain.name);
}

/**
 * Validates the uniqueness of an application realm
 *
 * @param domain
 * @param realm
 * @param otherApplications - Must not include the application being validated
 */
 export function isApplicationRealmValid2(domain : Domain, realm: string, otherApplications : Application[]) : boolean {
    if (!otherApplications.length) return true;
    return !otherApplications.some((search) => search.realm === realm && search.domain.name === domain.name);
}