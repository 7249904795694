import React, { useReducer } from 'react';
import Button from "@app/components/Button";
import { GaussOrganization, GaussOrganizationPendingUser, GaussOrganizationUser } from "@app/models";
import { ConfirmModal } from '@app/components/Modal/Modal';
import { useRemoveOrganizationUserMutation } from '@app/api/gauss';
import { useProfile } from '@app/hooks/useAuth';
import { useHistory } from 'react-router-dom';

interface Props {
  organization: GaussOrganization,
  user: GaussOrganizationUser | GaussOrganizationPendingUser
}
export default function RemoveUserButton(props: Props) {
  const {user, organization} = props;
  const [showConfirm, toggleConfirm] = useReducer(value => !value, false);
  const profile = useProfile();
  const history = useHistory();
  const isSelf = "loginId" in user && user.loginId === profile?.sub;

  const [removeUser, removeUserResult] = useRemoveOrganizationUserMutation();

  const handleClick = () => {
    toggleConfirm();
  }

  const handleConfirm = async () => {
    try {
      await removeUser({
        organization,
        user
      }).unwrap().then(() => {
        toggleConfirm()

        if (isSelf) {
          history.push('/refreshlogin');
        }
      });
    }catch (err) {
      console.error(err);
    }
  };

  const isPending = "invitedAt" in user;

  return (
    <React.Fragment>
      <Button variant="danger" className="button-small" tooltip={isPending ? 'Remove invited user' : 'Remove user'} autoWidth onClick={handleClick}>
        <i className="fa fa-trash-alt" />
      </Button>
      <ConfirmModal
        onHide={toggleConfirm}
        onConfirm={handleConfirm}
        title={isPending ? 'Remove invited user' : 'Remove user'}
        confirmText="Remove user"
        show={showConfirm}
      >
        <p>Are you sure you want to remove this {isPending ? 'invited user' : 'user'} {user.name}?</p>

        {removeUserResult.error ? (
          <div className="alert alert-danger">There was an error removing the user</div>
        ) : null}
      </ConfirmModal>
    </React.Fragment>
  )
}