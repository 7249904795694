import React from 'react';
import Button from "@app/components/Button";
import { GaussOrganizationPendingUser } from "@app/models";
import { useResendInvitationMutation } from '@app/api/gauss';

interface Props {
  user: GaussOrganizationPendingUser
}
export default function ResendInvitationButton(props: Props) {
  const [resendInvitation, resendInvitationResult] = useResendInvitationMutation();

  const handleClick = async () => {
    await resendInvitation(props.user);
  };

  return (
    <Button working={resendInvitationResult.isLoading} variant="default" tooltip="Resend invitation"  className="button-small" data-test-id="resend-invitation-button" autoWidth onClick={handleClick}>
      <i className="fa fa-envelope" />
    </Button>
  );
}