import React from 'react';
import cx from 'classnames';
import {upperFirst, toLower} from 'lodash';

import './Tag.scss';
import useEnvironment from '@app/hooks/useEnvironment';

interface TagProps {
  children: React.ReactNode,
  className?: string,
  style?: React.CSSProperties
}
export function Tag(props : TagProps) {
  return (
    <div className={cx('tag', props.className)} style={props.style}>
      {props.children}
    </div>
  );
}

export function EnvironmentTag() {
  const environment = useEnvironment();

  return <Tag className={`tag-environment-${environment}`}>{upperFirst(toLower(environment))} environment</Tag>;
}
