import { createApi, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta, FetchArgs } from '@reduxjs/toolkit/query/react';

import { singleton as config } from '@app/config';
import {
    ApiRoot, deserializer, GaussOrganization, GaussServicePermission, GaussOrganizationUser,
    GaussUserApplicationRole,
    getHref, getTemplateHref, LinkRendition, ScopedClaims, GaussApplicationRoleGrant, GaussInvitationRequest, Linked, getOptionalHref, GaussOrganizationPendingUser, GaussApplicationRole } from '@app/models';
import { RootState } from '@app/redux/store';
import { GAUSS_REL } from '@app/constants';
import { NoScopedClaimsError } from '@app/redux/discoverySlice';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { build } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/cacheLifecycle';

export type GaussGetRequest = {
  method: 'GET',
  url: string,
  headers?: Headers
};

export type GaussRequest = GaussGetRequest;

function isGaussGetRequest(request: GaussRequest): request is GaussGetRequest {
  return request.method === 'GET';
}

type GaussCommandResponse = {
  status: "Pending" | "Applied" | "Rejected",
  links: LinkRendition[]
}

type GaussErrorResponse = {
  errors: {
    reason: string
  }[]
}

async function pollGaus(input: Linked, authorizationHeader: string) : Promise<Response> {
  const href = getOptionalHref(input, GAUSS_REL.COMMAND_STATUS);

  if (href) {
    const response = await fetch(href, {
      headers: {
        Authorization: authorizationHeader
      }
    });
    const data : GaussCommandResponse = await response.clone().json();
    if (data.status === "Applied") return response;
    if (data.status === 'Rejected') {
      const commandStatusHref = getHref(data, 'gauss:command-status');
      const commandStatusResponse = await fetch(commandStatusHref, {
        headers: {
          Authorization: authorizationHeader
        }
      });
      const commandStatusData = await commandStatusResponse.clone().json() as GaussErrorResponse;
      throw new Error(commandStatusData.errors[0].reason);
    }
  }
  await new Promise(resolve => setTimeout(resolve, 250));
  return await pollGaus(input, authorizationHeader);
}

const baseQuery = fetchBaseQuery({
  baseUrl: config.gaussBase,
  prepareHeaders: (headers, api) => {
    const token = (api.getState() as RootState).auth.id_token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
  async fetchFn(input, init) {
    const response = await fetch(input, init);
    if (response.status === 202) {
      return await pollGaus(
        await response.json(),
        (input as any).headers.get('Authorization')
      );
    }
    return response;
  }
});

type FetchResult<T> = MaybePromise<QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta>>
type Fetch = (arg: string | FetchArgs) => MaybePromise<QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>>
async function narrowFetch<T>(fetch: Fetch, arg: string | FetchArgs) : Promise<QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta>> {
  const result = await fetch(arg);
  if (result.error) return {error: result.error};
  return {data: result.data as T};
}

export const gaussApi = createApi({
  reducerPath: 'gaussApi',
  baseQuery,
  tagTypes: ['Discover', 'ScopedClaims', 'Organization', 'OrganizationUsers', 'OrganizationPendingUsers'],
  endpoints: (builder) => ({
    discover: builder.query<ApiRoot, void>({
      query: () => '/',
      transformResponse: deserializer(ApiRoot),
      providesTags: ['Discover'],
    }),
    scopedClaims: builder.query<ScopedClaims, void>({
      async queryFn(root, queryApi, options, fetch) {
        const discover = await queryApi.dispatch(gaussApi.endpoints.discover.initiate());
        if (discover.error) throw discover.error;

        const href = getTemplateHref(discover.data!, GAUSS_REL.SCOPED_CLAIMS, {
          "application": btoa(config.gaussAppId)
        }, true);

        if (!href) {
          throw new NoScopedClaimsError("No scoped claims found in gauss root")
        }

        const result = await fetch({
          url: href
        });
        if (result.data) return {data: deserializer(ScopedClaims)(result.data)};
        return { error: result.error as FetchBaseQueryError };
      },
      providesTags: ['ScopedClaims']
    }),
    organization: builder.query<GaussOrganization, string>({
      query: (id) => `/organization/${id}`,
      transformResponse: deserializer(GaussOrganization),
      providesTags: (result, error, id) => [{type: 'Organization', id}]
    }),
    applicationRoleGrants: builder.query<{applicationRoleGrants: GaussApplicationRoleGrant[], servicePermissions: GaussServicePermission[]}, GaussOrganization>({
      query: (organization) => getHref(organization, GAUSS_REL.APPLICATION_ROLE_GRANTS)
    }),
    organizationUsers: builder.query<GaussOrganizationUser[], GaussOrganization>({
      queryFn: async (organization, queryApi, options, fetch) => {
        const usersResult = await (fetch({url: getHref(organization, GAUSS_REL.USERS)}) as FetchResult<{users: {links: LinkRendition[]}[]}>);
        if (usersResult.error) throw usersResult.error;

        const users : GaussOrganizationUser[] = await Promise.all(usersResult.data.users.map(async links => {
          const userResult = await (
            fetch({url: getHref(links, GAUSS_REL.USER)}) as
            FetchResult<Omit<GaussOrganizationUser, 'servicePermissions' | 'roles'>>
          );

          if (userResult.error) throw userResult.error;

          const user = userResult.data;
          const rolesResult =
            await (
              fetch({url: getHref(user, GAUSS_REL.USER_APPLICATION_ROLES)}) as
              FetchResult<{roles: GaussUserApplicationRole[], servicePermissions: GaussServicePermission[]}>
            );
          if (rolesResult.error) throw rolesResult.error;

          const rolesData = rolesResult.data;

          return {...user, roles: rolesData.roles, servicePermissions: rolesData.servicePermissions} as GaussOrganizationUser;
        }));

        return {data: users};
      },
      providesTags: (result, _, organization) => [{type: 'OrganizationUsers', id: organization.id}]
    }),
    organizationPendingUsers: builder.query<GaussOrganizationPendingUser[], GaussOrganization>({
      queryFn: async (organization, queryApi, options, fetch) => {
        let result =
          await (
            fetch({url: getHref(organization, GAUSS_REL.PENDING_USERS)}) as
              FetchResult<{users: GaussOrganizationPendingUser[], links: LinkRendition[]}>
          );
        if (result.error) throw result.error;

        let data = result.data!;
        let users = data!.users;

        while (getOptionalHref(data, 'next')) {
          result =
            await (
              fetch({url: getOptionalHref(data, 'next')!}) as
                FetchResult<{users: GaussOrganizationPendingUser[], links: LinkRendition[]}>
            );
          if (result.error) throw result.error;

          data = result.data!;
          users = users.concat(data.users);
        }

        return {data: users};
      },
      providesTags: (result, _, organization) => [{type: 'OrganizationPendingUsers', id: organization.id}]
    }),
    inviteOrganizationUser: builder.mutation<any, {organization: GaussOrganization, request: GaussInvitationRequest}>({
      queryFn: async ({organization, request}, queryApi, options, fetch) => {
        const result = await fetch({
          url: getHref(organization, GAUSS_REL.INVITE_USER),
          method: 'POST',
          body: JSON.stringify(request),
          headers: {
            'Content-Type': 'application/vnd.grn.authorization.user-invitation+json'
          }
        });

        // TODO: Remove once we no longer use ElasticSearch
        if (!result.error) await new Promise((resolve) => setTimeout(resolve, 2000));

        return result;
      },
      invalidatesTags: (result, _, input) => [{type: 'OrganizationPendingUsers', id: input.organization.id}]
    }),
    acceptInvitation: builder.mutation<GaussOrganization, string>({
      queryFn: async (href: string, queryApi, options, fetch) => {
        const acceptResult = await (
          fetch({
            url: href,
            method: 'PUT'
          }) as FetchResult<{links: LinkRendition[]}>
        );

        if (acceptResult.error?.status === 410) throw new Error('Invitation has already been accepted (or has been deleted)');
        if (acceptResult.error) throw acceptResult.error;

        const invitationHref = getHref(acceptResult.data, GAUSS_REL.USER_INVITATION);
        const invitationResult = await (
          fetch(invitationHref) as FetchResult<{links: LinkRendition[]}>
        );

        if (invitationResult.error) throw invitationResult.error;

        const organizationHref = getHref(invitationResult.data, GAUSS_REL.ORGANIZATION);
        const organizationResult = await (
          fetch(organizationHref) as FetchResult<GaussOrganization>
        );

        return organizationResult;
      }
    }),
    removeOrganizationUser: builder.mutation<any, {organization: GaussOrganization, user: GaussOrganizationUser | GaussOrganizationPendingUser}>({
      queryFn: async ({organization, user}, queryApi, options, fetch) => {
        const result = await fetch({
          url: "invitedAt" in user ? getHref(user, GAUSS_REL.USER_INVITATION) : getHref(user, 'self'),
          method: 'DELETE'
        });

        // TODO: Remove once we no longer use ElasticSearch
        if (!result.error) await new Promise((resolve) => setTimeout(resolve, 2000));

        return result;
      },
      invalidatesTags: (result, _, {organization, user}) => [{type: "invitedAt" in user ? 'OrganizationPendingUsers' : 'OrganizationUsers', id: organization.id}]
    }),
    updateOrganizationUser:
      builder.mutation<
        any,
        {organization: GaussOrganization, user: GaussOrganizationUser, newRoles: GaussApplicationRole[], newPermissions: GaussServicePermission[]}
      >({
        queryFn: async ({organization, user, newRoles, newPermissions}, queryAPi, options, fetch) => {
          const permissionsAdd = newPermissions.filter(p => !user.servicePermissions.some(s => s.id === p.id));
          const permissionsRemove = user.servicePermissions.filter(p => !newPermissions.some(s => s.id === p.id));

          const allExistingRoles = user.roles.flatMap(r => r.applicationRoles);
          const rolesRemove = allExistingRoles.filter(r => !newRoles.some(s => s.roleId === r.roleId));
          const rolesAdd = newRoles.filter(r => !allExistingRoles.some(s => s.roleId === r.roleId));

          const results = await Promise.all(
            permissionsAdd.map(async permission => (
              fetch({
                url: getHref(user, GAUSS_REL.USER_SERVICE_PERMISISONS),
                method: 'POST',
                body: JSON.stringify(permission),
                headers: {
                  'Content-Type': 'application/vnd.grn.authorization.user-service-permission+json'
                }
              })
            )).concat(
              permissionsRemove.map(async permission => (
                fetch({
                  url: getHref(permission, 'self'),
                  method: 'DELETE'
                })
              ))
            ).concat(
              rolesAdd.map(async role => (
                fetch({
                  url: getHref(user, GAUSS_REL.USER_APPLICATION_ROLES),
                  method: 'POST',
                  body: JSON.stringify(role),
                  headers: {
                    'Content-Type': 'application/vnd.grn.authorization.user-application-role+json'
                  }
                })
              ))
            ).concat(
              rolesRemove.map(async role => (
                fetch({
                  url: getHref(role, 'self'),
                  method: 'DELETE'
                })
              ))
            )
          );

          for (const result of results) {
            if (result.error) throw result.error;
          }

          return {data: null};
        },
        invalidatesTags: (result, _, {organization, user}) => [{type: 'OrganizationUsers', id: organization.id}]
      }),
    resendInvitation: builder.mutation<any, GaussOrganizationPendingUser>({
      query: (user) => ({
        url: getHref(user, GAUSS_REL.RESEND_INVITATION),
        method: 'POST'
      })
    })
  }),
});

export const {
    useDiscoverQuery, useScopedClaimsQuery, useOrganizationQuery,
    useOrganizationUsersQuery, useOrganizationPendingUsersQuery, useApplicationRoleGrantsQuery,
    useInviteOrganizationUserMutation, useRemoveOrganizationUserMutation,
    useResendInvitationMutation, useUpdateOrganizationUserMutation,
    useAcceptInvitationMutation
} = gaussApi;
