import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import '@fortawesome/fontawesome-free/css/all.css';
import "@fontsource/raleway/latin-300.css";
import "@fontsource/raleway/latin-400.css";
import "@fontsource/raleway/latin-500.css";
import "@fontsource/raleway/latin-600.css";
import "@fontsource/raleway/latin-700.css";

import "@fontsource/roboto-slab/latin-400.css";

import './assets/main.scss';
import './app/main.css';
import { Router } from "./app/routes";

import './app/segment/load';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0
  });

  if ('SecurityPolicyViolationEvent' in window) {
    window.addEventListener('securitypolicyviolation', function(e) {
      const error = new Error(`Refused to load ${e.violatedDirective} ${e.blockedURI} becaues it violates the Content Security Policy`);
      Sentry.captureException(error, {
        extra: {
          policy: e.originalPolicy
        }
      });
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <Router />
    </Sentry.ErrorBoundary>,
    document.getElementById('react_root')
  );
});