import React from 'react';

import {Application} from '@app/models';
import {InputField} from '@app/components/Form';
import { translate } from '@app/i18n';

const ApplicationNameField = () => (
  <InputField<Application>
    type="text"
    label={translate('LABEL_APP_NAME')}
    name="name"
    placeholder="My application"
    required
  />
);
export default ApplicationNameField;