/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DeleteApiKeyButton_application = {
    readonly id: string;
    readonly " $refType": "DeleteApiKeyButton_application";
};
export type DeleteApiKeyButton_application$data = DeleteApiKeyButton_application;
export type DeleteApiKeyButton_application$key = {
    readonly " $data"?: DeleteApiKeyButton_application$data;
    readonly " $fragmentRefs": FragmentRefs<"DeleteApiKeyButton_application">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteApiKeyButton_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Application",
  "abstractKey": null
};
(node as any).hash = '2667a198ca9e05a6313e0e10411e05cf';
export default node;
