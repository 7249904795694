import React, {useState} from 'react';
import { useField } from 'formik';
import cx from 'classnames';

import { Application, ClientSecretMatch, ClientSecret, deserializer, getHref } from '@app/models';
import { REL } from '@app/constants';
import { translate } from '@app/i18n';
import { useDispatch, useSelector } from '@app/redux';

import usePromise from '@app/hooks/usePromise';

import Button from '@app/components/Button';
import ClientSecretModal from '../ClientSecretModal';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';

interface Props {
  application: Application,
  isCreate: boolean
}

export default function CodeFlow(props : Props) {
  const {isCreate, application} = props;
  const dispatch = useDispatch();
  const wasEnabled = !isCreate && application.oAuth2ClientSecret?.enabled;
  const [{value}, , {setValue}] = useField<Application['oAuth2ClientSecret'] | null>('oAuth2ClientSecret');

  const handleValue = (event : React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, enabled: event.target.checked});
  };

  /* Client secret match logic, TODO: Consider moving to smaller, seperate component */
  const [matchValue, setMatchValue] = useState('');
  const [isMatch, matchState, triggerMatch] = usePromise(async () => {
    try {
      const response = await dispatchVerifyRequest(
        dispatch,
        {
          method: "GET",
          url: `${getHref(application, REL.CLIENTSECRET)}?secret=${encodeURIComponent(matchValue)}`
        },
        deserializer(ClientSecretMatch)
      );
      return response.clientSecretMatches;
    } catch (err) {
      return false;
    }
  }, false);
  const handleMatch = () => {
    triggerMatch();
  };

  /* Logic for showing secrets and regenerating */
  const [showClientSecret, setShowClientSecret] = useState(false);
  const [clientSecret, generateState, triggerGenerate] = usePromise(async () => {
    const response = await dispatchVerifyRequest(
      dispatch,
      {
        method: "PUT",
        url: getHref(application, REL.CLIENTSECRET),
        data: undefined
      },
      deserializer(ClientSecret)
    );

    setValue({...value, enabled: true});

    return response.clientSecret;
  }, false);
  const handleGenerate = () => {
    triggerGenerate().then(() => setShowClientSecret(true));
  };

  return (
    <React.Fragment>
      <div className="form-group horizontal">
        <label className="switch">
          <input type="checkbox" checked={value?.enabled} onChange={handleValue} /><i />
        </label>
        <label className="control-label">{translate('HINT_OAUTH2_AUTHZCODEFLOW_ENABLED')}</label>
      </div>

      {isCreate && value?.enabled && (
        <small className="help-block" >
          <span>{translate('HINT_OAUTH2_NEW_APPLICATION')}</span>
        </small>
      )}

      {!isCreate && !wasEnabled && value?.enabled && (
        <small className="help-block" >
          <span>{translate('HINT_OAUTH2_AFTER_SAVE')}</span>
        </small>
      )}

      {wasEnabled && value?.enabled && (
        <React.Fragment>
          <div className="form-group" style={{marginTop: "15px"}}>
            <label>{translate('CLIENT_SECRET')}</label>
            <div className="input-with-button">
              <input
                type="password"
                className={cx('form-control', {'has-success': isMatch === true, 'has-error': isMatch === false})}
                placeholder={translate('OAUTH2_MATCH_CLIENTSECRET')}
                value={matchValue}
                onChange={(event) => setMatchValue(event.target.value)}
              />

              <Button variant="primary" className="button-icon" onClick={handleMatch} working={matchState.pending}>
                {!matchState.pending && (
                  <i className={cx('fa', {'fa-search': isMatch !== true, 'fa-check': isMatch})} />
                )}
              </Button>
            </div>

            <Button variant="primary" onClick={handleGenerate} working={generateState.pending}>
              {translate('OAUTH2_RECYCLE_CLIENTSECRET')}
            </Button>
          </div>
        </React.Fragment>
      )}

      <ClientSecretModal show={showClientSecret} onHide={() => setShowClientSecret(false)} application={application} clientSecret={clientSecret!} />
    </React.Fragment>
  )
}