import React, {useEffect, useState} from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {REL} from '@app/constants';
import {Domain, deserializer, IpLookup, DomainAvailability, getTemplateHref, getHref} from '@app/models';
import { useDispatch, useSelector } from '@app/redux';
import {translate} from '@app/i18n';
import {singleton as config} from '@app/config';
import usePromise from '@hooks/usePromise';
import { createDomain } from '@app/redux/tenantSlice';
import { dispatchVerifyRequest, verifyRequest} from '@app/redux/apiSlice';

import DomainSSLSection from '../DomainEditor/Sections/SSL';

import './DomainCreater.scss';
import { validateDomainPrefix, trimDomainPrefix } from '@app/helpers';
import { identity } from 'lodash';
import useEnvironment from '@app/hooks/useEnvironment';
import useTracking from '@app/hooks/useTracking';

interface Props {
  onCancel: () => void,
  onSave: (domain: Domain) => void
}

type DomainType = "CRIIPTO" | "CLIENT";

export default function DomainCreater(props : Props) {
  const [domainTopName, setDomainTopName] = useState('');
  const [domainSubName, setDomainSubName] = useState('');
  const tenant = useSelector(state => state.tenant.tenant);
  const tracking = useTracking();
  const [domain, setDomain] = useState<Domain | null>(null);
  const environment = useEnvironment();
  const [domainType, setDomainType] = useState<DomainType>('CRIIPTO');
  const domainSuffix =
    domainType === 'CLIENT' ? `.${domainTopName}` :
      `.${config.easyIdDns}`;

  const subdomainSuffix = environment === 'TEST' ? '-test' : '';

  const domainName =
      domainType === 'CLIENT' ?
      domainSubName && domainTopName ? `${domainSubName}${subdomainSuffix}${domainSuffix}` : null :
      domainSubName ? `${domainSubName}${subdomainSuffix}${domainSuffix}` : null;

  const production = useSelector(state => state.environment.isProduction);
  const dispatch = useDispatch();

  const [verifyDomainResponse, verifyDomainState, verifyDomainTrigger, verifyDomainReset] = usePromise(async () => {
    return await dispatch(verifyRequest({
      method: 'GET',
      url: getTemplateHref(tenant!, REL.LOOKUP, {
        "hostname": domainName!
      })!
    })).then(unwrapResult).then(deserializer(IpLookup));
  }, false);

  const [domainAvailableResponse, domainAvailableState, domainAvailableTrigger, domainAvailableReset] = usePromise(async () => {
    return await dispatch(verifyRequest({
      method: 'GET',
      url: getTemplateHref(tenant!, REL.DNS_AVAILABLE, {
        "domain": domainName!
      })!
    })).then(unwrapResult).then(deserializer(DomainAvailability));
  }, false);

  useEffect(() => {
    verifyDomainReset();
    domainAvailableReset();
  }, [domainType]);

  const error = domainAvailableState.error || verifyDomainState.error || null;

  const hasChecked = (domainType !== 'CLIENT' || verifyDomainResponse) && domainAvailableResponse && true;
  const domainPrefixError = validateDomainPrefix(domainSubName, domainSuffix);
  const isValid =
    !error &&
    domainName &&
    hasChecked &&
    domainPrefixError === undefined &&
    (domainType !== 'CLIENT' || verifyDomainResponse && verifyDomainResponse.matches()) &&
    (domainAvailableResponse && domainAvailableResponse.available);

  const canSubmit = domainName && domainPrefixError === undefined;

  const handleCheck = async (event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    return await Promise.all([
      domainType === 'CLIENT' ? verifyDomainTrigger() : Promise.resolve(null),
      domainAvailableTrigger()
    ]);
  };

  const [, saveState, saveTrigger] = usePromise(async () => {
    if (!domainName) return;
    const [verifyDomainResponse, domainAvailableResponse] = await handleCheck();

    if (verifyDomainResponse) {
      if (!verifyDomainResponse.matches()) return;
    }

    if (!domainAvailableResponse.available) return;

    const domain = await dispatch(createDomain({name: domainName, production: environment === "PRODUCTION"})).then(unwrapResult);

    if (domainType === 'CLIENT') {
      await dispatchVerifyRequest(
        dispatch,
        {
          method: "POST",
          url: getHref(domain, REL.MANAGED_CERTIFICATES),
          contentType: undefined
        },
        identity
      );
    }

    tracking.domainCreated(domain);
    props.onSave(domain);
    return domain;
  }, false);

  const toggleClientDomain = (event: React.MouseEvent) => {
    event.preventDefault();
    setDomainType('CLIENT');
  }
  const toggleCriiptoDomain = (event: React.MouseEvent) => {
    event.preventDefault();
    setDomainType('CRIIPTO');
  }

  return (
    <div className="domain-creater">
      <div style={{maxWidth: '768px'}}>
        <div className="domain-input-group">
          <input
            type="text"
            className="form-control subdomain"
            placeholder="Your subdomain"
            value={domainSubName}
            onChange={(event) => setDomainSubName(trimDomainPrefix(event.target.value))}
            disabled={saveState.pending}
            required />
          {environment === 'TEST' ? (
            <div className="subdomain-suffix">-test.</div>
          ) : (
            <div className="subdomain-suffix">.</div>
          )}
          {domainType === 'CLIENT' ? (
            <React.Fragment>
              <input type="text"
                className="form-control"
                placeholder="your-domain.*"
                value={domainTopName}
                onChange={(event) => setDomainTopName(trimDomainPrefix(event.target.value))}
                disabled={saveState.pending}
                required />
              <div className="reset">
                <a href="#" onClick={toggleCriiptoDomain}>reset</a>
              </div>
            </React.Fragment>
          ) : (
            <div className="tld">
              {config.easyIdDns}
              <a href="#" onClick={toggleClientDomain}>change</a>
            </div>
          )}
        </div>
        <small className="form-text">
          {domainName || ''}&nbsp;
          {domainName && domainPrefixError !== undefined ? (
            <span className="text-danger">{domainPrefixError}</span>
          ) : null}
          {domainAvailableResponse && !domainAvailableResponse.available && (
            <span className="text-danger">{translate('DNS_NAME_UNAVAILABLE')}</span>
          )}
        </small>
        {verifyDomainResponse && !verifyDomainResponse.matches() && (
          <div className="alert alert-danger">
            {translate('VERIFY_ERROR', { expected: verifyDomainResponse.expected, actual: verifyDomainResponse.actual })}
          </div>
        )}
      </div>

      {domainType === 'CLIENT' && (
        <React.Fragment>
          <p>
            To make your domain work you must configure a <strong>DNS CNAME record</strong> to point from your domain ({domainName}) to <strong>idp.{config.easyIdDns}</strong>
          </p>
          <p>
            We will automatically provision a (free) managed TLS (https) certificate for you. <br />
            If you wish, you can upload your own certificate later.
          </p>
        </React.Fragment>
      )}

      {saveState.error ? saveState.error.message.includes('No such host is known') ? (
        <div className="alert alert-danger">No DNS record found, please make sure you have configured a <strong>DNS CNAME record</strong></div>
      ) : (
        <div className="alert alert-danger">{saveState.error.message}</div>
      ) : null}

      <div className="form-actions">
        <div className="button-group">
          <Button variant="default" type="button" onClick={props.onCancel} >
            {translate('CANCEL')}
          </Button>
          <Button variant="primary" type="submit" onClick={saveTrigger} disabled={!canSubmit} working={saveState.pending}>
            Add domain
          </Button>
        </div>
      </div>
    </div>
  );
}