import React, {useState} from 'react';
import {useDispatch, useSelector } from 'react-redux';
import {toggleSlackModal} from '@app/redux/modalsSlice';
import Modal from '@components/Modal';
import Button from '@components/Button';
import {RootState} from '@app/redux/store';
import { translate } from '@app/i18n';
import {singleton as config} from '@app/config';

export function SlackModal() {
  const dispatch = useDispatch();
  const showModal = useSelector((state : RootState) => state.modals.showSlackModal);
  const toggleModal = () => {
    dispatch(toggleSlackModal());
  }

  return (
    <Modal show={showModal} onHide={toggleModal} title="Slack support" color="grey">
      <Modal.Body>
        <SlackForm />
      </Modal.Body>
    </Modal>
  )
}


export function SlackForm() {
  const {supportSlackUrl, slackWebTask, slackChannel} = config;

  const [email, setEmail] = useState('');
  const [{pending, error, success}, setJoinState] = useState<{pending: boolean, success: boolean, error: Error | null}>({
    pending: false,
    success: false,
    error: null
  });

  const handleSubmit = (event : React.SyntheticEvent) => {
    event.preventDefault();

    if (!email) return;

    setJoinState(state => ({...state, pending : true, success: false, error: null}));

    fetch(`${slackWebTask}?channel=${encodeURIComponent(slackChannel)}&email=${encodeURIComponent(email)}`)
    .then(response => response.json())
    .then(data => {
      if (data.ok) {
        setJoinState(state => ({...state, pending : false, success: true}));
      }
      else {
        switch (data.error) {
          case "already_invited":
            throw new Error("Seems you have already received and invitation to our Slack community. Search for email from feedback@slack.com.");
            break;
          case "already_in_team":
            throw new Error("You are already a member of our Slack community.");
            break;
          case "user_disabled":
            throw new Error("You have onboarded before and automatically been deactivated after 2 months. Get in touch to be activated");
            break;
          default:
            throw new Error("Slack responded like this: " + data.error);
        }
      }
    })
    .catch(error => {
      setJoinState(state => ({...state, pending : false, error}));
    });
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>{translate('SLACK_JOIN_EMAIL')}</label>
          <div className="input-with-button">
            <input type="email" value={email} required onChange={(event) => setEmail(event.target.value)} placeholder="Your email address" />
            <Button variant="primary" type="submit" working={pending} autoWidth={true}>Send</Button>
          </div>
        </div>
      </form>
      {success && (
        <p style={{marginTop: '10px'}}><em>Slack no longer supports the user invitation API. But we have received your request, and instead we will send you an invite as soon as we have a few spare clock cycles!</em></p>
      )}
      {error && (
        <p className="text-danger" style={{marginTop: '10px'}}>{error.message}</p>
      )}
      <p style={{marginTop: '10px'}}>Or <a href={supportSlackUrl} target="_blank" className="!underline">go to Slack</a> if you already joined.</p>
    </React.Fragment>
  )
}
