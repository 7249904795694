import { TENANT_ID_PREFIX } from "@app/constants";
import { GaussOrganization } from "@app/models";
import { useSelector } from "@app/redux";
import useTenant from "./useTenant";

export default function useGaussOrganization() : GaussOrganization | undefined | null {
  const tenant = useTenant();
  const scopedClaims = useSelector(state => state.discovery.gauss.scopedClaims);
  const scopes = scopedClaims?.claimScopes;

  // Pending state
  if (tenant === undefined || scopes === undefined) return undefined;

  let organization = scopes.map(x => x.organization).reduce((memo, x) => x.id === tenant?.tenantId.replace(TENANT_ID_PREFIX, '') ? x : memo, null);

  return organization;
}