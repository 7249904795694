import React, {useCallback, useEffect} from 'react';
import {identity} from 'lodash';

import {useSelector, useDispatch} from '@app/redux';
import {fetchDomains} from '@redux/tenantSlice';
import {translate} from '@app/i18n';

import {
  NorwegianBankIdConfiguration,
  Tenant, DetailedIdentityProvider,
  getHref
} from '@app/models';

import {REL, CONTENT_TYPE} from '@app/constants';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch} from '@components/Form';

import EnvironmentDialog from '../EnvironmentDialog';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  configuration: NorwegianBankIdConfiguration,
  provider: DetailedIdentityProvider,
  apiRoot: Tenant
}

export interface FormValues {
  clientId : string
  clientSecret : string,
  requestAddress: boolean,
  requestEmail: boolean,
  requestPhone: boolean,
  requestSocialNo: boolean
}

export function NOBankIDProvider(props : Props) {
  const tenant = props.apiRoot;
  const {configuration} = props;

  const initialValues : FormValues = {
    clientId: configuration?.clientId || '',
    clientSecret: '',
    requestAddress: configuration?.requestAddress || false,
    requestEmail: configuration?.requestEmail || false,
    requestPhone: configuration?.requestPhone || false,
    requestSocialNo: configuration?.requestSocialNo || false,
  };

  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";

  const dispatch = useDispatch();
  const domains = useSelector(state => state.tenant.domains);

  useEffect(() => {
    dispatch(fetchDomains({force: false}));
  }, []);

  const saveProvider = useCallback(async (values : FormValues) => {
    let hrefSettings = getHref(tenant, REL.NO_BANKID_SETTINGS);

    return await dispatchVerifyRequest(
      dispatch,
      {
        url: hrefSettings,
        method: "POST",
        data: {
          ...values,
          production: isProduction
        },
        contentType: CONTENT_TYPE.NO_BANKID_SETTINGS
      },
      identity
    );
  }, [tenant]);

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return saveProvider(values);
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p className="green">⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_NOBANKID_WHAT_TO_AVOID')}</em></p>
          </EnvironmentDialog>
          <EnvironmentDialog environment="PRODUCTION" heading={(
            <React.Fragment>
              <h1>
                <strong>{translate('INFO_NO_BANKID_CLIENT')}</strong>
              </h1>
              <br />
              <h3>{translate('INFO_NO_BANKID_CALLBACK_URL_PROD')}</h3>
            </React.Fragment>
          )}>
            {domains.items?.filter(domain => domain.production).map(domain => (
              <p className="green" key={domain.name}>
                https://{domain.name}/NOBankIdOidc/Callback
              </p>
            ))}
          </EnvironmentDialog>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} Norwegian BankID
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label={translate('LABEL_NOBANKID_CLIENTID')}
                name="clientId"
                placeholder={translate('LABEL_NOBANKID_CLIENTID')}
              />
              <InputField
                type="password"
                label={translate('LABEL_NOBANKID_SECRET')}
                name="clientSecret"
                placeholder={translate('LABEL_NOBANKID_SECRET')}
              />
              <div className="form-group switch-group">
                <label className="control-label">{translate('VERIFIED_DATA')}</label>
                <Switch name="requestSocialNo" label={translate('LABEL_NOBANKID_SOCIALNOREQUIRED')} />
              </div>
              <div className="form-group switch-group">
                <label className="control-label">{translate('UNVERIFIED_DATA')}</label>
                <Switch name="requestAddress" label={translate('LABEL_NOBANKID_REQUESTADDRESS')} />
                <Switch name="requestEmail" label={translate('LABEL_NOBANKID_REQUESTEMAIL')} />
                <Switch name="requestPhone" label={translate('LABEL_NOBANKID_REQUESTEPHONE')} />
              </div>
              <p className="form-group">Learn more by visiting our <a href="https://docs.criipto.com/getting-started/no-bankid/" className="text-underline" target="_blank">BankID documentation</a></p>
              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your norwegian bankid settings have been updated` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_NO_BANKID_CLIENT')}</p>
              <p>{translate(isProduction ? 'INFO_NO_BANKID_CALLBACK_URL_PROD': 'INFO_NO_BANKID_CALLBACK_URL_TEST')}</p>
              {domains.items?.filter(domain => domain.production === isProduction).map(domain => (
                <p key={domain.name}>
                  https://{domain.name}/NOBankIdOidc/Callback
                </p>
              ))}
              <h4 style={{marginTop: '50px'}} className="text-underline">
                <a href="https://docs.criipto.com/getting-started/no-bankid/" target="_blank">BankID documentation</a>
              </h4>
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}
