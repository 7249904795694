/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RefreshApiKeyButton_apiKey = {
    readonly id: string;
    readonly " $refType": "RefreshApiKeyButton_apiKey";
};
export type RefreshApiKeyButton_apiKey$data = RefreshApiKeyButton_apiKey;
export type RefreshApiKeyButton_apiKey$key = {
    readonly " $data"?: RefreshApiKeyButton_apiKey$data;
    readonly " $fragmentRefs": FragmentRefs<"RefreshApiKeyButton_apiKey">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefreshApiKeyButton_apiKey",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ApplicationApiKey",
  "abstractKey": null
};
(node as any).hash = '1feb7bfe908982c19bec552d1937c02a';
export default node;
