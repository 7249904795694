import React, {useEffect, useState, useRef} from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import {singleton as config} from '@app/config';
import {Application, Domain, ApplicationValues} from '@app/models';
import {translate} from '@app/i18n';
import {Form, InputField, Switch, FormError, FormSuccess} from '@app/components/Form';
import {EnvironmentTag} from '@app/components/Tag';
import Button from '@app/components/Button';
import {useDispatch, useSelector} from '@app/redux';
import {createApplication, updateApplication, fetchApplications, createApplicationClientSecret, deleteApplicationClientSecret} from '@app/redux/tenantSlice';

import ApplicationRealmValidation from './ApplicationEditor/ApplicationRealmValidation';
import ReturnURLs from './ApplicationEditor/ReturnURLs';
import DomainField from './ApplicationEditor/DomainField';
import ApplicationNameField from './ApplicationEditor/ApplicationNameField';

import IdentityProvidersSection from './ApplicationEditor/Sections/IdentityProviders';
import AdvancedOptionsSection from './ApplicationEditor/Sections/AdvancedOptions';
import OIDCSection from './ApplicationEditor/Sections/OIDC';

import DeleteApplicationButton from './DeleteApplicationButton';
import ClientSecretModal from './ClientSecretModal';

import { authorizeUrl } from '@app/helpers';

import './ApplicationEditor.scss';
import useTracking from '@app/hooks/useTracking';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  application: Application,
  isCreate: boolean,
  onDelete: () => void,
  onCancel: () => void,
  onSave: (application?: Application) => void
}

function handleNulls(application: Application) {
  return application.clone({
    name: application.name || '',
    cssClass: application.cssClass || '',
    sessionLifetime: application.sessionLifetime || ''
  });
}

export default function ApplicationEditor(props : Props) {
  const {isCreate} = props;
  const dispatch = useDispatch();
  const tracking = useTracking();
  const [application, setApplication] = useState(props.application);
  const [isRealmValid, setRealmValid] = useState(!props.isCreate);
  const [domain, setDomain] = useState<Domain>(props.application.domain);
  const environment = useEnvironment();

  const [showClientSecret, setShowClientSecret] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  useEffect(() => {
    setApplication(props.application);
  }, [props.application]);

  const isAuth0 = application.hasTag('auth0');
  const isSignatures = application.hasTag('signatures');
  const isCreateAuth0 = props.isCreate && isAuth0;
  const isFormValid = (isRealmValid || isCreateAuth0) && domain;

  const handleClientSecretHide = () => {
    setShowClientSecret(false);
    if (isCreate) props.onSave(application);
  }

  const handleSubmit = async (values : Application) => {
    if (!isFormValid) return Promise.reject(new Error('Invalid form fields'));

    let applicationResult : Application;
    if (props.isCreate) {
      values.domain = domain;
      [, applicationResult] = unwrapResult(await dispatch(createApplication(values)));
    } else {
      [, applicationResult] = unwrapResult(await dispatch(updateApplication(values)));
      tracking.applicationUpdated(applicationResult);
    }

    const wasEnabled = application.oAuth2ClientSecret?.enabled;
    const isEnabled = values.oAuth2ClientSecret?.enabled;
    const createClientSecret = !wasEnabled && isEnabled;
    const deleteClientSecret = !createClientSecret && wasEnabled && !isEnabled;

    if (createClientSecret) {
      let response;
      [response, applicationResult] = await dispatch(createApplicationClientSecret(applicationResult)).then(unwrapResult);

      setClientSecret(response.clientSecret);
      setShowClientSecret(true);
    } else if (deleteClientSecret) {
      applicationResult = await dispatch(deleteApplicationClientSecret(applicationResult)).then(unwrapResult);
    }

    setApplication(applicationResult);

    if (isCreate) {
      if (createClientSecret) return;
      // Give some visual feedback before redirecting
      setTimeout(() => props.onSave(applicationResult), 3000);
      return;
    } else {
      props.onSave(applicationResult);
    }
  }

  if (isCreateAuth0) return <Auth0ApplicationEditor {...props} />;

  return (
    <div className="application-editor">
      <Form initialValues={handleNulls(application)} onSubmit={handleSubmit} data-test-id="form" key="application_editor">
        {({isPending, error, isSuccess}) => (
          <React.Fragment>
            <div className="form-section">
              <div className="form-fields">
                <ApplicationNameField />
                <DomainField domain={domain} isCreate={props.isCreate} onChange={setDomain} />

                <InputField<Application>
                  type="text"
                  label={translate('LABEL_CLIENT_ID')}
                  name="realm"
                  required
                  placeholder={translate('LABEL_CLIENT_ID')}
                  help={domain && <ApplicationRealmValidation domain={domain} application={application} onValid={setRealmValid} />}
                />

                <ReturnURLs />

                <Switch name="errorStrategy" label={translate('LABEL_APPLICATION_ERRORSTRATEGY')} off="legacy" on="protocol" />

                {config.enableDynamicScope && (
                  <React.Fragment>
                    <Switch name="scopeStrategy" label={translate('LABEL_APPLICATION_SCOPESTRATEGY')} off="static" on="dynamic" />
                    <div className="help-block">
                      Dynamic scopes let you define the data you need per request. <a href="https://docs.criipto.com/" target="_blank">Read more in our documentation</a>.
                    </div>
                  </React.Fragment>
                )}
              </div>
              <div className="info">
                <p className="info-name">{translate('INFO_NEW_APPLICATION')}</p>
                {application.tags.includes('auth0') ? (
                  <React.Fragment>
                    <p >{translate('INFO_NEW_APPLICATION_AUTH0_1')}</p>
                    <p>
                      <span >{translate('INFO_NEW_APPLICATION_AUTH0_2')}</span>
                      <a style={{color: "#2c3e50", textDecoration: "underline"}} target="_blank" rel="noopener" href="https://manage.auth0.com/#/applications" >
                        {translate('INFO_NEW_APPLICATION_AUTH0_3')}
                      </a>
                      <span >{translate('INFO_NEW_APPLICATION_AUTH0_4')}</span>
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p className="info-realm" ng-show="!isAuth0" >{translate('INFO_REALM')}</p>
                    <p className="info-callback-urls" ng-show="!isAuth0" >{translate('INFO_CALLBACK_URLS')}</p>
                  </React.Fragment>
                )}
                <p className="info-error-strategy" >{translate('INFO_APPLICATION_ERRORSTRATEGY')}</p>
              </div>
            </div>

            <h3>Select e-IDs</h3>
            <div className="form-section">
              <div className="form-fields">
                <IdentityProvidersSection
                  isCreate={props.isCreate}
                  application={props.application}
                />
              </div>
              <div className="info">&nbsp;</div>
            </div>
            <OIDCSection isCreate={isCreate} application={application} />

            {!props.isCreate && (
              <AdvancedOptionsSection />
            )}

            <div className="form-actions">
              {error && (
                <FormError error={error} />
              )}

              {isSuccess && (
                <FormSuccess message={isCreate ? 'Application created' : 'Application updated!'} />
              )}

              <div className="button-group vertical-center">
                <Button variant="primary" type="submit" working={isPending} disabled={!isFormValid}>
                  {translate('SAVE')}
                </Button>
                {!props.isCreate && (
                  <DeleteApplicationButton onDelete={props.onDelete} application={application} />
                )}

                <Button variant="default" type="submit" onClick={props.onCancel} >
                  {translate('CANCEL')}
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </Form>

      <ClientSecretModal show={showClientSecret} onHide={handleClientSecretHide} application={application} clientSecret={clientSecret!} />
    </div>
  );
}

interface Auth0Values extends ApplicationValues {
  connection: string
  instance: string
}

export function Auth0ApplicationEditor(props : Props) {
  const dispatch = useDispatch();
  const [domain, setDomain] = useState<Domain>(props.application.domain);
  const dialog = useRef<Window | null>();

  const initialValues : Auth0Values = {
    ...props.application,
    connection: "criipto-verify",
    instance: "https://auth0.auth0.com"
  };

  const handleCancel = () => {
    if (dialog.current) dialog.current.close();
    props.onCancel();
  }

  const handleSubmit = async (values : Auth0Values) => {
    const state = {
      domain: domain.name,
      origin: `${window.location.protocol}//${window.location.host}`,
      connection: values.connection,
      instance: values.instance,
      applicationName: values.name,
      authMethodsFilter: values.authMethods
    };

    let url = values.instance
        + `/i/oauth2/authorize?client_id=${config.apiBase}`
        + "&response_type=token&response_mode=form_post"
        + `&scope=${encodeURIComponent(config.auth0ConsentScope)}`
        + `&expiration=86400000`
        + `&redirect_uri=${config.auth0ConsentCallback}`
        + `&state=${btoa(JSON.stringify(state))}`;

    let eventMethod = (window.addEventListener as any) ? "addEventListener" : "attachEvent"
      , eventer = (window as any)[eventMethod] as (event: string, handler: (event: MessageEvent) => void, untrusted: boolean) => void
      , messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message"
      , width = 525
      , height = 805
      , left = (screen.width / 2) - (width / 2)
      , top = (screen.height / 2) - (height / 2)
      , dialogOptions = `resizable,scrollbars,width=${width},height=${height},left=${left},top=${top}`
      , interval : any = null;

    return new Promise<void>((resolve, reject) => {
      let handler = (e: MessageEvent) => {
        if (e && e.origin === config.apiBase && e.data === "success") {
          resolve();
        }
      };

      eventer(messageEvent, handler, false);
      dialog.current = window.open(url, "consent", dialogOptions);

      interval = setInterval(() => {
        if (dialog.current?.closed) return reject('Auth0 dialog was closed');
      }, 1000);
    }).then(() => handleComplete()).finally(() => {
      clearInterval(interval);
    });
  };

  const handleComplete = async () => {
    if (dialog.current) dialog.current.close();
    await dispatch(fetchApplications({force: true}));
    props.onSave();
  };

  return (
    <div className="application-editor">
      <Form initialValues={initialValues} onSubmit={handleSubmit} data-test-id="form" key="auth0_application_editor">
        {({isPending, error, isSuccess}) => (
          <React.Fragment>
            <h3>Add a new Auth0 application</h3>
            <EnvironmentTag />

            <div className="form-section">
              <div className="form-fields">
                <ApplicationNameField />
                <DomainField domain={domain} isCreate={props.isCreate} onChange={setDomain} />

              </div>
              <div className="info">
                <p className="info-name">{translate('INFO_NEW_APPLICATION')}</p>
                  <p >{translate('INFO_NEW_APPLICATION_AUTH0_1')}</p>
                  <p>
                    <span >{translate('INFO_NEW_APPLICATION_AUTH0_2')}</span>
                    <a style={{color: "#2c3e50", textDecoration: "underline"}} target="_blank" rel="noopener" href="https://manage.auth0.com/#/applications" >
                      {translate('INFO_NEW_APPLICATION_AUTH0_3')}
                    </a>
                    <span >{translate('INFO_NEW_APPLICATION_AUTH0_4')}</span>
                  </p>
              </div>
            </div>

            <IdentityProvidersSection isCreate={props.isCreate} application={props.application} />

            <h3>Auth0</h3>
            <div className="form-section">
              <div className="form-fields">
                <InputField<Auth0Values>
                  type="text"
                  label={translate('LABEL_AUTH0_CONNECTION')}
                  name="connection"
                  required
                  help={ <p className="help-block">{translate('INFO_AUTH0_CONNECTION')}</p>}
                />

                <InputField<Auth0Values>
                  type="url"
                  label={translate('LABEL_AUTH0_INSTANCE')}
                  name="instance"
                  required
                  help={ <p className="help-block">{translate('INFO_AUTH0_INSTANCE')}</p>}
                />
              </div>
              <div className="info">

              </div>
            </div>

            <div className="form-actions">
              {error && (
                <FormError error={error} />
              )}

              <div className="button-group vertical-center">
                <Button variant="primary" type="submit">
                  {translate('SAVE')}
                </Button>

                <Button variant="default" type="submit" onClick={handleCancel} >
                  {translate('CANCEL')}
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </Form>
    </div>
  );
}