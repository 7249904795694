import React, {useEffect, useReducer, useState} from 'react';
import {useDispatch, useSelector} from '@app/redux';
import { ConfirmModal } from '@app/components/Modal/Modal';
import { unwrapResult } from '@reduxjs/toolkit';
import { Backoffice, BackofficeEnvironmentRendition } from '@app/models';
import Button from '@app/components/Button/Button';
import useEnvironment from '@app/hooks/useEnvironment';
import { useBackofficeQuery, useUpdateBackofficeMutation } from '@app/api/verify';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import useTenant from '@app/hooks/useTenant';
import useGaussOrganization from '@app/hooks/useGaussOrganization';
import { getErrorString } from '@app/api/helper';
import { useCustomerQuery } from '@app/api/subscription';

function getDateTimeValue(input: string) {
  if (!input) return input;
  const date = new Date(input);
  var isoString = date.toISOString();
  return isoString.substring(0, (isoString.indexOf("T")|0) + 6|0);
}

export default function CriiptoAdminScreen() {
  const dispatch = useDispatch();
  const tenant = useTenant();
  const organization = useGaussOrganization();
  const backofficeResponse = useBackofficeQuery(tenant ?? skipToken);
  const [updateBackoffice] = useUpdateBackofficeMutation();
  const environment = useEnvironment();
  const customerResponse = useCustomerQuery(tenant ?? skipToken);
  const customer = customerResponse.data;

  const [showConfirmModal, toggleConfirmModal] = useReducer((value) => !value, false);
  const [settings, setSettings] = useState<Backoffice | null>(null);
  const [settingsError, setSettingsError] = useState<string | null>(null);

  useEffect(() => {
    if (settings?.signaturesBillable) {
      setSettings({
        ...settings,
        verifyBillable: true,
        billable: true
      });
    }
  }, [settings?.signaturesBillable, settings?.verifyBillable, settings?.billable]);

  const setDanishCprServices = (environment: 'test' | 'prod', value: Partial<BackofficeEnvironmentRendition["danishCprServices"]>) => {
    setSettings(settings => ({
      ...settings!,
      [environment]: {
        ...settings![environment],
        danishCprServices: {
          ...settings![environment].danishCprServices,
          ...value
        }
      }
    }));
  }

  const handleSave = async () => {
    await updateBackoffice({
      tenant: tenant!,
      backoffice: settings!
    }).unwrap().catch(err => {
      setSettingsError(getErrorString(err));
    });

    toggleConfirmModal();
  }

  useEffect(() => {
    if (!backofficeResponse.data) return;

    setSettings({
      ...backofficeResponse.data,
      verifyBillableUntil: getDateTimeValue(backofficeResponse.data.verifyBillableUntil),
      signaturesBillableUntil: getDateTimeValue(backofficeResponse.data.signaturesBillableUntil)
    });
  }, [backofficeResponse.data])

  if (!tenant) return null;
  if (!organization) return null;
  if (backofficeResponse.isLoading) return null;
  if (!settings) return null;

  if (backofficeResponse.error) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 alert alert-danger">
            {getErrorString(backofficeResponse.error)}
          </div>
        </div>
      </div>
    );
  }

  const backoffice = backofficeResponse.data!;

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label">Entity ID</label>
            <input className="form-control" readOnly={true} defaultValue={tenant.entityId} />
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label">Tenant ID</label>
            <input className="form-control" readOnly={true} defaultValue={tenant.tenantId} />
          </div>
        </div>
      </div>
      {customer && (
        <div className="row" style={{marginTop: '15px', marginBottom: '15px'}}>
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <label className="control-label"><a href={`https://manage.stripe.com/customers/${customer.id}`} target="_blank">Stripe customer ID</a></label>
              <input className="form-control" readOnly={true} defaultValue={customer.id} />
            </div>
          </div>
        </div>
      )}
      <div className="row" style={{marginTop: '15px', marginBottom: '15px'}}>
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label" htmlFor="billable_verify">Billable (Verify)</label>
            <label className="switch">
              <input id="billable_verify" onChange={(event) => setSettings(settings => ({...settings!, billable: event.target.checked, verifyBillable: event.target.checked}))} type="checkbox" checked={settings.billable} /><i />
            </label>
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="billable_verify_until">Until</label>
            <div className="flex flex-row items-center">
              <input type="datetime-local" className="form-control h-[41px]" id="billable_verify_until" value={settings.verifyBillableUntil} onChange={((event) => setSettings(settings => ({...settings!, verifyBillableUntil: event.target.value})))} />
              <Button variant="default" className="!m-0" autoWidth onClick={(() => setSettings(settings => ({...settings!, verifyBillableUntil: ''})))}>Clear</Button>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label" htmlFor="billable_signatures">Billable (Signatures, Verify MUST also be enabled)</label>
            <label className="switch">
              <input id="billable_signatures" onChange={(event) => setSettings(settings => ({...settings!, signaturesBillable: event.target.checked}))} type="checkbox" checked={settings.signaturesBillable} /><i />
            </label>
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="billable_signatures_until">Until</label>
            <div className="flex flex-row items-center">
              <input type="datetime-local" className="form-control h-[41px]" id="billable_signatures_until" value={settings.signaturesBillableUntil} onChange={((event) => setSettings(settings => ({...settings!, signaturesBillableUntil: event.target.value})))} />
              <Button variant="default" className="!m-0" autoWidth onClick={(() => setSettings(settings => ({...settings!, signaturesBillableUntil: ''})))}>Clear</Button>
            </div>
          </div>
        </div>
      </div>

      <h3 className={`text-environment-${environment} mt-16`}>Environment specific - {environment === 'PRODUCTION' ? 'PRODUCTION' : 'TEST'}</h3>
      <div className="row" style={{marginTop: '15px'}}>
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label" htmlFor="prod_pocesAddressEnabled">POCES Address Enabled</label>
            <label className="switch">
              <input
                id="prod_pocesAddressEnabled"
                onChange={(event) => setDanishCprServices(environment === 'PRODUCTION' ? 'prod' : 'test', {pocesAddressEnabled: event.target.checked})}
                type="checkbox"
                checked={(environment === 'PRODUCTION' ? settings?.prod : settings?.test).danishCprServices.pocesAddressEnabled || false} /><i />
            </label>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="form-group">
            <label className="control-label" htmlFor="prod_companySignatoryEnabled">Company Signatory Enabled</label>
            <label className="switch">
              <input
                id="prod_companySignatoryEnabled"
                onChange={(event) => setDanishCprServices(environment === 'PRODUCTION' ? 'prod' : 'test', {companySignatoryEnabled: event.target.checked})}
                type="checkbox"
                checked={(environment === 'PRODUCTION' ? settings?.prod : settings?.test).danishCprServices.companySignatoryEnabled || false} /><i />
            </label>
          </div>
        </div>
      </div>
      <Button type="button" variant="primary" onClick={() => toggleConfirmModal()} style={{marginTop: '15px'}}>Save</Button>

      <ConfirmModal
        title={`Save settings for ${organization.name}?`}
        show={showConfirmModal}
        onHide={() => toggleConfirmModal()}
        confirmVariant="primary"
        confirmText={`Save settings`}
        onConfirm={() => handleSave()}
      >
        <p>
          Heads-up: You are about to:
        </p>
        <ul>
          {settings.billable !== backoffice.billable ? (
            <li>{settings.billable ? 'Enable' : 'Disable'} billable for {organization.name}</li>
          ) : null}

          {settings.verifyBillable !== backoffice.verifyBillable ? (
            <li>{settings.verifyBillable ? 'Enable' : 'Disable'} billable (verify) for {organization.name}</li>
          ) : null}

          {settings.verifyBillableUntil !== backoffice.verifyBillableUntil ? (
            <li>Set verify billable until to "{settings.verifyBillableUntil}" {organization.name}</li>
          ) : null}

          {settings.signaturesBillable !== backoffice.signaturesBillable ? (
            <li>{settings.signaturesBillable ? 'Enable' : 'Disable'} billable (signatures) for {organization.name}</li>
          ) : null}

          {settings.signaturesBillableUntil !== backoffice.signaturesBillableUntil ? (
            <li>Set signatures billable until to "{settings.signaturesBillableUntil}" {organization.name}</li>
          ) : null}

          {settings.prod.danishCprServices.pocesAddressEnabled !== backoffice.prod.danishCprServices.pocesAddressEnabled ? (
            <li>{settings.prod.danishCprServices.pocesAddressEnabled ? 'Enable' : 'Disable'} poces addresses for {organization.name} in PROD</li>
          ) : null}

          {settings.prod.danishCprServices.companySignatoryEnabled !== backoffice.prod.danishCprServices.companySignatoryEnabled ? (
            <li>{settings.prod.danishCprServices.companySignatoryEnabled ? 'Enable' : 'Disable'} company signatories for {organization.name} in PROD</li>
          ) : null}

          {settings.test.danishCprServices.pocesAddressEnabled !== backoffice.test.danishCprServices.pocesAddressEnabled ? (
            <li>{settings.test.danishCprServices.pocesAddressEnabled ? 'Enable' : 'Disable'} poces addresses for {organization.name} in TEST</li>
          ) : null}

          {settings.test.danishCprServices.companySignatoryEnabled !== backoffice.test.danishCprServices.companySignatoryEnabled ? (
            <li>{settings.test.danishCprServices.companySignatoryEnabled ? 'Enable' : 'Disable'} company signatories for {organization.name} in TEST</li>
          ) : null}
        </ul>

        {settingsError ? (
          <div className="alert alert-danger">
            {settingsError}
          </div>
        ) : null}
      </ConfirmModal>
    </div>
  );
}