import React from 'react';
import {translate} from '@app/i18n';

import {EnvironmentTag} from '@components/Tag';
import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';

export function FITeliaProvider() {
  return (
    <React.Fragment>
      <EnvironmentDialog environment="TEST" />
      <div className="row">
        <div className="col-sm-6">
          <h3>
            {translate('CONNECT')} Finnish Trust Network, FTN
          </h3>
          <EnvironmentTag />
          <a href="https://docs.criipto.com/eid-specifics/order-fi-bankid/" target="_blank" className="button button-primary">Order Finnish BankID</a>
        </div>
        <div className="col-sm-6">
        </div>
      </div>
    </React.Fragment>
  )
}