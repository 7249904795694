import React, { useReducer, useState } from 'react';

import { CustomerResponse, PlanRendition, useCustomerQuery, usePlansQuery } from '@app/api/subscription';
import usePermissions from '@app/hooks/usePermissions';
import useTenant from '@app/hooks/useTenant';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { getErrorString } from '@app/api/helper';
import { Tag } from '@app/components/Tag';
import { upperFirst } from 'lodash';
import Panel from '@app/components/Panel';
import moment from 'moment';
import {singleton as config} from '@app/config';
import Button, { AnchorButton, LinkButton } from '@app/components/Button/Button';
import CancelSubscriptionModal from './components/CancelSubscriptionModal/CancelSubscriptionModal';
import SubscriptionPlanModal from './components/SubscriptionPlanModal/SubscriptionPlanModal';

function getCustomerPlan(customer: CustomerResponse | null, plans: PlanRendition[]) {
  if (!customer) {
    return plans.find(s => "type" in s && s.type === "free")!;
  }
  if (customer.subscription?.status === 'canceled') {
    return plans.find(s => "type" in s && s.type === "free")!;
  }
  return plans.find(s => s.id === customer.plan);
}

export default function SubscriptionScreen() {
  const tenant = useTenant();
  const permissions = usePermissions();
  const customerResponse = useCustomerQuery(tenant && permissions?.financials ? tenant : skipToken);
  const plans = usePlansQuery();
  const customer = customerResponse.data;
  const [showCancelModal, toggleCancelModal] = useReducer(value => !value, false);
  const [showPlanModal, togglePlanModal] = useReducer(value => !value, false);
  const [planChanged, setPlanChanged] = useState(false);

  const subscription = customer?.subscription;
  const isCancelling = subscription?.cancelAtPeriodEnd;
  const plan = customer && plans.data ? getCustomerPlan(customer, plans.data) : undefined;

  if (!permissions?.financials || customerResponse.isLoading) return (<i className="fa fa-spinner fa-pulse fa-2x" />);
  if (customerResponse.error) {
    return (
      <div className="alert alert-danger">
        {getErrorString(customerResponse.error) || 'An error occurred'}
      </div>
    );
  }

  const handleCancelSubscription = (event: React.MouseEvent) => {
    event.preventDefault();
    toggleCancelModal();
  }

  const handleChangePlan = (event: React.MouseEvent) => {
    event.preventDefault();
    togglePlanModal();
  }

  return (
    <React.Fragment>
      {customerResponse.isLoading && (<i className="fa fa-spinner fa-pulse fa-2x" />)}
      {subscription ? (
        <div className="flex gap-[16px]">
          <Panel title="Subscription status">
            <div className="flex flex-col items-start gap-[16px]">
              <Tag>{isCancelling ? 'Canceling' : upperFirst(subscription.status)}</Tag>
              {isCancelling ? (
                'Cancels on ' + moment(subscription.periodEndAt).format('MMMM Do, YYYY')
              ) : subscription.status === 'canceled' ? (
                '-'
              ) : (
                'Renews on ' + moment(subscription.periodEndAt).format('MMMM Do, YYYY')
              )}
            </div>
            {!isCancelling && subscription.status !== 'canceled' ? (
              <a href="#" onClick={handleCancelSubscription} className="!underline">Cancel subscription</a>
            ) : (
              <a href="#" onClick={handleChangePlan} className="!underline">Reactivate subscription</a>
            )}
          </Panel>

          <Panel title="Your plan">
            <div className="mb-16">
              {plan ? "month" in plan ? (
                <React.Fragment>
                  <p className="!m-0 text-3xl">
                    {plan.name} - {plan[customer.period!][customer.currency!].price.toLocaleString()} {customer?.currency?.toUpperCase()} per {customer?.period}
                  </p>
                  <p className="!m-0 !mt-8 text-2xl">
                    {plan.volume.toLocaleString()} logins or native signatures monthly
                  </p>
                  <p className="!m-0 !mt-4 text-xl">
                    {customer.currency!.toUpperCase()} {plan[customer.period!][customer.currency!].unitprice} per transaction if you use more than {plan.volume.toLocaleString()} transactions.
                  </p>
                  <p className="!m-0 !mt-4 text-xl">
                    <a href="https://www.criipto.com/pricing#fees-block" target="_blank">Fees for underlying national/bank e-ID billed separately</a>
                  </p>
                </React.Fragment>
              ) : plan.name : 'N/A'}
            </div>
            <Button variant="default" onClick={handleChangePlan}>Change plan</Button>
          </Panel>
        </div>
      ) : (
        <div>
          Currently on a free/developer plan.
          Please <a href={`${config.SUBSCRIPTION_FRONTEND_URL}/${btoa(tenant!.entityId)}/plans`} className="!underline" target="_blank">sign up for a subscription.</a>
        </div>
      )}

      {planChanged && (
        <div className="alert alert-success">
          Subscription plan updated!
        </div>
      )}

      <CancelSubscriptionModal show={showCancelModal} onHide={toggleCancelModal} />
      <SubscriptionPlanModal show={showPlanModal} onHide={togglePlanModal} onChange={() => {togglePlanModal(); setPlanChanged(true);}} />
    </React.Fragment>
  );
}