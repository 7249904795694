import {Variables, GraphQLResponse} from 'relay-runtime';

import {singleton as config} from '@app/config';
import store from '@app/redux/store';

async function fetchGraphQL(text : string, variables : Variables) {
  const response = await fetch(config.signaturesGraphQLEndpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.getState().auth.access_token}`
    },
    body: JSON.stringify({
      query: text,
      variables,
    }),
  });

  // Get the response as JSON
  return await response.json() as GraphQLResponse;
}

export default fetchGraphQL;