import React from 'react';
import { Provider as ReduxProvider, useSelector, useDispatch } from 'react-redux';

import {default as reduxStore, RootState} from '@app/redux/store';
import {toggleProductionUpgradeModal} from '@app/redux/modalsSlice';
import {set as setEnvironment} from '@app/redux/environmentSlice';
import Modal from '@components/Modal';
import { GaussOrganization } from '@app/models';
import useTenant from '@app/hooks/useTenant';
import {singleton as config} from '@app/config';

import './ProductionUpgradeRequiredModal.scss';
import useGaussOrganization from '@app/hooks/useGaussOrganization';

export function ProductionUpgradeRequiredModal() {
  const dispatch = useDispatch();
  const tenant = useTenant();
  const organization = useGaussOrganization();
  const showModal = useSelector((state : RootState) => state.modals.showProductionUpgradeModal);
  const hideModal = () => {
    dispatch(setEnvironment({tenant: tenant!, environment: 'TEST'}));
    dispatch(toggleProductionUpgradeModal());
  };
  const title = <span><strong>{organization?.name ?? 'Your organization'}</strong> is not ready for production.</span>;

  return (
    <Modal show={showModal} onHide={hideModal} title={title} center={true} className="production-upgrade-required-modal">
      <Modal.Body>
        <p>Ready to go to production and let users log in with their real e-ID?</p>
        <a href={config.SUBSCRIPTION_FRONTEND_URL} target="_blank" className="button button-primary">
          <i className="fa fa-plus text-TEST" />
          <span className="text-TEST">Upgrade</span>&nbsp; to paid subscription
        </a>
      </Modal.Body>
    </Modal>
  )
}

export default ProductionUpgradeRequiredModal;