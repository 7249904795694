import { gaussApi } from '@app/api/gauss';
import { subscriptionApi } from '@app/api/subscription';
import { verifyApi } from '@app/api/verify';
import { configureStore } from '@reduxjs/toolkit';

import rootReducer from './reducers';

export const createStore = () => configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
    .concat(gaussApi.middleware)
    .concat(subscriptionApi.middleware)
    .concat(verifyApi.middleware)
});

export type RootState = ReturnType<typeof rootReducer>;
const store = createStore();

export type AppDispatch = typeof store.dispatch;

export default store;
