import React from 'react';
import Modal, { ModalProps } from "@app/components/Modal";
import InvitationForm from "../InvitationForm"

type Props = {
  show: ModalProps["show"]
  onHide: ModalProps["onHide"]
}

export default function UserInvitationModal(props: Props) {
  return (
    <Modal show={props.show} onHide={props.onHide} title="Invite user to organization">
      <Modal.Body>
        <InvitationForm />
      </Modal.Body>
    </Modal>
  )
}