import { Environment } from "@app/models";
import { useSelector } from "@app/redux";
import useTenant from "./useTenant";

export default function useEnvironment() : Environment {
  const tenant = useTenant();
  const environments = useSelector(state => state.environment);

  if (!tenant) return 'TEST';
  if (!environments[tenant.tenantId]) return 'TEST';
  return environments[tenant.tenantId]?.environment || 'TEST';
}
