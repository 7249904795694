import React, {useEffect} from 'react';

import {Domain} from '@app/models';
import {translate} from '@app/i18n';
import {useDispatch, useSelector} from '@app/redux';
import { fetchDomains } from '@app/redux/tenantSlice';
import { Link } from 'react-router-dom';
import useTenant from '@app/hooks/useTenant';
import { TENANT_ID_PREFIX } from '@app/constants';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  domain?: Domain,
  onChange: (domain : Domain) => void,
  isCreate: boolean
}

export default function DomainField(props : Props) {
  const {domain, onChange, isCreate} = props;
  const environment = useEnvironment();
  const domains = useSelector(state => state.tenant.domains.items?.filter(domain => domain.production === (environment === "PRODUCTION")) ?? []);
  const dispatch = useDispatch();
  const tenant = useTenant();

  useEffect(() => {
    if (!tenant) return;
    dispatch(fetchDomains({force: false}));
  }, [tenant]);

  useEffect(() => {
    if (!isCreate) return;
    if (domains.length !== 1) return;

    onChange(domains[0]);
  }, [domains, isCreate]);

  if (isCreate || !domain) {
    return (
      <div className="form-group">
        <label className="control-label">{translate('LABEL_CHOOSE_DOMAIN')}</label>
        <select onChange={(event) => onChange(domains?.find(search => search.name === event.target.value)!)} value={domain?.name} required>
          <option value="">Choose a domain</option>
          {domains?.map(domain => (
            <option key={domain.name} value={domain.name}>{domain.name}</option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <div className="form-group">
      <label className="control-label">{translate('LABEL_CHOOSE_DOMAIN')}</label>
      <input className="form-control" disabled={true} value={domain.name} />
    </div>
  );
}