import React from 'react';

import {Application} from '@app/models';
import {translate} from '@app/i18n';
import {InputField} from '@app/components/Form';
import {useSelector} from '@app/redux';

export default function AdvancedOptionsSection() {
  const organization = useSelector(state => state.tenant.organization);

  return (
    <React.Fragment>
      <h3>{translate('LABEL_ADVANCED_OPTIONS')}</h3>
      <div className="form-section">
        <div className="form-fields">
          <InputField<Application>
            type="text"
            label={translate('LABEL_FRAME_ORIGINS')}
            name="frameOrigins"
            placeholder="your-domain.com"
          />
          <InputField<Application>
            type="text"
            label={translate('LABEL_TOKEN_LIFETIME')}
            name="sessionLifetime"
            placeholder="1200"
            required
          />
          <InputField<Application>
            type="text"
            label={translate('LABEL_APPLICATION_CSS_CLASS')}
            name="cssClass"
            placeholder="custom-css-class"
          />
        </div>
        <div className="info hidden-xs">
          <p>{translate('INFO_FRAME_ORIGINS')}</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>{translate('INFO_CSS_CLASS')}</p>
        </div>
      </div>
    </React.Fragment>
  )
}