
import React, {useMemo, useState} from 'react';
import {LOGIN_NEMID_CALLBACK_URL} from '@app/constants';
import {createNemIDAuthClient} from '@app/auth';
import jwt_decode from "jwt-decode";

import Button from '@components/Button';
import { useField } from 'formik';
import { DanishMitIdApplyRendition } from '@app/models';

interface MocesClaims {
  'gov:saml:attribute:CvrNumberIdentifier': string,
  'oid:2.5.4.10': string
}

interface Props {
  acrValue: string,
  children: React.ReactNode,
  style?: React.CSSProperties
}

export default function NemIdLoginButton(props: Props) {
  const [error, setError] = useState<Error | null>(null);
  const [, , {setValue: setVatIdentifier}] = useField<DanishMitIdApplyRendition["vatIdentifier"]>('vatIdentifier');
  const [, , {setValue: setCompanyName}] = useField<DanishMitIdApplyRendition["companyName"]>('companyName');
  const [, , {setValue: setNemIdToken}] = useField<DanishMitIdApplyRendition["nemIdToken"]>('nemIdToken');
  const nemIdAuthClient = useMemo(() => createNemIDAuthClient(), []);

  const handleLogin = async () => {
    await nemIdAuthClient.popup.authorize({
      redirectUri: LOGIN_NEMID_CALLBACK_URL,
      acrValues: props.acrValue
    }).then(response => {
      if (response.error) {
        setError(new Error(response.error));
        return;
      }

      /*
      * We unsafely decode the token here to provide a preview of data
      * Backend will verify token and override data in case of modifications
      * TODO: @criipto/auth-js should return a claims object, that via ACR value and (namespaced|compact) can be type guarded into a specific claims type
      */
      const decoded = jwt_decode<MocesClaims>(response.id_token!);
      const vatNumber = decoded['gov:saml:attribute:CvrNumberIdentifier'].trim();
      setVatIdentifier({
        vatCountryCode: 'DK',
        vatNumber: vatNumber
      });
      setCompanyName(decoded['oid:2.5.4.10'].replace(`// CVR:${vatNumber}`, '').trim());
      setNemIdToken(response.id_token!);
    }).catch(err => {
      setError(err);
    });
  };

  return (
    <React.Fragment>
      {error && (
        <div className="alert alert-danger">
          {error.message}
        </div>
      )}
      <Button variant="primary" onClick={handleLogin} style={props.style}>{props.children}</Button>
    </React.Fragment>
  )
}