import moment from 'moment';
import { range } from 'lodash';
import { BUCKET, DIMENSION, TRANSACTION_TYPE } from '@app/api/subscription';
import { Environment } from '@app/models';

export type DateSelection = {
  preset?: string
  from: moment.Moment
  to: moment.Moment
};

export const COLORS = [
  '#0c4d87',
  '#7ac8f5',
  '#dc6a62',
  '#e49b4d',
  '#f0c97c',
  '#b3b3b1',
  '#e0e0dd',
  '#9ef1f6',
  '#e69691',
  '#6d94b7'
];

export const CHART_HEIGHT = 600;
export const X_AXIS_HEIGHT = 55;
export const Y_AXIS_WIDTH = 55

export const FAKE_CHART = {
  yTicks: [
      0,
      100,
      200,
      300,
      400,
      500,
      600,
      700,
      800,
      900,
      1000
  ],
  data: range(0, 30).reverse().map(days => ({
      bucket: moment().subtract(days, 'day').toJSON(),
      total: 0
  })),
  generateData: (dateSelection: DateSelection) => range(0, dateSelection.from.diff(dateSelection.to, 'days')).reverse().map(days => ({
      bucket: moment().subtract(days, 'day').toJSON(),
      total: 0
  }))
};

type DIMENSION_SETTINGS = {
  transactionType: {
    [key in TRANSACTION_TYPE]: {
      label: string
    }
  } & {label: string}
  authenticationType: {
    _valueLabel: (input: string) => string,
    label: string
  },
  domain: {
    _valueLabel: (input: string) => string,
    label: string
  },
  environment: {[key in Environment]: any}
}
export const DIMENSION_SETTINGS : DIMENSION_SETTINGS = {
  transactionType: {
    authentication: {
      label: 'Logins',
    },
    signature: {
      label: 'Native Signatures'
    },
    'criipto-signature': {
      label: 'Criipto Signatures'
    },
    label: 'Transaction Type'
  },
  authenticationType: {
    _valueLabel: (value: string) => value.replace('urn:grn:authn:dk:', '').replace('urn:grn:authn:se:', '').replace('urn:grn:authn:no:', '').replace('urn:grn:authn:fi:', ''),
    label: 'Authentication Type'
  },
  domain: {
    _valueLabel: (value: string) => value,
    label: 'Domain'
  },
  environment: {
    PRODUCTION: {
      label: 'Production',
    },
    TEST: {
      label: 'Test'
    }
  }
};

type BUCKET_SETTINGS = {
  [key in BUCKET]: {
    label: string,
    tickFormatter: (input: string) => string,
    tooltipFormatter: (input: string) => string,
    exportFormatter: (input: string) => string
  }
}

function removeTimezone(input: string) {
  return input.substr(0, 19);
}

export const BUCKET_SETTINGS = {
  HOUR: {
    label: 'Hour',
    tickFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('HH:mm'),
    tooltipFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('HH:mm, dddd MMM D, YYYY'),
    exportFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('YYYY-MM-DD HH:mm')
  },
  DAY: {
    label: 'Day',
    tickFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('MMM D'),
    tooltipFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('dddd MMM D, YYYY'),
    exportFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('YYYY-MM-DD')
},
  WEEK: {
    label: 'Week',
    tickFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('MMM D'),
    tooltipFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('dddd MMM D, YYYY'),
    exportFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('YYYY-MM-DD')
  },
  MONTH: {
    label: 'Month',
    tickFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('MMM YYYY'),
    tooltipFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('MMM YYYY'),
    exportFormatter: (bucket: string) => moment(removeTimezone(bucket)).format('YYYY-MM')
  }
};