export class EasyIdConfig {
  apiBase: string;
  subscriptionBase: string;
  subscriptionAuth0ClientID: string;
  subscriptionAuth0Audience: string;
  subscriptionGaussAppId: string;
  gaussBase: string;
  gaussUI: string;
  gaussAppId: string;
  auth0Dns: string;
  auth0ClientId: string;
  auth0Audience: string;
  auth0ConsentScope: string;
  auth0ConsentCallback: string;
  easyIdDns: string;
  slackChannel: string;
  slackWebTask: string;
  supportSlackUrl: string;
  slackNewTenantNotificationUrl: string;
  applicationName: string;
  hideNorwegianBankIdOidcSettings: string;
  enableHeadsUpNotification: string;
  norwegianBankIdOidc: boolean;
  enableProviderStylingPreview: boolean;
  providerStylingViewsAuthority: string;
  providerStylingViewsPort: number;
  enableItsme: boolean;
  enableDKMitID: boolean;
  enableGermanSofort: boolean;
  enableFinnishTelia: boolean;
  enableBelgianEID: boolean;
  enableOnboardingMitID: boolean;
  enableExtensibility: boolean;
  enableDynamicScope: boolean;
  nemIdVerifyDomain: string;
  nemIdVerifyRealm: string;
  signaturesEnabled: boolean;
  signaturesGraphQLEndpoint: string;

  STRIPE_PK: string;
  SUBSCRIPTION_FRONTEND_URL: string;

  constructor() {
    this.applicationName = "Criipto Verify"
    this.readAll();
  }

  readAll() {
    let config = (window as any)["easyIdConfig"] as { [key: string]: any };

    if (!config) {
      config = {};
    }

    this.apiBase = config.apiBase ?? process.env.apiBase;
    this.subscriptionBase = config.subscriptionBase ?? process.env.subscriptionBase;
    this.subscriptionAuth0ClientID = config.subscriptionAuth0ClientID ?? process.env.subscriptionAuth0ClientID;
    this.subscriptionAuth0Audience = config.subscriptionAuth0Audience ?? process.env.subscriptionAuth0Audience;
    this.subscriptionGaussAppId = config.subscriptionGaussAppId ?? process.env.subscriptionGaussAppId;
    this.gaussBase = config.gaussBase ?? process.env.gaussBase;
    this.gaussUI = config.gaussUI ?? process.env.gaussUI;
    this.gaussAppId = config.gaussAppId ?? process.env.gaussAppId;
    this.auth0Dns = config.auth0Dns ?? process.env.auth0Dns;
    this.auth0ClientId = config.auth0ClientId ?? process.env.auth0ClientId;
    this.auth0Audience = config.auth0Audience ?? process.env.auth0Audience;
    this.auth0ConsentScope = config.auth0ConsentScope ?? process.env.auth0ConsentScope;
    this.auth0ConsentCallback = config.auth0ConsentCallback ?? process.env.auth0ConsentCallback;
    this.easyIdDns = config.easyIdDns ?? process.env.easyIdDns ?? "grean.id";
    this.slackWebTask = config.slackWebTask ?? process.env.slackWebTask ?? "https://criipto.eu8.webtask.io/slackinvite";
    this.slackChannel = config.slackChannel ?? process.env.slackChannel ?? "C288XEY4U"; // defaults to #easyid-support
    this.supportSlackUrl = config.supportSlackUrl ?? process.env.supportSlackUrl ?? "https://grean.slack.com";
    this.slackNewTenantNotificationUrl = config.slackNewTenantNotificationUrl ?? process.env.slackNewTenantNotificationUrl;
    this.enableHeadsUpNotification = config.enableHeadsUpNotification ?? process.env.enableHeadsUpNotification;
    this.hideNorwegianBankIdOidcSettings = config.hideNorwegianBankIdOidcSettings ?? process.env.hideNorwegianBankIdOidcSettings;
    this.norwegianBankIdOidc = (config.norwegianBankIdOidc ?? process.env.norwegianBankIdOidc) === "true" ? true : false;
    this.enableProviderStylingPreview = (config.enableProviderStylingPreview ?? process.env.enableProviderStylingPreview) === "true" ? true : false;
    this.providerStylingViewsAuthority = config.providerStylingViewsAuthority ?? process.env.providerStylingViewsAuthority;
    this.providerStylingViewsPort = parseInt(config.providerStylingViewsPort ?? process.env.providerStylingViewsPort, 10);
    this.enableItsme = (config.enableItsme ?? process.env.enableItsme) === "true" ? true : false;
    this.enableDKMitID = (config.enableDKMitID ?? process.env.enableDKMitID) === "true" ? true : false;
    this.enableGermanSofort = (config.enableGermanSofort ?? process.env.enableGermanSofort) === "true" ? true : false;
    this.enableFinnishTelia = (config.enableFinnishTelia ?? process.env.enableFinnishTelia) === "true" ? true : false;
    this.enableBelgianEID = (config.enableBelgianEID ?? process.env.enableBelgianEID) === "true" ? true : false;
    this.enableOnboardingMitID = (config.enableOnboardingMitID ?? process.env.enableOnboardingMitID) === "true" ? true : false;
    this.enableExtensibility = (config.enableExtensibility ?? process.env.enableExtensibility) === "true" ? true : false;
    this.enableDynamicScope = (config.enableDynamicScope ?? process.env.enableDynamicScope) === "true" ? true : false;
    this.nemIdVerifyDomain = config.nemIdVerifyDomain ?? process.env.nemIdVerifyDomain;
    this.nemIdVerifyRealm = config.nemIdVerifyRealm ?? process.env.nemIdVerifyRealm;
    this.signaturesGraphQLEndpoint = config.signaturesGraphQLEndpoint ?? process.env.signaturesGraphQLEndpoint;
    this.signaturesEnabled = this.signaturesGraphQLEndpoint && this.signaturesGraphQLEndpoint.length ? true : false;
    this.STRIPE_PK = process.env.STRIPE_PK!;
    this.SUBSCRIPTION_FRONTEND_URL = process.env.SUBSCRIPTION_FRONTEND_URL!;
  }
}

export const singleton = new EasyIdConfig();