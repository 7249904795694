import React, { useState } from 'react';
import {Modal, ModalProps} from '@app/components/Modal';
import { Domain } from '@app/models';
import DomainCertificateUploadForm from '../DomainCertificateUploadForm';
import { FormSuccess } from '@app/components/Form/Form';
import Button from '@app/components/Button/Button';

interface Props {
  onHide: ModalProps["onHide"]
  show: ModalProps["show"]
  domain: Domain
}

export default function DomainCertificateUploadModal(props : Props) {
  const {domain} = props;
  const [saved, setSaved] = useState(false);

  return (
    <Modal show={props.show} onHide={props.onHide} title={`Upload SSL certificate for ${domain.name}`}>
      <Modal.Body>
        {saved ? (
          <React.Fragment>
            <FormSuccess message="Domain SSL certificate uploaded" />
            <Button variant="primary" onClick={props.onHide}>Close</Button>
          </React.Fragment>
        ) : (
          <DomainCertificateUploadForm domain={domain} onSave={() => setSaved(true)} />
        )}
      </Modal.Body>
    </Modal>
  );
}