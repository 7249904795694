import React, {useCallback} from 'react';
import {identity, omit} from 'lodash';

import {useDispatch, useSelector} from '@app/redux';
import {RootState} from '@redux/store';
import {translate} from '@app/i18n';

import {
  DanishNemIdConfiguration, PocesCprStrategy,
  Tenant, DetailedIdentityProvider, getHref
} from '@app/models';

import {REL, CONTENT_TYPE} from '@app/constants';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch} from '@components/Form';
import CertificateForm from '@components/CertificateForm';

import EnvironmentDialog from '../EnvironmentDialog';
import { dispatchVerifyRequest, valuesToFormData } from '@app/redux/apiSlice';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  configuration: DanishNemIdConfiguration,
  provider: DetailedIdentityProvider,
  apiRoot: Tenant
}

export interface FormValues {
  spIdPid : string,
  logonTo : string,
  cprStrategy : PocesCprStrategy,
  certificate?: {
    file: File,
    password: string
  }
}

function apiValues(values : FormValues) {
  return {
    ...omit(values, 'certificate')
  };
}

export function DKNemIDProvider(props : Props) {
  const tenant = props.apiRoot;
  const {configuration} = props;
  const dispatch = useDispatch();

  const initialValues : FormValues = {
    spIdPid: configuration?.spIdPid,
    logonTo: configuration?.logonTo,
    cprStrategy: configuration?.cprStrategy
  };

  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";

  const saveProvider = useCallback(async (values : FormValues) => {
    let hrefUpload = getHref(tenant, REL.DK_NEMID);
    let hrefSettings = getHref(tenant, REL.DK_NEMID_SETTINGS);

    if (values.certificate?.file) {
      return await dispatchVerifyRequest(
        dispatch,
        {
          url: hrefUpload,
          method: 'POST',
          data: valuesToFormData({
            file: values.certificate.file,
            metadata: JSON.stringify({
              ...apiValues(values),
              password: values.certificate.password,
              production: isProduction
            }),
          })
        },
        identity
      );
    }

    return await dispatchVerifyRequest(
      dispatch,
      {
        url: hrefSettings,
        method: "POST",
        data: {
          ...apiValues(values),
          production: isProduction
        },
        contentType: CONTENT_TYPE.DKNEMID_SETTINGS
      },
      identity
    );
  }, [tenant]);

  let cprStrategyOff : PocesCprStrategy = "omit";
  let cprStrategyOn : PocesCprStrategy = "askuser";

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return saveProvider(values);
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p className="green">⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_DKNEMID_WHAT_TO_AVOID')}</em></p>
            <p className="green">❓{translate('HINT_ID_SERVICE_CONFIG_WHEN_IN_DOUBT_DKNEMID')}</p>
          </EnvironmentDialog>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} Danish NemID
              </h3>
              <EnvironmentTag />
              <CertificateForm
                certificate={configuration?.vocesCertificate}
                fileFieldName="certificate.file"
                passwordFieldName="certificate.password"
              />
              <InputField
                type="text"
                label={translate('LABEL_DKNEMID_PIDCPR_SPID')}
                name="spIdPid"
                placeholder={translate('HINT_DKNEMID_PIDCPR_SPID')}
              />
              <Switch name="cprStrategy" off={cprStrategyOff} on={cprStrategyOn} label={translate('HINT_DKNEMID_CPR_FOR_POCES')} />
              <InputField
                type="text"
                label={translate('LABEL_DKNEMID_LOGON_TO')}
                name="logonTo"
                placeholder={translate('HINT_DKNEMID_LOGON_TO')}
              />
              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your danish nemid settings have been updated`} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6 nemid">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_CERTIFICATE', { provider: 'Danish NemID' })}</p>
              <p>{translate('INFO_DK_NEMID')}</p>
              <p>
                {translate('INFO_DK_NEMID_TU_SIGNUP')}
                <em>Tjenesteudbyder aftale</em>&nbsp;
                <a href="https://www.nets.eu/dk-da/kundeservice/nemid-tjenesteudbyder/bestilling" target="_blank">{translate('INFO_DK_NEMID_TU_SIGNUP_WHERE')}</a>
              </p>
              <em><p>{translate('INFO_DK_NEMID_DETAILS')}</p></em>
              <p >{translate('INFO_DK_NEMID_PUBLICSECTOR')}</p>
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}
