import React from 'react';
import {Application} from '@app/models';
import {translate} from '@app/i18n';
import CodeFlow from '../CodeFlow';
import OAuth2Config from '../OAuth2Config';

interface Props {
  application: Application,
  isCreate: boolean
}

export default function OIDCSection(props : Props) {
  return (
    <React.Fragment>
      <h3>{translate('OIDC')}</h3>
      <div className="form-section">
        <div className="form-fields">
          <CodeFlow isCreate={props.isCreate} application={props.application} />
          <OAuth2Config />
        </div>
        <div className="info">
          <p>{translate('INFO_OAUTH2')}</p>
          <span><strong>{translate('HINT_OAUTH2_AUTHZCODEFLOW_ENABLED')} toggle</strong></span>
          <p>{translate('INFO_OAUTH2_AUTHZCODEFLOW_ENABLED')}</p>
          <span><strong>{translate('LABEL_OAUTH2_CALLBACKONLOCATIONHASH')} toggle</strong></span>
          <p>{translate('INFO_OAUTH2_CALLBACKONLOCATIONHASH')}</p>
          <span><strong>{translate('HINT_OAUTH2_NULL_CLAIMS_VALUES_HANDLING')} toggle</strong></span>
          <p>{translate('INFO_OAUTH2_NULL_CLAIMS_VALUES_HANDLING')}</p>
          <div>
              <span><strong>{translate('LABEL_CHOOSE_USERINFO_RESPONSE_STRATEGY')} dropdown</strong></span>
              <p>{translate('INFO_OIDC_USERINFO_STRATEGY')}</p>
              <span><strong>{translate('LABEL_CHOOSE_JWT_PROPERTY_FORMAT')} dropdown</strong></span>
              <p>{translate('INFO_OIDC_JWT_PROPERTY_FORMAT')}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}