import React, {useEffect, useState} from 'react';

import {collections, PreviewCollection, PreviewItem, Styling} from './constants';

import {useDispatch, useSelector} from '@app/redux';
import {fetchDomains, fetchApplications} from '@app/redux/tenantSlice';
import { singleton as config } from '@app/config';

import './PreviewsScreen.scss';
import WindowRenderer from './components/WindowRenderer';
import IframeRenderer from './components/IframeRenderer';
import { Application, Domain } from '@app/models';

interface Props {
  styling: Styling
}

type StringPOJO = {[key: string]: string};

export default function PreviewsScreen(props : Props) {
  const {styling} = props;
  const [width, setWidth] = useState(375);
  const [height, setHeight] = useState(667);

  const [simulatedDomain, setSimulatedDomain] = useState<null | Domain>(null);
  const [simulatedApplication, setSimulatedApplication] = useState<null | Application>(null);

  const [windowRendererItem, setWindowRendererItem] = useState<[PreviewItem, PreviewCollection] | null>(null);

  const dispatch = useDispatch();
  const domains = useSelector(state => state.tenant.domains.items);
  const applications = useSelector(state => state.tenant.applications.items);

  useEffect(() => {
    dispatch(fetchApplications({force: false})); // Also fetches domains
  }, []);

  const [languages, setLanguages] = useState<StringPOJO>(() => {
    return collections.reduce((memo, collection) => {
      memo[collection.key] = collection.languages[0];
      return memo;
    }, {} as StringPOJO);
  });

  const domain = `samples.${config.easyIdDns}`;

  const handleFullscreenClick = (event: React.MouseEvent, collection: PreviewCollection, item: PreviewItem) => {
    event.preventDefault();
    setWindowRendererItem([item, collection]);
  }

  let bodyCssClass = simulatedDomain ? `host-${simulatedDomain.name.replace(/(\.)/g, '-')}` : undefined;
  bodyCssClass = simulatedApplication && simulatedApplication.cssClass ? `${bodyCssClass || ''} ${simulatedApplication.cssClass}` : bodyCssClass;

  return (
    <div className="container-fluid styling-preview-container">
      <div className="container settings">
        <div className="dimensions">
          {/* <button type="button" className="btn" ng-click="vm.preview.refresh()">Refresh preview</button> */}
          <input type="text" className="form-control" value={width} onChange={(event) => setWidth(parseInt(event.target.value, 10))} />
          &nbsp;x&nbsp;
          <input type="text" className="form-control" value={height} onChange={(event) => setHeight(parseInt(event.target.value, 10))} />
        </div>
        <div>
          <select
            value={simulatedDomain?.name}
            onChange={(event) => setSimulatedDomain(domains?.find(s => s.name === event.target.value) || null)}
            className="form-control"
          >
            <option>Simulate domain</option>
            {domains?.map(domain => (
              <option value={domain.name}>{domain.name}</option>
            ))}
          </select>
        </div>

        <div>
          <select
            value={simulatedApplication?.realm}
            onChange={(event) => setSimulatedApplication(applications?.find(s => s.realm === event.target.value) || null)}
            className="form-control"
          >
            <option>Simulate application</option>
            {applications?.filter(s => !simulatedDomain || s.domain.name === simulatedDomain.name).map(application => (
              <option value={application.realm}>{application.name}</option>
            ))}
          </select>
        </div>
      </div>

      {collections.map(collection => (
        <div className="styling-preview-collection" key={collection.key}>
          <div className="styling-preview-collection-header">
            <h2 className="styling-preview">{collection.label}</h2>
            <select onChange={(event) => setLanguages(languages => ({...languages, [collection.key]: event.target.value}))}>
              {collection.languages.map(language => (
                <option value={language} key={language}>{language}</option>
              ))}
            </select>
            <div className="clearfix"></div>
          </div>
          <div className="application-body styling-preview-collection-items">
            {collection.items.map(item => (
              <div className="styling-preview-item" key={item.id} style={{width: `${width}px`}}>
                <div className="header">
                  <a href="#" title="View in fullscreen" className="expand" onClick={(event) => handleFullscreenClick(event, collection, item)}>
                    <i className="fa fa-expand" />
                  </a>
                  <h3 className="styling-preview">{item.label}</h3>
                </div>
                <div style={{width: `${width}px`, height: `${height}px`}} className="styling-preview-iframe-wrapper">
                  <IframeRenderer
                    style={{width: `${width}px`, height: `${height}px`}}
                    item={item}
                    domain={domain}
                    styling={styling}
                    language={languages[collection.key]}
                    bodyCssClass={bodyCssClass}
                  />
                </div>
              </div>
            ))}
            <div className="clearfix"></div>
          </div>
        </div>
      ))}

      <WindowRenderer
        item={windowRendererItem && windowRendererItem[0]}
        styling={styling}
        domain={domain}
        language={windowRendererItem ? languages[windowRendererItem[1].key] : 'en'}
        onClose={() => setWindowRendererItem(null)}
        bodyCssClass={bodyCssClass}
      />
    </div>
  );
}