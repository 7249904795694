import React, {useReducer} from 'react';
import Button, { ButtonProps } from '@app/components/Button/Button';
import UserInvitationModal from '../UserInvitationModal/UserInvitationModal';

type Props = Omit<ButtonProps, "onClick">;

export default function UserInvitationButton(props: Props) {
  const [showModal, toggleModal] = useReducer(value => !value, false);
  return (
    <React.Fragment>
      <Button {...props} onClick={toggleModal}>
        {props.children || 'Add user'}
      </Button>
      <UserInvitationModal show={showModal} onHide={toggleModal} />
    </React.Fragment>
  )
}