import React, { useEffect } from 'react';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import {singleton as config} from '@app/config';
import {
  Elements
} from '@stripe/react-stripe-js';

let stripePromise : Promise<Stripe | null> | null = null;

export function StripeElementsProvider(props: {children: React.ReactNode}) {
  useEffect(() => {
    stripePromise = stripePromise || loadStripe(config.STRIPE_PK);
  }, []);

  return (
    <Elements stripe={stripePromise}>
      {props.children}
    </Elements>
  );
}