import React, {useEffect} from 'react';
import {sortBy, toLower, upperFirst} from 'lodash';

import {Switch, Route, useRouteMatch, Link, useHistory, useParams, NavLink} from 'react-router-dom';

import {useSelector, useDispatch} from '@app/redux';
import {fetchDomains} from '@app/redux/tenantSlice';
import {translate} from '@app/i18n';
import {Domain, Environment} from '@app/models';

import {LinkButton} from '@app/components/Button/Button';

import DeleteDomainButton from './components/DeleteDomainButton';
import DomainEditor from './components/DomainEditor';
import DomainCreater from './components/DomainCreater';

import './DomainsScreen.scss';
import { EnvironmentTag, Tag } from '@app/components/Tag';
import LogsScreen from '../LogsScreen/LogsScreen';
import AnalyticsScreen from '../AnalyticsScreen/AnalyticsScreen';
import DomainTokenSigningKeysSection from './components/DomainEditor/Sections/TokenSigningKeys';
import DomainSSOSection from './components/DomainEditor/Sections/SSO';
import useEnvironment from '@app/hooks/useEnvironment';

export function DomainsScreen() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const environment = useEnvironment();
  const domainsState = useSelector(state => state.tenant.domains.state);
  const tenant = useSelector(state => state.tenant.tenant);
  const domains = useSelector(state => (state.tenant.domains.items || []).filter(item => item.production === (environment === "PRODUCTION")));

  useEffect(() => {
    if (!tenant) return;
    dispatch(fetchDomains({force: false}));
  }, [tenant]);

  // Render dummy accordion (actually uses links)
  return (
    <React.Fragment>
      <div className="app-content-header flex align-items-center justify-content-between">
        <h1>Domains</h1>
        <div>
          <LinkButton variant="primary" to={`${match.url}/add`}>
            <i className="fa fa-plus"></i>&nbsp;Add {environment.toLowerCase()} domain
          </LinkButton>
        </div>
      </div>
      <div className="app-content-main">
        <div className="accordion">
          {domainsState.pending ? (
            <i className="fa fa-spinner fa-pulse fa-2x" />
          ) : (
            <React.Fragment>
              <div className="accordion-header">
                <div className="row">
                  <div className="col-xs-9">
                    <h3>{environment === 'PRODUCTION' ? translate('PRODUCTION_DOMAINS') : translate('TEST_DOMAINS')}</h3>
                  </div>
                  <div className="col-xs-3">

                  </div>
                </div>
              </div>

              {sortBy(domains, ['name']).map(domain => (
                <div className="accordion-item" key={domain.name}>
                  <Link to={`${match.url}/${domain.name}`} className="accordion-header clickable">
                    <div className="row">
                      <div className="col-xs-9">
                        <strong>{domain.name}</strong>
                      </div>
                      <div className="col-xs-3" onClick={(e) => e.stopPropagation() /* Fix react-portal click propagation */}>
                        <div className="button-group flex-end">
                          <DeleteDomainButton domain={domain} className="button-small" />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </React.Fragment>
          )}

          <div className="accordion-item ">
            <div className="accordion-header large">
              <div className="row">
                <div className="col-xs-12 flex flex-end">
                  <LinkButton variant="primary" to={`${match.url}/add`} className="button-icon">
                    <i className="fa fa-plus"></i>
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export function EditDomainScreen(props : {parentUrl: string}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{domain: string}>();
  const match = useRouteMatch();
  const domainsState = useSelector(state => state.tenant.domains.state);
  const domain = useSelector(state => state.tenant.domains.items?.find(search => search.name === params.domain));
  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";
  const domainEnvironment : Environment = domain?.production ? "PRODUCTION" : "TEST";

  useEffect(() => {
    dispatch(fetchDomains({force: false}));
  }, []);

  useEffect(() => {
    if (!domain) return;
    if (isProduction !== domain.production) {
      history.push(props.parentUrl);
    }
  }, [isProduction, domain]);

  const handleDelete = () => {
    history.push(props.parentUrl);
  };
  const handleCancel = () => {
    history.push(props.parentUrl);
  };

  return (
    <React.Fragment>
      <div className="app-content-header with-tabs">
        <div className="breadcrumb">
          <Link to={props.parentUrl}>Domains</Link>
          <i className="fa fa-angle-right" />
          <div>Domain Details</div>
        </div>

        <h1>{domain?.name}</h1>

        <div className="app-content-tabs">
          <ul>
            <li>
              <NavLink to={match.url} exact={true} activeClassName="active">Settings</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/sso`} exact={true} activeClassName="active">SSO</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/keys`} exact={true} activeClassName="active">Keys</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/logs`} exact={true} activeClassName="active">Logs</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/analytics`} exact={true} activeClassName="active">Analytics</NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Switch>
        <Route path={match.path} exact={true}>
          <div className="app-tab-content">
            {domainsState.pending || !domain ? (<i className="fa fa-spinner fa-pulse fa-2x" />) : (
              <DomainEditor
                domain={domain}
                onDelete={handleDelete}
                onSave={() => {}}
                onCancel={handleCancel}
              />
            )}
          </div>
        </Route>
        <Route path={`${match.path}/sso`}>
          <div className="app-tab-content">
            {domainsState.pending || !domain ? (<i className="fa fa-spinner fa-pulse fa-2x" />) : (
              <React.Fragment>
                <p>
                  Configure your domains SSO settings per protocol.
                </p>
                <p>
                  You can also disable SSO for OAuth2 requests by using <code>prompt=login</code>
                </p>
                <DomainSSOSection domain={domain} />
              </React.Fragment>
            )}
          </div>
        </Route>
        <Route path={`${match.path}/keys`}>
          <div className="app-tab-content">
            {domainsState.pending || !domain ? (<i className="fa fa-spinner fa-pulse fa-2x" />) : (
              <React.Fragment>
                <p>
                  Here, you can manage the active token signing key for your Criipto Verify domain.
                  The token signing key is trusted by your application.
                </p>
                <p>
                  Sometimes, depending on the platform your application is built on, it is pre-registered there.<br />
                  In those cases, you must register new token signing keys in your applications trust - store before activating a new key.
                  Otherwise, your application will not be able to validate the tokens signed by the new keys.
                </p>
                <DomainTokenSigningKeysSection domain={domain} />
              </React.Fragment>
            )}
          </div>
        </Route>
        <Route path={`${match.path}/logs`}>
          <div className="app-tab-content">
            {domainsState.pending || !domain ? (<i className="fa fa-spinner fa-pulse fa-2x" />) : (
              <LogsScreen domain={domain} />
            )}
          </div>
        </Route>
        <Route path={`${match.path}/analytics`}>
          <div className="app-tab-content full-screen">
            {domainsState.pending || !domain ? (<i className="fa fa-spinner fa-pulse fa-2x" />) : (
              <AnalyticsScreen domain={domain} />
            )}
          </div>
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export function CreateDomainScreen(props : {parentUrl: string}) {
  const history = useHistory();

  const handleSave = (domain? : Domain) => {
    if (!domain) return history.push(props.parentUrl);
    history.push(props.parentUrl + `/${domain.name}`);
  };

  const handleCancel = () => {
    history.push(props.parentUrl);
  };

  return (
    <React.Fragment>
      <div className="app-content-header">
        <div className="breadcrumb">
          <Link to={props.parentUrl}>Domains</Link>
          <i className="fa fa-angle-right" />
          <div>Add new domain</div>
        </div>

        <h1>Add new domain</h1>
      </div>
      <div className="app-content-main">
        <DomainCreater onSave={handleSave} onCancel={handleCancel} />
      </div>
    </React.Fragment>
  );
}

export default function DomainRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.path} exact={true}>
        <DomainsScreen />
      </Route>
      <Route path={`${match.path}/add`}>
        <CreateDomainScreen parentUrl={match.url} />
      </Route>
      <Route path={`${match.path}/:domain`}>
        <EditDomainScreen parentUrl={match.url} />
      </Route>
    </Switch>
  )
}