import { useApplicationRoleGrantsQuery, useUpdateOrganizationUserMutation } from '@app/api/gauss';
import Button from '@app/components/Button';
import { ModalProps, Modal } from '@app/components/Modal';
import { GaussApplicationRole, GaussOrganization, GaussOrganizationUser, GaussServicePermission, permissionsToRole, roleGrantsToRoles, ROLE_DESCRIPTION, ROLE_TO_PERMISSIONS, UserRole, USER_ROLES } from '@app/models';
import React, { useEffect, useState } from 'react';
import Permissions from '../Permissions';

export type EditUserModalProps = {
  show: ModalProps["show"]
  onHide: ModalProps["onHide"]
  organization: GaussOrganization
  user: GaussOrganizationUser
}

export default function EditUserModal(props: EditUserModalProps) {
  const {user, organization} = props;

  const applicationRoleGrants = useApplicationRoleGrantsQuery(organization);
  const allRoles = applicationRoleGrants.data ? roleGrantsToRoles(applicationRoleGrants.data.applicationRoleGrants) : undefined;

  const userPermissions = user.servicePermissions.map(s => s.id);
  const userRoles = allRoles?.filter(s => user.roles.some(ur => ur.applicationRoles.some(uar => uar.roleId === s.roleId))).map(role => role.role)

  const existingRole = userRoles && applicationRoleGrants.data ?
    permissionsToRole({roles: userRoles, servicePermissions: userPermissions}, applicationRoleGrants.data) : undefined

  const [role, setRole] = useState<UserRole | undefined>(existingRole);

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const [updateUser, updateUserResult] = useUpdateOrganizationUserMutation();

  useEffect(() => {
    setRole(existingRole);
  }, [existingRole]);

  const handleSelectRole = (event: React.ChangeEvent<HTMLSelectElement> | UserRole) => {
    const role = (event as any).target ? (event as any).target.value as UserRole : event as UserRole;
    setRole(role);
    const permissions = ROLE_TO_PERMISSIONS[role];
    if (permissions === '__CUSTOM__') return true;
    if (permissions === '__ALL__') {
      setSelectedRoles(
        applicationRoleGrants!.data!.applicationRoleGrants.reduce((memo, roleGrant) => {
          return memo.concat(roleGrant.applicationRoles.map(role => role.roleId))
        }, [] as string[])
      );
      setSelectedPermissions(applicationRoleGrants!.data!.servicePermissions.map(permission => permission.id));
      return true;
    }

    setSelectedRoles(applicationRoleGrants!.data!.applicationRoleGrants.reduce((memo, roleGrant) => {
      return memo.concat(roleGrant.applicationRoles.filter(role => permissions.roles.includes(role.role)).map(role => role.roleId));
    }, [] as string[]));
    setSelectedPermissions(permissions.servicePermissions);
  }

  const handlePermission = (item: GaussApplicationRole | GaussServicePermission, selected: boolean) => {
    if ("roleId" in item) {
      setSelectedRoles(roles => {
        if (selected) {
          return roles.concat(item.roleId);
        }
        return roles.filter(s => s != item.roleId);
      });

      return
    }

    setSelectedPermissions(permissions => {
      if (selected) {
        return permissions.concat(item.id);
      }
      return permissions.filter(s => s != item.id);
    });
  }

  const handleHide = () => {
    handleSelectRole(existingRole!);
    props.onHide();
  };

  const handleSave = async () => {
    const rolePermissions = ROLE_TO_PERMISSIONS[role!];
    const servicePermissions =
      rolePermissions === '__CUSTOM__' ? selectedPermissions :
      rolePermissions === '__ALL__' ? applicationRoleGrants!.data!.servicePermissions.map(permission => permission.id) :
      rolePermissions.servicePermissions;

    const allServicePermissions = applicationRoleGrants!.data!.servicePermissions;
    const allRoles = applicationRoleGrants!.data!.applicationRoleGrants.flatMap(r => r.applicationRoles);

    await updateUser({
      organization,
      user,
      newRoles:
        rolePermissions === '__ALL__' ? allRoles :
        rolePermissions === '__CUSTOM__' ? allRoles.filter(role => selectedRoles.includes(role.roleId)) :
        allRoles.filter(role => rolePermissions.roles.includes(role.role)),
      newPermissions:
        rolePermissions === '__ALL__' ? allServicePermissions :
        rolePermissions === '__CUSTOM__' ? allServicePermissions.filter(permission => servicePermissions.includes(permission.id)) :
        allServicePermissions.filter(permission => rolePermissions.servicePermissions.includes(permission.id)),
    }).unwrap();

    props.onHide();
  };

  return (
    <Modal {...props} title="Edit user" onHide={handleHide}>
      <Modal.Body>
        <div className="form-group">
          <label className="control-label">Role</label>
          <select className="form-control" name="role" required onChange={handleSelectRole} value={role}>
            {USER_ROLES.map(role => (
              <option key={role} value={role}>{role}</option>
            ))}
          </select>
          {role && role !== 'Custom' ? (
            <p className="help-block">
              {ROLE_DESCRIPTION[role]}
            </p>
          ) : null}
        </div>
        {role === 'Custom' ? (
           <div className="flex flex-wrap mt-[15px]">
            <Permissions
              applicationRoleGrants={applicationRoleGrants.data!.applicationRoleGrants.map(roleGrant => ({
                ...roleGrant,
                applicationRoles: roleGrant.applicationRoles.map(role => ({
                  ...role,
                  selected: selectedRoles.includes(role.roleId)
                }))
              }))}

              servicePermissions={applicationRoleGrants.data!.servicePermissions.map(permission => ({
                ...permission,
                selected: selectedPermissions.includes(permission.id)
              }))}

              selectable={true}
              onSelect={handlePermission}
            />
          </div>
        ) : null}

        {updateUserResult.isError ? (
          <div className="alert alert-danger mt-[15px]">
            {"message" in updateUserResult.error ? updateUserResult.error.message : updateUserResult.error}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-col lg:flex-row gap-[8px] justify-between">
          <Button variant="default" onClick={handleHide}>Cancel</Button>
          <Button variant="primary" working={updateUserResult.isLoading} onClick={handleSave}>Save</Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}