import React, {useState} from 'react';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {ConfirmModal} from '@app/components/Modal';
import {RefreshApiKeyButton_apiKey$key} from './__generated__/RefreshApiKeyButton_apiKey.graphql';
import {RefreshApiKeyButton_application$key} from './__generated__/RefreshApiKeyButton_application.graphql';
import {RefreshApiKeyButtonMutation} from './__generated__/RefreshApiKeyButtonMutation.graphql';
import Button from '@app/components/Button';
import useMutation from '@app/hooks/useMutation';
import ApiKeyModal from '../ApiKeyModal/ApiKeyModal';


interface Props {
  apiKey: RefreshApiKeyButton_apiKey$key,
  application: RefreshApiKeyButton_application$key
}

export default function RefreshApiKeyButton(props: Props) {
  const [showApiKeySecret, setShowApiKeySecret] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const apiKey = useFragment(graphql`
    fragment RefreshApiKeyButton_apiKey on ApplicationApiKey {
      id
    }
  `, props.apiKey);

  const application = useFragment(graphql`
    fragment RefreshApiKeyButton_application on Application {
      id
    }
  `, props.application);

  const [refreshMutationExecutor, refreshMutationState] = useMutation<RefreshApiKeyButtonMutation>(graphql`
    mutation RefreshApiKeyButtonMutation($input: RefreshApplicationApiKeyInput!) {
      refreshApplicationApiKey(input: $input) {
        application {
          id
          ... ApiKeys_application
        }

        apiKey {
          ... ApiKeyModal_apiKey
        }
      }
    }
  `);

  const handleClick = () => {
    setShowConfirm(true);
  };

  const handleConfirm = () => {
    return refreshMutationExecutor.executePromise({
      input: {
        applicationId: application.id,
        apiKeyId: apiKey.id
      }
    }).then(() => {
      setShowConfirm(false);
      setShowApiKeySecret(true);
    }).catch(() => {});
  }

  return (
    <React.Fragment>
      <Button variant="primary" className="button-small" autoWidth onClick={handleClick} data-test-id="button">
        <i className="fa fa-redo" />
      </Button>
      <ConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        title={"Renew API key"}
        confirmText={"Renew API key"}
        confirmVariant="primary"
        data-test-id="confirm-modal"
      >
        Are you sure you want to renew this API key? Any calls using the old client secret will start to fail.

        {refreshMutationState.error && (
          <div className="alert alert-danger" data-test-id="error">{refreshMutationState.error.message}</div>
        )}
      </ConfirmModal>

      <ApiKeyModal show={showApiKeySecret} onHide={() => setShowApiKeySecret(false)} apiKey={refreshMutationState.response?.refreshApplicationApiKey!.apiKey!} data-test-id="modal" />
    </React.Fragment>
  )
}