import React from 'react';
import { useRouteMatch, Switch, Route, Link } from 'react-router-dom';

import { singleton as config } from '@app/config';
import LogsScreen from '../LogsScreen/LogsScreen';
import Button, { LinkButton } from '@app/components/Button/Button';
import Panel from '@app/components/Panel/Panel';
import usePermissions from '@app/hooks/usePermissions';
import ExtensibilityScreen from '../ExtensibilityScreen/ExtensibilityScreen';

import logsImage from './images/logs.png';
import extensibilityImage from './images/extensibility.png';
import adminImage from './images/admin.png';
import CriiptoAdminScreen from '../CriiptoAdminScreen/CriiptoAdminScreen';

export function ToolsScreen() {
  const match = useRouteMatch();
  const permissions = usePermissions();

  return (
    <React.Fragment>
      <div className="app-content-header">
        <h1>Tools</h1>
      </div>
      <div className="app-content-main">
        <div className="flex flex-column lg:flex-row gap-[16px]">
          {(permissions?.testDomains || permissions?.productionDomains) && (
            <Panel title="Logs" image={logsImage} to={`${match.url}/logs`}>
              <p>
                <strong>Debug authorize requests</strong> by investigating domain logs. Also accessible via indiviudal domains.
              </p>
              <Button variant="default">Access logs</Button>
            </Panel>
          )}
          {config.enableExtensibility && permissions?.extensibility && (
            <Panel title="Extensibility" image={extensibilityImage} to={`${match.url}/extensibility`}>
              <p>
                Customize the login experience.
              </p>
              <Button variant="default">Access extensibility</Button>
            </Panel>
          )}
          {permissions?.criiptoAdmin && (
            <Panel title="Criipto Admin" image={adminImage} to={`${match.url}/criipto-admin`}>
              <p>&nbsp;</p>
              <Button variant="default">Criipto Admin</Button>
            </Panel>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default function ToolsRoutes() {
  const match = useRouteMatch();
  const permissions = usePermissions();

  return (
    <Switch>
      <Route path={match.path} exact={true}>
        <ToolsScreen />
      </Route>
      {(permissions?.testDomains || permissions?.productionDomains) && (
        <Route path={`${match.path}/logs/:domain?`}>
          <div className="app-content-header">
            <div className="breadcrumb">
              <Link to={match.url}>Tools</Link>
              <i className="fa fa-angle-right" />
              <div>Logs</div>
            </div>
            <h1>Logs</h1>
          </div>
          <div className="app-content-main">
            <LogsScreen />
          </div>
        </Route>
      )}
      {config.enableExtensibility && permissions?.extensibility && (
        <Route path={`${match.path}/extensibility`}>
          <div className="app-content-header">
            <div className="breadcrumb">
              <Link to={match.url}>Tools</Link>
              <i className="fa fa-angle-right" />
              <div>Extensibility</div>
            </div>
            <h1>Extensibility</h1>
          </div>
          <div className="app-content-main full-screen">
            <ExtensibilityScreen />
          </div>
        </Route>
      )}
      {permissions?.criiptoAdmin ? (
        <Route path={`${match.path}/criipto-admin`}>
          <div className="app-content-header">
            <div className="breadcrumb">
              <Link to={match.url}>Tools</Link>
              <i className="fa fa-angle-right" />
              <div>Criipto Admin</div>
            </div>
            <h1>Criipto Admin</h1>
          </div>
          <div className="app-content-main">
            <CriiptoAdminScreen />
          </div>
        </Route>
      ) : null}
    </Switch>
  )
}