import useTenant from '@app/hooks/useTenant';
import { useBillableQuery } from '@app/api/verify';
import { skipToken } from '@reduxjs/toolkit/dist/query';

export default function useBillable(product: 'VERIFY' | 'SIGNATURES') {
  const tenant = useTenant();
  const billable = useBillableQuery(tenant ?? skipToken);

  if (product === 'VERIFY') {
    return billable?.data?.verifyBillable || billable?.data?.billable;
  }
  if (product === 'SIGNATURES') {
    return billable?.data?.signaturesBillable;
  }
  throw new Error('Unreachable code');
}