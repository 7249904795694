import React, { useEffect } from 'react';
import './StylingScreen.scss';

import {translate} from '@app/i18n';
import {Form, Switch, InputField, FormError} from '@app/components/Form';
import Button from '@app/components/Button';

import {useDispatch, useSelector} from '@app/redux';
import {fetchStyling, updateStyling} from '@app/redux/tenantSlice';
import { IdServicesStyling } from '@app/models';
import PreviewsScreen from './PreviewsScreen';
import { useFormikContext } from 'formik';
import { unwrapResult } from '@reduxjs/toolkit';
import useEnvironment from '@app/hooks/useEnvironment';
import useTenant from '@app/hooks/useTenant';
import useTracking from '@app/hooks/useTracking';

type Values = Omit<IdServicesStyling, 'deserialize'>;

export default function StylingScreen() {
  const dispatch = useDispatch();
  const environment = useEnvironment()
  const state = useSelector(state => state.tenant.styling.state);
  const tenant = useTenant()
  const styling = useSelector(state => environment === 'PRODUCTION' ? state.tenant.styling.response?.prodStyle : state.tenant.styling.response?.testStyle);

  useEffect(() => {
    if (!tenant) return;
    dispatch(fetchStyling({force: false}));
  }, [tenant]);

  const handleSubmit = async (values: Values) => {
    await dispatch(updateStyling([environment, values])).then(unwrapResult);
  };

  const tracking = useTracking();
  useEffect(() => {
    if (!tenant) return;
    tracking.viewedDashboardScreen();
  }, [tenant]);

  if (state.pending) return null;

  const initialValues : Values = styling || {cssUrl: '', viewVersion: 'unified', viewVersionEditable: true};
  const viewVersionEditable = styling?.viewVersionEditable || false;

  return (
    <React.Fragment>
      <div className="styling-screen">
        <div className="container">
          <div className="row">
            <div className="col-xs-6"><h3>{translate('CUSTOM_STYLING')}</h3></div>
          </div>
        </div>
        <Form initialValues={initialValues} onSubmit={handleSubmit} key={environment}>
          {({isPending, error, setFieldValue}) => (
            <React.Fragment>
              <div className="container">
                <div className="row">
                  <div className="col-sm-8">
                    <InputField<Values> type="text" name="cssUrl" label={translate('LABEL_ID_SERVICE_STYLESHEET_TEST')}  />
                    {error && <FormError error={error} />}

                    <div className="form-group d-flex align-items-center justify-content-between">
                      {viewVersionEditable ? (
                        <div className="d-flex align-items-center justify-content-between" style={{width: '185px'}}>
                          <label className="control-label" onClick={() => setFieldValue('viewVersion', 'initial')}>Legacy</label>
                          <Switch<Values> off="initial" on="unified" name="viewVersion" />
                          <label className="control-label" onClick={() => setFieldValue('viewVersion', 'unified')}>Unified</label>
                        </div>
                      ) : <div />}
                      <Button type="submit" working={isPending} variant="primary" style={{marginTop: '0px'}}>
                        {translate('SAVE')}
                      </Button>
                    </div>
                  </div>
                  <div className="col-sm-4">
                      <h3 style={{marginTop: '0px'}}><span className="experimental">{translate('INFO_FEATURE_STYLING')}</span></h3>
                      <p>{translate('INFO_FEATURE_STYLING_DESCRIPTION')}</p>
                      {styling?.viewVersionEditable && (
                        <p>{translate('INFO_FEATURE_STYLING_DESCRIPTION_B')}</p>
                      )}
                  </div>
                </div>
              </div>
              <PreviewsScreenWrapper />
            </React.Fragment>
          )}
        </Form>
      </div>
    </React.Fragment>
  )
}

function PreviewsScreenWrapper() {
  const context = useFormikContext<Values>();

  return <PreviewsScreen styling={context.values} />;
}