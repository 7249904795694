import React, { useReducer } from 'react';

import {Domain, getHref, DomainConfiguration, DomainCertificate} from '@app/models';
import {FormError, FormSuccess} from '@components/Form';
import { translate } from '@app/i18n';
import Button from '@components/Button';
import {REL} from '@app/constants';
import { useDispatch } from '@app/redux';
import {dispatchVerifyRequest} from '@redux/apiSlice';
import { identity } from 'lodash';
import DomainCertificateUploadModal from '../../DomainCertificateUploadModal/DomainCertificateUploadModal';
import usePromise from '@app/hooks/usePromise';

interface Props {
  domain: Domain
}

function isCertificateExpired(certificate: DomainCertificate) {
  return certificate.expiresAt <= new Date();
}

function isManagedCertificate(certificate: DomainCertificate) {
  return certificate.issuer.includes(`Let's Encrypt`);
}

function isAzureCertificate(certificate: DomainCertificate) {
  return certificate.subject.includes('azurewebsites.net');
}

function hasHTTPSEnabled(certificate: DomainConfiguration["certificate"] | undefined) {
  if (!certificate) return false;
  if (isCertificateExpired(certificate)) return false;
  if (isAzureCertificate(certificate)) return false;
  return true;
}

export default function DomainSSLSection(props : Props) {
  const {domain} = props;
  const dispatch = useDispatch();
  const [showUploadModal, toggleUploadModal] = useReducer(value => !value, false);

  const [managedCertificateSuccess, managedCertificateState, handleManagedCertificate] = usePromise(async () => {
    await dispatchVerifyRequest(
      dispatch,
      {
        method: "POST",
        url: getHref(domain, REL.MANAGED_CERTIFICATES),
        contentType: undefined
      },
      identity
    );

    return true;
  }, false);

  const {certificate} = props.domain.configuration || {};

  return (
    <React.Fragment>
      <div style={{marginBottom: '25px'}}>
        {hasHTTPSEnabled(certificate) ? (
          <span>
            <i className="fa fa-check" />&nbsp;
            HTTPS is enabled for domain.
          </span>
        ) : (
          <span>
            <i className="fa fa-times" />&nbsp;
            HTTPS is <strong>not</strong> enabled for domain.
          </span>
        )}
      </div>

      {certificate && !isAzureCertificate(certificate) ? (
        <div style={{marginBottom: '25px'}}>
          <div className="form-group">
            <label className="control-label">Type</label>
            <div style={{marginBottom: '10px'}}>{isManagedCertificate(certificate) ? 'Managed certificate' : 'Uploaded certificate'}</div>

            <div className="flex align-items-center" style={{gap: '20px'}}>
              <div style={{width: '100%'}}>
                <label className="control-label">Issuer</label>
                <pre>{certificate.issuer}</pre>
              </div>
              <div style={{width: '100%'}}>
                <label className="control-label">Subject</label>
                <pre>{certificate.subject}</pre>
              </div>
            </div>

            <div className="flex align-items-center" style={{gap: '20px'}}>
              <div style={{width: '100%'}}>
                <label className="control-label">Created At</label>
                <pre>{certificate.issuedAt.toLocaleString()}</pre>
              </div>
              <div style={{width: '100%'}}>
                <label className="control-label">Expires At</label>
                <pre>{certificate.expiresAt.toLocaleString()}</pre>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <DomainCertificateUploadModal show={showUploadModal} domain={props.domain} onHide={toggleUploadModal} />

      <div className="flex" style={{marginBottom: '15px', gap: '10px'}}>
        <Button variant="default" onClick={toggleUploadModal}>Upload SSL certificate</Button>
        <Button variant="default" onClick={handleManagedCertificate} working={managedCertificateState.pending}>Provision managed certificate</Button>
      </div>

      {managedCertificateState.error && (
        <FormError error={managedCertificateState.error.message} />
      )}

      {managedCertificateSuccess && (
        <FormSuccess message={'Managed SSL certificate provisioned'} />
      )}
    </React.Fragment>
  )
}

DomainSSLSection.Help = (
  <React.Fragment>
    <p><span>{translate('INFO_MANAGED_CERTIFICATE')}</span></p>
    <p><strong>{translate('INFO_MANAGED_CERTIFICATE_DELAY_SHORT')}</strong></p>
  </React.Fragment>
)