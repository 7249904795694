import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';

import {Domain} from '@app/models';
import {translate} from '@app/i18n';
import Button from '@app/components/Button';
import {singleton as config} from '@app/config';
import { useSelector, useDispatch } from '@app/redux';
import usePromise from '@hooks/usePromise';

import './DomainEditor.scss';
import DeleteDomainButton from '../DeleteDomainButton';
import DomainSSOSection from './Sections/SSO';
import DomainSSLSection from './Sections/SSL';
import { fetchDomainConfiguration } from '@app/redux/tenantSlice';

interface Props {
  domain: Domain,
  onDelete: () => void,
  onCancel: () => void,
  onSave: (domain: Domain) => void
}

export default function DomainEditor(props : Props) {
  const {domain} = props;
  const tenant = useSelector(state => state.tenant.tenant);
  const dispatch = useDispatch();
  const isCriiptoDomain = domain.name.endsWith(config.easyIdDns);
  const isMitIDDomain = domain.name.endsWith('.mitid.dk');
  const clientDomain = !isCriiptoDomain && !isMitIDDomain;

  const [, fetchDomainState] = usePromise(async () => {
    await dispatch(fetchDomainConfiguration({
      cache: true,
      domain: props.domain
    })).then(unwrapResult);
  }, []);

  const [, refetchDomainState, refetchDomainTrigger] = usePromise(async () => {
    return await dispatch(fetchDomainConfiguration({
      cache: false,
      domain: props.domain
    })).then(unwrapResult);
  }, false);

  if (fetchDomainState.pending) return <i className="fa fa-spinner fa-pulse fa-2x" />;

  return (
    <div className="domain-editor">
      <div className="form-section">
        <div className="form-fields">
          <div className="form-group">
            <label className="control-label">
              {translate('LABEL_DOMAIN')}
              &nbsp;
              {domain.configuration?.dnsConfigured === true && (
                <i className="fa fa-check lookup-checkmark" />
              )}
              {domain.configuration?.dnsConfigured === false && (
                <i className="fa fa-times lookup-cross" />
              )}
            </label>
            <input type="text" className="form-control" readOnly value={domain.name} disabled />
            {domain.configuration?.dnsConfigured !== true && (
              <React.Fragment>
                <div className="help-block">
                  <i className="fa fa-exclamation-triangle"></i> DNS is not configured.
                </div>
                {refetchDomainState.error && (
                  <div className="alert alert-danger">{refetchDomainState.error.message}</div>
                )}
                <Button variant="primary" working={refetchDomainState.pending} onClick={() => refetchDomainTrigger()}>{translate('VERIFY_DOMAIN')}</Button>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="info">
          {clientDomain && (
            <React.Fragment>
              <p>
                <span>{translate('INFO_NEW_DOMAIN')}</span>&nbsp;<strong>idp.{config.easyIdDns}</strong>
              </p>

              <p>
                <code>{domain.name} CNAME idp.{config.easyIdDns}.</code>
              </p>
            </React.Fragment>
          )}
        </div>
      </div>

      {clientDomain && (
        <React.Fragment>
          <h3>HTTPS/SSL</h3>
          <div className="form-section">
            <div className="form-fields">
              <DomainSSLSection domain={domain} />
            </div>
            <div className="info">{DomainSSLSection.Help}</div>
          </div>
        </React.Fragment>
      )}
      <div className="form-actions">
        <div className="button-group vertical-center">
          <DeleteDomainButton onDelete={props.onDelete} domain={domain} />
          <Button variant="default" type="button" onClick={props.onCancel} >
            {translate('CANCEL')}
          </Button>
        </div>
      </div>
    </div>
  );
}
