import { useApplicationRoleGrantsQuery, useOrganizationQuery, useOrganizationUsersQuery, useOrganizationPendingUsersQuery } from '@app/api/gauss';
import { TENANT_ID_PREFIX } from '@app/constants';
import usePermissions from '@app/hooks/usePermissions';
import useTenant from '@app/hooks/useTenant';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React from 'react';

import './UsersScreen.scss';

import Permissions from './components/Permissions';
import InvitationForm from './components/InvitationForm';
import {
  GaussOrganization, GaussApplicationRoleGrant, GaussOrganizationPendingUser, GaussOrganizationUser,
  GaussServicePermission, permissionsToRole, roleGrantsToRoles, ROLE_DESCRIPTION
} from '@app/models';
import Tooltip from '@app/components/Tooltip/Tooltip';
import RemoveUserButton from './components/RemoveUserButton/RemoveUserButton';
import ResendInvitationButton from './components/ResendInvitationButton/ResendInvitationButton';
import { LinkButton } from '@app/components/Button/Button';
import { useRouteMatch } from 'react-router-dom';
import EditUserButton from './components/EditUserButton/EditUserButton';
import UserInvitationButton from './components/UserInvitationButton/UserInvitationButton';

function isUserAdmin(user: GaussOrganizationUser) {
  return user.servicePermissions.map(s => s.id).includes("854f67627ecb4d10beedeff43edc9f2a");
}


export default function UsersScreen() {
  const permissions = usePermissions();
  const tenant = useTenant();
  const gaussId = tenant?.tenantId.replace(TENANT_ID_PREFIX, '');

  const organization = useOrganizationQuery(permissions?.users && gaussId ? gaussId : skipToken);
  const applicationRoleGrants = useApplicationRoleGrantsQuery(organization?.data ?? skipToken);
  const users = useOrganizationUsersQuery(organization?.data ?? skipToken);
  const pendingUsers = useOrganizationPendingUsersQuery(organization?.data ?? skipToken);

  if (!permissions?.users) return null;

  const userAdminUsers = users.data?.filter(u => u.servicePermissions.some(p => p.id === "854f67627ecb4d10beedeff43edc9f2a"));

  return (
    <React.Fragment>
      <div className="app-content-header flex align-items-center justify-content-between">
        <h1>Users</h1>
        <div className="actions">
          <UserInvitationButton variant="primary"><i className="fa fa-plus"></i>&nbsp;Add user</UserInvitationButton>
        </div>
      </div>
      <div className="app-content-main users-screen">
        <div className="accordion">
          <div className="accordion-header">
            <div className="row">
              <div className="col-xs-3">
                <h3>Name</h3>
              </div>
              <div className="col-xs-4">
                <h3>Email</h3>
              </div>
              <div className="col-xs-3">
                <h3>Role</h3>
              </div>
              <div className="col-xs-2">

              </div>
            </div>
          </div>

          {users.data?.map(user => (
            <User
              key={user.loginId}
              organization={organization.data!}
              user={user}
              applicationRoleGrants={applicationRoleGrants.data?.applicationRoleGrants}
              servicePermissions={applicationRoleGrants.data?.servicePermissions}
              allowRemove={isUserAdmin(user) ? userAdminUsers!.length > 1 : true}
            />
          ))}

          {pendingUsers.data?.map(user => (
            <User
              key={`${user.email}_${user.invitedAt}`}
              organization={organization.data!}
              user={user}
              applicationRoleGrants={applicationRoleGrants.data?.applicationRoleGrants}
              servicePermissions={applicationRoleGrants.data?.servicePermissions}
              allowRemove={true}
            />
          ))}

          {(tenant === undefined || organization.isLoading || users.isLoading || pendingUsers.isLoading || applicationRoleGrants.isLoading) ? (
            <div className="flex justify-content-center" style={{margin: '20px 0px'}}>
              <i className="fa fa-spinner fa-pulse fa-2x" />
            </div>
          ) : null}

          <div className="accordion-item ">
            <div className="accordion-header large" style={{height: 'auto'}}>
              <InvitationForm />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

function User(props: {
  organization: GaussOrganization,
  user: GaussOrganizationUser | GaussOrganizationPendingUser,
  applicationRoleGrants?: GaussApplicationRoleGrant[],
  servicePermissions?: GaussServicePermission[],
  allowRemove: boolean
}) {
  const match = useRouteMatch();
  const {user, organization, applicationRoleGrants, servicePermissions, allowRemove} = props;
  const allRoles = applicationRoleGrants ? roleGrantsToRoles(applicationRoleGrants) : undefined;
  const userPermissions = user.servicePermissions.map(s => s.id);
  const userRoles =
    "roles" in user ?
      allRoles?.filter(s => user.roles.some(ur => ur.applicationRoles.some(uar => uar.roleId === s.roleId))).map(role => role.role) :
      roleGrantsToRoles(user.applicationRoleGrants).map(role => role.role);

  const role = userRoles && applicationRoleGrants && servicePermissions ?
    permissionsToRole({roles: userRoles, servicePermissions: userPermissions}, {applicationRoleGrants, servicePermissions}) : undefined

  const roleTooltip =
    role === 'Custom' ?
      allRoles?.filter(s => userRoles?.includes(s.role)).map(r => r.displayName)
        .concat(user.servicePermissions.map(s => s.displayName))
        .join(', ') :
      role ? ROLE_DESCRIPTION[role] : undefined;

  return (
    <div className="accordion-item">
      <div className="accordion-header">
        <div className="row">
          <div className="col-xs-3">
            <strong>{user.name}</strong>
          </div>
          <div className="col-xs-4">
            {user.email}
          </div>
          <div className="col-xs-3">
            <Tooltip id={`${user.name}_permissions`} tooltip={roleTooltip ?? ''}>
              <span>{role}{"invitedAt" in user ? " (pending)" : null}</span>
            </Tooltip>
          </div>
          <div className="col-xs-2 flex justify-content-end" style={{gap: '10px'}}>
            {"invitedAt" in user ?
              <ResendInvitationButton user={user} /> :
              <EditUserButton user={user} organization={organization} />
            }
            {allowRemove ? <RemoveUserButton user={user} organization={organization} /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}