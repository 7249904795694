import React, {useState} from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {omit} from 'lodash';

import { Domain } from '@app/models';
import Button, {ButtonProps} from '@app/components/Button';
import { Modal, ConfirmModal} from '@app/components/Modal';
import { translate } from '@app/i18n';
import { deleteDomain } from '@app/redux/tenantSlice';
import { useDispatch } from '@app/redux';

interface Props extends Omit<ButtonProps, 'variant' | 'children'> {
  domain: Domain,
  onDelete?: () => void
}
export default function DeleteDomainButton(props : Props) {
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = async () => {
    setShowConfirm(false);

    await dispatch(deleteDomain(props.domain)).then(unwrapResult);
    if (props.onDelete) props.onDelete();
  }

  const handleConfirm = () => {
    handleDelete();
  }

  const handleClick = (event : React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setShowConfirm(true);
  }

  return (
    <React.Fragment>
      <Button {...omit(props, 'onDelete')} variant="danger" type="button" onClick={handleClick} >
        <i className="fa fa-trash-alt" />
      </Button>
      <ConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        title={translate('DELETE_DOMAIN')}
        confirmText={translate('DELETE_DOMAIN')}
        confirmVariant="danger"
      >
        {translate('INFO_DELETE_DOMAIN', {domain: props.domain.name})}
      </ConfirmModal>
    </React.Fragment>
  )
}