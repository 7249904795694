import React, {useEffect, useState, useReducer} from 'react';

import {useDispatch, useSelector} from '@app/redux';
import { fetchExtensibility, saveExtensibility } from '@app/redux/extensibilitySlice';

import FusebitEditor from './components/FusebitEditor';
import { unwrapResult } from '@reduxjs/toolkit';
import { ConfirmModal } from '@app/components/Modal/Modal';
import { fetchDomains } from '@app/redux/tenantSlice';
import { LinkButton } from '@app/components/Button/Button';
import { TENANT_ID_PREFIX } from '@app/constants';
import useEnvironment from '@app/hooks/useEnvironment';

type FormValues = {enabled: boolean};

export default function ExtensibilityScreen() {
  const dispatch = useDispatch();
  const [showEnabledConfirm, toggleEnabledConfirm] = useReducer((value) => !value, false);
  const [enablingError, setEnablingError] = useState<Error | null>(null)
  const tenant = useSelector(state => state.tenant.tenant);
  const environment = useEnvironment();
  const domainsState = useSelector(state => state.tenant.domains.state);
  const domains = useSelector(state => state.tenant.domains.items?.filter(s => s.production === (environment === 'PRODUCTION')));
  const fusebitConfigurationState = useSelector(state => state.extensibility.environments[environment].state);
  const fusebitConfiguration = useSelector(state => state.extensibility.environments[environment].configuration);
  const fusebitEnabled = useSelector(state => state.extensibility.environments[environment].enabled);

  const handleSubmit = async (values: FormValues) => {
    if (!tenant) return;
    setEnablingError(null);
    await dispatch(saveExtensibility({environment, tenant, enabled: values.enabled})).then(unwrapResult).then(() => {
      toggleEnabledConfirm();
    }).catch(err => {
      setEnablingError(err?.message ? err : new Error(err));
    });
  };

  const hasDomains = domains ? domains.length > 0 : false;

  useEffect(() => {
    if (!tenant) return;
    dispatch(fetchDomains({force: false}));
  }, []);

  useEffect(() => {
    if (!tenant) return;
    if (!hasDomains) return;
    dispatch(fetchExtensibility({force: false, tenant, environment}));
  }, [tenant, environment, hasDomains]);

  return (
    <React.Fragment>
      {domainsState.pending ? (
        <i className="fa fa-spinner fa-pulse fa-2x" />
      ) : (domainsState.error || fusebitConfigurationState.error) ? (
        <div className="alert alert-danger">
          {(domainsState || fusebitConfigurationState).error!.message}
        </div>
      ) : !hasDomains ? (
        <div className="flex flex-col">
          <p>
            Everything you do with Criipto, be it signatures or logins, requires that you register a <strong>domain</strong> first.
          </p>
          <LinkButton variant="primary" to={`/org/${tenant?.tenantId.replace(TENANT_ID_PREFIX, '')}/domains/add`} style={{alignSelf: 'flex-start'}}>
            <i className="fa fa-plus"></i>&nbsp;Add domain
          </LinkButton>
        </div>
      ) : (fusebitConfigurationState.pending || !fusebitConfiguration) ? (
        <i className="fa fa-spinner fa-pulse fa-2x" />
      ) : (
        <React.Fragment>
          <div className="row" style={{marginBottom: '20px'}}>
            <div className="col-sm-8">
              <div className="form-group horizontal">
                <label className="switch">
                  <input onChange={() => toggleEnabledConfirm()} type="checkbox" checked={fusebitEnabled} /><i />
                </label>
                <label className="control-label" onClick={() => toggleEnabledConfirm()}>Enabled</label>
              </div>
            </div>
          </div>

          <ConfirmModal
            title={`${fusebitEnabled ? 'Disable' : 'Enable'} extensibility?`}
            show={showEnabledConfirm}
            onHide={() => toggleEnabledConfirm()}
            confirmVariant="primary"
            confirmText={`${fusebitEnabled ? 'Disable' : 'Enable'} extensibility`}
            onConfirm={() => handleSubmit({enabled: !fusebitEnabled})}
          >
            <p>
              Heads-up: You are about to {fusebitEnabled ? 'disable' : 'enable'} extensibility.
            </p>
            <p>
              This will make all logins on your tenant {fusebitEnabled ? 'stop using' : 'use'} your extension implementation.
              We are sure that you have of course {fusebitEnabled ? 'made sure that your applications can do without it' : 'tested your implementation thoroughly'} , but we would like to give you an opportunity to double-check that assumption.
            </p>

            {enablingError ? (
              <div className="alert alert-danger">
                {enablingError.message}
              </div>
            ) : null}
          </ConfirmModal>

          <FusebitEditor configuration={fusebitConfiguration} height={800} />
        </React.Fragment>
        )}
    </React.Fragment>
  );
}