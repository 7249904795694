/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DeleteApiKeyButton_apiKey = {
    readonly id: string;
    readonly " $refType": "DeleteApiKeyButton_apiKey";
};
export type DeleteApiKeyButton_apiKey$data = DeleteApiKeyButton_apiKey;
export type DeleteApiKeyButton_apiKey$key = {
    readonly " $data"?: DeleteApiKeyButton_apiKey$data;
    readonly " $fragmentRefs": FragmentRefs<"DeleteApiKeyButton_apiKey">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteApiKeyButton_apiKey",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ApplicationApiKey",
  "abstractKey": null
};
(node as any).hash = '546c69cb6578197e1baba58a5508ef07';
export default node;
