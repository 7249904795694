import React, { useState } from 'react';
import cx from 'classnames';
import Dropdown from 'react-bootstrap/lib/Dropdown';

import './TenantNavigation.css';
import '@criipto/ui-operations-status/dist/index.css';

import useTenant from '@app/hooks/useTenant';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from '@app/redux';
import { TENANT_ID_PREFIX } from '@app/constants';
import usePermissions from '@app/hooks/usePermissions';
import { translate } from '@app/i18n';
import { singleton as config } from '@app/config';
import useGaussOrganization from '@app/hooks/useGaussOrganization';
import EnvironmentToggle from '../EnvironmentToggle/EnvironmentToggle';
import { OperationsStatusIcon, OperationsStatusOverlay, OperationsStatusProvider } from '@criipto/ui-operations-status';

export default function TenantNavigation(props: {show: boolean, onHide: () => void}) {
  const tenant = useTenant();
  const dispatch = useDispatch();
  const [showOperationsOverlay, setShowOperationsOverlay] = useState(false);
  const statuspage = useSelector(state => state.statuspage);
  const linkId = tenant?.tenantId.replace(TENANT_ID_PREFIX, '');
  const permissions = usePermissions();
  const organization = useGaussOrganization();

  const organizations = useSelector(state => state.discovery.gauss.organizations);

  const [showTenantDropdown, setShowTenantDropdown] = useState(false);
  const handleTenantDropDownToggle = (isOpen: boolean) => {
    setShowTenantDropdown(isOpen);
  }

  return (
    <OperationsStatusProvider>
      <div className={cx('sidebar-backdrop', {show: props.show})} onClick={props.onHide}>
        <nav className="sidebar" onClick={(event) => event.stopPropagation()}>
          <div className="top">
            <div className="criipto-logo">
              <Link to="/"  onClick={props.onHide}>
                <img src="/assets/images/criipto-logo-cyan.svg" alt="Criipto" />
              </Link>
            </div>

            <div className="tenant-dropdown">
              <Dropdown id="tenant_dropdown" onToggle={handleTenantDropDownToggle} open={showTenantDropdown}>
                <Dropdown.Toggle useAnchor={true} noCaret={true}>
                  {organization ? organization.name : 'Your tenant'}
                  &nbsp;
                  <i className="fa fa-angle-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu"  onClick={props.onHide}>
                <li>
                    <Link className="menu-option" to="/signup/organization" onClick={() => setShowTenantDropdown(false)}>
                      <span className="new-org">
                        {translate('NEW_ORGANIZATION')}
                      </span>
                    </Link>
                  </li>
                  {organizations?.map(organization => (
                    <li key={organization.id}>
                      <Link to={`/org/${organization.id}/dashboard`} className="menu-option" onClick={() => setShowTenantDropdown(false)}>
                        {organization.name}
                      </Link>
                    </li>
                  ))}
                </Dropdown.Menu >
              </Dropdown>
            </div>

            <EnvironmentToggle />

            <div className="scroll">
              <ul className="links"  onClick={props.onHide}>
                <li>
                  <NavLink to={`/org/${linkId}/dashboard`} activeClassName="active">
                    <i className="fa fa-tachometer-alt" />
                    {translate('TAB_DASHBOARD')}
                  </NavLink>
                </li>
                {permissions?.integration && (
                  <li>
                    <NavLink to={`/org/${linkId}/applications`} activeClassName="active">
                      <i className="fa fa-cog" />
                      {translate('TAB_APPLICATIONS')}
                    </NavLink>
                  </li>
                )}
                {(permissions?.testDomains || permissions?.productionDomains) && (
                  <li>
                    <NavLink to={`/org/${linkId}/domains`} activeClassName="active">
                      <i className="fa fa-server" />
                      {translate('TAB_DOMAINS')}
                    </NavLink>
                  </li>
                )}
                {permissions?.providers && (
                  <li>
                    <NavLink to={`/org/${linkId}/providers`} activeClassName="active">
                      <i className="fa fa-passport" />
                      {translate('TAB_PROVIDERS')}
                    </NavLink>
                  </li>
                )}
                {permissions?.providers && (
                  <li>
                    <NavLink to={`/org/${linkId}/styling`} activeClassName="active">
                      <i className="fab fa-css3" />
                      {translate('TAB_STYLING')}
                    </NavLink>
                  </li>
                )}
                {permissions?.reporting && (
                  <li>
                    <NavLink to={`/org/${linkId}/analytics`} activeClassName="active">
                      <i className="fa fa-chart-bar" />
                      Analytics
                    </NavLink>
                  </li>
                )}
                <li>
                  <NavLink to={`/org/${linkId}/tools`} activeClassName="active">
                    <i className="fa fa-wrench" />
                    Tools
                  </NavLink>
                </li>
              </ul>

              {permissions?.users || permissions?.financials ? (
                <ul className="links"  onClick={props.onHide}>
                  {permissions?.users && (
                    <li>
                      <NavLink to={`/org/${linkId}/users`} activeClassName="active">
                        <i className="fa fa-users" />
                        Users
                      </NavLink>
                    </li>
                  )}
                  {permissions?.financials && (
                    <li>
                      <a href={config.SUBSCRIPTION_FRONTEND_URL} target="_blank">
                        <i className="fa fa-money-check" />
                        Billing
                      </a>
                    </li>
                  )}
                </ul>
              ) : null}
            </div>
          </div>
          <div className="bottom">

            <ul className="links" onClick={props.onHide}>
              <li>
                <NavLink to={`/org/${linkId}/support`} activeClassName="active">
                  <i className="fa fa-question" />
                  Support
                </NavLink>
              </li>
              <li onClick={() => setShowOperationsOverlay(true)} className="hidden lg:block">
                <a href="#" onClick={event => event.preventDefault()}>
                  <OperationsStatusIcon />
                  Operations status&nbsp;
                </a>
              </li>
              <li className="hidden lg:block">
                <a href="https://docs.criipto.com/" target="_blank">
                  <i className="fa fa-book" />
                  Documentation
                </a>
              </li>
              <li className="lg:hidden" >
                <a href="#" onClick={event => event.preventDefault()}>
                  <i className="fa fa-caret-left" />
                  Close&nbsp;
                </a>
              </li>
            </ul>
          </div>
          <OperationsStatusOverlay show={showOperationsOverlay} onHide={() => setShowOperationsOverlay(false)} />
        </nav>
      </div>
    </OperationsStatusProvider>
  );
}