import React, {useCallback, useEffect} from 'react';
import {identity} from 'lodash';

import {useSelector, useDispatch} from '@app/redux';
import {fetchDomains, updateProviderSettings} from '@redux/tenantSlice';
import {translate} from '@app/i18n';

import {
  BelgianeIDConfiguration,
  Tenant, DetailedIdentityProvider
} from '@app/models';

import {REL, CONTENT_TYPE} from '@app/constants';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch} from '@components/Form';

import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import { unwrapResult } from '@reduxjs/toolkit';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  configuration: BelgianeIDConfiguration,
  provider: DetailedIdentityProvider
}

// Extend BelgianeIDConfiguration ?
export interface FormValues extends BelgianeIDConfiguration  {
  clientId : string
  clientSecret : string,
  requestPersonalInfo: boolean,
  requestAddress: boolean,
  requestPhoto: boolean,
  requestNationalNumber: boolean,
  skipSummary: boolean
}

export function BEeIDProvider(props : Props) {
  const {configuration} = props;
  const dispatch = useDispatch();

  const initialValues : FormValues = {
    clientId: configuration?.clientId || '',
    clientSecret: '',
    requestPersonalInfo: configuration?.requestPersonalInfo || false,
    requestAddress: configuration?.requestAddress || false,
    requestPhoto: configuration?.requestPhoto || false,
    requestNationalNumber: configuration?.requestNationalNumber || false,
    skipSummary: configuration?.skipSummary || false,
    deserialize: (data) => data
  };

  const environment = useEnvironment();
  const isProduction = environment === 'PRODUCTION';
  const domains = useSelector(state => state.tenant.domains);

  useEffect(() => {
    dispatch(fetchDomains({force: false}));
  }, []);

  const saveProvider = async (values : FormValues) => {
    return dispatch(updateProviderSettings({
      rel: 'BE_EID_SETTINGS',
      values
    })).then(unwrapResult);
  };

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return saveProvider(values);
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p className="green">⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_BE_EID_WHAT_TO_AVOID')}</em></p>
          </EnvironmentDialog>
          <EnvironmentDialog environment="PRODUCTION" heading={(
            <React.Fragment>
              <h1>
                <strong>{translate('INFO_BE_EID_CLIENT')}</strong>
              </h1>
              <br />
              <h3>{translate('INFO_BE_EID_CALLBACK_URL_PROD')}</h3>
            </React.Fragment>
          )}>
            {domains.items?.map(domain => domain.production ? (
              <p className="green" key={domain.name}>
                https://{domain.name}/BEEIDConnective/Callback
              </p>
            ) : null)}
          </EnvironmentDialog>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} Belgian eID Authentication
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label={translate('LABEL_BE_EID_CLIENTID')}
                name="clientId"
                placeholder={translate('LABEL_BE_EID_CLIENTID')}
              />
              <InputField
                type="password"
                label={translate('LABEL_BE_EID_CLIENTSECRET')}
                name="clientSecret"
                placeholder={translate('LABEL_BE_EID_CLIENTSECRET')}
              />

              <Switch name="requestPersonalInfo" label={translate('LABEL_BE_EID_REQUEST_PERSONAL_INFO')} />
              <Switch name="requestAddress" label={translate('LABEL_BE_EID_REQUEST_ADDRESS')} />
              <Switch name="requestPhoto" label={translate('LABEL_BE_EID_REQUEST_PHOTO')} />
              <Switch name="requestNationalNumber" label={translate('LABEL_BE_EID_REQUEST_NATIONAL_NUMBER')} />
              <Switch name="skipSummary" label={translate('LABEL_BE_EID_SKIP_SUMMARY')} />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your belgian eID settings have been updated`} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_BE_EID_CLIENT')}</p>
              <p>{translate(isProduction ? 'INFO_BE_EID_CALLBACK_URL_PROD': 'INFO_BE_EID_CALLBACK_URL_TEST')}</p>
              {domains.items?.filter(domain => domain.production === isProduction).map(domain => (
                <p key={domain.name}>
                  https://{domain.name}/BEEIDConnective/Callback
                </p>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}