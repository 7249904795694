import React, { useEffect, useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import {LOGOUT_URL} from '@app/constants';
import { translate } from '@app/i18n';

import {ProductionUpgradeRequiredModal} from '../ProductionUpgradeRequiredModal';
import { useDispatch, useSelector } from '@app/redux';
import { fetchComponents } from '@app/redux/statuspageSlice';
import { fetchOrganizations } from '@redux/discoverySlice';

import './Header.scss';
import useTenant from '@app/hooks/useTenant';
import useGaussOrganization from '@app/hooks/useGaussOrganization';
import { EnvironmentTag } from '../Tag/Tag';

export default function Header(props: {onHamburger?: () => void}) {
  const tenant = useTenant();
  const organization = useGaussOrganization();
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.valid);
  const profile = useSelector(state => state.auth.profile);
  const authClient = useSelector(state => state.auth.client);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchOrganizations({force: false}));
    }
  }, [isAuthenticated]);

  const handleLogout = () => {
    authClient.logout({
      returnTo: LOGOUT_URL
    });
  }

  const [showDropDown, setShowDropDown] = useState(false);
  const handleDropDownToggle = (isOpen: boolean) => {
    setShowDropDown(isOpen);
  }

  const isGlobalScreen = location.pathname.includes('/users') || location.pathname.includes('/billing') || location.pathname.includes('tools/criipto-admin');

  return (
    <React.Fragment>
      {isAuthenticated && (
        <header id="header">
          <div className="toolbar">
            <div className="flex align-items-center" style={{gap: '10px'}}>
              {props.onHamburger ? (
                <div className="sidebar-hamburger" onClick={props.onHamburger}>
                  <i className="fa fa-bars" />
                </div>
              ) : null}

              {tenant && !isGlobalScreen && (<EnvironmentTag />)}
            </div>

            {!tenant && (
              <div className="flex align-items-center justify-content-center">
                <Link to="/">
                  <img src="/assets/images/criipto-logo-cyan.svg" alt="Criipto" />
                </Link>
              </div>
            )}

            <nav id="nav" className="tenant-dropdown">
              <div className="nav-drop">
                {/* <div className="nav-opener visible-xs"><span>Menu</span></div> */}
                <ul className="admin-menu" >
                  <Dropdown id="tenant_dropdown" componentClass={"li"} onToggle={handleDropDownToggle} open={showDropDown}>
                    <Dropdown.Toggle useAnchor={true} noCaret={true}>
                      <span className="hidden-xs">{profile?.name ?? 'Your account'}&nbsp;</span>
                      <i className="fa fa-angle-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu">
                      {profile?.email && (
                        <React.Fragment>
                          <li>
                            <div className="menu-option">
                              {profile?.email}
                            </div>
                          </li>
                          <li className="menu-seperator" />
                        </React.Fragment>
                      )}
                      <li>
                        <div className="menu-option" onClick={handleLogout} style={{cursor: 'pointer'}}>
                          <span>
                            {translate('LOG_OUT')}
                          </span>
                        </div>
                      </li>
                    </Dropdown.Menu>
                  </Dropdown>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      )}
      <ProductionUpgradeRequiredModal />
    </React.Fragment>
  )
}