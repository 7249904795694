import React, {useState} from 'react';
import cx from 'classnames';
import { Formik, Field, Form as FormikForm, FormikHelpers, FormikValues, useField, FieldAttributes, FormikErrors, FormikSharedConfig } from 'formik';
import {omit} from 'lodash';

import Button from '@components/Button';
import './Form.scss';
import { getErrorString } from '@app/api/helper';

export interface RenderProps<T = FormikValues> {
  isPending: boolean,
  isSuccess: boolean,
  error: string | null,
  values: T,
  errors: FormikErrors<T>
  setValues: (values: React.SetStateAction<T>, shouldValidate?: boolean | undefined) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
  resetForm: () => void
}

interface FormProps<T = FormikValues> {
  initialValues: T,
  enableReinitialize?: FormikSharedConfig["enableReinitialize"]
  onSubmit: (values : T, actions : FormikHelpers<T>) => Promise<any>,
  children: React.ReactNode | ((props : RenderProps<T>) => React.ReactNode),
  key: string,
  className?: string,
  style?: React.CSSProperties
}

const isFunction = (obj: any): obj is Function =>
  typeof obj === 'function';

export function Form<T>(props : FormProps<T>) {
  const [error, setError] = useState<string | null>(null);
  const [pending, setPending] = useState(false);
  const [success, setSuccess] = useState(false);
  const render =
    isFunction(props.children) ?
    props.children :
    (_ : RenderProps<T>) => props.children

  return (
    <Formik<T>
      initialValues={props.initialValues}
      enableReinitialize={props.enableReinitialize}
      onSubmit={(values, actions) => {
        if (pending) return Promise.resolve();

        setPending(true);
        setSuccess(false);
        setError(null);

        return props.onSubmit(values, actions).then(result => {
          setSuccess(true);
          return result;
        }).catch((error) => {
          setError(getErrorString(error));
        }).finally(() => {
          setPending(false);
        })
      }}
    >
      {({values, setValues, setFieldValue, errors, resetForm}) => (
        <FormikForm className={props.className} style={props.style}>
          {render({isPending: pending, error, errors, isSuccess: success, values, setValues, setFieldValue, resetForm})}
        </FormikForm>
      )}
    </Formik>
  )
}

export interface InputFieldProps<T> {
  name: T extends never ? string : keyof T
  type: string
  label?: string,
  disabled?: boolean,
  placeholder?: string,
  help?: React.ReactNode
  required?: boolean
  pattern?: string,
  minLength?: number,
  maxLength?: number,
  validate?: FieldAttributes<any>["validate"],
  'data-testid'?: string,
  groupClassName?: string
}

export function InputField<T = never>(props : InputFieldProps<T>) {
  if (!props.label) return <Field className="form-control" {...omit(props, 'label', 'help')} />;

  return (
    <div className={cx('form-group', props.groupClassName)}>
      <label className="control-label">{props.label}</label>
      <Field className="form-control" {...omit(props, 'label', 'help', 'groupClassName')} />
      {props.help}
    </div>
  );
}

export interface SelectProps<T> {
  name: T extends never ? string : keyof T
  label?: string
  options: {label: string, value: string}[],
  className?: string,
  groupClassName?: string,
  required?: boolean
}

export function Select<T = never>(props : SelectProps<T>) {
  return (
    <div className={cx('form-group', props.groupClassName)}>
      {props.label && (<label className="control-label">{props.label}</label>)}
      <Field as="select" name={props.name} className={props.className} required={props.required}>
        {props.options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Field>
    </div>
  );
}

interface FileUploadProps {
  name: string
  label: string,
  placeholder?: string
}

export function FileUpload(props : FileUploadProps) {
  const [field, meta, {setValue}] = useField(props.name);
  const handleFile = (event : any) => {
    setValue(event.target.files[0]);
  }

  return (
    <div className="form-group">
      <label className="control-label">{props.label}</label>
      <div className="form-control-file">
        <input type="text" className="form-control" placeholder={props.placeholder} value={field.value?.name || ''} readOnly />
        <Button variant="primary"><i className="fa fa-upload" /></Button>
        <Field className="form-control" type="file" value="" onChange={handleFile}/>
      </div>
    </div>
  );
}

interface SwitchProps<T> {
  name: T extends never ? string : keyof T
  label?: string,
  off?: string // Support switching between two string values rather than a boolean
  on?: string,

  value?: boolean | string,
  onChange?: (value: boolean | string) => void
}

export function Switch<T = never>(props : SwitchProps<T>) {
  const [field, meta, {setValue}] = useField(props.name.toString());
  const value = props.value ?? field.value;

  const handleChange = (checked: boolean) => {
    const value = props.on ? (checked ? props.on! : props.off!) : checked;
    setValue(value);
    if (props.onChange) props.onChange(value);
  }

  const handleChangeEvent = (event : React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event.target.checked);
  }

  const handleClick = (event : React.MouseEvent) => {
    handleChange(props.on ? value !== props.on : !value);
  };

  if (!props.label) {
    return (
      <label className="switch">
        <input onChange={handleChangeEvent} type="checkbox" checked={props.on ? props.on === value : value} /><i />
      </label>
    );
  }

  return (
    <div className="form-group horizontal">
      <label className="switch">
        <input onChange={handleChangeEvent} type="checkbox" checked={props.on ? props.on === value : value} /><i />
      </label>
      <label className="control-label" onClick={handleClick}>{props.label}</label>
    </div>
  )
}

interface FormErrorProps {
  error: string | undefined | null | false
}
export function FormError(props : FormErrorProps) {
  if (!props.error) return null;

  return (
    <div className="form-group">
      <div className="alert alert-danger">{props.error}</div>
    </div>
  );
}

interface FormSuccessProps {
  message: string | undefined | null | false
}
export function FormSuccess(props : FormSuccessProps) {
  if (!props.message) return null;

  return (
    <div className="form-group">
      <div className="alert alert-success">{props.message}</div>
    </div>
  );
}