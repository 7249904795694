import React, {useEffect, useState, useCallback} from 'react';

import {Domain} from '@app/models';
import Renderer from './Renderer';
import { PreviewItem, Styling } from '../constants';

interface Props {
  item: PreviewItem,
  styling: Styling,
  domain: string,
  language: string,
  bodyCssClass?: string,
  style: React.CSSProperties
}

export default function IframeRenderer(props : Props) {
  const {item} = props;
  const [iframe, setIframe] = useState<HTMLIFrameElement | null>(null);
  const [renderKey, setRenderKey] = useState(item.htmlCacheable === false ? Math.random().toString() : item.id); // Use renderKey to force regenerating iframe in certain cases

  const iframeCallback = useCallback((iframe : HTMLIFrameElement) => {
    setIframe(iframe);
  }, []);

  const handleHtml = () => {
    if (item.htmlCacheable === false) {
      setRenderKey(Math.random().toString())
    }
  }

  return (
    <Renderer {...props} item={item} renderRef={iframe} onHtml={handleHtml}>
      <iframe ref={iframeCallback} style={props.style} key={renderKey} />
    </Renderer>
  )
}