import { PERMISSION, PERMISSION_CLAIM_TYPE, ROLE, ROLE_CLAIM_TYPE, TENANT_ID_PREFIX } from "@app/constants";
import { GaussOrganization, IPermissions } from "@app/models";
import { useSelector } from "@app/redux";
import { VerifyAuthToken } from "@app/redux/authSlice";
import jwtDecode from "jwt-decode";
import { find } from "lodash";
import { useMemo } from "react";
import {useVerifyAccessToken} from "./useAuth";
import useTenant from "./useTenant";

function gaussRoleToClaim(input: "configuration-management" | "private-key-management") {
  if (input === "configuration-management") return 'cfg';
  if (input === "private-key-management") return 'pkm';
  throw new Error(`Unknown gauss role ${input}`);
}

export default function usePermissions() : IPermissions | undefined {
  const tenant = useTenant();
  const token = useVerifyAccessToken();
  const tokenClaims = useMemo(() => token !== null ? jwtDecode<VerifyAuthToken>(token) : null, [token]);
  const verifyGaussClaims = useSelector(state => state.discovery.gauss.verifyScopedClaims);
  const subscriptionGaussClaims = useSelector(state => state.discovery.gauss.subscriptionScopedClaims);
  const verifyGaussScopes = verifyGaussClaims?.claimScopes;
  const subscriptionGaussScopes = subscriptionGaussClaims?.claimScopes;
  const gaussOrganization: GaussOrganization | null | undefined = verifyGaussScopes?.map(x => x.organization).reduce((memo, x) => x.id === tenant?.tenantId.replace(TENANT_ID_PREFIX, '') ? x : memo, null);
  const gaussOrganizationPermissions = find(verifyGaussScopes, scope => scope.organization.id === gaussOrganization?.id)?.getClaims(PERMISSION_CLAIM_TYPE);
  const gaussOrganizationRoles =
    find(verifyGaussScopes, scope => scope.organization.id === gaussOrganization?.id)?.getClaims(ROLE_CLAIM_TYPE).concat(
      find(subscriptionGaussScopes, scope => scope.organization.id === gaussOrganization?.id)?.getClaims(ROLE_CLAIM_TYPE) || []
    );

  if (tenant === undefined) return undefined;

  const tenantClaims =
    tenant && tokenClaims ?
      tokenClaims['http://criipto/permit/scopedUserClaims']?.[tenant.tenantId.replace(TENANT_ID_PREFIX, '')] :
      undefined;

  return {
    testDomains: tenantClaims?.includes(gaussRoleToClaim(ROLE.TEST_DOMAINS)) ?? false,
    productionDomains: tenantClaims?.includes(gaussRoleToClaim(ROLE.PRODUCTION_DOMAINS)) ?? false,
    providers: tenantClaims?.includes(gaussRoleToClaim(ROLE.ID_ADMIN)) ?? false,
    integration: tenantClaims?.includes(gaussRoleToClaim(ROLE.APP_ADMIN)) || tenantClaims?.includes(gaussRoleToClaim(ROLE.APP_DEV)) || false,
    users: gaussOrganizationPermissions?.some(x => x.value === PERMISSION.USER_ADMIN),
    extensibility: tenantClaims?.includes(gaussRoleToClaim(ROLE.APP_ADMIN)) || tenantClaims?.includes(gaussRoleToClaim(ROLE.APP_DEV)) || false,
    criiptoAdmin: tenantClaims?.includes('billing-management') ?? false,
    reporting: gaussOrganizationRoles?.some(x => x.value === "reporting"),
    financials: gaussOrganizationRoles?.some(x => x.value === "financials")
  };
}