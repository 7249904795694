import { BUCKET, DIMENSION, SeriesResponse } from '@app/api/subscription';
import Button from '@app/components/Button/Button';
import Tooltip from '@app/components/Tooltip/Tooltip';
import React from 'react';
import { BUCKET_SETTINGS } from '../../constants';

import { objectsToCSV, downloadCSV } from './csv';

interface Props {
  dimension: DIMENSION,
  bucket: BUCKET,
  results?: SeriesResponse

}
export default function DownloadButton(props: Props) {
  const {results, dimension, bucket} = props;
  const disabled = !results?.length;
  const handleDownload = () => {
    if (disabled) return;
    if (!results?.length) return;

    const exporting =
      results.map(result => ({
        date: BUCKET_SETTINGS[bucket].exportFormatter(result.bucket),
        total: result.total,
        ...result.dimension[dimension]
      }));

    console.log(exporting);
    downloadCSV('criipto_export.csv', objectsToCSV(exporting, ',')!);
  };

  return (
    <Tooltip id="analytics_download" tooltip="Download as CSV">
      <Button variant="default" onClick={handleDownload} disabled={disabled} autoWidth>
        <i className="fa fa-arrow-alt-circle-down" />
      </Button>
    </Tooltip>
  )
}