
import React from 'react';
import {identity} from 'lodash';

import {useDispatch, useSelector} from '@app/redux';
import {REL, CONTENT_TYPE} from '@app/constants';
import {DanishMitIdApplyRendition, GaussOrganization, getHref} from 'app/models';
import {translate} from '@app/i18n';
import {Form, InputField, FormError, FormSuccess, Select} from '@components/Form';
import Button from '@components/Button';
import VATField from './VATField';

import './Apply.scss';
import NemIdLoginButton from './NemIdLoginButton';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';
import { trimDomainPrefix, validateDomainPrefix } from '@app/helpers';
import useTenant from '@app/hooks/useTenant';
import useGaussOrganization from '@app/hooks/useGaussOrganization';
import useEnvironment from '@app/hooks/useEnvironment';

const CompanyTypes = ["private", "public"] as const;

function encode(str: string) {
  return str.replace(/ /g, '%20').replace(/\n/g, '%0D%0A');
}


const GAUSS_COMPANY_CLAIM = 'https://gov.eu/claims/company';
const GAUSS_VAT_NUMBER_CLAIM = 'https://gov.eu/claims/vat/number';
const GAUSS_VAT_COUNTRY_CLAIM = 'https://gov.eu/claims/vat/country';

interface VatIdClaim {
  vatNumber: string,
  vatCountry: string,
  companyName: string
}

function findGaussVatClaims(organization: GaussOrganization) : VatIdClaim | null {
  if (!organization || !organization.userClaims) return null;
  const candidate = {
    vatNumber: organization.userClaims.find(claim => claim.type === GAUSS_VAT_NUMBER_CLAIM)?.value,
    vatCountry: organization.userClaims.find(claim => claim.type === GAUSS_VAT_COUNTRY_CLAIM)?.value,
    companyName: organization.userClaims.find(claim => claim.type === GAUSS_COMPANY_CLAIM)?.value,
  };

  if (!candidate.vatNumber || !candidate.vatCountry || !candidate.companyName) return null;

  return candidate;
}

export default function DKMitIDProviderApply() {
  const dispatch = useDispatch();
  const environment = useEnvironment();
  const tenant = useTenant();
  const organization = useGaussOrganization();

  if (!tenant || !organization) {
    // Is actually guarded by parent component
    // but for defensive coding purposes, edge cases and for making the compiler happy
    return (
      <i className="fa fa-spinner fa-pulse fa-2x" />
    );
  }

  const supportSubject = encode(`${organization.name} requires assistance with MitID application`);
  const supportBody = `Hi Criipto
%0D%0A
%0D%0AI want to apply for Danish MitID but I cannot authenticate with NemID for employees, as our company is not registered in Denmark.
%0D%0A
%0D%0ACompany Name:
%0D%0AVAT Country:
%0D%0AVAT Number:
%0D%0A
%0D%0AMy tenant identifier is: ${organization?.entityIdentifier}`;

  const gaussVatClaim : VatIdClaim | null = findGaussVatClaims(organization);
  const requireNemID = environment === 'PRODUCTION' && !gaussVatClaim;

  const initialValues : DanishMitIdApplyRendition = {
    vatIdentifier: {
      vatCountryCode: gaussVatClaim?.vatCountry ?? 'DK',
      vatNumber: gaussVatClaim?.vatNumber ?? '',
    },
    companyName: gaussVatClaim?.companyName ?? '',
    companyAlias: "",
    companyType: CompanyTypes[0],
    contactEmail: "",
    contactName: "",
    domainPrefix: "",
    nemIdToken: "",
    production : environment === 'PRODUCTION'
  };

  const domainSuffix = environment === 'PRODUCTION' ? '.mitid.dk' : '.pp.mitid.dk';

  return (
    <Form<DanishMitIdApplyRendition>
      key={environment}
      initialValues={initialValues}
      onSubmit={async (values : DanishMitIdApplyRendition) => {
        values.domainPrefix = trimDomainPrefix(values.domainPrefix);

        const url = getHref(tenant!, REL.DK_MITID_APPLY);
        await dispatchVerifyRequest(
          dispatch,
          {
            url,
            method: "POST",
            data: values,
            contentType: CONTENT_TYPE.DK_MITID_APPLY
          },
          identity
        );
      }}
      data-test-id="form"
    >
      {({values, isPending, error, errors, isSuccess}) => (
        <div className="row">
          <div className="col-sm-6">
            <h3>
                Apply for Danish MitId
            </h3>
            {(requireNemID && !values.vatIdentifier.vatNumber) ? (
              <React.Fragment>
                <NemIdLoginButton acrValue="urn:grn:authn:dk:nemid:moces">
                  Login with NemID to start your application
                </NemIdLoginButton>
                <NemIdLoginButton acrValue="urn:grn:authn:dk:nemid:moces:codefile" style={{marginTop: '10px'}}>
                  Login with NemID (Codefile) to start your application
                </NemIdLoginButton>
                <p style={{marginTop: "15px"}}>
                  NemID login will open in a popup.
                </p>
                <p style={{marginTop: "15px"}}>
                  If your company is not registered in Denmark, please <a href={`mailto:support@criipto.com?subject=${supportSubject}&body=${supportBody}`}>contact our support team</a>.
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="row" style={{marginTop: "25px"}}>
                  <div className="col-sm-6">
                    <InputField<DanishMitIdApplyRendition>
                      type="text"
                      label={translate('LABEL_DK_MITID_COMPANY_NAME')}
                      name="companyName"
                      required
                      disabled={requireNemID || !!gaussVatClaim}
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputField<DanishMitIdApplyRendition>
                      type="text"
                      label={translate('LABEL_DK_MITID_COMPANY_ALIAS')}
                      name="companyAlias"
                      required
                      help={<small className="form-text text-muted">{translate('HINT_DK_MITID_COMPANY_ALIAS')}</small>}
                    />
                  </div>
                </div>
                <div className="row" style={{marginTop: "25px"}}>
                    <div className="col-sm-6">
                      <Select<DanishMitIdApplyRendition>
                        name="companyType"
                        label={translate('LABEL_DK_MITID_COMPANY_TYPE')}
                        options={CompanyTypes.map(type => ({label: type, value: type}))}
                      />
                    </div>
                    <div className="col-sm-6">
                      <VATField disabled={requireNemID || !!gaussVatClaim} />
                    </div>
                </div>

                <div className="row" style={{marginTop: "25px"}}>
                  <div className="col-sm-6">
                    <InputField<DanishMitIdApplyRendition>
                      type="email"
                      label={translate('LABEL_DK_MITID_CONTACT_EMAIL')}
                      name="contactEmail"
                      required
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputField<DanishMitIdApplyRendition>
                      type="text"
                      label={translate('LABEL_DK_MITID_CONTACT_NAME')}
                      name="contactName"
                      required
                    />
                  </div>
                </div>

                <div className="form-group" style={{marginTop: "25px"}}>
                  <InputField<DanishMitIdApplyRendition>
                    type="text"
                    label={translate('LABEL_DK_MITID_DOMAIN_PREFIX')}
                    name="domainPrefix"
                    required
                    validate={validateDomainPrefix}
                    help={errors.domainPrefix ? (
                      <small className="form-text text-danger">{errors.domainPrefix}</small>
                    ) : values.domainPrefix ? (
                      <small className="form-text text-muted">{trimDomainPrefix(values.domainPrefix)}{domainSuffix}</small>
                    ) : null}
                  />
                </div>

                <FormError error={error} />
                <FormSuccess message={!isPending && isSuccess && `Thank you! You will hear from us.`} />
                <Button className="pull-right" variant="primary" working={isPending} disabled={isSuccess} type="submit">Apply</Button>
              </React.Fragment>
            )}
          </div>
          <div className="col-sm-6">
            {(requireNemID && !values.vatIdentifier.vatNumber) ? null : (
              <React.Fragment>
                <h3>{translate('INFO')}</h3>
                <p>{translate('INFO_MITID_APPLY')}</p>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </Form>
  )
}
