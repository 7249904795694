import React, {useState} from 'react';
import { Application, Domain } from '@app/models';
import { useDispatch } from '@app/redux';
import { createApplication } from '@app/redux/tenantSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {Form, InputField, Switch, FormError, FormSuccess} from '@app/components/Form';
import ApplicationNameField from '../ApplicationEditor/ApplicationNameField';
import DomainField from '../ApplicationEditor/DomainField';
import { translate } from '@app/i18n';
import ApplicationRealmValidation from '../ApplicationEditor/ApplicationRealmValidation';
import ReturnURLs from '../ApplicationEditor/ReturnURLs';
import Button from '@app/components/Button/Button';

interface Props {
  application: Application,
  onSave: (application: Application) => void
}

export default function VerifyApplicationCreater(props: Props) {
  const [isRealmValid, setRealmValid] = useState(true);
  const dispatch = useDispatch();
  const [domain, setDomain] = useState<Domain | undefined>(undefined);
  const isFormValid = isRealmValid && domain;

  const handleSubmit = async (values : Application) => {
    if (!isFormValid) return Promise.reject(new Error('Invalid form fields'));

    values.domain = domain;
    const [, applicationResult] = await dispatch(createApplication(values)).then(unwrapResult);

    props.onSave(applicationResult);
  }

  return (
    <Form
      initialValues={props.application}
      onSubmit={handleSubmit}
      data-test-id="form"
      key="application_creater"
      className="max-w-[750px] flex flex-col gap-[25px] form-gap"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <div>
            <ApplicationNameField />
            <small className="help-text">
              The application name will be shown to your end users in certain corner cases.
            </small>
          </div>

          <DomainField domain={domain} isCreate={true} onChange={setDomain} />

          <div>
            <InputField<Application>
              type="text"
              label={translate('LABEL_CLIENT_ID')}
              name="realm"
              required
              placeholder={translate('LABEL_CLIENT_ID')}
              help={domain && <ApplicationRealmValidation domain={domain} application={props.application} onValid={setRealmValid} />}
            />
            <small className="help-text">
              The realm/client ID must be unique for each application within the selected domain.<br />
              When integration Criipto in your code you will need the domain name and your client ID.
            </small>
          </div>

          <ReturnURLs />

          {error && (
            <FormError error={error} />
          )}

          {isSuccess && (
            <FormSuccess message={'Application created'} />
          )}

          <div>
            <Button variant="primary" type="submit" working={isPending}>Create application</Button>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}