import { useApplicationRoleGrantsQuery, useInviteOrganizationUserMutation, useOrganizationQuery } from '@app/api/gauss';
import { TENANT_ID_PREFIX } from '@app/constants';
import usePermissions from '@app/hooks/usePermissions';
import useTenant, { TenantRouteParams } from '@app/hooks/useTenant';
import {Form, FormError, FormSuccess, InputField, Select} from '@app/components/Form';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import React, { useCallback, useEffect, useState } from 'react';

import { GaussInvitationRequest, GaussApplicationRoleGrant, GaussApplicationRole, GaussServicePermission, UserRole, USER_ROLES, ROLE_DESCRIPTION, ROLE_TO_PERMISSIONS } from '@app/models';
import Button from '@app/components/Button/Button';
import { Field, FormikHelpers } from 'formik';
import Permissions from '../Permissions';
import { curryN } from 'lodash/fp';
import useTracking from '@app/hooks/useTracking';

function filterRoleGrants(input: GaussApplicationRoleGrant[], callback: (role: GaussApplicationRole) => boolean) {
  return input.map(roleGrant => ({
    ...roleGrant,
    applicationRoles: roleGrant.applicationRoles.filter(callback)
  })).filter(roleGrant => roleGrant.applicationRoles.length > 0)
}

export default function InvitationForm(props: {onInvited?: () => void}) {
  const permissions = usePermissions();
  const tenant = useTenant();
  const tracking = useTracking();
  const gaussId = tenant?.tenantId.replace(TENANT_ID_PREFIX, '');

  const organization = useOrganizationQuery(permissions?.users && gaussId ? gaussId : skipToken);
  const applicationRoleGrants = useApplicationRoleGrantsQuery(organization?.data ?? skipToken);

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);

  const [inviteUser, inviteUserResult] = useInviteOrganizationUserMutation();

  const resetRoles = useCallback(() => {
    setSelectedRoles(
      applicationRoleGrants!.data!.applicationRoleGrants.reduce((memo, roleGrant) => {
        return memo.concat(roleGrant.applicationRoles.map(role => role.roleId))
      }, [] as string[])
    );
    setSelectedPermissions(applicationRoleGrants!.data!.servicePermissions.map(permission => permission.id));
  }, [applicationRoleGrants?.data]);

  useEffect(() => {
    if (!applicationRoleGrants?.data) return;

    resetRoles();
  }, [applicationRoleGrants?.data]);

  if (!permissions?.users) return null;
  if (!applicationRoleGrants?.data) return null;

  const handleSubmit = async (values: {name: string, email: string, role: UserRole}, actions: FormikHelpers<any>) => {
    const rolePermissions = ROLE_TO_PERMISSIONS[values.role];
    const servicePermissions =
      rolePermissions === '__CUSTOM__' ? selectedPermissions :
      rolePermissions === '__ALL__' ? applicationRoleGrants!.data!.servicePermissions.map(permission => permission.id) :
      rolePermissions.servicePermissions;

    const allServicePermissions = applicationRoleGrants!.data!.servicePermissions;
    const allApplicationRoleGrants = applicationRoleGrants!.data!.applicationRoleGrants;
    const acceptUrl = window.location.origin + '/invitation/accept?href={0}';

    const request : GaussInvitationRequest = {
      name: values.name,
      contactEmail: values.email,
      returnUrl: window.location.origin,
      invitationCompletionFlow: 'linkInEmail',
      emailTemplate: {
        subject: 'Welcome to Criipto Verify',
        bodyTemplate: `You have been invited to Criipto Verify.\n\nFollow the link to accept the invitation:\n${acceptUrl}\n\n`
      },
      servicePermissions:
        rolePermissions === '__ALL__' ? allServicePermissions:
        rolePermissions === '__CUSTOM__' ? allServicePermissions.filter(permission => servicePermissions.includes(permission.id)) :
        allServicePermissions.filter(permission => rolePermissions.servicePermissions.includes(permission.id)),
      applications:
        rolePermissions === '__ALL__' ? allApplicationRoleGrants :
        rolePermissions === '__CUSTOM__' ? filterRoleGrants(allApplicationRoleGrants, role => selectedRoles.includes(role.roleId)) :
        filterRoleGrants(allApplicationRoleGrants, role => rolePermissions.roles.includes(role.role))
    };

    await inviteUser({
      organization: organization.data!,
      request,
    }).unwrap();

    tracking.userInvited(values.role);

    actions.resetForm();
    resetRoles();

    if (props.onInvited) props.onInvited();
  };

  const handlePermission = (item: GaussApplicationRole | GaussServicePermission, selected: boolean) => {
    if ("roleId" in item) {
      setSelectedRoles(roles => {
        if (selected) {
          return roles.concat(item.roleId);
        }
        return roles.filter(s => s != item.roleId);
      });

      return
    }

    setSelectedPermissions(permissions => {
      if (selected) {
        return permissions.concat(item.id);
      }
      return permissions.filter(s => s != item.id);
    });
  }

  return (
    <Form<{name: string, email: string, role: UserRole | ''}> initialValues={{name: '', email: '', role: ''}} onSubmit={handleSubmit} key="invite_user" style={{width: '100%'}}>
      {({isPending, error, isSuccess, values}) => (
        <React.Fragment>
          <div className="flex flex-col lg:flex-row gap-[10px]">
            <InputField
              type="text"
              name="name"
              required
              placeholder={"Name"}
            />
            <InputField
              type="email"
              name="email"
              required
              placeholder={"Email"}
            />
            <div className="grow">
              <Field as="select" className="form-control min-w-[150px]" name="role" required data-testid="invitation_form_role">
                <option value="">Select role</option>
                {USER_ROLES.map(role => (
                  <option key={role} value={role}>{role}</option>
                ))}
              </Field>
              {values.role && values.role !== 'Custom' ? (
                <p className="help-block">
                  {ROLE_DESCRIPTION[values.role]}
                </p>
              ) : null}
            </div>
            <Button variant="primary" type="submit" working={isPending}>Invite user</Button>
          </div>
          {values.role === 'Custom' ? (
            <div className="flex flex-wrap gap-[10px]">
              <Permissions
                applicationRoleGrants={applicationRoleGrants.data!.applicationRoleGrants.map(roleGrant => ({
                  ...roleGrant,
                  applicationRoles: roleGrant.applicationRoles.map(role => ({
                    ...role,
                    selected: selectedRoles.includes(role.roleId)
                  }))
                }))}

                servicePermissions={applicationRoleGrants.data!.servicePermissions.map(permission => ({
                  ...permission,
                  selected: selectedPermissions.includes(permission.id)
                }))}

                selectable={true}
                onSelect={handlePermission}
              />
            </div>
          ) : null}

          {error && (
            <FormError error={error} />
          )}
          {isSuccess && (
            <FormSuccess message={'User invited!'} />
          )}
        </React.Fragment>
      )}
    </Form>
  )
}