import { Application, Domain } from "@app/models";
import {singleton as config} from '@app/config';

const application = new Application();
application.domain = new Domain();
application.domain.name = `samples.${config.easyIdDns}`;
application.realm = 'urn:criipto:dashboard:test-login';
application.returnUrls = ["https://jwt.io"];

// Pseudo application to enable "Try a test login" against criipto.samples.io|id
// We used to automatically create a domain and application for new tenants to try this
export default function useTestLoginApplication() {
  return application;
}