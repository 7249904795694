import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { singleton as config } from '@app/config';
import { RootState } from '@app/redux/store';
import {
  Backoffice,
  Billable, deserializer,
  getHref, Tenant
} from '@app/models';
import { CONTENT_TYPE, REL } from '@app/constants';

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiBase,
  prepareHeaders: (headers, api) => {
    const token = (api.getState() as RootState).auth.access_token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  }
});

export const verifyApi = createApi({
  reducerPath: 'verifyApi',
  baseQuery,
  tagTypes: ['Billable', 'Backoffice'],
  endpoints: (builder) => ({
    billable: builder.query<Billable, Tenant>({
      query: (tenant) => getHref(tenant, REL.BILLABLE),
      transformResponse: deserializer(Billable),
      providesTags: (result, error, tenant) => [{type: 'Billable', id: tenant.tenantId}],
      keepUnusedDataFor: 3600 // TODO: manually unset cache on subscription selection
    }),
    backoffice: builder.query<Backoffice, Tenant>({
      query: (tenant) => getHref(tenant, REL.BACKOFFICE),
      providesTags: (result, error, tenant) => [{type: 'Backoffice', id: tenant.tenantId}],
    }),
    updateBackoffice: builder.mutation<Backoffice, {tenant: Tenant, backoffice: Backoffice}>({
      query: ({tenant, backoffice}) => ({
        method: 'PUT',
        url: getHref(tenant, REL.BACKOFFICE),
        headers: {
          'Content-Type': CONTENT_TYPE.BACKOFFICE
        },
        body: JSON.stringify(backoffice)
      }),
      invalidatesTags: (result, error, {tenant}) => [{type: 'Billable', id: tenant.tenantId}, {type: 'Backoffice', id: tenant.tenantId}],
    }),

    refetchBillable: builder.mutation<null, Tenant>({
      queryFn: () => ({ data: null }),
      invalidatesTags: (result, error,  tenant) => [{type: 'Billable', id: tenant.tenantId}],
    }),
    refetchBackoffice: builder.mutation<null, Tenant>({
      queryFn: () => ({ data: null }),
      invalidatesTags: (result, error,  tenant) => [{type: 'Backoffice', id: tenant.tenantId}, ],
    })
  })
});

export const {useBillableQuery, useBackofficeQuery, useUpdateBackofficeMutation} = verifyApi;