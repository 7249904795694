import React from 'react';

import {translate} from '@app/i18n';
import {Modal, ModalProps} from '@app/components/Modal';
import {Application} from '@app/models';

interface Props {
  application: Application,
  clientSecret: string,
  onHide: ModalProps["onHide"],
  show: ModalProps["show"]
}

export default function ClientSecretModal(props : Props) {
  return (
    <Modal show={props.show} onHide={props.onHide} title={translate('CLIENT_SECRET_FOR') + ' ' + props.application.name}>
      <Modal.Body>
        <p className="help-block">{translate('INFO_CLIENT_SECRET')}</p>
        <input type="text" className="form-control" value={props.clientSecret} readOnly />
      </Modal.Body>
    </Modal>
  )
}