import React, {useState} from 'react';
import {Modal as BsModal} from 'react-bootstrap';
import cx from 'classnames';

import Button, {ButtonProps} from '@app/components/Button';
import './Modal.scss';
import { translate } from '@app/i18n';

export interface ModalProps {
  show: boolean
  onHide: () => void
  title: React.ReactNode
  children: React.ReactNode
  backdrop?: boolean
  center?: boolean
  color?: "white" | "grey"
  className?: string
}

export function Modal(props : ModalProps) {
  return (
    <BsModal
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop !== undefined ? props.backdrop : true}
      keyboard={true}
      bsClass={cx(props.color, {center: props.center}, props.className, 'modal')}
    >
      <BsModal.Header closeButton>
        {props.title && (<BsModal.Title>{props.title}</BsModal.Title>)}
      </BsModal.Header>
      {props.children}
    </BsModal>
  )
}

Modal.Body = (props : {children: React.ReactNode}) => {
  return (
    <BsModal.Body>
      {props.children}
    </BsModal.Body>
  );
}

Modal.Footer = (props : {children: React.ReactNode, className?: string}) => {
  return (
    <BsModal.Footer className={props.className}>
      {props.children}
    </BsModal.Footer>
  );
}

export default Modal;

export interface ConfirmModalProps extends ModalProps {
  onConfirm: () => Promise<void> | void,
  cancelText?: string
  confirmText: string
  confirmVariant?: ButtonProps["variant"]
}
export function ConfirmModal(props : ConfirmModalProps) {
  const [working, setWorking] = useState(false);
  const handleConfirm = () => {
    setWorking(true);
    Promise.resolve(props.onConfirm()).finally(() => {
      setWorking(false);
    });
  };

  return (
    <Modal
      {...props}
    >
      <Modal.Body>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-between">
         <Button variant="default" onClick={props.onHide}>
            {props.cancelText || translate('CANCEL')}
          </Button>
          <Button variant={props.confirmVariant || 'primary'} onClick={handleConfirm} working={working}>
            {props.confirmText}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
