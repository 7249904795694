import React, {useCallback, useEffect} from 'react';
import {identity} from 'lodash';

import {useSelector, useDispatch} from '@app/redux';
import {fetchDomains} from '@redux/tenantSlice';
import {translate} from '@app/i18n';

import {
  ItsmeConfiguration,
  Tenant, DetailedIdentityProvider, getHref
} from '@app/models';

import {REL, CONTENT_TYPE} from '@app/constants';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch} from '@components/Form';

import EnvironmentDialog from '../EnvironmentDialog/EnvironmentDialog';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  configuration: ItsmeConfiguration,
  provider: DetailedIdentityProvider,
  apiRoot: Tenant
}

export interface FormValues {
  clientId : string
  serviceCode : string
  requestExtraIdData : boolean
  requestSecurityData : boolean
}

export function ItsmeProvider(props : Props) {
  const tenant = props.apiRoot;
  const {configuration} = props;

  const initialValues : FormValues = {
    clientId: configuration?.clientId,
    serviceCode: configuration?.serviceCode,
    requestExtraIdData: configuration?.requestExtraIdData || false,
    requestSecurityData: configuration?.requestSecurityData || false
  };

  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";

  const dispatch = useDispatch();
  const domains = useSelector(state => state.tenant.domains);

  useEffect(() => {
    dispatch(fetchDomains({force: false}));
  }, []);

  const saveProvider = useCallback(async (values : FormValues) => {
    let hrefSettings = getHref(tenant, REL.ITSME_SETTINGS);
    return await dispatchVerifyRequest(
      dispatch,
      {
        url: hrefSettings,
        method: "POST",
        data: {
          ...values,
          production: isProduction
        },
        contentType: CONTENT_TYPE.ITSME_SETTINGS
      },
      identity
    );
  }, [tenant]);

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : FormValues) => {
        return saveProvider(values);
      }}
      data-test-id="form"
    >
      {({isPending, isSuccess, error}) => (
        <React.Fragment>
          <EnvironmentDialog environment="TEST">
            <p className="green">⚠️<em>{translate('HINT_ID_SERVICE_CONFIG_CUSTOM_ITSME_WHAT_TO_AVOID')}</em></p>
          </EnvironmentDialog>
          <EnvironmentDialog environment="PRODUCTION" heading={(
            <React.Fragment>
              <h1>
                <strong>{translate('INFO_ITSME_CLIENT')}</strong>
              </h1>
              <br />
              <h3>{translate('INFO_ITSME_URLS_PROD')}</h3>
            </React.Fragment>
          )}>
            {domains.items?.filter(domain => domain.production).map(domain => (
              <p className="green" key={domain.name}>
                <div>
                  <i>{translate('PRODUCTION_DOMAIN')}</i>&nbsp;<strong>{domain.name}</strong>
                </div>
                <div>
                  <div>
                    <i>{translate('INFO_ITSME_REDIRECT_URI')}</i>
                  </div>
                  https://{domain.name}/Itsme/Callback
                </div>
                <div>
                  <div>
                    <i>{translate('INFO_ITSME_JWKSET_URL')}</i>
                  </div>
                  https://{domain.name}/.well-known/client-jwks
                </div>
              </p>
            ))}
          </EnvironmentDialog>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} Itsme Authentication
              </h3>
              <EnvironmentTag />
              <InputField
                type="text"
                label={translate('LABEL_ITSME_CLIENTID')}
                name="clientId"
                placeholder={translate('LABEL_ITSME_CLIENTID')}
              />
              <InputField
                type="text"
                label={translate('LABEL_ITSME_SERVICECODE')}
                name="serviceCode"
                placeholder={translate('LABEL_ITSME_SERVICECODE')}
              />

              <Switch name="requestExtraIdData" label={translate('LABEL_ITSME_REQUEST_EXTRAIDDATA')} />
              <Switch name="requestSecurityData" label={translate('LABEL_ITSME_REQUEST_SECURITYDATA')} />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your itsme settings have been updated` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_ITSME_CLIENT')}</p>
              <p>{translate(isProduction ? 'INFO_ITSME_URLS_PROD': 'INFO_ITSME_URLS_TEST')}</p>
              {domains.items?.filter(domain => domain.production === isProduction).map(domain => (
                <p key={domain.name}>
                  <div>
                    <i>{isProduction ? translate('PRODUCTION_DOMAIN') : translate('TEST_DOMAIN')}</i>&nbsp;<strong>{domain.name}</strong>
                  </div>
                  <div>
                    <div>
                      <i>{translate('INFO_ITSME_REDIRECT_URI')}</i>
                    </div>
                    https://{domain.name}/Itsme/Callback
                  </div>
                  <div>
                    <div>
                      <i>{translate('INFO_ITSME_JWKSET_URL')}</i>
                    </div>
                    https://{domain.name}/.well-known/client-jwks
                  </div>
                </p>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}