interface SlackNotification {
  channel: string
  username: string
  text: string
  icon_emoji?: string
}

export async function slackNotification(url : string, notification : SlackNotification) {
  if (!url.length) return;
  return await fetch(url, {
    method: 'POST',
    body: JSON.stringify(notification)
  });
}