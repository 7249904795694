import React, { useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import 'moment-timezone';
import {ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip} from 'recharts';
import {get, sortBy, upperFirst} from 'lodash';

import {
  BUCKET, TRANSACTION_TYPE, DIMENSION,
  useAnalyticsDimensionsQuery, useAnalyticsSeriesQuery, useAnalyticsTotalsQuery, BUCKETS, TRANSACTION_TYPES, DimensionsResponse, DIMENSIONS
} from '@app/api/subscription';
import useEnvironment from '@app/hooks/useEnvironment';
import usePermissions from '@app/hooks/usePermissions';
import useTenant from '@app/hooks/useTenant';
import PRESETS from './presets';
import './AnalyticsScreen.scss';
import { CHART_HEIGHT, FAKE_CHART, DateSelection, X_AXIS_HEIGHT, Y_AXIS_WIDTH, DIMENSION_SETTINGS, COLORS, BUCKET_SETTINGS } from './constants';
import Checkbox from '@app/components/Form/Checkbox';
import ChartTooltip from './components/ChartTooltip/ChartTooltip';
import DownloadButton from './components/DownloadButton/DownloadButton';
import ChartLegend from './components/ChartLegend/ChartLegend';
import DateRangePickerButton from './components/DateRangePickerButton/DateRangePickerButton';
import StatBox from './components/StatBox';
import { Domain } from '@app/models';
import useTracking from '@app/hooks/useTracking';

const timezone = moment.tz.guess();
const startOfMonth = moment().startOf('month').startOf('day').toDate();
const endOfToday = moment().endOf('day').toDate();

const shouldDisallowHourBucket = (dateSelection: DateSelection) => {
  const diff = Math.abs(dateSelection.from.diff(dateSelection.to));
  return diff > (3 * 31 * 24 * 60 * 60 * 1000);
}

export default function AnalyticsScreen(props: {domain?: Domain}) {
  const {domain} = props;
  const permissions = usePermissions();
  const tenant = useTenant();
  const environment = useEnvironment();
  const embedded = !!props.domain;
  const tracking = useTracking();

  useEffect(() => {
    if (!tenant) return;

    tracking.viewedAnalyticsScreen(domain ? 'domain' : undefined);
  }, [tenant, domain]);

  const [transactionType, setTransactionType] = useState<TRANSACTION_TYPE[]>(['authentication']);
  const [bucket, setBucket] = useState<BUCKET>('DAY');
  const [selectedDimension, setDimension] = useState<DIMENSION>('authenticationType');
  const [dateSelection, setDateSelection] = useState<DateSelection>(PRESETS[1]);
  const [dimensionsFilter, setDimensionsFilter] = useState('');

  const totals = useAnalyticsTotalsQuery(permissions?.reporting && tenant && !embedded ? tenant : skipToken);
  const dimensions = useAnalyticsDimensionsQuery(permissions?.reporting && tenant ? {
    tenant,
    timezone,
    environment: [environment],
    transactionType
  } : skipToken);

  const loginsThisMonth = useAnalyticsSeriesQuery(permissions?.reporting && tenant && !embedded ? {
    tenant,
    timezone,
    environment: [environment],
    dataDimension: 'authenticationType',
    bucket: 'MONTH',
    from: startOfMonth,
    to: endOfToday,
    transactionType: ['authentication']
  } : skipToken);

  const series = useAnalyticsSeriesQuery(permissions?.reporting && tenant ? {
    tenant,
    timezone,
    environment: [environment],
    dataDimension: selectedDimension,
    bucket,
    from: dateSelection.from.toDate(),
    to: dateSelection.to.toDate(),
    transactionType,
    domain: props.domain ? [props.domain.name] : undefined
  } : skipToken);

  const dataDimension = selectedDimension;
  /*
     * Let user switch between dimension types and toggle dimension values
     */
  const [selectedDataDimensionValues, setSelectedDataDimensionValues] = useState<string[]>(dimensions.data ? (dimensions.data[dataDimension] || []) : []);
  const resetSelectedDataDimensionValues = (dimension: keyof DimensionsResponse) => setSelectedDataDimensionValues(dimensions.data?.[dimension] as string[] || []);
  const allDimensionValuesToggled = selectedDataDimensionValues.length === dimensions.data?.[dataDimension]?.length;

  useEffect(() => {
    if (dimensions.data && series.data) {
      if (!selectedDataDimensionValues.length) {
        resetSelectedDataDimensionValues(dataDimension);
      }
    }
  }, [series.data, dimensions.data]);

  const handleToggleDimensionValue = (dimension: string) => {
    console.log(dimension);
    setSelectedDataDimensionValues((selectedDataDimensions) => {
      if (selectedDataDimensions.includes(dimension)) {
        return selectedDataDimensions.filter(search => search !== dimension);
      }

      return selectedDataDimensions.concat([dimension]);
    });
  };

  const handleToggleAllDimensionValues = () => {
    if (!allDimensionValuesToggled) return resetSelectedDataDimensionValues(dataDimension);
    setSelectedDataDimensionValues([]);
  };

  const handleSelectDimension = (dimension: DIMENSION) => {
    resetSelectedDataDimensionValues(dimension);
    setDimension(dimension);
  };

  const toggleTransactionType = (t: TRANSACTION_TYPE, checked: boolean) => {
    setTransactionType(e => checked ? e.concat(t) : e.filter(s => s !== t));
  };

  const handleDateSelection = (dateSelection: DateSelection) => {
    setDateSelection(dateSelection);

    if (shouldDisallowHourBucket(dateSelection) && bucket === 'HOUR') {
        setBucket('DAY');
    }
  };

  const handleDimensionsFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDimensionsFilter(event.target.value);
  }

  /*
    * If the current user selections will result in an empty chart we will render a placeholder chart with a message
    */
  const shouldFakeData = !series.data?.length || !dimensions.data || !selectedDataDimensionValues.length;
  const data = useMemo(() => {
      return shouldFakeData ? FAKE_CHART.generateData(dateSelection) : (series.data || []).map(result => {
          // Fixes null values by making sure that each dimension is always represented by atleast a 0
          return {
              ...result,
              dimension: {
                  ...result.dimension,
                  [dataDimension]: {
                      ...get(dimensions.data!, dataDimension as any, []).reduce((memo: any, dimension: any) => {
                          memo[dimension] = 0;
                          return memo;
                      }, {}),
                      ...result.dimension[dataDimension]
                  }
              }
          };
      });
  }, [
      shouldFakeData,
      series.data,
      dataDimension
  ]);

  /*
     * Lets make sure colors don't jump around as we change selected dimension values and therefore amount of area charts
     */
  const colorByDimensionValue = (dimensions.data?.[dataDimension] ?? []).reduce((memo: any, dimension: string, index: number) => {
    memo[dimension] = COLORS[index % COLORS.length];
    return memo;
  }, {});

  /*
  * Calculate sum by dimension to order area charts for it (so largest values gets rendered at the bottom)
  */
  const totalByDimensionValue = series.data && dimensions.data ? (dimensions.data[dataDimension] ?? []).reduce((memo: any, dimension: string) => {
      memo[dimension] = series.data!.reduce((sum, result) => get(result, ['dimension', dataDimension, dimension]), 0);
      return memo;
  }, {}) : {};

  /*
    * Filter areas to the selected dimensions
    * Sort them by totals to get the highest chart at the bottom
    */
  const dimensionsList = dimensions.data?.[dataDimension] ?? [];
  const dimensionCount = dimensionsList.length;
  const filteredDimensions =
    dimensionsList.filter(dimension => !dimensionsFilter || DIMENSION_SETTINGS[dataDimension]._valueLabel(dimension).toLowerCase().includes(dimensionsFilter.toLowerCase()));
  const areas = sortBy(
    (dimensions.data && series.data?.length) ? filteredDimensions.filter(dimensionValue => selectedDataDimensionValues.includes(dimensionValue)).map(dimensionValue => ({
        dimensionValue: dimensionValue
    })) : [],
    (area) => -totalByDimensionValue[area.dimensionValue]
  );

  if (!permissions?.reporting) return null;

  const loading = series.isLoading || dimensions.isLoading || series.isFetching;
  const chartWrapperClassName = embedded ? 'analytics-screen chart-container' : 'analytics-screen container-full-screen chart-container';

  return (
    <React.Fragment>
      {!embedded && (
        <div className="container">
          <div className="analytics-screen stat-boxes">
            <StatBox
              prefix={<React.Fragment>Total <strong className={`text-${environment}`}>logins</strong></React.Fragment>}
              number={loginsThisMonth.data ? (loginsThisMonth.data[0]?.total ?? 0) : 'N/A'}
              suffix={<React.Fragment>Since {moment(startOfMonth).format('MMMM Do, YYYY')}</React.Fragment>}
            />

            <StatBox
              prefix={<React.Fragment>Total unique <strong className="text-PRODUCTION">users</strong></React.Fragment>}
              number={totals.data?.uniqueUsersAllTime ?? 'N/A'}
              suffix={<React.Fragment>Since you signed up</React.Fragment>}
            />
          </div>
        </div>
      )}

      <div className={chartWrapperClassName}>
        <div className="chart-top">
          <div className="flex" style={{gap: '10px'}}>
            <DateRangePickerButton
              selected={dateSelection}
              dimensions={dimensions.data}
              onChange={handleDateSelection}
            />

            <select className="form-control" value={bucket} onChange={(event) => setBucket(event.target.value as BUCKET)}>
              {BUCKETS.filter(bucket => bucket !== 'HOUR' || !shouldDisallowHourBucket(dateSelection)).map(bucket => (
                <option key={bucket} value={bucket}>{upperFirst(bucket.toLowerCase())}</option>
              ))}
            </select>

            <div className="checkboxes">
              {TRANSACTION_TYPES.map(t => (
                <div className="form-group horizontal" key={t}>
                  <Checkbox checked={transactionType.includes(t)} onChange={(checked) => toggleTransactionType(t, checked)} />
                  <label className="control-label" onClick={() => toggleTransactionType(t, !transactionType.includes(t))} >
                    {t === 'authentication' ? 'Logins' : t === 'signature' ? 'Native Signatures' : 'Criipto Signatures'}
                  </label>
                </div>
              ))}
            </div>

            <DownloadButton
              bucket={bucket}
              dimension={dataDimension}
              results={series.data}
            />
          </div>
          <div className="flex" style={{gap: '10px'}}>
            {dimensionCount > 8 ? (
              <input type="text" style={{width: '230px'}} className="form-control" placeholder="Filter dimensions ..." value={dimensionsFilter} onChange={handleDimensionsFilter} />
            ) : null}
          </div>
        </div>
        <div className="flex">
          <div className="chart">
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <AreaChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                <CartesianGrid stroke="#faf6f6" fill="#FFF" />
                <XAxis
                    dataKey="bucket"
                    tickSize={0}
                    tick={{ transform: 'translate(0, 5)' }}
                    tickCount={Math.min(data.length, 30)}
                    interval={data.length <= 30 ? 0 : undefined}
                    tickFormatter={BUCKET_SETTINGS[bucket].tickFormatter}
                    label={{
                        value: "Date",
                        transform: 'translate(0, 10)'
                    }}
                    height={X_AXIS_HEIGHT}
                />
                <YAxis
                    type="number"
                    domain={[0, 'auto']}
                    tickSize={0}
                    tick={{ transform: 'translate(-5, 0)' }}
                    tickCount={11}
                    interval={0}
                    label={{
                        value: `Total ${transactionType.map(value => DIMENSION_SETTINGS.transactionType[value].label).join(' + ')}`,
                        angle: -90, // Make the label vertical
                        position: 'insideLeft'
                    }}
                    width={Y_AXIS_WIDTH}
                    ticks={shouldFakeData ? FAKE_CHART.yTicks : undefined}
                />
                <Tooltip
                  content={(props: any) => <ChartTooltip bucket={bucket} dataDimension={dataDimension} selectedDataDimensionValues={selectedDataDimensionValues} {...props} />}
                />

                {areas.map(area => (
                  <Area
                    isAnimationActive={true}
                    type="linear"
                    key={`dimension.${dataDimension}.${area.dimensionValue}`}
                    name={`dimension.${dataDimension}.${area.dimensionValue}`}
                    /*
                    * Use a function rather than a string in case our dimension contains dots
                    */
                    dataKey={(dataObject) => get(dataObject, ['dimension', dataDimension, area.dimensionValue])}
                    stackId="1"
                    stroke={colorByDimensionValue[area.dimensionValue]}
                    strokeWidth={2.5}
                    fill={colorByDimensionValue[area.dimensionValue]}
                    fillOpacity="0.25"
                    activeDot={false}
                  />
                ))}
              </AreaChart>
            </ResponsiveContainer>
            {!loading && shouldFakeData && <div className="no-data-banner">No data available for selected criteria</div>}
            {loading && <div className="loading-banner"><i className="fa fa-spinner fa-pulse fa-1x"></i> Loading data ...</div>}
          </div>
          <div className="dimensions">
            <div className="list">
              {dimensionCount > 3 ? (
                <ChartLegend label="All" color="#000" onToggle={handleToggleAllDimensionValues} selected={allDimensionValuesToggled} />
              ) : null}
              {filteredDimensions.map(dimension => (
                <ChartLegend
                  key={dimension}
                  label={DIMENSION_SETTINGS[dataDimension]._valueLabel(dimension)}
                  color={colorByDimensionValue[dimension]}
                  onToggle={() => handleToggleDimensionValue(dimension)}
                  selected={selectedDataDimensionValues.includes(dimension)}
                />
              ))}
            </div>
            {!embedded && (
              <div style={{margin: 10}}>
                <select className="form-control" value={dataDimension} onChange={(event) => handleSelectDimension(event.target.value as DIMENSION)}>
                  {DIMENSIONS.map(dimension => (
                    <option key={dimension} value={dimension}>{DIMENSION_SETTINGS[dimension].label}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}