import { skipToken } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import moment from 'moment';

import { CustomerRendition, useCustomerQuery, useUpdateCustomerMutation } from '@app/api/subscription';
import usePermissions from '@app/hooks/usePermissions';
import useTenant from '@app/hooks/useTenant';
import { getErrorString } from '@app/api/helper';
import { Form, FormError, FormSuccess, InputField, Select } from '@app/components/Form';
import Button from '@app/components/Button';
import {singleton as config} from '@app/config';

import './BillingInformationScreen.css';
import countries from '../../countries';

// https://en.wikipedia.org/wiki/VAT_identification_number
// https://github.com/wbond/vat-moss.js/blob/master/vat-moss.js
type VAT_HELP = {
  [key: string]: {
    text: string,
    pattern: string
  }
}
const VAT_HELP : VAT_HELP = {
  DK: {
    text: 'E.g. DK12345678',
    pattern: 'DK[0-9]{8}'
  },
  SE: {
    text: 'E.g. SE999999999901',
    pattern: 'SE[0-9]{12}'
  },
  NO: {
    text: 'E.g. NO993101583MVA',
    pattern: 'NO[0-9]{9}MVA'
  }
}

export default function BillingInformationScreen() {
  const tenant = useTenant();
  const permissions = usePermissions();
  const customer = useCustomerQuery(tenant && permissions?.financials ? tenant : skipToken);

  const [update, updateResult] = useUpdateCustomerMutation();

  const handleSubmit = async (values: CustomerRendition) => {
    await update({tenant: tenant!, request: values}).unwrap();
  };

  const initialValues : CustomerRendition | undefined = customer.data === null ? {} : customer.data ? {...customer.data, name: customer.data?.nameOnCard || customer.data?.name} : undefined;

  if (customer.data === null) {
      return (
      <React.Fragment>
        Please <a href={`${config.SUBSCRIPTION_FRONTEND_URL}/${btoa(tenant!.entityId)}/plans`} className="!underline" target="_blank">sign up for a subscription.</a>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {customer.error ? (
        <div className="alert alert-danger">
          {getErrorString(customer.error) || 'An error occurred'}
        </div>
      ) : null}
      {(!permissions?.financials || customer.isFetching) ? (<i className="fa fa-spinner fa-pulse fa-2x" />) : null}

      {initialValues && (
        <Form
          initialValues={initialValues}
          key={`${tenant?.tenantId}_billing_information`}
          onSubmit={handleSubmit}
          className="billing-information-form max-w-[750px]"
        >
          {({isPending, isSuccess, error, values}) => (
            <React.Fragment>
              <div className="flex flex-col gap-4 mb-8">
                <InputField<CustomerRendition>
                  type="text"
                  name="company"
                  placeholder="Company"
                  label="Company"
                  required
                />

                <div className="flex flex-row gap-8">
                  <InputField<CustomerRendition>
                    type="text"
                    name="street"
                    placeholder="Street Address"
                    label="Street Address"
                    required
                    groupClassName="flex-1"
                  />

                  <InputField<CustomerRendition>
                    type="text"
                    name="city"
                    placeholder="City"
                    label="City"
                    required
                    groupClassName="flex-1"
                  />
                </div>

                <div className="flex flex-row gap-8">
                  <InputField<CustomerRendition>
                    type="number"
                    name="zip"
                    placeholder="Postal code"
                    label="Postal code"
                    required
                    groupClassName="flex-1"
                  />

                  <Select<CustomerRendition>
                    name="country"
                    label="Country"
                    groupClassName="flex-1"
                    options={[
                      {value: '', label: 'Select country'}
                    ].concat(Object.entries(countries).map(([key, value]) => ({
                      value: key,
                      label: value
                    })))}
                    required
                  />
                </div>

                <InputField<CustomerRendition>
                  type="email"
                  name="email"
                  placeholder="Invoice Email"
                  label="Invoice Email"
                  required
                />

                <InputField<CustomerRendition>
                  type="text"
                  name="taxId"
                  placeholder={(values.country && VAT_HELP[values.country]?.text) ? VAT_HELP[values.country].text : 'EU VAT number - E.g. DK12345678'}
                  label="EU VAT number"
                  required
                  pattern={(values.country && VAT_HELP[values.country]?.pattern) ? VAT_HELP[values.country].pattern : undefined}
                  // Required by default to emulate how it is in the current subscription UI
                  // Ultimately this field should only be shown if an EU country is selected.
                />
                <small className="help-text">{(values.country && VAT_HELP[values.country]) ? VAT_HELP[values.country].text : 'EU VAT number - E.g. DK12345678'}</small>
              </div>

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your billing information has been updated` || null} />
              <Button variant="primary" working={isPending} type="submit">Update billing information</Button>
            </React.Fragment>
          )}
        </Form>
      )}
    </React.Fragment>
  );
}