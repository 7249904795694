import { skipToken } from '@reduxjs/toolkit/dist/query';
import React from 'react';
import moment from 'moment';

import { useInvoicesQuery } from '@app/api/subscription';
import usePermissions from '@app/hooks/usePermissions';
import useTenant from '@app/hooks/useTenant';
import { getErrorString } from '@app/api/helper';

export default function PaymentHistoryScreen() {
  const tenant = useTenant();
  const permissions = usePermissions();
  const invoices = useInvoicesQuery(tenant && permissions?.financials ? tenant : skipToken);

  return (
    <div className="accordion">
      <div className="accordion-header">
        <div className="row">
          <div className="col-xs-3">
            <h3>Date</h3>
          </div>
          <div className="col-xs-3">
            <h3>Paid at</h3>
          </div>
          <div className="col-xs-3">
            <h3>Amount</h3>
          </div>
          <div className="col-xs-3">
            Invoice
          </div>
        </div>
      </div>
      {invoices.data?.invoices.map(invoice => (
        <div className="accordion-item" key={invoice.id}>
          <div className="accordion-header">
            <div className="col-xs-3">
              <a href={invoice.hostedInvoiceUrl} target="_blank">
                {moment(invoice.periodEnd).format('MMM Do, Y')}
              </a>
            </div>
            <div className="col-xs-3">
              {invoice.paid ? moment(invoice.paidAt).format('MMM Do, Y') : 'Not Paid'}
            </div>
            <div className="col-xs-3">
              {invoice.currency.toUpperCase()} {(invoice.amountPaid / 100).toLocaleString()}
            </div>
            <div className="col-xs-3">
              <a href={invoice.invoicePdf} target="_blank"><i className="fa fa-file-pdf" /></a>
            </div>
          </div>
        </div>
      ))}
      {invoices.error ? (
        <div className="alert alert-danger">
          {getErrorString(invoices.error) || 'An error occurred'}
        </div>
      ) : null}
      {(!permissions?.financials || invoices.isFetching) ? (<i className="fa fa-spinner fa-pulse fa-2x" />) : invoices.data?.invoices?.length === 0 ? (<p>No invoices yet</p>) : null}
    </div>
  );
}