import { createApi, fetchBaseQuery, FetchBaseQueryError, FetchBaseQueryMeta, FetchArgs } from '@reduxjs/toolkit/query/react';
import { singleton as config } from '@app/config';
import { Environment, Tenant } from '@app/models';
import { createSubscriptionAuthClient } from '@app/auth';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { StringMappingType } from 'typescript';
import { verifyApi } from './verify';

type FetchResult<T> = MaybePromise<QueryReturnValue<T, FetchBaseQueryError, FetchBaseQueryMeta>>

const authClient = createSubscriptionAuthClient();

const ACCESS_TOKEN_CACHE : {[key: string]: Promise<any>} = {};
function getAccessToken(tenant: Tenant) {
  if (!ACCESS_TOKEN_CACHE[tenant.entityId]) {
    ACCESS_TOKEN_CACHE[tenant.entityId] = authClient.getTokenSilently({
      scope: `openid tenant:${btoa(tenant.entityId)}`
    }).finally(() => {
      delete ACCESS_TOKEN_CACHE[tenant.entityId];
    });
  }
  return ACCESS_TOKEN_CACHE[tenant.entityId];
}

const baseQuery = fetchBaseQuery({
  baseUrl: config.subscriptionBase
});

export const TRANSACTION_TYPES = ['authentication', 'signature', 'criipto-signature'] as const;
export type TRANSACTION_TYPE = typeof TRANSACTION_TYPES[number];
export const BUCKETS = ["HOUR", "DAY", "WEEK", "MONTH"] as const;
export type BUCKET = typeof BUCKETS[number];
export const DIMENSIONS = ["authenticationType", "domain"] as const;
export type DIMENSION = typeof DIMENSIONS[number];

type SeriesRequest = {
  tenant: Tenant
  timezone: string
  bucket: BUCKET
  from: Date
  to: Date
  transactionType: TRANSACTION_TYPE[]
  environment: Environment[]
  dataDimension: DIMENSION
  domain?: string[]
}

export type SeriesResponse = {
  bucket: string,
  total: number,
  dimension: {
    [key in DIMENSION]: {
      [key: string]: number
    }
  }
}[];

export type DimensionData = {
  [key in DIMENSION]: string[]
}

export type DimensionsResponse = DimensionData & {
  transactionType: TRANSACTION_TYPE[]
  from: string
  to: string
}
type DimensionsRequest = {
  tenant: Tenant
  timezone: string
  transactionType: TRANSACTION_TYPE[]
  environment: Environment[]
}

type InvoicesResponse = {
  hasMore: boolean
  invoices: {
    amountDue: number
    amountPaid: number
    amountRemaining: number
    currency: string
    dueDate: string
    endingBalance: number
    hostedInvoiceUrl: string
    id: string
    invoicePdf: string
    paid: boolean
    paidAt: string
    periodEnd: string
    periodStart: string
    status: string
  }[]
}

export type CustomerRendition = {
  city?: string
  company?: string
  country?: string
  name?: string
  email?: string
  street?: string
  taxId?: string
  zip?: string
}

export type Currency = "dkk" | "eur";
export type Period = "month" | "year";
export type CustomerResponse = CustomerRendition & {
  cardBrand?: string
  cardLast4?: string
  currency?: Currency
  id: string
  nameOnCard?: string
  period?: Period
  plan?: string
  subscription?: {
    cancelAtPeriodEnd: boolean
    // https://stripe.com/docs/api/subscriptions/object?lang=dotnet#subscription_object-status
    status: "active" | "past_due" | "unpaid" | "canceled" | "incomplete" | "incomplete_expired" | "trialing"
    periodStartAt: string
    periodEndAt: string
  }
}
type CustomerRequest = CustomerRendition & {

}

export type SubscriptionRequest = CustomerRequest & {paymentToken?: string} &  {
  currency: Currency
  period: Period
  plan: string
}

type CustomPlanRendition = {
  id: string
  type: 'free' | 'invoicing'
  name: string
}
type StripePlanRendition = {
  price: number
  stripePlan: string
  unitprice: number
}
export type PricedPlanRendition = {
  id: string
  name: string
  volume: number
} & {
  [key in Period]: {
    [key in Currency]: StripePlanRendition
  }
}
export type PlanRendition = CustomPlanRendition | PricedPlanRendition;

export function toISOLocal(d: Date) {
  var z  = (n: number) => ('0' + n).slice(-2);
  var zz = (n: number) =>('00' + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off < 0? '+' : '-';
  off = Math.abs(off);

  return d.getFullYear() + '-'
         + z(d.getMonth()+1) + '-' +
         z(d.getDate()) + 'T' +
         z(d.getHours()) + ':'  +
         z(d.getMinutes()) + ':' +
         z(d.getSeconds()) + '.' +
         zz(d.getMilliseconds()) +
         sign + z(off/60|0) + ':' + z(off%60);
}


export const subscriptionApi = createApi({
  reducerPath: 'subscriptionApi',
  baseQuery,
  tagTypes: ["Customer"],
  endpoints: (builder) => ({
    analyticsTotals: builder.query<{uniqueUsersAllTime: number}, Tenant>({
      async queryFn(tenant, queryApi, options, fetch) {
        const accessToken = await getAccessToken(tenant);

        return await fetch({
          url: '/reporting/events/totals',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }) as FetchResult<{uniqueUsersAllTime: number}>;
      }
    }),
    analyticsSeries: builder.query<SeriesResponse, SeriesRequest>({
      async queryFn(request, queryApi, options, fetch) {
        const tenant = request.tenant;
        const accessToken = await getAccessToken(tenant);

        const query = new URLSearchParams({
          timezone: request.timezone,
          bucket: request.bucket,
          from: toISOLocal(request.from),
          to: toISOLocal(request.to),
          dataDimension: request.dataDimension
        });

        let url = `/reporting/events/series?`+query;

        if (request.environment) {
          request.environment.forEach((value, index) => {
            query.append(`environment[${index}]`, value);
          });
        }
        if (request.transactionType) {
          request.transactionType.forEach((value, index) => {
            query.append(`transactionType[${index}]`, value);
          });
        }

        if (request.domain) {
          request.domain.forEach((value, index) => {
            query.append(`domain[${index}]`, value);
          });
        }

        return await fetch({
          url: `/reporting/events/series?`+query,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }) as FetchResult<SeriesResponse>;
      }
    }),
    analyticsDimensions: builder.query<DimensionsResponse, DimensionsRequest>({
      async queryFn(request, queryApi, options, fetch) {
        const tenant = request.tenant;
        const accessToken = await getAccessToken(tenant);

        let url = `/reporting/events/dimensions?timezone=${request.timezone}`;

        if (request.environment) {
          request.environment.forEach((value, index) => {
            url += `&environment[${index}]=${value}`
          });
        }
        if (request.transactionType) {
          request.transactionType.forEach((value, index) => {
            url += `&transactionType[${index}]=${value}`
          });
        }

        return await fetch({
          url,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }) as FetchResult<DimensionsResponse>;
      }
    }),
    invoices: builder.query<InvoicesResponse, Tenant>({
      async queryFn(tenant, queryApi, options, fetch) {
        const accessToken = await getAccessToken(tenant);
        const response = await (fetch({
          url: '/customer/invoices',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }) as FetchResult<InvoicesResponse>);

        if (response.error?.status === 404) {
          return {
            data: {
              hasMore: false,
              invoices: []
            }
          };
        }
        return response;
      }
    }),
    customer: builder.query<CustomerResponse | null, Tenant>({
      async queryFn(tenant, queryApi, options, fetch) {
        const accessToken = await getAccessToken(tenant);
        const response = await (fetch({
          url: '/customer',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }) as FetchResult<CustomerResponse>);

        if (response.error?.status === 404) {
          return {data: null};
        }
        return response;
      },
      providesTags: (result, error, arg) => [{type: 'Customer', id: arg.tenantId}]
    }),
    updateCustomer: builder.mutation<any, {tenant: Tenant, request: CustomerRequest}>({
      async queryFn({tenant, request}, queryApi, options, fetch) {
        const accessToken = await getAccessToken(tenant);
        const response = await fetch({
          url: '/subscription/creditcard',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: request
        });

        if (response.error) {
          // API does not return JSON
          if ("originalStatus" in response.error && response.error.originalStatus === 200) {
            return {data: null};
          }
          return response;
        }

        return response;
      },
      invalidatesTags: (result, error, arg) => [{type: 'Customer', id: arg.tenant.tenantId}]
    }),
    updateCard: builder.mutation<any, {tenant: Tenant, request: CustomerRequest & {paymentToken: string}}>({
      async queryFn({tenant, request}, queryApi, options, fetch) {
        const accessToken = await getAccessToken(tenant);
        const response = await fetch({
          url: '/subscription/creditcard',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: request
        });

        if (response.error) {
          // API does not return JSON
          if ("originalStatus" in response.error && response.error.originalStatus === 200) {
            return {data: null};
          }
          return response;
        }

        return response;
      },
      invalidatesTags: (result, error, arg) => [{type: 'Customer', id: arg.tenant.tenantId}]
    }),
    updateSubscription: builder.mutation<{success: boolean, clientSecret?: string}, {tenant: Tenant, request: SubscriptionRequest}>({
      async queryFn({request, tenant}, queryApi, options, fetch) {
        const accessToken = await getAccessToken(tenant);
        const response = await fetch({
          url: '/subscription',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: request
        });

        if (response.error) throw response.error
        return {data: response.data as any};
      },
      invalidatesTags: (result, error, arg) => [{type: 'Customer', id: arg.tenant.tenantId}]
    }),
    cancelSubscription: builder.mutation<any, Tenant>({
      async queryFn(tenant, queryApi, options, fetch) {
        const accessToken = await getAccessToken(tenant);
        const response = await fetch({
          url: '/subscription/cancel',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          method: 'PUT',
          body: {
            cancelImmediately: false
          }
        });

        if (response.error) {
          // API does not return JSON
          if ("originalStatus" in response.error && response.error.originalStatus === 200) {
            return {data: null};
          }
          if (typeof response.error.data === "string") {
            throw new Error(response.error.data);
          }
          return response;
        }

        // Clear Verify cache since Subscription API does machine-to-machine call
        verifyApi.endpoints.refetchBackoffice.initiate(tenant);
        verifyApi.endpoints.refetchBillable.initiate(tenant);

        return response;
      },
      invalidatesTags: (result, error, tenant) => [{type: 'Customer', id: tenant.tenantId}]
    }),
    plans: builder.query<PlanRendition[], void>({
      query: () => '/plan',
      transformResponse: (response: {plans: PlanRendition[]}) => response.plans
    })
  })
});

export const {
  useAnalyticsTotalsQuery,
  useAnalyticsDimensionsQuery,
  useAnalyticsSeriesQuery,
  useInvoicesQuery,
  useCustomerQuery,
  useUpdateCustomerMutation,
  useUpdateCardMutation,
  usePlansQuery,
  useCancelSubscriptionMutation,
  useUpdateSubscriptionMutation
} = subscriptionApi;