import React, {useCallback, useEffect} from 'react';

import {useSelector, useDispatch} from '@app/redux';
import {translate} from '@app/i18n';

import {
  DanishMitIdConfiguration, PocesCprStrategy
} from '@app/models';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, InputField, FormError, FormSuccess, Switch, Select} from '@components/Form';
import { fetchDomains } from '@app/redux/tenantSlice';
import { updateProviderSettings } from '@app/redux/tenantSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import useEnvironment from '@app/hooks/useEnvironment';

// import EnvironmentDialog from '../EnvironmentDialog';

interface Props {
  configuration: DanishMitIdConfiguration
}

export default function DKNemIDProviderConfiguration(props : Props) {
  const dispatch = useDispatch();
  const {configuration} = props;

  const initialValues : DanishMitIdConfiguration = configuration;;

  const environment = useEnvironment();
  const domains = useSelector(state => (state.tenant.domains.items || []).filter(domain => domain.production === (environment === "PRODUCTION")));

  useEffect(() => {
    dispatch(fetchDomains({force: false}));
  }, []);

  const saveProvider = async (values : DanishMitIdConfiguration) => {
    return dispatch(updateProviderSettings({
      rel: 'DK_MITID_SETTINGS',
      values
    })).then(unwrapResult);
  };

  let cprStrategyOff : PocesCprStrategy = "omit";
  let cprStrategyOn : PocesCprStrategy = "askuser";

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={(values : DanishMitIdConfiguration) => {
        return saveProvider(values);
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} Danish MitID
              </h3>
              <EnvironmentTag />
              <Select<DanishMitIdConfiguration>
                name="brokerLandingPageDomain"
                label={translate('LABEL_DK_MITID_BROKER_DOMAIN')}
                options={domains.filter(domain => domain.name.endsWith('.mitid.dk')).map(domain => ({label: domain.name, value: domain.name}))}
              />
              <Select<DanishMitIdConfiguration>
                name="nemIdDomain"
                label={translate('LABEL_DK_MITID_NEMID_DOMAIN')}
                options={domains.map(domain => ({label: domain.name, value: domain.name}))}
              />
              <InputField<DanishMitIdConfiguration>
                type="text"
                label={translate('LABEL_DK_MITID_REFERENCE_TEXT')}
                name="referenceText"
              />
              <Switch<DanishMitIdConfiguration>
                name="cprLookupStrategy"
                off={cprStrategyOff}
                on={cprStrategyOn}
                label={translate('HINT_DK_MITID_CPR')}
              />
              <Switch<DanishMitIdConfiguration>
                name="cprIsOptional"
                label={translate('HINT_DK_MITID_CPR_IS_OPTIONAL')}
              />
              {/* <Switch<DanishMitIdConfiguration>
                name="includePostalAddress"
                label={translate('HINT_DK_MITID_INCLUDE_POSTAL_ADDRESS')}
              /> */}
              <Switch<DanishMitIdConfiguration>
                name="showOnNemId"
                label={translate('HINT_DK_MITID_SHOW_ON_NEMID')}
              />
              <InputField<DanishMitIdConfiguration>
                type="text"
                label={translate('LABEL_DK_MITID_SERVICE_PROVIDER_ID')}
                name="serviceProviderId"
                disabled
              />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your Danish MitID settings have been updated` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6 nemid">
            <h3>{translate('INFO')}</h3>
            <p>{translate('INFO_DK_MITID_BROKER_DOMAIN')}</p>
            <p>&nbsp;</p>
            <p>{translate('INFO_DK_MITID_NEMID_DOMAIN')}</p>
            <p>&nbsp;</p>
            <p>{translate('INFO_DK_MITID_REFERENCE_TEXT')}</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>{translate('INFO_MITID_SERVICE_PROVIDER_ID')}</p>
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}
