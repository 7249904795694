import { createSlice } from '@reduxjs/toolkit';

interface ModalState {
  showSlackModal: boolean
  showProductionUpgradeModal: boolean
};

export const initialState: ModalState = {
  showSlackModal: false,
  showProductionUpgradeModal: false
};

export const ModalSlice = createSlice({
  name: 'modals',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleSlackModal: (state) => {
      return {
        ...state,
        showSlackModal: !state.showSlackModal
      };
    },
    toggleProductionUpgradeModal: (state) => {
      return {
        ...state,
        showProductionUpgradeModal: !state.showProductionUpgradeModal
      };
    },
    showProductionUpgradeModal: (state) => {
      return {
        ...state,
        showProductionUpgradeModal: true
      };
    }
  }
})

export const { toggleSlackModal, toggleProductionUpgradeModal, showProductionUpgradeModal } = ModalSlice.actions;
export default ModalSlice;