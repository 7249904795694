import React, { useEffect, useMemo } from "react";
import {SIGNUP_CALLBACK_URL} from '@app/constants';

import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  useHistory
} from "react-router-dom";

import {useSelector, useDispatch} from '@app/redux';
import {setTokens} from '@redux/authSlice';
import {singleton as segment} from '@app/segment';
import SignupOrganizationScreen from './SignupOrganizationScreen';

import checkmark from './assets/checkmark.svg';
import alka from './assets/alka.png';
import almbrand from './assets/almbrand.png';
import coloplast from './assets/coloplast.png';
import eniro from './assets/eniro.png';
import telia from './assets/telia.png';
import './SignupScreen.scss';

import SignupBox from './components/SignupBox';

interface Props {}

export default function Routes(props : Props) {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/organization`}>
        <SignupOrganizationScreen />
      </Route>
      <Route path={`${match.path}/callback`}>
        <CallbackScreen />
      </Route>
      <Route path={match.path}>
        <SignupScreen />
      </Route>
    </Switch>
  );
}

export function CallbackScreen(props : Props) {
  const auth = useSelector(state => state.auth.client);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  useEffect(() => {
    // Social signup
    if (query.get('code')) {
      auth.handleRedirectCallback().then(() => {
        return Promise.all([
          auth.getIdTokenClaims(),
          auth.getTokenSilently()
        ]).then(([claims, accessToken]) => {
          dispatch(setTokens({
            access_token: accessToken,
            id_token: claims.__raw,
            profile: claims
          }));

          segment.identify(claims);
          segment.track('SIGNED_UP_USER');
          history.push('/');
        });
      })
    }
    // email signup does not use PKCE
    // we start a new login that can use the existing SSO session
    // send them back here to get auth0-spa to setup everything and track segment signup events
    else {
      auth.loginWithRedirect({
        response_type: 'token id_token',
        redirect_uri: SIGNUP_CALLBACK_URL
      });
    }
  }, []);

  return (
    <div></div>
  );
}

export function SignupScreen() {

  return (
    <div className="container">
      <div className="signup-flow signup-screen">
        <div>
          <h2>
            Get started with a free Criipto account.<br />
            <div className="text-red">No credit card required.</div>
          </h2>
          <ul>
            <li><img src={checkmark} /> Do your first login with MitID in a few minutes.</li>
            <li><img src={checkmark} /> Mauris non congue leo.</li>
            <li><img src={checkmark} /> Vivamus accumsan velit quis nulla vestibulum plenum.</li>
            <li><img src={checkmark} /> Dui quam ultricies purus, in ultricies eros nisl quis metus.</li>
            <li><img src={checkmark} /> Mauris non congue leo.</li>
            <li><img src={checkmark} /> Vivamus accumsan velit quis nulla vestibulum plenum.</li>
          </ul>
          <div className="logos">
            <div>
              <img src={alka} className="alka" />
              <img src={telia} className="telia" />
              <img src={almbrand} className="almbrand" />
            </div>
            <div>
              <img src={coloplast} className="coloplast" />
              <img src={eniro} className="eniro" />
            </div>
          </div>
        </div>
        <div>
          <SignupBox />
        </div>
      </div>
    </div>
  );
}