/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EditSignaturesApplicationScreenQueryVariables = {
    id: string;
};
export type EditSignaturesApplicationScreenQueryResponse = {
    readonly tenant: {
        readonly applications: ReadonlyArray<{
            readonly verifyApplication: {
                readonly domain: string;
                readonly realm: string;
            };
            readonly " $fragmentRefs": FragmentRefs<"ApiKeys_application">;
        }>;
    } | null;
};
export type EditSignaturesApplicationScreenQuery = {
    readonly response: EditSignaturesApplicationScreenQueryResponse;
    readonly variables: EditSignaturesApplicationScreenQueryVariables;
};



/*
query EditSignaturesApplicationScreenQuery(
  $id: ID!
) {
  tenant(id: $id) {
    applications {
      verifyApplication {
        domain
        realm
      }
      ...ApiKeys_application
      id
    }
    id
  }
}

fragment AddApiKeyButton_application on Application {
  id
}

fragment AddApiKeyForm_application on Application {
  id
}

fragment ApiKeys_application on Application {
  apiKeys {
    id
    clientId
    mode
    note
    ...DeleteApiKeyButton_apiKey
    ...RefreshApiKeyButton_apiKey
  }
  ...AddApiKeyButton_application
  ...AddApiKeyForm_application
  ...DeleteApiKeyButton_application
  ...RefreshApiKeyButton_application
}

fragment DeleteApiKeyButton_apiKey on ApplicationApiKey {
  id
}

fragment DeleteApiKeyButton_application on Application {
  id
}

fragment RefreshApiKeyButton_apiKey on ApplicationApiKey {
  id
}

fragment RefreshApiKeyButton_application on Application {
  id
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "VerifyApplication",
  "kind": "LinkedField",
  "name": "verifyApplication",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "domain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "realm",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSignaturesApplicationScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "applications",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApiKeys_application"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditSignaturesApplicationScreenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "applications",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ApplicationApiKey",
                "kind": "LinkedField",
                "name": "apiKeys",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88baf722ad587c07ac48dd9a98e20c05",
    "id": null,
    "metadata": {},
    "name": "EditSignaturesApplicationScreenQuery",
    "operationKind": "query",
    "text": "query EditSignaturesApplicationScreenQuery(\n  $id: ID!\n) {\n  tenant(id: $id) {\n    applications {\n      verifyApplication {\n        domain\n        realm\n      }\n      ...ApiKeys_application\n      id\n    }\n    id\n  }\n}\n\nfragment AddApiKeyButton_application on Application {\n  id\n}\n\nfragment AddApiKeyForm_application on Application {\n  id\n}\n\nfragment ApiKeys_application on Application {\n  apiKeys {\n    id\n    clientId\n    mode\n    note\n    ...DeleteApiKeyButton_apiKey\n    ...RefreshApiKeyButton_apiKey\n  }\n  ...AddApiKeyButton_application\n  ...AddApiKeyForm_application\n  ...DeleteApiKeyButton_application\n  ...RefreshApiKeyButton_application\n}\n\nfragment DeleteApiKeyButton_apiKey on ApplicationApiKey {\n  id\n}\n\nfragment DeleteApiKeyButton_application on Application {\n  id\n}\n\nfragment RefreshApiKeyButton_apiKey on ApplicationApiKey {\n  id\n}\n\nfragment RefreshApiKeyButton_application on Application {\n  id\n}\n"
  }
};
})();
(node as any).hash = '94e8e14a26bdb41f05c8b9d89fa4d075';
export default node;
