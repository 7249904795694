import React from 'react';

import { singleton as segment } from '@app/segment';
import { Domain, getHref } from '@app/models';
import { useDispatch } from '@app/redux';
import { identity } from 'lodash';
import { dispatchVerifyRequest, valuesToFormData } from '@app/redux/apiSlice';
import { REL } from '@app/constants';
import { Form, FormError, FormSuccess, Select } from '@app/components/Form/Form';
import CertificateForm from '@app/components/CertificateForm/CertificateForm';
import { translate } from '@app/i18n';
import Button from '@app/components/Button/Button';
import { fetchDomainConfiguration } from '@app/redux/tenantSlice';

interface Props {
  onSave?: () => void,
  domain: Domain
}

type Values = {
  certificate?: {
    file: File,
    password: string
  }
}

export default function DomainCertificateUploadForm(props: Props) {
  const {onSave, domain} = props;
  const dispatch = useDispatch();

  const handleSubmit = async (values: Values) => {
    await dispatchVerifyRequest(
      dispatch,
      {
        url: getHref(domain, REL.CREATE_CERTIFICATE),
        method: 'POST',
        data: valuesToFormData({
          "file": values.certificate?.file!,
          "metadata": JSON.stringify({
            password: values.certificate?.password
          })
        })
      },
      identity
    );

    segment.track(
      'DOMAIN_SSL_CERT_ADDED',
      {
        "Production": domain.production,
        "Domain": domain.name
      }
    );

    await dispatch(fetchDomainConfiguration({
      cache: false,
      domain
    }));

    if (onSave) onSave();
  };

  return (
    <Form initialValues={{}} onSubmit={handleSubmit} data-test-id="form" key="domain_certificate_form">
      {({isPending, error, isSuccess, values}) => (
        <React.Fragment>
          <CertificateForm
            certificate={null}
            fileFieldName="certificate.file"
            passwordFieldName="certificate.password"
          />

          {error && (
            <FormError error={error} />
          )}

          {isSuccess && (
            <FormSuccess message={'Domain SSL certificate uploaded'} />
          )}

          <p style={{marginTop: '15px'}}>
            NOTE: It may take several minutes before your new certificate will take effect.
          </p>

          <div className="button-group flex-end" style={{marginTop: '15px'}}>
            <Button variant="primary" type="submit" working={isPending} autoWidth>
              {translate('SAVE')}
            </Button>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}