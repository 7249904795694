import {IdServicesStyling} from '@app/models';
export type Styling = Omit<IdServicesStyling, 'deserialize' | 'viewVersionEditable'>;

export interface PreviewItem {
  id: string;
  label: string;
  htmlUrl: string;
  htmlCacheable?: boolean;
  afterRender?: (target: HTMLIFrameElement | Window) => void;
  afterScripts?: (target: HTMLIFrameElement | Window) => void;
}
export interface PreviewCollection {
  key: string;
  label: string;
  items: PreviewItem[];
  languages: string[];
};

export const DKNEMID_COLLECTION:PreviewCollection = {
  key: 'dknemid',
  label: 'DKNemID',
  languages: ['da', 'en'],
  items: [
    {
      id: "dknemidasync_keycardtemplate",
      label: "Key Card",
      htmlUrl: '/dknemidasync/keycardlaunchtemplate?viewVersion=$viewVersion&language=$language&criiptoBranding=$criiptoBranding',
      htmlCacheable: false
    }, {
      id: "dknemidasync_cprentrytemplate",
      label: "CPR Entry",
      htmlUrl: '/dknemidasync/cprentrytemplate?viewVersion=$viewVersion&language=$language&isError=false&criiptoBranding=$criiptoBranding'
    }, {
      id: "dknemidasync_cprentrytemplate_error",
      label: "CPR Entry (With Error)",
      htmlUrl: '/dknemidasync/cprentrytemplate?viewVersion=$viewVersion&language=$language&isError=true&criiptoBranding=$criiptoBranding'
    }, {
      id: "dknemidasync_cvrselecttemplate_few",
      label: "CVR Select (few companies)",
      htmlUrl: '/dknemidasync/cvrselecttemplate?numCompanies=2&viewVersion=$viewVersion&language=$language&criiptoBranding=$criiptoBranding'
    }, {
      id: "dknemidasync_cvrselecttemplate_many",
      label: "CVR Select (many companies)",
      htmlUrl: '/dknemidasync/cvrselecttemplate?numCompanies=15&viewVersion=$viewVersion&language=$language&criiptoBranding=$criiptoBranding'
    }, {
      id: "dknemidasync_errormessagetemplate_erstdown",
      label: "CVR Select (ERST Error)",
      htmlUrl: "/dknemidasync/errormessagetemplate?viewVersion=$viewVersion&language=$language&message=ERST%20Down&category=erstDown&criiptoBranding=$criiptoBranding"
    }, {
      id: "dknemidasync_errormessagetemplate_notsignatory",
      label: "CVR Select (Not Signatory Error)",
      htmlUrl: "/dknemidasync/errormessagetemplate?viewVersion=$viewVersion&language=$language&message=Not%20company%20signatory&category=notSignatory&criiptoBranding=$criiptoBranding"
    }, {
      id: "dknemidasync_errormessagetemplate_generic_error",
      label: "Generic Error",
      htmlUrl: "/dknemidasync/errormessagetemplate?viewVersion=$viewVersion&language=$language&message=An%20error%20occurred&category=blank&criiptoBranding=$criiptoBranding"
    }, {
      id: "dknemidasync_codefiletemplate",
      label: "Code file",
      htmlUrl: '/dknemidasync/codefilelaunchtemplate?viewVersion=$viewVersion&language=$language&criiptoBranding=$criiptoBranding',
      htmlCacheable: false
    }
  ]
};

export const SEBANKID_COLLECTION:PreviewCollection = {
  key: 'sebankid',
  label: 'SEBankID',
  languages: ['sv', 'en'],
  items: [{
    id: "sebankidasync_collecttemplate",
    label: "This Device: Auto-launch BankID App",
    htmlUrl: '/sebankidasync/collecttemplate?viewVersion=$viewVersion&language=$language&criiptoBranding=$criiptoBranding'
  }, {
    id: "sebankidasync_collecttemplate_android",
    label: "This Device: Open BankID App on Android Prompt",
    htmlUrl: '/sebankidasync/collecttemplate?viewVersion=$viewVersion&language=$language&criiptoBranding=$criiptoBranding',
    afterRender: (target: HTMLIFrameElement | Window,) => {
      const targetDocument = (target as HTMLIFrameElement).contentDocument || (target as Window).document;
      if (targetDocument.getElementById('launchbox')) {
        targetDocument.getElementById('launchbox')!.classList.remove('hidden');
      }
      targetDocument.body.classList.add('is-android');
    }
  }, {
    id: "sebankidasync_anotherdevicetemplate",
    label: "Another Device: Prompt for SSN",
    htmlUrl: '/sebankidasync/anotherdevicetemplate?viewVersion=$viewVersion&language=$language&criiptoBranding=$criiptoBranding'
  }, {
    id: "sebankidasync_anotherdeviceqrtemplate",
    label: "Another Device: QR Code",
    htmlUrl: '/sebankidasync/anotherdeviceqrtemplate?viewVersion=$viewVersion&language=$language&criiptoBranding=$criiptoBranding',
    afterRender: (target: HTMLIFrameElement | Window,) => {
      const targetDocument = (target as HTMLIFrameElement).contentDocument || (target as Window).document;
      (targetDocument.querySelector('.centered.qr-code') as HTMLElement).style.background = 'url(/assets/images/qrcode_example.png)';
    }
  }, {
    id: "sebankidasync_startapptemplate",
    label: "Another Device: Prompt for App Start",
    htmlUrl: '/sebankidasync/startapptemplate?viewVersion=$viewVersion&language=$language&criiptoBranding=$criiptoBranding'
  }, {
    id: "sebankidasync_error",
    label: "Generic Error",
    htmlUrl: '/sebankidasync/collecttemplate?viewVersion=$viewVersion&language=$language&criiptoBranding=$criiptoBranding',
    afterRender: (target: HTMLIFrameElement | Window) => {
      const targetDocument = (target as HTMLIFrameElement).contentDocument || (target as Window).document;
      if (targetDocument.getElementById('user')) {
        targetDocument.getElementById('user')!.innerHTML = "User-oriented error message";
      }
      if (targetDocument.getElementById('error')) {
        targetDocument.getElementById('error')!.innerHTML = "Technical details error message";
      }
      targetDocument.body.classList.add('has-error');
    }
  }]
}

export const DKMITID_COLLECTION:PreviewCollection = {
  key: 'dkmitid',
  label: 'DKMitID',
  languages: ['da', 'en'],
  items: [{
    id: "dkmitid_cprentrytemplate",
    label: "CPR Entry",
    htmlUrl: '/dkmitid/cprentrytemplate?language=$language&isError=false&criiptoBranding=false'
  }, {
    id: "dkmitid_cprentrytemplate_error",
    label: "CPR Entry (With Error)",
    htmlUrl: '/dkmitid/cprentrytemplate?language=$language&isError=true&criiptoBranding=false'
  }]
};

export const collections:PreviewCollection[] = [DKNEMID_COLLECTION, SEBANKID_COLLECTION, DKMITID_COLLECTION];