import React, { useEffect } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {TENANT_PREFIX} from '@app/constants';

import {fetchTenants} from '@redux/discoverySlice';
import { useDispatch, useSelector } from '@app/redux';
import { checkSession } from '@app/redux/authSlice';
import { set as setEnvironment } from '@app/redux/environmentSlice';
import { Environment } from '@app/models';

export default function RedirectScreen() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams<{environment?: string}>();
  const dispatch = useDispatch();
  const tenantState = useSelector(state => state.discovery.verify.state);
  const tenants = useSelector(state => state.discovery.verify.tenants);
  const auth = useSelector(state => state.auth.client);
  const environment = params.environment ? params.environment.toUpperCase() as Environment : undefined;

  useEffect(() => {
    dispatch(checkSession()).then(unwrapResult).then(result => {
      if (!result) {
        auth.loginWithRedirect({
          appState: {
            returnTo: location.pathname + location.search
          },
          response_type: 'token id_token'
        });
      } else {
        dispatch(fetchTenants({force: false}));
      }
    });
  }, []);

  useEffect(() => {
    if (!tenants) return;
    const tenant = tenants[0];
    let pathname = location.pathname;

    if (environment) {
      pathname = pathname.replace(`/${environment.toLowerCase()}`, '');
      if (tenant) dispatch(setEnvironment({tenant, environment}));
    }

    if (!pathname) pathname = '/';

    if (tenant) {
      pathname = pathname == '/' ? '/dashboard' : (pathname + (location.search || ''));
      history.push(`/org/${tenant.tenantId.replace(TENANT_PREFIX, '')}${pathname}`);
    } else {
      const returnTo = pathname == '/' ? null : (pathname + (location.search || ''));
      if (returnTo) {
        history.push(`/signup/organization?returnTo=${encodeURIComponent(returnTo)}`);
      } else {
        history.push("/signup/organization");
      }
    }
  }, [tenants]);

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/criipto-logo-cyan.svg" alt="Criipto" />
        </div>
        <div className="content">
          <h4>Working ...</h4>
          {tenantState.error && (
            <div className="modal-body alert alert-danger">
              {tenantState.error.message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
