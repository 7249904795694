import React, {useState, useMemo, useEffect} from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {useHistory} from 'react-router-dom';

import {translate} from '@app/i18n';
import usePromise from '@hooks/usePromise';
import {DomainRegistration} from '@app/domains/registration/registration';
import Button from '@components/Button';
import {GaussOrganizationDetail} from '@app/models';
import {ENTITY_ID_PREFIX} from '@app/constants';
import {singleton as segment} from '@app/segment';
import {setTokens} from '@redux/authSlice';
import { fetchOrganizations } from '@app/redux/discoverySlice';
import { clearTenant, signupTenant } from '@app/redux/tenantSlice';
import {useSelector, useDispatch} from '@app/redux';

import './SignupScreen.scss';
import './SignupOrganizationScreen.scss';
import useQuery from '@app/hooks/useQuery';

export default function SignupOrganizationScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth.client);
  const query = useQuery();
  const registration = useMemo(() => {
    return new DomainRegistration();
  }, []);
  const existingOrganizations = useSelector(state => state.discovery.gauss.organizations);
  const gaussState = useSelector(state => state.discovery.gauss.state);

  const profile = useSelector(state => state.auth.profile);
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    dispatch(fetchOrganizations({force: false}));
  }, []);

  useEffect(() => {
    segment.identify(profile!);
  }, [profile?.sub]);

  const nameAvailable = useMemo(() => {
    if (!existingOrganizations) return false;
    return !existingOrganizations.some(search => search.name === organizationName);
  }, [existingOrganizations, organizationName]);

  const [ignore, submitState, submit] = usePromise(async () => {
    const details = new GaussOrganizationDetail();
    details.entityIdentifier = ENTITY_ID_PREFIX + registration.canonicalize(organizationName);
    details.name = organizationName;
    details.contactName = profile!.name ?? '';
    details.contactEmail = profile!.email ?? '';

    const organization = await dispatch(signupTenant({organization: details})).then(unwrapResult);
    segment.group(null, organization);
    segment.organization(profile!, organization);

    const traits = { Name: details.name, Owner: details.contactName, OwnerEmail: details.contactEmail };
    segment.track('NEW_TENANT_CREATED', traits);

    /* If the org we just created is the first one, then we track a SIGNED UP EVENT */
    if (existingOrganizations?.length === 0) {
      segment.track('SIGNED_UP_TENANT', traits);
      segment.track('SIGN_UP_BEGIN', traits);
      segment.track('SIGNED_UP_ORGANIZATION', traits);
    }

    // Get new tokens after creating a tenant
    const [claims, accessToken] = await Promise.all([
      auth.getIdTokenClaims(),
      auth.getTokenSilently({
        ignoreCache: true
      })
    ]);

    dispatch(setTokens({
      access_token: accessToken,
      id_token: claims.__raw,
      profile: claims
    }));

    return organization;
  }, false);

  const handleSubmit = async (event : React.FormEvent) => {
    event.preventDefault();

    if (!nameAvailable) return;
    if (submitState.pending) return;

    const organization = await submit();
    const returnTo = query.get('returnTo');

    dispatch(clearTenant());

    if (returnTo) {
      if (returnTo.includes('/org/')) {
        history.push(returnTo);
      } else {
        history.push(`/org/${organization.id}${returnTo}`);
      }
    } else {
      history.push(`/org/${organization.id}/dashboard`);
    }
  }

  return (
    <div className="app-content container">
      <div className="signup-flow signup-organization-screen">
        <h1>{translate('SIGN_UP_HEADER')}</h1>

        {gaussState.error && (
          <div className="alert alert-danger" data-test-id="gauss_error">{gaussState.error.message}</div>
        )}

        <form name="form" onSubmit={handleSubmit} data-test-id="form">
          <p className="form-group">
            The organization name is for you only and will not be shown to your users.<br />
            Both testing and production happens within the context of an organization.
          </p>
          <p className="form-group text-red">
            It is free to setup your organization - no credit card needed
          </p>
          <div className="form-group">
            <label htmlFor="signup_companyname">{translate('SIGNUP_ORGANIZATION_NAME')}</label>
            <input
              type="text"
              id="signup_companyname"
              value={organizationName}
              onChange={(event) => setOrganizationName(event.target.value)}
              ng-disabled="vm.processing"
              placeholder="Name of organization"
              data-test-id="name_input"
              required
            />
            <div className="help-block">
              {submitState.pending ? (
                <React.Fragment>
                  <i className="fa fa-check" />&nbsp;
                  "{organizationName}" is available
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {gaussState.pending && (<i className="fa fa-spinner fa-pulse" />)}
                  {existingOrganizations && nameAvailable && organizationName && (
                    <React.Fragment>
                      <i className="fa fa-check" data-test-id="name_available" />&nbsp;
                      "{organizationName}" is available
                    </React.Fragment>
                  )}
                  {existingOrganizations && !nameAvailable && organizationName && (
                    <React.Fragment>
                      <i className="fa fa-times" data-test-id="name_unavailable"></i>&nbsp;
                      <span>{translate('TENANT_NAME_EXISTS')}</span>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
          {submitState.error && (
            <p className="form-group text-danger" data-test-id="submit_error">{submitState.error.message}</p>
          )}
          <div className="form-group">
            <Button variant="primary" type="submit" working={submitState.pending} disabled={!nameAvailable}>Submit</Button>
            </div>
        </form>
      </div>
    </div>
  );
}
