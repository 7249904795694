/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ApplicationApiKeyMode = "READ_ONLY" | "READ_WRITE" | "%future added value";
export type ApiKeys_application = {
    readonly apiKeys: ReadonlyArray<{
        readonly id: string;
        readonly clientId: string;
        readonly mode: ApplicationApiKeyMode;
        readonly note: string | null;
        readonly " $fragmentRefs": FragmentRefs<"DeleteApiKeyButton_apiKey" | "RefreshApiKeyButton_apiKey">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"AddApiKeyButton_application" | "AddApiKeyForm_application" | "DeleteApiKeyButton_application" | "RefreshApiKeyButton_application">;
    readonly " $refType": "ApiKeys_application";
};
export type ApiKeys_application$data = ApiKeys_application;
export type ApiKeys_application$key = {
    readonly " $data"?: ApiKeys_application$data;
    readonly " $fragmentRefs": FragmentRefs<"ApiKeys_application">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApiKeys_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ApplicationApiKey",
      "kind": "LinkedField",
      "name": "apiKeys",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clientId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "note",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeleteApiKeyButton_apiKey"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RefreshApiKeyButton_apiKey"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddApiKeyButton_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddApiKeyForm_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteApiKeyButton_application"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RefreshApiKeyButton_application"
    }
  ],
  "type": "Application",
  "abstractKey": null
};
(node as any).hash = '37918af6c972ff995036a799e2406321';
export default node;
