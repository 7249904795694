import Button from "@app/components/Button";
import Tooltip from "@app/components/Tooltip";
import React, { useReducer } from "react";
import EditUserModal, {EditUserModalProps} from "../EditUserModal/EditUserModal";

type Props = Omit<EditUserModalProps, 'show' | 'onHide'>;

export default function EditUserButton(props: Props) {
  const [showModal, toggleModal] = useReducer(value => !value, false);

  return (
    <React.Fragment>
      <Tooltip tooltip="Edit user" id="edit_user">
        <Button variant="default" className="button-small" autoWidth onClick={toggleModal}><i className="fa fa-edit" /></Button>
      </Tooltip>
      <EditUserModal show={showModal} onHide={toggleModal} {...props} />
    </React.Fragment>
  );
}