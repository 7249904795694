import React, {useEffect, useMemo} from 'react';
import {sortBy} from 'lodash';

import {Switch, Route, Link, useRouteMatch, useParams, useHistory} from 'react-router-dom';
import useQuery from '@app/hooks/useQuery';

import {useSelector, useDispatch} from '@app/redux';
import {fetchApplications} from '@app/redux/tenantSlice';
import useTenant from '@app/hooks/useTenant';
import {Application, ApplicationTag, validApplicationTags, Environment} from '@app/models';
import {translate} from '@app/i18n';
import {LinkButton, AnchorButton} from '@components/Button/Button';
import {Tag} from '@components/Tag';
import Tooltip from '@components/Tooltip';

import './ApplicationsScreen.scss';

import ApplicationEditor  from './components/ApplicationEditor';
import DeleteApplicationButton from './components/DeleteApplicationButton';
import { getIdp } from '@app/providers';
import { TENANT_ID_PREFIX } from '@app/constants';
import useTracking from '@app/hooks/useTracking';
import VerifyApplicationCreater from './components/VerifyApplicationCreater';
import SignaturesApplicationCreater from './components/SignaturesApplicationCreater/SignaturesApplicationCreater';
import useEnvironment from '@app/hooks/useEnvironment';
import EditSignaturesApplicationScreen, { EditSignaturesApplicationScreenHeader } from './screens/EditSignaturesApplicationScreen/EditSignaturesApplicationScreen';
import useBillable from '@app/hooks/useBillable';

function encodeApplicationId(application : Application) {
  return btoa(application.domain.name + "|" + application.realm);
}

export function ApplicationsScreen() {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const applicationsState = useSelector(state => state.tenant.applications.state);
  const tenant = useSelector(state => state.tenant.tenant);
  const organization = useSelector(state => state.tenant.organization);
  const signaturesBillable = useBillable('SIGNATURES');
  const environment = useEnvironment();
  const domains = useSelector(state => state.tenant.domains.items?.filter(s => s.production === (environment === "PRODUCTION")));
  const applications = useSelector(state => (state.tenant.applications.items || []).filter(item => item.domain.production === (environment === "PRODUCTION")));

  useEffect(() => {
    if (!tenant) return;
    dispatch(fetchApplications({force: false}));
  }, [tenant]);

  const hasDomains = domains ? domains.length > 0 : true;

  // Render dummy accordion (actually uses links)
  return (
    <React.Fragment>
      <div className="app-content-header flex align-items-center justify-content-between">
        <h1>Applications</h1>
        <div className="actions">
          {hasDomains ? (
            <React.Fragment>
              <LinkButton variant="primary" to={`${match.url}/add`}>
                <i className="fa fa-plus"></i>&nbsp;Add login application
              </LinkButton>
              {(environment === 'TEST' || organization?.hasUserRoleClaim('signing-service') || signaturesBillable) ? (
                <LinkButton variant="primary" to={`${match.url}/add?tags=signatures`}>
                  <i className="fa fa-plus"></i>&nbsp;Add signatures application
                </LinkButton>
              ) : null}
            </React.Fragment>
          ) : (
            <Tooltip tooltip="You must register a domain before you can create an application" id="domain_required_tooltip">
              <LinkButton variant="primary" to={`/org/${tenant?.tenantId.replace(TENANT_ID_PREFIX, '')}/domains/add`}>
                <i className="fa fa-plus"></i>&nbsp;Add domain
              </LinkButton>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="app-content-main">
        {hasDomains ? (
          <div className="accordion">
            {applicationsState.pending ? (
              <i className="fa fa-spinner fa-pulse fa-2x" />
            ) : (
              <React.Fragment>
                <div className="accordion-header">
                  <div className="row">
                    <div className="col-xs-3">
                      <h3>{environment === 'PRODUCTION' ? translate('PRODUCTION_APPLICATIONS') : translate('TEST_APPLICATIONS')}</h3>
                    </div>
                    <div className="col-xs-3">
                      <h3>{translate('DOMAIN')}</h3>
                    </div>
                    <div className="col-xs-4">
                      <h3>{translate('APPLICATION_IDENTITIES')}</h3>
                    </div>
                    <div className="col-xs-2">

                    </div>
                  </div>
                </div>

                {sortBy(applications, ['domain.name', 'name']).map(application => (
                  <div className="accordion-item" key={encodeApplicationId(application)}>
                    <Link to={`${match.url}/${encodeApplicationId(application)}`} className="accordion-header clickable">
                      <div className="row">
                        <div className="col-xs-3">
                          <strong>{application.name}</strong>
                          {application.hasTag("auth0") && (
                            <Tag className="tag-small" style={{marginLeft: '5px'}}>Auth0</Tag>
                          )}
                          {application.hasTag("signatures") && (
                            <Tag className="tag-small" style={{marginLeft: '5px'}}>Signatures</Tag>
                          )}
                        </div>
                        <div className="col-xs-3">
                          {application.domain.name}
                        </div>
                        <div className="col-xs-4">
                          <Tooltip id={`application_tooltip_${application.realm}`} tooltip={application.authMethods?.length === 0 ? 'ALL' : application.authMethods.map(acr => getIdp(acr)?.name).join(', ')}>
                            <div className="text-overflow-ellipsis">{application.authMethods?.length === 0 ? 'ALL' : application.authMethods.map(acr => getIdp(acr)?.name).join(', ')}</div>
                          </Tooltip>
                        </div>
                        <div className="col-xs-2" onClick={(e) => e.stopPropagation() /* Fix react-portal click propagation */}>
                          <div className="button-group flex-end">
                            <DeleteApplicationButton application={application} className="button-small" />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </React.Fragment>
            )}

            <div className="accordion-item">
              <div className="accordion-header large">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="button-group flex-end">
                      <AnchorButton href="https://docs.criipto.com/authentication/auth0" target="_blank" variant="default">
                        {translate('VIA_AUTH0')}
                      </AnchorButton>
                      <AnchorButton href="https://docs.criipto.com/authentication/okta" target="_blank" variant="default">
                        {translate('VIA_OKTA')}
                      </AnchorButton>
                      <AnchorButton href="https://docs.criipto.com/authentication/onelogin" target="_blank" variant="default">
                        {translate('VIA_ONELOGIN')}
                      </AnchorButton>
                      <AnchorButton href="https://docs.criipto.com/authentication/pingfederate" target="_blank" variant="default">
                        {translate('VIA_PINGFEDERATE')}
                      </AnchorButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <p>
              Everything you do with Criipto, be it signatures or logins, requires that you register a <strong>domain</strong> first.
            </p>
            <LinkButton variant="primary" to={`/org/${tenant?.tenantId.replace(TENANT_ID_PREFIX, '')}/domains/add`} style={{alignSelf: 'flex-start'}}>
              <i className="fa fa-plus"></i>&nbsp;Add domain
            </LinkButton>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export function EditApplicationScreen(props : {parentUrl: string}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{applicationId: string}>();
  const [domain, realm] = atob(params.applicationId).split('|');
  const tenant = useSelector(state => state.tenant.tenant);
  const applicationsState = useSelector(state => state.tenant.applications.state);
  const application = useSelector(state => state.tenant.applications.items?.find(search => search.domain.name === domain && search.realm === realm));
  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";

  useEffect(() => {
    dispatch(fetchApplications({force: false}));
  }, []);

  useEffect(() => {
    if (!application) return;
    if (isProduction !== application.domain.production) {
      history.push(props.parentUrl);
    }
  }, [isProduction, application]);

  const handleDelete = () => {
    history.push(props.parentUrl);
  };
  const handleCancel = () => {
    history.push(props.parentUrl);
  };
  const handleSave = (application: Application) => {
    if (encodeApplicationId(application) !== params.applicationId) {
      history.push(`${props.parentUrl}/${encodeApplicationId(application)}`);
    }
  }

  const isSignatures = application?.hasTag('signatures');
  if (application && isSignatures) {
    return (
      <React.Suspense
        fallback={
          <React.Fragment>
            <EditSignaturesApplicationScreenHeader
              application={application}
              parentUrl={props.parentUrl}
              onDelete={handleDelete}
            />
            <div className="app-content-main">
              <i className="fa fa-spinner fa-pulse fa-2x" />
            </div>
          </React.Fragment>
        }
      >
        <EditSignaturesApplicationScreen
          application={application}
          parentUrl={props.parentUrl}
          onDelete={handleDelete}
        />
      </React.Suspense>
    );
  }

  return (
    <React.Fragment>
      <div className="app-content-header">
        <div className="breadcrumb">
          <Link to={props.parentUrl}>Applications</Link>
          <i className="fa fa-angle-right" />
          <div>Application Details</div>
        </div>

        <h1>{application?.name}</h1>
      </div>
      <div className="app-content-main">
        <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
          {applicationsState.pending && (<i className="fa fa-spinner fa-pulse fa-2x" />)}
          {application && (
            <ApplicationEditor
              application={application}
              isCreate={false}
              onDelete={handleDelete}
              onSave={handleSave}
              onCancel={handleCancel}
            />
          )}
        </React.Suspense>
      </div>
    </React.Fragment>
  )
}

export function CreateApplicationScreen(props : {parentUrl: string}) {
  const query = useQuery();
  const history = useHistory();
  const tenant = useTenant();
  const tracking = useTracking();
  const environment = useEnvironment();
  const domains = useSelector(state => state.tenant.domains.items?.filter(s => s.production === (environment === "PRODUCTION")));
  const hasDomains = domains ? domains.length > 0 : true;

  const application = useMemo(() => {
    const application = new Application();
    application.realm = query.get('realm') || application.realm;
    application.tags = (query.get('tags') || '').split(',').filter(tag => validApplicationTags.includes(tag as ApplicationTag)) as ApplicationTag[];
    application.name = query.get('name') || '';
    application.returnUrls = query.getAll('returnUrls');
    application.authMethods = query.getAll('authMethods');

    return application;
  }, [query]);

  const handleSave = (application : Application) => {
    tracking.applicationCreated(application);

    // Show success message for a bit
    setTimeout(() => {
      history.push(props.parentUrl + `/${encodeApplicationId(application)}`);
    }, 1000);
  };

  const isSignatures = application.hasTag('signatures');

  return (
    <React.Fragment>
      <div className="app-content-header">
        <div className="breadcrumb">
          <Link to={props.parentUrl}>Applications</Link>
          <i className="fa fa-angle-right" />
          <div>Add new {isSignatures ? 'signatures' : 'login'} application</div>
        </div>

        <h1>Add new {isSignatures ? 'signatures' : 'login'} application</h1>
      </div>
      <div className="app-content-main">
        {hasDomains ? (
          <React.Suspense fallback={<i className="fa fa-spinner fa-pulse fa-2x" />}>
            {isSignatures ? (
              <SignaturesApplicationCreater application={application} onSave={handleSave} />
            ) : (
              <VerifyApplicationCreater application={application} onSave={handleSave} />
            )}
          </React.Suspense>
        ) : (
          <div className="flex flex-col">
            <p>
              Everything you do with Criipto, be it signatures or logins, requires that you register a <strong>domain</strong> first.
            </p>
            <LinkButton variant="primary" to={`/org/${tenant?.tenantId.replace(TENANT_ID_PREFIX, '')}/domains/add`} style={{alignSelf: 'flex-start'}}>
              <i className="fa fa-plus"></i>&nbsp;Add domain
            </LinkButton>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}


export default function ApplicationRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.path} exact={true}>
        <ApplicationsScreen />
      </Route>
      <Route path={`${match.path}/add`}>
        <CreateApplicationScreen parentUrl={match.url} />
      </Route>
      <Route path={`${match.path}/:applicationId`}>
        <EditApplicationScreen parentUrl={match.url} />
      </Route>
    </Switch>
  )
}