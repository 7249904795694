
import { GaussOrganization, UserRole, Domain, Environment, Application } from "@app/models";
import { PlanChoice } from "@app/screens/BillingScreen/screens/SubscriptionScreen/components/PlanSelection/PlanSelection";
import { singleton as segment } from "@app/segment";
import { IdToken } from "@auth0/auth0-spa-js";

export interface Tracking {
  userInvited: (role: UserRole) => void
  invitationAccepted: (organization: GaussOrganization) => void
  domainCreated: (domain: Domain) => void
  applicationCreated: (application: Application) => void,
  applicationUpdated: (application: Application) => void,
  login: (claims: IdToken) => void

  // Subscription events
  subscriptionCanceled: (reason?: string) => void,
  subscriptionChangedPlan: (plan: PlanChoice) => void,

  // Screen/View events
  viewedOnboardingScreen: () => void,
  viewedDashboardScreen: () => void,
  viewedAnalyticsScreen: (context: 'domain' | undefined) => void,
  viewedLogsScreen: (context: 'domain' | undefined) => void,
  viewedStylingScreen: () => void,
}

export default function useTracking() {
  return {
    userInvited(role: UserRole) {
      segment.track('USER_INVITED', {
        role
      });
    },
    invitationAccepted(organization: GaussOrganization) {
      segment.group(null, organization);
      segment.track('INVITATION_ACCEPTED');
    },
    domainCreated(domain: Domain) {
      segment.track('DOMAIN_REGISTERED', {
        "Production": domain.production,
        "Environment": domain.production ? "PRODUCTION" : "TEST" as Environment
      });

      if (!domain.production) {
        segment.track('TEST_DOMAIN_CREATED');
      }
    },
    applicationCreated(application: Application) {
      if (!application.tags.includes('automatic')) {
        segment.track('CLIENT_CREATED', {
          clientName: application.name,
          domain: application.domain.name,
          environment: application.domain.production ? "PRODUCTION" : "TEST"
        });

        segment.track('APPLICATION_CREATED', {
          environment: application.domain.production ? "PRODUCTION" : "TEST" as Environment,
          product: application.tags.includes('signatures') ? 'signatures' : 'logins'
        });
      }
    },
    applicationUpdated(application: Application) {
      segment.track('CLIENT_UPDATED', {
        clientName: application.name,
        domain: application.domain.name,
        environment: application.domain.production ? "PRODUCTION" : "TEST"
      });

      segment.track('APPLICATION_UPDATED', {
        environment: application.domain.production ? "PRODUCTION" : "TEST" as Environment,
        product: application.tags.includes('signatures') ? 'signatures' : 'logins'
      });
    },
    login(claims: IdToken) {
      const method = claims.sub ? claims.sub.split("|")[0] : null;
      segment.track('SIGN_IN', {
        method
      });
    },

    subscriptionCanceled: (reason?: string) => {
      segment.track('SUBSCRIPTION_CANCELED', {reason});
    },
    subscriptionChangedPlan: (choice: PlanChoice) => {
      console.log(':D:D::D');
      segment.track('SUBSCRIPTION_PLAN_CHANGED', {
        period: choice.period,
        currency: choice.currency,
        plan: choice.plan.id,
        volume: choice.plan.volume
      });
    },

    viewedOnboardingScreen: () => {
      segment.track('VIEWED_ONBOARDING_SCREEN');
    },
    viewedDashboardScreen: () => {
      segment.track('VIEWED_DASHBOARD_SCREEN');
    },
    viewedAnalyticsScreen: (context: 'domain' | undefined) => {
      segment.track('VIEWED_ANALYTICS_SCREEN', {context});
    },
    viewedLogsScreen: (context: 'domain' | undefined) => {
      segment.track('VIEWED_LOGS_SCREEN', {context});
    },
    viewedStylingScreen: () => {
      segment.track('VIEWED_STYLING_SCREEN');
    }
  } as Tracking;
}