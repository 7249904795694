import React, {useCallback, useEffect, useState} from 'react';
import {identity} from 'lodash';

import {useSelector, useDispatch} from '@app/redux';
import {fetchDomains} from '@redux/tenantSlice';
import {translate} from '@app/i18n';

import {
  DutchDigIdConfiguration,
  Tenant, DetailedIdentityProvider, Domain,
  getTemplateHref
} from '@app/models';

import {REL} from '@app/constants';

import {EnvironmentTag} from '@components/Tag';
import Button from '@components/Button';
import {Form, FormError, FormSuccess} from '@components/Form';
import CertificateForm from '@components/CertificateForm';
import { dispatchVerifyRequest, valuesToFormData } from '@app/redux/apiSlice';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  configuration: DutchDigIdConfiguration,
  provider: DetailedIdentityProvider,
  apiRoot: Tenant
}

export interface FormValues {
  certificate?: {
    file: File,
    password: string
  }
}

export function NLDigIDProvider(props : Props) {
  const {configuration, apiRoot: tenant} = props;

  const dispatch = useDispatch();
  const environment = useEnvironment();
  const isProduction = environment === "PRODUCTION";
  const domains = useSelector(state => state.tenant.domains.items?.filter(domain => domain.production === isProduction));
  const [domain, setDomain] = useState<Domain | null>(null);
  const domainConfiguration = configuration.domainConfigurations?.find(search => search.domain === domain?.name);

  useEffect(() => {
    dispatch(fetchDomains({force: false}));
  }, []);

  useEffect(() => {
    if (domains && !domain) {
      setDomain(domains[0]);
    }
  }, [domains]);

  const handleDomain = (event : React.ChangeEvent<HTMLSelectElement>) => {
    setDomain(domains?.find(search => search.name === event.target.value) || null);
  };

  const saveProvider = useCallback(async (values) => {
    let href = getTemplateHref(tenant, REL.NL_DIGID, {
      "tenantDomain": domain!.name
    });

    return await dispatchVerifyRequest(
      dispatch,
      {
        url: href!,
        method: 'POST',
        data: valuesToFormData({
          file: values.certificate.file,
          metadata: JSON.stringify({
            password: values.certificate.password,
            production: isProduction
          })
        })
      },
      identity
    );
  }, [domain, tenant]);

  return (
    <Form
      key={environment}
      initialValues={{}}
      onSubmit={async (values, actions) => {
        await saveProvider(values);
        actions.resetForm();
      }}
      data-test-id="form"
    >
      {({isPending, error, isSuccess}) => (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-6">
              <h3>
                {translate('CONNECT')} Dutch DigiD
              </h3>
              <EnvironmentTag />

              <div className="form-group">
                <label className="control-label">{translate('NL_DIGID_CHOOSE_DOMAIN')}</label>
                <select className="form-control" value={domain?.name} onChange={handleDomain} data-test-id="domain-select">
                  {domains?.map(domain => (
                    <option value={domain.name} key={domain.name}>{domain.name}</option>
                  ))}
                </select>
              </div>

              <CertificateForm
                certificate={domainConfiguration?.pkiOverheidCertificate}
                fileFieldName="certificate.file"
                passwordFieldName="certificate.password"
              />

              <FormError error={error} />
              <FormSuccess message={!isPending && isSuccess && `Your dutch digid settings have been updated for ${domain?.name}` || null} />
              <Button className="pull-right" variant="primary" working={isPending} type="submit">{translate('SAVE')}</Button>
            </div>
            <div className="col-sm-6">
              <h3>{translate('INFO')}</h3>
              <p>{translate('INFO_CERTIFICATE', { provider: 'Dutch DigiD' })}</p>
              <p>{translate('INFO_NL_DIGID')}</p>
              <p>{translate('INFO_NL_DIGID_METADATA_ENDPOINT')}<a>https://{domain?.name}nldigid/metadata</a></p>
              <p >{translate('INFO_NL_DIGID_SIGNUP')} <a ng-href="https://www.logius.nl/ondersteuning/digid/" target="_blank">{translate('INFO_NL_DIGID_SIGNUP_HERE')}</a></p>
            </div>
          </div>
        </React.Fragment>
      )}
    </Form>
  )
}