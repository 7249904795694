import React, {useEffect, useState} from 'react';
import {Domain} from '@app/models';

import { PreviewItem, Styling } from '../constants';

type RenderRef = Window | HTMLIFrameElement;
interface Props {
  children?: JSX.Element,
  renderRef: RenderRef | null,
  item: PreviewItem,
  styling: Styling,
  domain: string,
  language: string,
  bodyCssClass?: string,
  onHtml?: (html: string) => void
}

function isWindow(ref: RenderRef): ref is Window {
  return ref && (ref as Window).document !== undefined;
}
function isIframe(ref: RenderRef): ref is HTMLIFrameElement {
  return ref && (ref as HTMLIFrameElement).contentDocument !== undefined;
}

export default function Renderer(props : Props) : JSX.Element {
  const [cache, setCache] = useState<{[key: string]: Promise<string>}>({});
  const [renderOptions, setRenderOptions] = useState<{html: string | null, hasRendered: boolean}>({
    html: null,
    hasRendered: false
  });
  const {styling, item, children, domain, language, renderRef, bodyCssClass} = props;
  const targetDocument = renderRef ? isWindow(renderRef) ? renderRef.document : renderRef.contentDocument : null;
  const authority = `https://${domain}`;

  useEffect(() => {
    const url =
      item.htmlUrl
        .replace('$language', language)
        .replace('$viewVersion', styling.viewVersion || 'initial')
        .replace('$criiptoBranding', styling.cssUrl ? "false" : "true")

    if (item.htmlCacheable !== false) {
      setCache(cache => {
        if (!cache[`${authority}${url}`]) {
          cache[`${authority}${url}`] = fetch(`${authority}${url}`).then(response => response.text());
        }

        cache[`${authority}${url}`].then(html => {
          if (props.onHtml) props.onHtml(html);
          setRenderOptions({
            html,
            hasRendered: false
          });
        });

        return cache;
      })
    } else {
      fetch(`${authority}${url}`).then(response => response.text()).then(html => {
        if (props.onHtml) props.onHtml(html);
        setRenderOptions({
          html,
          hasRendered: false
        });
      });
    }
  }, [item, language, styling]);

  // Pprimary renderer, updates HTML
  useEffect(() => {
    if (!renderOptions.html) return;
    if (!targetDocument) return;
    if (renderOptions.hasRendered && item.htmlCacheable === false) return;
    setRenderOptions(options => ({...options, hasRendered: true}));
    targetDocument.documentElement.innerHTML = renderOptions.html;

    for (let link of Array.from(targetDocument.getElementsByTagName('link'))) {
      if (!link.getAttribute('href')) continue;

      if (link.getAttribute('href')!.startsWith('/')) {
          link.setAttribute('href', `${authority}${link.getAttribute('href')}`);
      }
    }

    for (let form of Array.from(targetDocument.getElementsByTagName('form'))) {
        if (!form.getAttribute('action') || form.getAttribute('action')!.startsWith('#')) {
            form.setAttribute('action', `${authority}`);
        } else if (form.getAttribute('action')!.startsWith('/')) {
            form.setAttribute('action', `${authority}${form.getAttribute('action')}`);
        }
    }

    if (item.afterRender) item.afterRender(renderRef!);

    let promises:Promise<any>[] = [];
    for (let existingScript of Array.from(targetDocument.getElementsByTagName('script'))) {
        if (!existingScript.getAttribute('src')) continue;
        if (existingScript.getAttribute('data-preview-dynamic')) continue;

        const newScript = targetDocument.createElement('script');
        newScript.type = "text/javascript";
        newScript.async = false;
        newScript.setAttribute('data-preview-dynamic', 'true'); // Infinite loop guard
        if (existingScript.getAttribute('src')!.startsWith('/')) {
            newScript.setAttribute('src', `${authority}${existingScript.getAttribute('src')}`);
        } else {
            newScript.setAttribute('src', existingScript.getAttribute('src')!);
        }

        const deferred:any = {};
        deferred.promise = new Promise((resolve, reject) => {
            deferred.resolve = resolve;
            deferred.reject = reject;
        });
        newScript.onload = function () {
            deferred.resolve();
        };

        promises.push(deferred.promise);
        targetDocument.body.appendChild(newScript);
    }

    if (item.afterScripts) Promise.all(promises).then(() => item.afterScripts!(renderRef!));
  }, [renderOptions.html, renderRef]);

  // Update stylesheet
  useEffect(() => {
    if (!renderOptions.html) return;
    if (!targetDocument) return;

    if (targetDocument.getElementById('developer_css')) {
      targetDocument.head.removeChild(targetDocument.getElementById('developer_css')!);
    }

    const stylesheet = targetDocument.createElement('link');
    stylesheet.setAttribute('id', 'developer_css');
    stylesheet.setAttribute('type', 'text/css');
    stylesheet.setAttribute('rel', 'stylesheet');
    stylesheet.setAttribute('href', styling.cssUrl);
    targetDocument.head.appendChild(stylesheet);

    targetDocument.body.classList.remove('host-samples-criipto-io');
    targetDocument.body.classList.remove('host-samples-criipto-id');

    if (bodyCssClass) {
      bodyCssClass.split(' ').filter(s => s).forEach(className => {
        if (!targetDocument.body.classList.contains(className)) {
          targetDocument.body.classList.add(className);
        }
      });
    }
  }, [renderOptions.html, styling.cssUrl, bodyCssClass]);

  return children || <React.Fragment />;
}
