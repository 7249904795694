/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RefreshApiKeyButton_application = {
    readonly id: string;
    readonly " $refType": "RefreshApiKeyButton_application";
};
export type RefreshApiKeyButton_application$data = RefreshApiKeyButton_application;
export type RefreshApiKeyButton_application$key = {
    readonly " $data"?: RefreshApiKeyButton_application$data;
    readonly " $fragmentRefs": FragmentRefs<"RefreshApiKeyButton_application">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefreshApiKeyButton_application",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Application",
  "abstractKey": null
};
(node as any).hash = '26adabac1a8d3b64f9d8300def26e5a3';
export default node;
