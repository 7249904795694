import React, {useState} from 'react';
import graphql from 'babel-plugin-relay/macro';
import { unwrapResult } from '@reduxjs/toolkit';

import { Application, Domain } from '@app/models';
import { useDispatch } from '@app/redux';
import { createApplication } from '@app/redux/tenantSlice';

import {Form, InputField, FormError, FormSuccess} from '@app/components/Form';
import ApplicationNameField from '../ApplicationEditor/ApplicationNameField';
import DomainField from '../ApplicationEditor/DomainField';
import useMutation from '@app/hooks/useMutation';
import ApiKeyModal from '../ApplicationEditor/SignaturesApplicationEditor/ApiKeyModal';
import useTenant from '@app/hooks/useTenant';
import Button from '@app/components/Button/Button';
import useEnvironment from '@app/hooks/useEnvironment';
import IdentityProvidersSection from '../ApplicationEditor/Sections/IdentityProviders';
import { SignaturesApplicationCreaterMutation } from './__generated__/SignaturesApplicationCreaterMutation.graphql';
import { translate } from '@app/i18n';
import ApplicationRealmValidation from '../ApplicationEditor/ApplicationRealmValidation';

interface Props {
  application: Application,
  onSave: (application: Application) => void
}

export default function SignaturesApplicationCreater(props: Props) {
  const dispatch = useDispatch();
  const {application, onSave} = props;
  const tenant = useTenant();
  const environment = useEnvironment();
  const [isRealmValid, setRealmValid] = useState(true);
  const [domain, setDomain] = useState<Domain | undefined>(undefined);

  const [createApplicationMutationExecutor, createApplicationMutationState] = useMutation<SignaturesApplicationCreaterMutation>(graphql`
    mutation SignaturesApplicationCreaterMutation($input: CreateApplicationInput!) {
      createApplication(input: $input) {
        application {
          id
          verifyApplication {
            domain
            realm
          }

          ... ApiKeys_application
        }

        tenant {
          applications {
            verifyApplication {
              domain
              realm
            }

            ... ApiKeys_application
          }
        }

        apiKey {
          ... ApiKeyModal_apiKey
        }
      }
    }
  `);

const isFormValid = isRealmValid && domain;
const handleSubmit = async (values : Application) => {
  if (!isFormValid) return Promise.reject(new Error('Invalid form fields'));

  values.returnUrls = [
    `https://${domain.name}/Sign/Document`
  ];

  values.domain = domain;
  const [, applicationResult] = await dispatch(createApplication(values)).then(unwrapResult);
  await createApplicationMutationExecutor.executePromise({
    input: {
      tenantId: btoa(`Tenant:${tenant!.tenantId}`),
      name: applicationResult.name,
      verifyApplicationDomain: applicationResult.domain.name,
      verifyApplicationRealm: applicationResult.realm,
      verifyApplicationEnvironment: environment
    }
  });

  setShowApiKeySecret(true);
}

  const [showApiKeySecret, setShowApiKeySecret] = useState(false);
  const handleApiKeySecretHide = () => {
    setShowApiKeySecret(false);
    application.domain = (new Domain()).deserialize({name: createApplicationMutationState.response!.createApplication?.application.verifyApplication.domain});
    application.realm = createApplicationMutationState.response!.createApplication?.application.verifyApplication.realm!;
    onSave(application);
  }

  return (
    <React.Fragment>
      <Form
        initialValues={props.application}
        onSubmit={handleSubmit}
        data-test-id="form"
        key="application_creater"
        className="max-w-[750px] flex flex-col gap-[25px] form-gap"
      >
        {({isPending, error, isSuccess}) => (
          <React.Fragment>
            <div>
              <ApplicationNameField />
              <small className="help-text">
                The application name will be shown to your end users in certain corner cases.
              </small>
            </div>

            <DomainField domain={domain} isCreate={true} onChange={setDomain} data-test-id="domain" />

            <InputField<Application>
              type="text"
              label={translate('LABEL_CLIENT_ID')}
              name="realm"
              placeholder={translate('LABEL_CLIENT_ID')}
              help={<ApplicationRealmValidation data-test-id="realm-validator" domain={domain} application={application} onValid={setRealmValid} />}
            />

            <div>
              <IdentityProvidersSection
                isCreate={true}
                application={application}
                help={(
                  <p>
                    These e-IDs define the authentication choices a user can make when signing a document.
                  </p>
                )}
              />
            </div>

            <p>
              Criipto Signatures offers a GraphQL API to offer unique signature experiences to your end users.
              Please see our <a href="https://docs.criipto.com/signatures" target="_blank" className="text-primary-900">API documentation</a>
            </p>

            {error && (
              <FormError error={error} />
            )}

            {isSuccess && (
              <FormSuccess message={'Application created'} />
            )}

            <div>
              <Button variant="primary" type="submit" working={isPending}>Create signature application</Button>
            </div>
          </React.Fragment>
        )}
      </Form>
      <ApiKeyModal
        show={showApiKeySecret}
        onHide={handleApiKeySecretHide}
        apiKey={createApplicationMutationState.response?.createApplication!.apiKey!}
        data-test-id="api-key-modal"
      />
    </React.Fragment>
  );
}