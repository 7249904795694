import { useRouteMatch } from "react-router-dom";
import { Tenant } from '@app/models';
import { useSelector } from "@app/redux";
import { TENANT_ID_PREFIX } from "@app/constants";

export interface TenantRouteParams {
  organizationId: string | undefined
}

export default function useTenant() : Tenant | null | undefined {
  const match = useRouteMatch<TenantRouteParams>();
  const tenants = useSelector(state => state.discovery.verify.tenants);

  if (match.params.organizationId === undefined) return undefined;
  if (tenants === undefined) return undefined;
  const tenant = tenants?.reduce((memo, tenant) => (tenant.tenantId.replace(TENANT_ID_PREFIX, '') === match.params.organizationId || !memo) ? tenant : memo, null);

  return tenant;
}