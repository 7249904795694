import React, {useState, useEffect, useMemo} from 'react';
import { IdToken } from '@auth0/auth0-spa-js';
import {useParams, useHistory} from 'react-router-dom';

import {translate} from '@app/i18n';
import {RootState} from '@redux/store';
import Button from '@components/Button';
import {createNemIDAuthClient} from '@app/auth';
import { setTokens } from '@app/redux/authSlice';
import { useSelector, useDispatch } from '@app/redux';
import {singleton as segment} from '@app/segment';

import './LoginScreen.scss';
import { fetchOrganizations } from '@app/redux/discoverySlice';
import { unwrapResult } from '@reduxjs/toolkit';
import useTracking from '@app/hooks/useTracking';

export function LoginScreen() {
  const auth = useSelector(state => state.auth.client);

  const handleLogin = () => {
    auth.loginWithRedirect({
      response_type: 'token id_token'
    });
  };

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/criipto-logo-cyan.svg" alt="Criipto" />
        </div>
        <div className="content">
          <h4>Welcome to Criipto Verify</h4>
          <Button variant="primary" onClick={handleLogin}>{translate('LOG_IN')}</Button>
        </div>
      </div>
    </div>
  );
}

interface CallbackProps {

}

export function LoginCallbackScreen(props : CallbackProps) {
  const history = useHistory();
  const auth = useSelector((state : RootState) => state.auth.client);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const tracking = useTracking();

  const handleLogin = () => {
    auth.loginWithRedirect({
      response_type: 'token id_token'
    });
  };

  useEffect(() => {
    auth.handleRedirectCallback().then(loginResult => {
      return Promise.all<IdToken, any>([
        auth.getIdTokenClaims(),
        auth.getTokenSilently()
      ]).then(([claims, accessToken]) => {
        dispatch(setTokens({
          access_token: accessToken,
          id_token: claims.__raw,
          profile: claims
        }));
        segment.identify(claims);
        tracking.login(claims);

        if (loginResult.appState?.returnTo) {
          history.push(loginResult.appState?.returnTo);
        } else {
          history.push('/');
        }
      });
    }).catch(err => {
      console.error(err);
      setError(err.message || err);
    });
  }, []);

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/criipto-logo-cyan.svg" alt="Criipto" />
        </div>
        <div className="content">
          <h4>
            Logging in ...
          </h4>

          {error && (
            <div className="alert alert-danger" style={{marginTop: "25px", width: "100%"}}>
              <p>{ error }</p>
              <Button variant="primary" onClick={handleLogin} style={{float: "left"}}>{translate('LABEL_TRY_AGAIN')}</Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export function LoginRefreshScreen() {
  console.log('LoginRefreshScreen');
  const auth = useSelector((state : RootState) => state.auth.client);
  const dispatch = useDispatch();
  const params = useParams<{organizationId?: string}>();
  const history = useHistory();

  useEffect(() => {
    auth.checkSession().then(() => {
      return Promise.all([
        auth.getIdTokenClaims(),
        auth.getTokenSilently({
          ignoreCache: true
        })
      ]).then(([claims, accessToken]) => {
        dispatch(setTokens({
          access_token: accessToken,
          id_token: claims.__raw,
          profile: claims
        }));

        return Promise.all([
          // fetchOrganizations will also fetch tenants and scoped claims
          dispatch(fetchOrganizations({force: true})).then(unwrapResult)
        ]).then(() => {
          if (params.organizationId) {
            history.push(`/org/${params.organizationId}/dashboard`);
          } else {
            history.push('/');
          }
        });
      });
    }).catch((err) => {
      auth.loginWithRedirect({
        response_type: 'token id_token'
      });
    });
  }, []);

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/criipto-logo-cyan.svg" alt="Criipto" />
        </div>
        <div className="content">
          <h4>Working ...</h4>
        </div>
      </div>
    </div>
  );
}

export function NemIDLoginCallbackScreen(props : CallbackProps) {
  const nemIdAuthClient = useMemo(() => createNemIDAuthClient(), []);

   useEffect(() => {
     nemIdAuthClient.popup.callback(window.location.origin);
  }, []);

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/criipto-logo-cyan.svg" alt="Criipto" />
        </div>
        <div className="content">
          <div>
            Logging in ...
          </div>
        </div>
      </div>
    </div>
  );
}