import { combineReducers } from 'redux';

import environmentSlice from './environmentSlice';
import statuspageSlice from './statuspageSlice';
import modalsSlice from './modalsSlice';
import tenantSlice from './tenantSlice';
import apiSlice from './apiSlice';
import discoverySlice from './discoverySlice';
import authSlice from './authSlice';
import extensibilitySlice from './extensibilitySlice';

import { gaussApi } from '@app/api/gauss';
import { subscriptionApi } from '@app/api/subscription';
import { verifyApi } from '@app/api/verify';

const reducer = combineReducers({
  [environmentSlice.name]: environmentSlice.reducer,
  [statuspageSlice.name]: statuspageSlice.reducer,
  [modalsSlice.name]: modalsSlice.reducer,
  [tenantSlice.name]: tenantSlice.reducer,
  [apiSlice.name]: apiSlice.reducer,
  [discoverySlice.name]: discoverySlice.reducer,
  [authSlice.name]: authSlice.reducer,
  [extensibilitySlice.name]: extensibilitySlice.reducer,
  [gaussApi.reducerPath]: gaussApi.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [verifyApi.reducerPath]: verifyApi.reducer,
});

export default reducer;