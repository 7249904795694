import { createSlice, createAsyncThunk, PayloadAction, ActionReducerMapBuilder, unwrapResult } from '@reduxjs/toolkit';
import { values, identity, find } from 'lodash';

import {
  getHref, getTemplateHref, Tenant, GaussOrganization, Billable,
  IPermissions, propMultiDeserializer, deserializer, Domain,
  Application, OAuth2Config, IdServicesStylings, IdServicesStyling,
  Environment, ClientSecret, IdentityConnections, DomainConfiguration,
  TokenSigningKeysList, TokenSigningKey, GaussOrganizationDetail,
  DomainAvailability, CommandStatus, DanishMitIdConfiguration,
  DanishNemIdConfiguration, NorwegianBankIdConfiguration,
  NorwegianVippsLoginConfiguration, ItsmeConfiguration,
  GermanSofortConfiguration, BelgianeIDConfiguration,
  DutchiDINConfiguration, Backoffice
} from '@app/models';
import {REL, CONTENT_TYPE, RESERVED_DOMAINS, TENANT_ID_PREFIX, REL_CONTENT_TYPE_MAP} from '@app/constants';
import {
  default as apiSlice, ApiRequestState, defaultApiRequestState,
  pendingApiRequestState, successApiRequestState, errorApiRequestState,
  dispatchVerifyRequest,
  dispatchGaussRequest
} from '@redux/apiSlice';

import {DetailedIdentityProvider, IdentityProvider, IDENTITY_PROVIDERS} from '@app/providers';
import { singleton as segment } from '@app/segment';
import { singleton as config } from '@app/config';

import {AppDispatch, RootState} from './store';
import { discoverGauss, discoverVerify, fetchOrganizations, fetchScopedClaims, resetDiscovery } from './discoverySlice';
import { slackNotification } from '@app/slack';
import { set as setEnvironment } from './environmentSlice';

interface TenantState {
  tenant?: Tenant,
  organization?: GaussOrganization,
  permissions?: IPermissions,
  domains: {
    items?: Domain[]
    state: ApiRequestState
  },
  applications: {
    items?: Application[]
    state: ApiRequestState
  },
  styling: {
    response?: IdServicesStylings,
    state: ApiRequestState
  },
  providers: {
    response?: IdentityConnections,
    state: ApiRequestState
  }
};

type CacheArgs = {
  force?: boolean
}

export const initialState: TenantState = {
  domains: {
    state: defaultApiRequestState
  },
  applications: {
    state: defaultApiRequestState
  },
  styling: {
    state: defaultApiRequestState
  },
  providers: {
    state: defaultApiRequestState
  }
};

export const fetchStyling = createAsyncThunk<IdServicesStylings, CacheArgs, {state: RootState, dispatch: AppDispatch}>(
  'tenant/fetchStyling',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const {tenant, styling} = state.tenant;

    if (styling.response && !args.force) {
      return styling.response;
    }

    if (!tenant) throw new Error('No tenant available');

    return await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'GET',
        url: getHref(tenant, REL.ID_SERVICES_STYLING)
      },
      deserializer(IdServicesStylings)
    );
  }
)

export const updateStyling = createAsyncThunk<IdServicesStylings, [Environment, Omit<IdServicesStyling, 'deserialize'>], {state: RootState, dispatch: AppDispatch}>(
  'tenant/updateStyling',
  async ([environment, input], thunkAPI) => {
    const state = thunkAPI.getState();
    const {tenant, styling} = state.tenant;

    if (!tenant) throw new Error('No tenant available');

    const data = deserializer(IdServicesStylings)({
      ...styling.response,
      [environment === "PRODUCTION" ? "prodStyle" : "testStyle"]: input
    });

    await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'POST',
        url: getHref(tenant, REL.ID_SERVICES_STYLING),
        contentType: CONTENT_TYPE.ID_SERVICES_STYLING,
        data,
      },
      identity
    );

    return data;
  }
);

export const fetchProviders = createAsyncThunk<IdentityConnections, CacheArgs, {state: RootState, dispatch: AppDispatch}>(
  'tenant/fetchProviders',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const {tenant, organization, providers} = state.tenant;

    if (providers.response && !args.force) {
      return providers.response;
    }

    if (!tenant) throw new Error('No tenant available');

    return await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'GET',
        url: getHref(tenant, REL.ID_SERVICES)
      },
      deserializer(IdentityConnections)
    );
});

type ProviderSettingsUpdate = {
  rel: keyof typeof REL,
  values:
    | DanishMitIdConfiguration | DanishNemIdConfiguration | NorwegianBankIdConfiguration
    | NorwegianVippsLoginConfiguration | ItsmeConfiguration | GermanSofortConfiguration
    | BelgianeIDConfiguration | DutchiDINConfiguration
}
export const updateProviderSettings = createAsyncThunk<Promise<void>, ProviderSettingsUpdate, {state: RootState, dispatch: AppDispatch}>(
  'tenant/updateProviderSettings',
  async (input, thunkAPI) => {
    const state = thunkAPI.getState();
    const {tenant} = state.tenant;
    if (!tenant) throw new Error('No tenant available');

    const isProduction = state.environment[tenant.tenantId]?.environment === "PRODUCTION";

    await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        url: getHref(tenant, REL[input.rel]),
        method: "POST",
        data: {
          ...input.values,
          production: isProduction
        },
        contentType: CONTENT_TYPE[REL_CONTENT_TYPE_MAP[input.rel]!]
      },
      identity
    );
  }
)

export const createDomain = createAsyncThunk<Domain, {name: string, production: boolean}, {state: RootState, dispatch: AppDispatch}>(
  'tenant/createDomain',
  async (input, thunkAPI) => {
    const state = thunkAPI.getState();
    const {tenant} = state.tenant;

    if (!tenant) throw new Error('No tenant available');

    const domain = await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'POST',
        url: getHref(tenant, REL.CREATE_TENANT_DOMAIN),
        contentType: CONTENT_TYPE.DOMAIN,
        data: input,
      },
      deserializer(Domain)
    );

    await thunkAPI.dispatch(fetchDomains({force: true}));

    return domain;
  }
);

export const fetchDomains = createAsyncThunk<Domain[], CacheArgs, {state: RootState, dispatch: AppDispatch}>(
  'tenant/fetchDomains',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const {tenant, domains} = state.tenant;

    if (domains.items && !args.force) {
      return domains.items;
    }

    if (!tenant) throw new Error('No tenant available');

    return await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'GET',
        url: getHref(tenant, REL.DOMAINS)
      },
      propMultiDeserializer(Domain, "domains")
    );
  }
);

export const fetchDomainTokenSigningKeys = createAsyncThunk<[Domain, TokenSigningKeysList], Domain, {state: RootState, dispatch: AppDispatch}>(
  'tenant/fetchDomainTokenSigningKeys',
  async (domain, thunkAPI) => {
    const response = await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'GET',
        url: getHref(domain, REL.TOKENSIGNING_KEYS)
      },
      deserializer(TokenSigningKeysList)
    );

    return [domain, response] as [Domain, TokenSigningKeysList];
  }
);
export const addDomainTokenSigningKey = createAsyncThunk<void, Domain, {state: RootState, dispatch: AppDispatch}>(
  'tenant/addDomainTokenSigningKey',
  async (domain, thunkAPI) => {
    const href = getHref(domain.tokenSigningKeysList!, REL.PREPARE_NEW_TOKENSIGNING_KEYS);

    await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'POST',
        url: href,
        contentType: undefined,
        accept: CONTENT_TYPE.TOKEN_SIGNING_KEY
      },
      identity
    );

    await thunkAPI.dispatch(fetchDomainTokenSigningKeys(domain));

    return;
  }
);

export const activateDomainTokenSigningKey = createAsyncThunk<void, [Domain, TokenSigningKey], {state: RootState, dispatch: AppDispatch}>(
  'tenant/activateDomainTokenSigningKey',
  async (values, thunkAPI) => {
    const [domain, key] = values;
    const href = getHref(domain, REL.TOKENSIGNING_KEYS);

    await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'PUT',
        url: href,
        contentType: CONTENT_TYPE.TOKEN_SIGNING_KEY_VERSION,
        data: key
      },
      identity
    );

    await thunkAPI.dispatch(fetchDomainTokenSigningKeys(domain));

    return;
  }
);

type FetchDomainConfigurationArgs = {
  cache: boolean,
  domain: Domain
}
export const fetchDomainConfiguration = createAsyncThunk<[Domain, DomainConfiguration], FetchDomainConfigurationArgs, {state: RootState, dispatch: AppDispatch}>(
  'tenant/fetchDomainConfiguration',
  async (args, thunkAPI) => {
    const {cache, domain} = args;

    if (cache && domain.configuration) return [domain, domain.configuration];

    const configuration = await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'GET',
        url: getHref(domain, 'self')
      },
      deserializer(DomainConfiguration)
    );

    return [domain, configuration] as [Domain, DomainConfiguration];
  }
);

export const updateDomainConfiguration = createAsyncThunk<[Domain, DomainConfiguration], [Domain, DomainConfiguration], {state: RootState, dispatch: AppDispatch}>(
  'tenant/updateDomainConfiguration',
  async (values, thunkAPI) => {
    const [domain, configuration] = values;
    const state = thunkAPI.getState();

    const response = await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'PUT',
        url: getHref(domain, 'self'),
        contentType: CONTENT_TYPE.DOMAIN,
        data: configuration.serialize()
      },
      deserializer(DomainConfiguration)
    );

    return [domain, response] as [Domain, DomainConfiguration];
  }
);

export const deleteDomain = createAsyncThunk<Domain, Domain, {state: RootState, dispatch: AppDispatch}>(
  'tenant/deleteDomain',
  async (domain, thunkAPI) => {
    await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'DELETE',
        url: getHref(domain, "self")
      },
      identity
    );

    segment.track('DOMAIN_REMOVED', {
      "Production": domain.production,
      "Domain": domain.name
    });

    return domain;
  }
);

export const fetchApplications = createAsyncThunk<Application[], CacheArgs, {state: RootState, dispatch: AppDispatch}>(
  'tenant/fetchApplications',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const domains = await thunkAPI.dispatch(fetchDomains(args)).then(unwrapResult);
    const {applications} = state.tenant;

    if (applications.items && !args.force) {
      return applications.items;
    }

    const domainApplications = await Promise.all(domains.map(async domain => {
      const transform = (data : any) => Application.deserializeMultiWithDomain(data, "applications", domain);

      return await dispatchVerifyRequest(
        thunkAPI.dispatch,
        {
          method: 'GET',
          url: getHref(domain, REL.APPLICATIONS)
        },
        transform
      );
    }));


    return domainApplications.reduce((memo, applications) => {
      return memo.concat(applications);
    }, []);
  }
);

export const adjustFrameOrigins = (frameOrigins : string | string[]) => {
  if (typeof frameOrigins === "string") {
    return [frameOrigins];
  }
  return frameOrigins;
}
export const adjustReturnUrls = (returnUrls : string[]) => {
  return returnUrls?.filter(identity) ?? [];
};

type CreateUpdateResult = [Application[], Application];
export const createApplication = createAsyncThunk<CreateUpdateResult, Application, {state: RootState, dispatch: AppDispatch}>(
  'tenant/createApplication',
  async (application, thunkAPI) => {
    const state = thunkAPI.getState();
    const domain = application.domain;
    const transform = (data : any) => Application.deserializeMultiWithDomain(data, "applications", domain);
    const href = getHref(domain, REL.APPLICATIONS);

    const applications = await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'POST',
        url: href,
        contentType: CONTENT_TYPE.APPLICATION,
        data: {
          ...application,
          returnUrls: adjustReturnUrls(application.returnUrls),
          frameOrigins: adjustFrameOrigins(application.frameOrigins)
        },
      },
      transform
    );

    return [applications, applications.find(search => search.domain.name === application.domain.name && search.realm === application.realm)] as CreateUpdateResult;
  }
);

export const updateApplication = createAsyncThunk<CreateUpdateResult, Application, {state: RootState, dispatch: AppDispatch}>(
  'tenant/updateApplication',
  async (application, thunkAPI) => {
    const transform = (data : any) => Application.deserializeMultiWithDomain(data, "applications", application.domain);

    const applications = await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'PUT',
        url: getHref(application, 'self'),
        contentType: CONTENT_TYPE.APPLICATION,
        data: {
          ...application,
          returnUrls: adjustReturnUrls(application.returnUrls),
          frameOrigins: adjustFrameOrigins(application.frameOrigins)
        },
      },
      transform
    );

    segment.track('CLIENT_UPDATED', {
      clientName: application.name,
      domain: application.domain.name
    });

    return [applications, applications.find(search => search.domain.name === application.domain.name && search.realm === application.realm)] as CreateUpdateResult;
  }
);

export const deleteApplication = createAsyncThunk<Application, Application, {state: RootState, dispatch: AppDispatch}>(
  'tenant/deleteApplication',
  async (application, thunkAPI) => {
    await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'DELETE',
        url: getHref(application, "self")
      },
      identity
    );

    segment.track('CLIENT_DELETED', {
      clientName: application.name,
      domain: application.domain.name
    });

    return application;
  }
);

export const onboardingApplicationRealm = "urn:application:example";
export const createOnboardingApplication = createAsyncThunk<Application[], CacheArgs, {state: RootState, dispatch: AppDispatch}>(
  'tenant/createOnboardingApplication',
  async (args, thunkAPI) => {
    if (!args.force) {
      let applications = await thunkAPI.dispatch(fetchApplications(args)).then(unwrapResult);
      const existing = applications.find(search => search.realm === onboardingApplicationRealm);
      if (existing) {
        return applications;
      }
    }

    let domains = await thunkAPI.dispatch(fetchDomains(args)).then(unwrapResult);
    domains = domains.filter(domain => !domain.production && !domain.name.includes('mitid.dk'));

    if (!domains.length) {
      throw new Error(`No domains available to create onboarding application`);
    }

    const application = new Application();
    application.domain = domains[0];
    application.name = "Example Application";
    application.realm = onboardingApplicationRealm;
    application.returnUrls = [
      "https://jwt.io"
    ];
    application.tags = ["automatic"]; // We want to be able to identify that this application was not created manually
    application.errorStrategy = "protocol";
    application.oAuth2Config = new OAuth2Config();
    application.sessionLifetime = "1200";
    application.authMethods = values(IDENTITY_PROVIDERS).reduce((memo : string[], provider) => memo.concat(provider.specificProviders.map(s => s.urn)), []);

    return await thunkAPI.dispatch(createApplication(application)).then(unwrapResult).then(result => result[0]);
  }
);

type CreateApplicationClientSecretResult = [ClientSecret, Application];
export const createApplicationClientSecret = createAsyncThunk<CreateApplicationClientSecretResult, Application, {state: RootState, dispatch: AppDispatch}>(
  'tenant/createApplicationClientSecret',
  async (application, thunkAPI) => {
    const state = thunkAPI.getState();
    const transform = deserializer(ClientSecret);

    const response = await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'PUT',
        url: getHref(application, REL.CLIENTSECRET),
        data: undefined
      },
      transform
    );

    const clone = application.clone({
      oAuth2ClientSecret: {
        enabled: true
      }
    });
    return [response, clone] as CreateApplicationClientSecretResult;
  }
);

export const deleteApplicationClientSecret = createAsyncThunk<Application, Application, {state: RootState, dispatch: AppDispatch}>(
  'tenant/deleteApplicationClientSecret',
  async (application, thunkAPI) => {
    const state = thunkAPI.getState();

    await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'DELETE',
        url: getHref(application, REL.CLIENTSECRET)
      },
      identity
    );

    const clone = application.clone({
      oAuth2ClientSecret: {
        enabled: false
      }
    });
    return clone;
  }
);

export const signupTenant = createAsyncThunk<GaussOrganization, {domain?: string, organization: GaussOrganizationDetail}, {state: RootState, dispatch: AppDispatch}>(
  'tenant/signupTenant',
  async (input, thunkAPI) => {
    const verifyRoot = await thunkAPI.dispatch(discoverVerify({force: false})).then(unwrapResult);
    const state = thunkAPI.getState();

    if (input.domain && RESERVED_DOMAINS.includes(input.domain)) {
      throw new Error("Invalid domain name");
    }

    if (input.domain) {
      const availability = await dispatchVerifyRequest(
        thunkAPI.dispatch,
        {
          method: 'GET',
          url: getTemplateHref(verifyRoot, REL.DNS_AVAILABLE, {
            domain: input.domain
          })!
        },
        deserializer(DomainAvailability)
      );

      if (!availability.available) throw new Error(`${input.domain} not available`);
      }

    // onboard new gauss partner
    await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        url: getHref(verifyRoot, REL.ACCESS_REQUEST),
        method: "POST",
        data: input.organization,
        contentType: CONTENT_TYPE.ACCESS_REQUEST
      },
      deserializer(CommandStatus)
    );

    const [scopedClaims] = await thunkAPI.dispatch(fetchScopedClaims({force: true})).then(unwrapResult);
    const newOrg = find(
      scopedClaims.claimScopes.map(x => x.organization),
      x => x.entityIdentifier === input.organization.entityIdentifier
    )!;

    // create tenant and maybe test domain
    await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        url: getHref(verifyRoot, REL.ENROLLMENT),
        method: "POST",
        data: {
          "tenantId": TENANT_ID_PREFIX + newOrg.id,
          "entityId": newOrg.entityIdentifier,
          "domainName": input.domain
        },
        contentType: CONTENT_TYPE.ENROLLMENT
      },
      deserializer(Domain)
    );

    slackNotification(config.slackNewTenantNotificationUrl, {
      channel: '#signups',
      username: 'easyID',
      text: `New tenant created:\nName: ${input.organization.name}\nDomain: ${input.domain || ''}\nOwner: ${input.organization.contactName}\nOwner email: ${input.organization.contactEmail}`,
      icon_emoji: ':slot_machine:'
    });

    thunkAPI.dispatch(resetDiscovery());

    return newOrg;
  }
);

function addApiListResourceCases<A extends typeof fetchDomains | typeof fetchApplications>(builder : ActionReducerMapBuilder<TenantState>, action : A, key: keyof TenantState) {
  builder.addCase(action.pending, (state) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        state: pendingApiRequestState
      }
    };
  })
  builder.addCase(action.fulfilled, (state, { payload }) => {
    return {
      ...state,
      [key]: {
        items: payload,
        state: successApiRequestState
      }
    };
  });
  builder.addCase(action.rejected, (state, { error }) => {
    return {
      ...state,
      [key]: {
        items: undefined,
        state: errorApiRequestState(new Error(error.message))
      }
    };
  });
}

function addApiResourceCases<A extends typeof fetchStyling | typeof fetchProviders>(builder : ActionReducerMapBuilder<TenantState>, action : A, key: keyof TenantState) {
  builder.addCase(action.pending, (state) => {
    return {
      ...state,
      [key]: {
        ...state[key],
        state: pendingApiRequestState
      }
    };
  })
  builder.addCase(action.fulfilled, (state, { payload }) => {
    return {
      ...state,
      [key]: {
        response: payload,
        state: successApiRequestState
      }
    };
  });
  builder.addCase(action.rejected, (state, { error }) => {
    return {
      ...state,
      [key]: {
        response: undefined,
        state: errorApiRequestState(new Error(error.message))
      }
    };
  });
}

export const TenantSlice = createSlice({
  name: 'tenant',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearTenant: () => {
      return {
        ...initialState
      };
    },
    setTenant: (state, action: PayloadAction<{tenant: Tenant, organization: GaussOrganization | null | undefined, permissions: IPermissions}>) => {
      return {
        ...(action.payload.tenant.entityId === state.tenant?.entityId ? state : initialState),
        tenant: action.payload.tenant,
        organization: action.payload.organization || undefined,
        permissions: action.payload.permissions
      };
    }
  },
  extraReducers: (builder) => {
    addApiListResourceCases<typeof fetchDomains>(builder, fetchDomains, 'domains');
    addApiListResourceCases<typeof fetchApplications>(builder, fetchApplications, 'applications');
    addApiResourceCases<typeof fetchStyling>(builder, fetchStyling, 'styling');
    addApiResourceCases<typeof fetchProviders>(builder, fetchProviders, 'providers');

    builder.addCase(createApplication.fulfilled, (state, { payload }) => {
      const [applications, application] = payload;
      return {
        ...state,
        applications: {
          items:
            (state.applications.items ?? [])
            .filter(search => search.domain.name !== application.domain.name)
            .concat(applications),
          state: successApiRequestState
        }
      };
    });
    builder.addCase(updateApplication.fulfilled, (state, { payload }) => {
      const [applications, application] = payload;
      return {
        ...state,
        applications: {
          items:
            (state.applications.items ?? [])
            .filter(search => search.domain.name !== application.domain.name)
            .concat(applications),
          state: successApiRequestState
        }
      };
    });

    builder.addCase(updateStyling.fulfilled, (state, { payload }) => {
      return {
        ...state,
        styling: {
          response: payload,
          state: successApiRequestState
        }
      };
    });
    builder.addCase(deleteApplication.fulfilled, (state, { payload }) => {
      return {
        ...state,
        applications: state.applications.items ? {
          ...state.applications,
          items: state.applications.items.filter(search => !search.is(payload))
        } : state.applications
      };
    });
    builder.addCase(createApplicationClientSecret.fulfilled, (state, {payload}) => {
      const [, application] = payload
      return {
        ...state,
        applications: {
          ...state.applications,
          items: (state.applications.items ?? []).map(item => {
            if (application.is(item as Application)) return application;
            return item;
          })
        }
      }
    });
    builder.addCase(deleteApplicationClientSecret.fulfilled, (state, {payload: application}) => {
      return {
        ...state,
        applications: {
          ...state.applications,
          items: (state.applications.items ?? []).filter(item => {
            if (application.is(item as Application)) return application;
            return true;
          })
        }
      }
    });
    builder.addCase(fetchDomainConfiguration.fulfilled, (state, { payload: [domain, configuration] }) => {
      return {
        ...state,
        domains: {
          ...state.domains,
          items:
            (state.domains.items ?? []).map(search => {
              if (search.name === domain.name) {
                return search.clone({
                  configuration
                });
              }
              return search;
            }),
        }
      };
    });
    builder.addCase(updateDomainConfiguration.fulfilled, (state, { payload: [domain, configuration] }) => {
      return {
        ...state,
        domains: {
          ...state.domains,
          items:
            (state.domains.items ?? []).map(search => {
              if (search.name === domain.name) {
                return search.clone({configuration});
              }
              return search;
            }),
        }
      };
    });
    builder.addCase(fetchDomainTokenSigningKeys.fulfilled, (state, { payload: [domain, tokenSigningKeysList] }) => {
      return {
        ...state,
        domains: {
          ...state.domains,
          items:
            (state.domains.items ?? []).map(search => {
              if (search.name === domain.name) {
                return search.clone({
                  tokenSigningKeysList
                });
              }
              return search;
            }),
        }
      };
    });

    builder.addCase(deleteDomain.fulfilled, (state, { payload }) => {
      return {
        ...state,
        domains: state.domains.items ? {
          ...state.domains,
          items: state.domains.items.filter(search => search.name !== payload.name)
        } : state.domains
      };
    });
  }
})

export const { setTenant, clearTenant } = TenantSlice.actions;
export default TenantSlice;