import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ApiRequestState, defaultApiRequestState,
  pendingApiRequestState, successApiRequestState, errorApiRequestState,
  dispatchVerifyRequest
} from '@redux/apiSlice';

import {REL, CONTENT_TYPE} from '@app/constants';
import { getHref, Environment, Tenant } from '@app/models';
import {RootState} from './store';

export interface ExtensibilityConfiguration {
  accessToken: string
  accountId: string
  baseUrl: string
  boundaryId: string
  functionId: string
  subscriptionId: string
}

interface ExtensibilityState {
  tenant?: Tenant,
  environments: {
    [key in Environment]: {
      state: ApiRequestState,
      configuration?: ExtensibilityConfiguration
      enabled?: boolean
    }
  }
};

export const initialState: ExtensibilityState = {
  environments: {
    TEST: {
      state: defaultApiRequestState
    },
    PRODUCTION: {
      state: defaultApiRequestState
    }
  }
};

type FetchExtensibilityArgs = {
  tenant: Tenant,
  environment: Environment
  force?: boolean
}

type ExtensibilityResponse = {
  enabled: boolean,
  configuration: ExtensibilityConfiguration,
  tenant: Tenant
}
export const fetchExtensibility = createAsyncThunk<ExtensibilityResponse, FetchExtensibilityArgs, {state: RootState}>(
  'extensibility/fetchExtensibility',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const {tenant, environment} = args;
    const {extensibility} = state;
    const sameTenant = extensibility.tenant === tenant;

    if (sameTenant && extensibility.environments[environment].configuration && !args.force) {
      return {
        enabled: extensibility.environments[environment].enabled!,
        configuration: extensibility.environments[environment].configuration!,
        tenant
      };
    }

    const [enabled, configuration] = await Promise.all([
      dispatchVerifyRequest(
        thunkAPI.dispatch,
        {
          method: 'GET',
          url: getHref(tenant, REL.EXTENSIBILITY_CONFIGURATION) + `?production=${args.environment === 'PRODUCTION' ? 'true' : 'false'}`
        },
        (data: {enabled: boolean}) => {
          return data.enabled;
        }
      ),
      dispatchVerifyRequest(
        thunkAPI.dispatch,
        {
          method: 'PUT',
          url: getHref(tenant, REL.EXTENSIBILITY_CONFIGURATION),
          contentType: CONTENT_TYPE.EXTENSIBILITY_ENVIRONMENT,
          data: {
            production: args.environment === 'PRODUCTION'
          }
        },
        (data: ExtensibilityConfiguration) => {
          return data;
        }
      )
    ]);

    return {
      enabled,
      configuration,
      tenant
    };
});

type SaveExtensibilityArgs = {
  tenant: Tenant,
  environment: Environment
  enabled: boolean
}

export const saveExtensibility = createAsyncThunk<ExtensibilityResponse, SaveExtensibilityArgs, {state: RootState}>(
  'extensibility/saveExtensibility',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState();
    const {tenant} = args;

    await dispatchVerifyRequest(
      thunkAPI.dispatch,
      {
        method: 'POST',
        url: getHref(tenant, REL.EXTENSIBILITY_CONFIGURATION),
        contentType: CONTENT_TYPE.EXTENSIBILITY_ENABLED,
        data: {
          production: args.environment === 'PRODUCTION',
          enabled: args.enabled
        }
      },
      (data: any) => {
        return data;
      }
    );

    return {
      enabled: args.enabled,
      configuration: state.extensibility.environments[args.environment].configuration!,
      tenant: tenant
    };
});

export const extensibilitySlice = createSlice({
  name: 'extensibility',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExtensibility.pending, (state, action) => {
      const sameTenant = action.meta.arg.tenant === state.tenant;
      return sameTenant ? {
        ...state,
        environments: {
          ...state.environments,
          [action.meta.arg.environment]: {
            ...state.environments[action.meta.arg.environment],
            state: pendingApiRequestState
          }
        }
      } : {...initialState, tenant: action.meta.arg.tenant};
    });
    builder.addCase(fetchExtensibility.fulfilled, (state, action) => {
      return {
        ...state,
        tenant: action.payload.tenant,
        environments: {
          ...state.environments,
          [action.meta.arg.environment]: {
            state: successApiRequestState,
            enabled: action.payload.enabled,
            configuration: action.payload.configuration,
          }
        }
      };
    });
    builder.addCase(fetchExtensibility.rejected, (state, action) => {
      return {
        ...state,
        environments: {
          ...state.environments,
          [action.meta.arg.environment]: {
            state: errorApiRequestState,
            enabled: undefined,
            configuration: undefined,
          }
        }
      };
    });
    builder.addCase(saveExtensibility.fulfilled, (state, action) => {
      return {
        ...state,
        environments: {
          ...state.environments,
          [action.meta.arg.environment]: {
            state: successApiRequestState,
            enabled: action.payload.enabled,
            configuration: action.payload.configuration,
          }
        }
      };
    });
  }
})

export default extensibilitySlice;