import React from 'react';
import {useDispatch, useSelector} from '@app/redux';
import {DanishMitIdStatus, deserializer, getHref} from '@app/models';
import {REL} from '@app/constants';
import usePromise from '@app/hooks/usePromise';

import {
  DanishMitIdConfiguration
} from '@app/models';

import Configuration from './Configuration/Configuration';
import Apply from './Apply/Apply';
import Pending from './Pending/Pending';
import Domain from './Domain/Domain';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';
import useTenant from '@app/hooks/useTenant';
import useEnvironment from '@app/hooks/useEnvironment';

interface Props {
  configuration: DanishMitIdConfiguration
}

export function DKMitIDProvider(props: Props) {
  const dispatch = useDispatch();
  const tenant = useTenant();
  const environment = useEnvironment();

  const [statusResponse, statusState] = usePromise(async () => {
    if (!tenant) return;
    return await dispatchVerifyRequest(
      dispatch,
      {
        url: getHref(tenant!, REL.DK_MITID_STATUS),
        method: "GET"
      },
      deserializer(DanishMitIdStatus)
    );
  }, [tenant]);

  if (statusState.pending || !tenant) return null;

  const isPending =
    props.configuration.serviceProviderId &&
      (environment === 'PRODUCTION' ?
      (statusResponse?.production === 'PENDING' || statusResponse?.productionServiceProviderState === 'pending' || statusResponse?.productionCname === false) :
      (statusResponse?.test === 'PENDING' || statusResponse?.testServiceProviderState === 'pending' || statusResponse?.testCname === false));

  return (
    <React.Fragment>
      {!props.configuration.serviceProviderId && (<Apply />)}
      {isPending && (<Pending status={statusResponse!} configuration={props.configuration} />)}
      {props.configuration.serviceProviderId && (<Configuration {...props} />)}
      {isPending && (<Domain />)}
    </React.Fragment>
  )
}