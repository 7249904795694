import React, {useState, useEffect} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {upperFirst} from 'lodash';
import moment from 'moment';
import cx from 'classnames';

import {fetchDomains} from '@app/redux/tenantSlice';
import Button from '@app/components/Button';
import {deserializer, LogEntry, LogEntryList, getHref, getOptionalHref, Domain} from '@app/models';
import {translate} from '@app/i18n';
import { useSelector, useDispatch } from '@app/redux';

import './LogsScreen.scss';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';
import useEnvironment from '@app/hooks/useEnvironment';
import useTracking from '@app/hooks/useTracking';

export default function LogsScreen(props: {domain?: Domain}) {
  const dispatch = useDispatch();
  const tenant = useSelector(state => state.tenant.tenant);
  const history = useHistory();
  const match = useRouteMatch<{domain?: string}>();
  const params = match.params;
  const baseUrl = params.domain ? match.url.replace(`/${params.domain}`, '') : match.url;
  const [pagination, setPagination] = useState<{prev: string | null; next: string |null}>({prev: null, next: null});
  const [search, setSearch] = useState('');
  const [entries, setEntries] = useState<LogEntry[]>([]);
  const [expanded, setExpanded] = useState<{[key: string]: boolean}>({});
  const [loadMorePending, setLoadMorePending] = useState(false);
  const environment = useEnvironment();
  const tracking = useTracking();

  useEffect(() => {
    if (!tenant) return;

    tracking.viewedLogsScreen(props.domain ? 'domain' : undefined);
  }, [tenant, props.domain]);

  let {items : domains, state: {pending, error}} = useSelector(state => state.tenant.domains);

  useEffect(() => {
    if (props.domain) return;
    dispatch(fetchDomains({force: false}));
  }, [tenant?.tenantId, props.domain]);

  domains = domains?.filter(search => search.production === (environment === "PRODUCTION"));
  const domain = props.domain || domains?.find(search => search.name === params.domain);

  const handleDomain = (event : React.ChangeEvent<HTMLSelectElement>) => {
    if (!event.target.value) return history.push(baseUrl);
    history.push(`${baseUrl}/${event.target.value}`);
  };

  const loadMore = (url: string) => {
    if (!domain) throw new Error('loadMore(): domain is required');

    setLoadMorePending(true);

    dispatchVerifyRequest(dispatch, {
      method: 'GET',
      url
    }, deserializer(LogEntryList)).then(response => {
      setPagination(pagination => ({
        prev: getOptionalHref(response, 'pagination:prev'),
        next: pagination.next || getOptionalHref(response, 'pagination:next')
      }));

      setEntries(previous => previous.concat(response.entries));
      setLoadMorePending(false);
    });
  }

  const toggleEntry = (entry: LogEntry) => {
    setExpanded(expanded => ({
      ...expanded,
      [entry.cursor]: !expanded[entry.cursor]
    }));
  }

  useEffect(() => {
    if (!domain) return;
    setEntries([]);
    loadMore(getHref(domain, 'easyid:domain-http-logs'));
  }, [domain]);

  const className = props.domain ? 'logs-screen' : 'container logs-screen';

  if (pending) return <div className={className}><span className="fa fa-spinner fa-pulse"></span></div>;
  if (error) return <div className={className}>{error}</div>;

  return (
    <div className={className}>
      <div className="action-bar">
        {!props.domain && (
          <select className="form-control" onChange={handleDomain} value={domain && domain.name}>
            <option value="">{translate('HINT_CHOOSE_DOMAIN')}</option>
            {domains?.map(domain => (
              <option key={domain.name} value={domain.name}>{domain.name}</option>
            ))}
          </select>
        )}
        <div className="search-input">
          <div className="fa fa-search" />
          <input type="search" placeholder="Search ..." value={search} onChange={(event) => setSearch(event.target.value)}/>
        </div>
      </div>

      {entries.length ? (
        <div className="log-entry-wrapper">
          {entries.filter(entry => !search || JSON.stringify(entry).includes(search)).map(entry => (
            <div className="entry" key={entry.cursor}>
              <div className="header" onClick={() => toggleEntry(entry)}>
                <div className="timestamp">{moment(entry.timestamp).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div>{entry.statusCode}</div>
                <div>{entry.method}</div>
                <div className="url">{entry.url}</div>
              </div>
              <div className={cx('details', {'expanded': expanded[entry.cursor]})}>
                <div>
                  <strong>Request</strong>
                  <div>
                    <strong>{entry.method}</strong> {entry.url}
                  </div>
                  <div>
                    <HeadersFormatter headers={entry.requestHeaders} /><br />
                  </div>
                  <div className="html">
                    <NewlineFormatter text={entry.requestBody} />
                  </div>
                </div>
                <div>
                  <strong>Response</strong>
                  <div>
                    <strong>{entry.statusCode}</strong>
                  </div>
                  <div>
                    <HeadersFormatter headers={entry.responseHeaders} /><br />
                  </div>
                  <div className="html">
                    <NewlineFormatter text={entry.responseBody} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null }

      {pagination.prev && (
        <Button variant="primary" working={loadMorePending} onClick={() => loadMore(pagination.prev!)}>Load more</Button>
      )}
    </div>
  );
}

interface HeadersFormatterProps {
  headers: {[key: string]: string};
}

function HeadersFormatter(props: HeadersFormatterProps) {
  if (!Object.keys(props.headers).length) return null;

  return (
    <React.Fragment>
      {Object.keys(props.headers).map(key => (
        <React.Fragment key={key}>
          <strong>{upperFirst(key)}</strong>: {props.headers[key]}<br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}

interface NewlineFormatterProps {
  text: string;
}
function NewlineFormatter(props: NewlineFormatterProps) {
  if (!props.text) return null;
  return (
    <React.Fragment>
      {props.text.split("\n").map((text, index) => (
        <React.Fragment key={index}>
          <span>{text}</span>
          <br />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}