import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import {Tenant} from '@app/models';

import {useSelector, useDispatch} from '@app/redux';
import {set as setEnvironment} from '@app/redux/environmentSlice';

import OnboardingScreenApplication from './OnboardingScreenApplication';
import OnboardingScreenLogin from './OnboardingScreenLogin';
import OnboardingScreenMitID from './OnboardingScreenMitID';
import Panel from '@app/components/Panel/Panel';
import useTenant from '@app/hooks/useTenant';
import { TENANT_ID_PREFIX } from '@app/constants';
import { LinkButton } from '@app/components/Button/Button';
import useEnvironment from '@app/hooks/useEnvironment';
import useTracking from '@app/hooks/useTracking';

interface Props {
  tenant : Tenant
}

export default function OnboardingRoutes(props : Props) {
  const match = useRouteMatch();
  const tenant = useTenant();
  const dispatch = useDispatch();
  const environment = useEnvironment();

  useEffect(() => {
    if (!tenant) return;
    if (environment !== "TEST") {
      dispatch(setEnvironment({tenant: tenant!, environment: "TEST"}));
    }
  }, [tenant, environment]);

  return (
    <Switch>
      <Route path={`${match.path}/login/:applicationId`}>
        <OnboardingScreenLogin />
      </Route>
      <Route path={`${match.path}/login`}>
        <OnboardingScreenLogin />
      </Route>
      <Route path={`${match.path}/mitid`}>
        <OnboardingScreenMitID />
      </Route>
      <Route>
        <OnboardingScreen />
      </Route>
    </Switch>
  );
}
export function OnboardingScreen() {
  const tenant = useTenant();
  const tracking = useTracking();

  useEffect(() => {
    if (!tenant) return;

    tracking.viewedOnboardingScreen();
  }, [tenant]);

  return (
    <div className="app-content-main onboarding-screen">
      <div className="panels">
        <Panel title="Your first test domain">
          <p>
            Everything you do with Criipto, be it signatures or logins, requires that you register a <strong>domain</strong>.
          </p>
          <LinkButton variant="primary" to={`/org/${tenant?.tenantId.replace(TENANT_ID_PREFIX, '')}/domains/add`}>
            Add test domain
          </LinkButton>
        </Panel>
        <Panel title="Invite team member">
          <p>
            If you are not a developer, or if you need someone else to handle the billing, you can invite them to your Criipto Organization.
          </p>
          <LinkButton variant="primary" to={`/org/${tenant?.tenantId.replace(TENANT_ID_PREFIX, '')}/users`}>
            Invite team member
          </LinkButton>
        </Panel>
        <Panel title="Test login">
          <p>
            If you wish to see the contents of the JWT that Criipto Verify issues when a user logs in, you can try a test login.
          </p>
          <LinkButton variant="primary" to={`/org/${tenant?.tenantId.replace(TENANT_ID_PREFIX, '')}/onboarding/login`}>
            Try a test login
          </LinkButton>
        </Panel>
      </div>
      <div className="panels">
        <Panel title="Start integrating Criipto Verify">
          <p>
            To start accepting user logins with e-IDs you will first need to integrate a test application.
          </p>
          <LinkButton variant="primary" to={`/org/${tenant?.tenantId.replace(TENANT_ID_PREFIX, '')}/applications/add`}>
            Create a verify/login application
          </LinkButton>
        </Panel>
        <Panel title="Start integrating Criipto Signatures">
          <p>
            Sign PDF documents with any of the e-IDs that Criipto supports.
          </p>
          <LinkButton variant="primary" to={`/org/${tenant?.tenantId.replace(TENANT_ID_PREFIX, '')}/applications/add?tags=signatures`}>
            Create a signatures application
          </LinkButton>
        </Panel>
      </div>
    </div>
  );
}