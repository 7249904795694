import React from 'react';
import cx from 'classnames';
import {Link, LinkProps} from 'react-router-dom';

import './Button.scss';
import Tooltip from '@app/components/Tooltip';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  working?: boolean,
  variant: "primary" | "default" | "danger"
  autoWidth?: boolean
  children: React.ReactNode
  tooltip?: string,
  buttonRef?: React.RefObject<HTMLButtonElement>
}
export default function Button({working, variant, children, autoWidth, tooltip, buttonRef, ...props} : ButtonProps) {
  const disabled = props.disabled || working;
  const button = (
    <button type="button" {...props} className={cx(`button button-${variant}`, {'button-auto': autoWidth}, props.className)} disabled={disabled} ref={buttonRef}>
      {working && <i className="fa fa-spinner fa-pulse" />}
      {working && <>&nbsp;</>}
      {children}
    </button>
  );

  if (tooltip) {
    return (
      <Tooltip id="button_tooltip" tooltip={tooltip}>
        {button}
      </Tooltip>
    )
  }

  return button;
}

export interface AnchorButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  variant: "primary" | "default" | "danger",
  autoWidth?: boolean,
  children: React.ReactNode
}
export function AnchorButton({variant, children, autoWidth, ...props} : AnchorButtonProps) {
  return (
    <a {...props} className={cx(`button button-${variant}`, {'button-auto': autoWidth}, props.className)}>
      {children}
    </a>
  )
}

export interface LinkButtonProps extends LinkProps {
  variant: "primary" | "default" | "danger",
  autoWidth?: boolean,
  children: React.ReactNode
}
export function LinkButton({variant, children, autoWidth, ...props} : LinkButtonProps) {
  return (
    <Link {...props} className={cx(`button button-${variant}`, {'button-auto': autoWidth}, props.className)}>
      {children}
    </Link>
  )
}