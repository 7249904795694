import Button, { AnchorButton } from '@app/components/Button/Button';
import Panel from '@app/components/Panel/Panel';
import { SlackForm } from '@app/components/Slack/Slack';
import useGaussOrganization from '@app/hooks/useGaussOrganization';
import useTenant from '@app/hooks/useTenant';
import { useDispatch, useSelector } from '@app/redux';
import { fetchDomains } from '@app/redux/tenantSlice';
import React, { useEffect } from 'react';

export default function SupportScreen() {
  const tenant = useTenant();
  const organization = useGaussOrganization();
  const dispatch = useDispatch();
  const domains = useSelector(state => state.tenant.domains.items);

  useEffect(() => {
    if (!tenant) return;

    dispatch(fetchDomains({force: false}));
  }, [tenant]);

  return (
    <React.Fragment>
      <div className="app-content-header">
        <h1>Support</h1>
      </div>
      <div className="app-content-main max-w-[850px]">
        <div className="flex flex-column lg:flex-row gap-[16px]">
          <Panel title="Slack support">
            <SlackForm />
          </Panel>
          <Panel title="Email support">
            <p>
              Slack is the easiest way to get direct support from Criipto Developers but you can also send us an email at <a href="mailto:support@criipto.com">support@criipto.com</a>
            </p>
            <AnchorButton variant="default" href="mailto:support@criipto.com">Write an email</AnchorButton>
          </Panel>
        </div>
        <div className="flex flex-column lg:flex-row gap-[16px]">
          <Panel title="Screenshot me!">
            <p>
              When talking to support, please include a screenshot of this panel as it helps us identify your account.
            </p>

            <div className="form-group">
              <label className="control-label">Tenant name</label>
              <input className="form-control" readOnly={true} defaultValue={organization?.name} />
            </div>

            <div className="form-group">
              <label className="control-label">Entity ID</label>
              <input className="form-control" readOnly={true} defaultValue={tenant?.entityId} />
            </div>

            <div className="form-group">
              <label className="control-label">Tenant ID</label>
              <input className="form-control" readOnly={true} defaultValue={tenant?.tenantId} />
            </div>

            <div className="form-group">
              <label className="control-label">Domains</label>
              <textarea className="form-control" readOnly={true} defaultValue={(domains || []).map(d => d.name).join(', ')} />
            </div>
          </Panel>
        </div>
      </div>
    </React.Fragment>
  );
}