import React, {useState} from 'react';

import Button from '@app/components/Button';
import Modal, { ModalProps } from '@app/components/Modal';
import PlanSelection, {PlanChoice} from '../PlanSelection/PlanSelection';
import { CustomerResponse, PlanRendition, PricedPlanRendition, useCustomerQuery, usePlansQuery, useUpdateSubscriptionMutation } from '@app/api/subscription';
import useTenant from '@app/hooks/useTenant';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { getErrorString } from '@app/api/helper';
import useTracking from '@app/hooks/useTracking';

function getCustomerPlan(customer: CustomerResponse, plans: PlanRendition[]) {
  return plans.filter(p => "volume" in p).find(s => s.id === customer.plan) as PricedPlanRendition | undefined;
}

interface Props {
  show: ModalProps["show"]
  onHide: ModalProps["onHide"]
  onChange: () => void
}

export default function SubscriptionPlanModal(props: Props) {
  const [updateSubscription, updateSubscriptionResult] = useUpdateSubscriptionMutation();
  const tenant = useTenant();
  const customerResponse = useCustomerQuery(tenant ?? skipToken);
  const [choice, setChoice] = useState<PlanChoice | null>(null);
  const customer = customerResponse.data;
  const plansResponse = usePlansQuery();
  const plans = plansResponse.data;
  const tracking = useTracking();

  if (!customer || !tenant) return null;

  const plan = plans && getCustomerPlan(customer, plans);

  const handleHide = () => {
    props.onHide();
  }

  const handleSubmit = async () => {
    if (!choice) return;

    await updateSubscription({
      tenant,
      request: {
        ...customer,
        currency: choice.currency,
        period: choice.period,
        plan: choice.plan.id
      }
    }).unwrap();

    tracking.subscriptionChangedPlan({
      currency: choice.currency,
      period: choice.period,
      plan: choice.plan
    });
    props.onChange();
  };

  const changed = choice &&
                  (choice.currency !== customer.currency ||
                  choice.period !== customer.period ||
                  choice.plan.id !== plan?.id);

  return (
    <Modal {...props} title="Select plan">
      <Modal.Body>
        <PlanSelection
          selected={plan ? {
            plan,
            period: customer.period!,
            currency: customer.currency!
          } : undefined}
          onSelect={setChoice}
        />
      </Modal.Body>
      <Modal.Footer>
        {updateSubscriptionResult.error && (
          <p className="alert alert-danger">
            {getErrorString(updateSubscriptionResult.error)}
          </p>
        )}

        <div className="flex flex-col lg:flex-row gap-[8px] justify-between">
          <Button variant="default" onClick={handleHide}>Cancel</Button>
          <Button variant="primary" working={updateSubscriptionResult.isLoading} disabled={!changed} onClick={handleSubmit}>
            Change to {choice ? `${choice.plan.name} (${choice.period}ly in ${choice.currency.toUpperCase()})` : 'selected plan'}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}