import React from 'react';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';

import {singleton as config} from '@app/config';

import DeleteApiKeyButton from '../DeleteApiKeyButton';
import RefreshApiKeyButton from '../RefreshApiKeyButton';

import {ApiKeys_application$key} from './__generated__/ApiKeys_application.graphql';

import './ApiKeys.scss';
import AddApiKeyForm from '../AddApiKeyForm/AddApiKeyForm';

interface Props {
  application: ApiKeys_application$key
}

export default function SignatureApplicationApiKeys(props: Props) {
  const application = useFragment(graphql`
    fragment ApiKeys_application on Application {
      apiKeys {
        id
        clientId

        mode
        note

        ...DeleteApiKeyButton_apiKey
        ...RefreshApiKeyButton_apiKey
      }

      ...AddApiKeyButton_application
      ...AddApiKeyForm_application
      ...DeleteApiKeyButton_application
      ...RefreshApiKeyButton_application
    }
  `, props.application);

  return (
    <React.Fragment>
      <div className="signatures-api-keys">
        <p>
          You can try out your api keys in our <a href="https://docs.criipto.com/signatures" target="_blank" className="underline text-primary-900">API documentation</a>
          &nbsp;or our <a href={config.signaturesGraphQLEndpoint?.replace('v1/graphql', 'v1/explorer')} target="_blank" className="underline text-primary-900">GraphQL Explorer</a>
        </p>
        <table className="mb-8">
          <thead>
            <tr>
              <th>Client ID</th>
              <th className="px-2">Client Secret</th>
              <th className="px-2">Mode</th>
              <th className="px-2">Note</th>
            </tr>
          </thead>
          <tbody>
            {application.apiKeys.map(apiKey => (
              <tr key={apiKey.id}>
                <td>{apiKey.clientId}</td>
                <td className="px-2">***********************</td>
                <td className="px-2">{apiKey.mode}</td>
                <td className="px-2">{apiKey.note}</td>
                <td className="actions flex justify-end">
                  <RefreshApiKeyButton apiKey={apiKey} application={application} />
                  <DeleteApiKeyButton apiKey={apiKey} application={application} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="max-w-[750px]">
          <AddApiKeyForm application={application} />
        </div>
      </div>
    </React.Fragment>
  )
}