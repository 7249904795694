
import React from 'react';
import {identity} from 'lodash';

import {useDispatch, useSelector} from '@app/redux';
import {REL, CONTENT_TYPE} from '@app/constants';
import {getHref, DanishMitIdDomainRendition} from 'app/models';
import {translate} from '@app/i18n';
import {Form, InputField, FormError, FormSuccess} from '@components/Form';
import Button from '@components/Button';
import { fetchDomains, fetchProviders } from '@app/redux/tenantSlice';
import { dispatchVerifyRequest } from '@app/redux/apiSlice';
import useEnvironment from '@app/hooks/useEnvironment';
import useTenant from '@app/hooks/useTenant';

export default function DKMitIDProviderApply() {
  const environment = useEnvironment();
  const tenant = useTenant();
  const dispatch = useDispatch();

  const initialValues : DanishMitIdDomainRendition = {
    domainPrefix: "",
    production : environment === 'PRODUCTION'
  };

  return (
    <Form
      key={environment}
      initialValues={initialValues}
      onSubmit={async (values : DanishMitIdDomainRendition) => {
        values.domainPrefix = values.domainPrefix.toLowerCase();

        const url = getHref(tenant!, REL.DK_MITID_DOMAIN);
        await dispatchVerifyRequest(
          dispatch,
          {
            url,
            method: "POST",
            data: values,
            contentType: CONTENT_TYPE.DK_MITID_DOMAIN
          },
          identity
        );

        // Domains & providers will have changed based on the domain re-apply, so we make sure they are refetched.
        dispatch(fetchDomains({force: true}));
        dispatch(fetchProviders({force: true}));
      }}
      data-test-id="form"
    >
      {({values, isPending, error, isSuccess}) => (
        <div className="row">
          <div className="col-sm-6">
            <h3>
                Apply for Danish MitId domain change
            </h3>
            <p>
              If you made a mistake or otherwise want to change the MitID domain, you can do so here.
            </p>
            <div className="form-group" style={{marginTop: "25px"}}>
              <InputField<DanishMitIdDomainRendition>
                type="text"
                label={translate('LABEL_DK_MITID_DOMAIN_PREFIX')}
                name="domainPrefix"
                required
                pattern="^[\d\w]+$"
                help={values.domainPrefix && (
                  <small className="form-text text-muted">{values.domainPrefix}.{environment === 'PRODUCTION' ? 'mitid.dk' : 'pp.mitid.dk'}</small>
                )}
              />
            </div>

            <FormError error={error} />
            <FormSuccess message={!isPending && isSuccess && `Thank you! You will hear from us.`} />
            <Button className="pull-right" variant="primary" working={isPending} type="submit">Apply for new domain</Button>
          </div>
          <div className="col-sm-6">

          </div>
        </div>
      )}
    </Form>
  )
}
