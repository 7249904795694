/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ApplicationApiKeyMode = "READ_ONLY" | "READ_WRITE" | "%future added value";
export type CreateApplicationApiKeyInput = {
    applicationId: string;
    mode?: ApplicationApiKeyMode | null;
    note?: string | null;
};
export type AddApiKeyFormMutationVariables = {
    input: CreateApplicationApiKeyInput;
};
export type AddApiKeyFormMutationResponse = {
    readonly createApplicationApiKey: {
        readonly application: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"ApiKeys_application">;
        };
        readonly apiKey: {
            readonly " $fragmentRefs": FragmentRefs<"ApiKeyModal_apiKey">;
        };
    } | null;
};
export type AddApiKeyFormMutation = {
    readonly response: AddApiKeyFormMutationResponse;
    readonly variables: AddApiKeyFormMutationVariables;
};



/*
mutation AddApiKeyFormMutation(
  $input: CreateApplicationApiKeyInput!
) {
  createApplicationApiKey(input: $input) {
    application {
      id
      ...ApiKeys_application
    }
    apiKey {
      ...ApiKeyModal_apiKey
      id
    }
  }
}

fragment AddApiKeyButton_application on Application {
  id
}

fragment AddApiKeyForm_application on Application {
  id
}

fragment ApiKeyModal_apiKey on ApplicationApiKey {
  clientId
  clientSecret
}

fragment ApiKeys_application on Application {
  apiKeys {
    id
    clientId
    mode
    note
    ...DeleteApiKeyButton_apiKey
    ...RefreshApiKeyButton_apiKey
  }
  ...AddApiKeyButton_application
  ...AddApiKeyForm_application
  ...DeleteApiKeyButton_application
  ...RefreshApiKeyButton_application
}

fragment DeleteApiKeyButton_apiKey on ApplicationApiKey {
  id
}

fragment DeleteApiKeyButton_application on Application {
  id
}

fragment RefreshApiKeyButton_apiKey on ApplicationApiKey {
  id
}

fragment RefreshApiKeyButton_application on Application {
  id
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddApiKeyFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateApplicationApiKeyOutput",
        "kind": "LinkedField",
        "name": "createApplicationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApiKeys_application"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApplicationApiKey",
            "kind": "LinkedField",
            "name": "apiKey",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApiKeyModal_apiKey"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddApiKeyFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateApplicationApiKeyOutput",
        "kind": "LinkedField",
        "name": "createApplicationApiKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "application",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ApplicationApiKey",
                "kind": "LinkedField",
                "name": "apiKeys",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "note",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApplicationApiKey",
            "kind": "LinkedField",
            "name": "apiKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientSecret",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7484e8fc5adbe8078d565f6bf450e9dd",
    "id": null,
    "metadata": {},
    "name": "AddApiKeyFormMutation",
    "operationKind": "mutation",
    "text": "mutation AddApiKeyFormMutation(\n  $input: CreateApplicationApiKeyInput!\n) {\n  createApplicationApiKey(input: $input) {\n    application {\n      id\n      ...ApiKeys_application\n    }\n    apiKey {\n      ...ApiKeyModal_apiKey\n      id\n    }\n  }\n}\n\nfragment AddApiKeyButton_application on Application {\n  id\n}\n\nfragment AddApiKeyForm_application on Application {\n  id\n}\n\nfragment ApiKeyModal_apiKey on ApplicationApiKey {\n  clientId\n  clientSecret\n}\n\nfragment ApiKeys_application on Application {\n  apiKeys {\n    id\n    clientId\n    mode\n    note\n    ...DeleteApiKeyButton_apiKey\n    ...RefreshApiKeyButton_apiKey\n  }\n  ...AddApiKeyButton_application\n  ...AddApiKeyForm_application\n  ...DeleteApiKeyButton_application\n  ...RefreshApiKeyButton_application\n}\n\nfragment DeleteApiKeyButton_apiKey on ApplicationApiKey {\n  id\n}\n\nfragment DeleteApiKeyButton_application on Application {\n  id\n}\n\nfragment RefreshApiKeyButton_apiKey on ApplicationApiKey {\n  id\n}\n\nfragment RefreshApiKeyButton_application on Application {\n  id\n}\n"
  }
};
})();
(node as any).hash = '032b4621d37aa41823f0d3677db3358d';
export default node;
