import usePermissions from '@app/hooks/usePermissions';
import React from 'react';
import { NavLink, useRouteMatch, Switch, Route } from 'react-router-dom';
import PaymentMethodScreen from './screens/PaymentMethodScreen';
import PaymentHistoryScreen from './screens/PaymentHistoryScreen';
import BillingInformationScreen from './screens/BillingInformationScreen';
import SubscriptionScreen from './screens/SubscriptionScreen/SubscriptionScreen';

export default function BillingScreen() {
  const match = useRouteMatch();
  const permissions = usePermissions();

  if (permissions?.financials === false) return null;

  return (
    <React.Fragment>
      <div className="app-content-header with-tabs">
        <h1>Billing</h1>

        <div className="app-content-tabs">
          <ul>
          <li>
              <NavLink to={`${match.url}/subscription`} exact={true} activeClassName="active">Subscription</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/payment-method`} exact={true} activeClassName="active">Payment method</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/information`} exact={true} activeClassName="active">Billing information</NavLink>
            </li>
            <li>
              <NavLink to={`${match.url}/history`} exact={true} activeClassName="active">Payment history</NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="app-tab-content">
        <Switch>
          <Route path={`${match.path}/subscription`}>
            <SubscriptionScreen />
          </Route>
          <Route path={`${match.path}/payment-method`}>
            <PaymentMethodScreen />
          </Route>
          <Route path={`${match.path}/history`}>
            <PaymentHistoryScreen />
          </Route>
          <Route path={`${match.path}/information`}>
            <BillingInformationScreen />
          </Route>
        </Switch>
      </div>
    </React.Fragment>
  );
}
