import auth0 from 'auth0-js';
import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import {ICache} from '@auth0/auth0-spa-js/dist/typings/cache';
import CriiptoAuth from '@criipto/auth-js';
import {singleton as config} from '@app/config';
import {LOGIN_CALLBACK_URL, OAUTH2_SCOPE} from '@app/constants';

export {Auth0Client as Auth0Client};

export const sessionStorageCache : ICache = {
  get: function (key : string) {
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)!) : null;
  },

  set: function (key : string, value : any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  },

  remove: function (key : string) {
    sessionStorage.removeItem(key);
  },

  allKeys: function () {
    return Object.keys(sessionStorage);
  }
};

export function createWebAuth() {
  return new auth0.WebAuth({
    domain: config.auth0Dns,
    clientID: config.auth0ClientId,
    redirectUri: LOGIN_CALLBACK_URL,
    audience: config.auth0Audience,
    responseType: 'id_token token',
    scope: OAUTH2_SCOPE
  });
}

export function createAuthClient(options?: Partial<Auth0ClientOptions>) {
  const auth0 = new Auth0Client({
    domain: config.auth0Dns,
    client_id: config.auth0ClientId,
    redirect_uri: LOGIN_CALLBACK_URL,
    audience: config.auth0Audience,
    scope: OAUTH2_SCOPE,
    useRefreshTokens: true,
    cache: sessionStorageCache,
    ...options
  });

  return auth0;
}

export function createSubscriptionAuthClient(options?: Partial<Auth0ClientOptions>) {
  const auth0 = new Auth0Client({
    domain: config.auth0Dns,
    client_id: config.subscriptionAuth0ClientID,
    redirect_uri: LOGIN_CALLBACK_URL,
    audience: config.subscriptionAuth0Audience,
    scope: OAUTH2_SCOPE,
    cache: sessionStorageCache,
    ...options
  });

  return auth0;
}

export function createNemIDAuthClient() {
  var criiptoAuth = new CriiptoAuth({
    domain: config.nemIdVerifyDomain,
    clientID: config.nemIdVerifyRealm,
    store: window.sessionStorage
  });
  return criiptoAuth;
}