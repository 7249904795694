import React, { useEffect, useState } from 'react';

import usePermissions from "@app/hooks/usePermissions";
import useTenant from "@app/hooks/useTenant";
import { ConfirmModal, ConfirmModalProps } from '@app/components/Modal';
import { useCancelSubscriptionMutation, useCustomerQuery } from '@app/api/subscription';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import moment from 'moment';
import { getErrorString } from '@app/api/helper';
import useTracking from '@app/hooks/useTracking';

const CUSTOM_REASON = 'Other reason';
const REASONS = [
  'We no longer need e-ID services',
  'We are switching to another provider',
  CUSTOM_REASON
];

interface Props {
  show: ConfirmModalProps["show"]
  onHide: ConfirmModalProps["onHide"]
}
export default function CancelSubscriptionModal(props: Props) {
  const {show, onHide} = props;
  const tenant = useTenant();
  const permissions = usePermissions();
  const tracking = useTracking();
  const customerResponse = useCustomerQuery(tenant && permissions?.financials ? tenant : skipToken);
  const [cancelSubscription, cancelSubscriptionState] = useCancelSubscriptionMutation();
  const [selectedReason, setReason] = useState('');
  const [customReason, setCustomReason] = useState('');
  const customer = customerResponse.data;

  const handleConfirm = async () => {
    if (!tenant) return;
    await cancelSubscription(tenant).unwrap();

    const reason = selectedReason === CUSTOM_REASON ? customReason : selectedReason;
    tracking.subscriptionCanceled(reason || undefined);
    onHide();
  };

  if (!customer) return null;

  return (
    <ConfirmModal
      title="Cancel Criipto subscription"
      onConfirm={handleConfirm}
      show={show}
      onHide={onHide}
      confirmText="Yes, cancel my subscription"
      confirmVariant="danger"
      cancelText="No, nevermind"
    >
      <p>Are you sure you wish to cancel your subscription?</p>
      {customer.subscription ? (
        <p>After <strong>{moment(customer.subscription?.periodEndAt).format('MMMM Do, YYYY')}</strong> you will no longer be able to accept logins from real users.</p>
      ) : (
        <p>you will no longer be able to accept logins from real users.</p>
      )}

      <div className="mt-8">
        <strong>If you don't mind, we would love to hear why you are cancelling:</strong>
        {REASONS.map(reason => (
          <div key={reason} className="flex flex-row items-center gap-[8px]" onClick={() => setReason(reason)}>
            <input type="radio" className="!m-0" name="reason" checked={reason === selectedReason} onChange={() => {}} />
            <label className="control-label !m-0">
              {reason}
            </label>
          </div>
        ))}

        {selectedReason === CUSTOM_REASON ? (
          <input placeholder="Please type your reason for cancelling" type="text" className="form-control mt-4" value={customReason} onChange={(event) => setCustomReason(event.target.value)} />
        ) : null}
      </div>

      {cancelSubscriptionState.error ? (
        <p className="alert alert-danger mt-8">
          {getErrorString(cancelSubscriptionState.error)}
        </p>
      ) : null}
    </ConfirmModal>
  )
}