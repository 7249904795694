import { useAcceptInvitationMutation } from '@app/api/gauss';
import useQuery from '@app/hooks/useQuery';
import useTracking from '@app/hooks/useTracking';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../LoginScreen/LoginScreen.scss';

export default function InvitationAcceptScreen() {
  const query = useQuery();
  const href = query.get('href');
  const history = useHistory();
  const [acceptInvitation, acceptResult] = useAcceptInvitationMutation();
  const tracking = useTracking();

  useEffect(() => {
    if (!href) return;

    acceptInvitation(href).unwrap().then(result => {
      tracking.invitationAccepted(result);
      history.push(`/refreshlogin/${result.id}`);
    }).catch(err => {
      console.log(err);
    });
  }, [href]);

  return (
    <div className="login-screen">
      <div className="login-box">
        <div className="header">
          <img src="/assets/images/criipto-logo-cyan.svg" alt="Criipto" />
        </div>
        <div className="content">
          {!href ? (
            <div className="alert alert-danger">
              You have opened an invalid invitation link, please ask to be invited again.
            </div>
          ) : acceptResult.error ? (
            <div className="alert alert-danger">
              {typeof acceptResult.error === "string" ? acceptResult.error : "message" in acceptResult.error ? acceptResult.error.message : 'An error occurred'}
            </div>
          ) : (
            <h4>Joining organization ...</h4>
          )}
        </div>
      </div>
    </div>
  )
}