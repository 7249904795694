import React, {useEffect} from 'react';
import {Switch, Route, Link, useRouteMatch, useParams, useHistory, useLocation} from 'react-router-dom';

import {useDispatch, useSelector} from '@app/redux';
import { IDENTITY_PROVIDERS, filterProviders, providerRequiresConfiguration, getProviderConfiguration } from '@app/providers';
import { translate } from '@app/i18n';
import {fetchProviders} from '@app/redux/tenantSlice';
import {singleton as config} from '@app/config';
import { BelgianeIDConfiguration, DanishMitIdConfiguration, DanishNemIdConfiguration, DutchDigIdConfiguration, DutchiDINConfiguration, GermanSofortConfiguration, ItsmeConfiguration, NorwegianBankIdConfiguration, NorwegianBankIdOldConfiguration, NorwegianVippsLoginConfiguration, SwedishBankIdConfiguration } from '@app/models';

import {BEeIDProvider} from './components/BEeIDProvider';
import {DESofortProvider} from './components/DESofortProvider';
import {DKMitIDProvider} from './components/DKMitIDProvider';
import {DKNemIDProvider} from './components/DKNemIDProvider';
import {SEBankIDProvider} from './components/SEBankIDProvider';
import {NOBankIDProvider} from './components/NOBankIDProvider';
import {NOBankIDOldProvider} from './components/NOBankIDOldProvider';
import {NOVippsProvider} from './components/NOVippsProvider';
import {NLDigIDProvider} from './components/NLDigIDProvider';
import {ItsmeProvider} from './components/ItsmeProvider';
import {FITeliaProvider} from './components/FITeliaProvider';
import {NLiDINProvider} from './components/NLiDINProvider';

import './ProvidersScreen.scss';
import { EnvironmentTag } from '@app/components/Tag/Tag';
import useEnvironment from '@app/hooks/useEnvironment';
import useTenant from '@app/hooks/useTenant';

export function ProvidersScreen() {
  const match = useRouteMatch();
  const environment = useEnvironment();
  const allProviders = filterProviders(IDENTITY_PROVIDERS, config);

  return (
    <React.Fragment>
      <div className="app-content-header">
        <h1>Identity providers</h1>
      </div>

      <div className="app-content-main providers-screen">
        <div className="accordion">
            <div className="accordion-header">
              <div className="row">
                <div className="col-xs-4">
                  <h3>{translate('PROVIDER')}</h3>
                </div>
                <div className="col-xs-4">

                </div>
                <div className="col-xs-4">

                </div>
              </div>
            </div>

            {allProviders.map(provider => (
              <div className="accordion-item" key={provider.id}>
                <Link to={`${match.url}/${provider.id}`} className="accordion-header clickable">
                  <div className="row">
                    <div className="col-xs-4">
                      {providerRequiresConfiguration(environment, provider) ? (
                        <strong>{provider.name}</strong>
                      ) : (
                        <span>{provider.name}</span>
                      )}
                    </div>
                    <div className="col-xs-4">
                      {providerRequiresConfiguration(environment, provider) ? null : (
                        <React.Fragment>
                          {translate('HINT_ID_SERVICE_CONFIG_ALL_SET')}. {translate('HINT_ID_SERVICE_CONFIG_NOT_NEEDED')}
                        </React.Fragment>
                      )}
                    </div>
                    <div className="col-xs-4">

                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export function ProviderScreen(props : {parentUrl: string}) {
  const params = useParams<{providerId: string}>();
  const dispatch = useDispatch();
  const tenant = useTenant();
  const providersState = useSelector(state => state.tenant.providers.state);
  const environment = useEnvironment();
  const providers = useSelector(state =>
      environment === 'PRODUCTION' ?
      state.tenant.providers.response?.production :
      state.tenant.providers.response?.test
  );
  const provider = filterProviders(IDENTITY_PROVIDERS, config).find(provider => provider.id === params.providerId);

  useEffect(() => {
    if (!tenant) return;
    // Refetch each time screen is opened as provider updating does not currently go through redux
    dispatch(fetchProviders({force: true}));
  }, [tenant]);
  const configuration = provider && providersState.success && getProviderConfiguration(provider, providers!);

  return (
    <React.Fragment>
      <div className="app-content-header">
        <div className="breadcrumb">
          <Link to={props.parentUrl}>Identity Providers</Link>
          <i className="fa fa-angle-right" />
          <div>Provider Details</div>
        </div>

        <h1>
          {provider === IDENTITY_PROVIDERS.DE_SOFORT && 'German Sofort'}
          {provider === IDENTITY_PROVIDERS.NO_BANKID && 'Norwegian BankID'}
          {provider === IDENTITY_PROVIDERS.DK_NEMID && 'Danish NemID'}
          {provider === IDENTITY_PROVIDERS.DK_MITID && 'Danish MitID'}
          {provider === IDENTITY_PROVIDERS.SE_BANKID && 'Swedish BankID'}
          {provider === IDENTITY_PROVIDERS.BE_EID && 'Belgian eID'}
          {provider === IDENTITY_PROVIDERS.NL_IDIN && 'Dutch iDIN'}
          {provider === IDENTITY_PROVIDERS.ITSME && 'itsME'}
          {provider === IDENTITY_PROVIDERS.NO_VIPPS && 'Norwegian Viips'}
          {provider === IDENTITY_PROVIDERS.NL_DIGID && 'DigID'}
          {provider === IDENTITY_PROVIDERS.NO_BANKID_OLD && 'Norwegian BankID (Deprecated)'}
          {provider === IDENTITY_PROVIDERS.FI_TELIA && 'Finnish Telia'}

        </h1>
      </div>
      <div className="app-content-main providers-screen">

        {(providersState.pending || !tenant) ? (
          <i className="fa fa-spinner fa-pulse fa-2x" />
        ) : null}

        {tenant && configuration && (
          <div className="provider">
            {provider === IDENTITY_PROVIDERS.DE_SOFORT && (<DESofortProvider key={provider.name + environment} apiRoot={tenant} provider={provider} configuration={configuration as GermanSofortConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.NO_BANKID && (<NOBankIDProvider key={provider.name + environment} apiRoot={tenant} provider={provider} configuration={configuration as NorwegianBankIdConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.DK_NEMID && (<DKNemIDProvider key={provider.name + environment} apiRoot={tenant} provider={provider} configuration={configuration as DanishNemIdConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.DK_MITID && (<DKMitIDProvider key={provider.name + environment} configuration={configuration as DanishMitIdConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.SE_BANKID && (<SEBankIDProvider key={provider.name + environment} apiRoot={tenant} provider={provider} configuration={configuration as SwedishBankIdConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.BE_EID && (<BEeIDProvider key={provider.name + environment} provider={provider} configuration={configuration as BelgianeIDConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.NL_IDIN && (<NLiDINProvider key={provider.name + environment} provider={provider} configuration={configuration as DutchiDINConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.ITSME && (<ItsmeProvider key={provider.name + environment} apiRoot={tenant} provider={provider}configuration={configuration as ItsmeConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.NO_VIPPS && (<NOVippsProvider key={provider.name + environment} apiRoot={tenant} provider={provider}configuration={configuration as NorwegianVippsLoginConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.NL_DIGID && (<NLDigIDProvider key={provider.name + environment} apiRoot={tenant} provider={provider} configuration={configuration as DutchDigIdConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.NO_BANKID_OLD && (<NOBankIDOldProvider key={provider.name + environment} apiRoot={tenant} provider={provider}configuration={configuration as NorwegianBankIdOldConfiguration} />)}
            {provider === IDENTITY_PROVIDERS.FI_TELIA && (<FITeliaProvider key={provider.name + environment} />)}
          </div>
        )}

        {providersState.error && (
          <div className="alert alert-danger">{providersState.error.message}</div>
        )}
      </div>
    </React.Fragment>
  );
}

export default function ProviderRoutes() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.path} exact={true}>
        <ProvidersScreen />
      </Route>
      <Route path={`${match.path}/:providerId`}>
        <ProviderScreen parentUrl={match.url} />
      </Route>
    </Switch>
  )
}