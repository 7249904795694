import React from 'react';
import { useField } from 'formik';

import { OAuth2Config, deserializer } from '@app/models';
import { translate } from '@app/i18n';
import { Switch } from '@app/components/Form/Form';

const userInfoResponseStrategies = ["plainJson", "signedJwt", "fromTokenEndpoint"];
const claimPropertyFormats = ["default", "compact", "namespaced"];
const nullClaimValues = ["allow", "remove"];

export default function OAuth2ConfigForm() {
  const [{value}, , {setValue}] = useField<OAuth2Config>('oAuth2Config');

  const handleCallbackChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    const newConfig = deserializer(OAuth2Config)(value);
    newConfig.callbackStrategy.onLocationHash = event.target.checked;

    setValue(newConfig);
  };

  const handleChange = (key : keyof OAuth2Config, event : React.ChangeEvent<HTMLSelectElement>) => {
    handleValue(key, event.target.value)
  }

  const handleValue = (key: keyof OAuth2Config, newValue: any) => {
    const newConfig = deserializer(OAuth2Config)({
      ...value,
      [key]: newValue
    });

    setValue(newConfig);
  }

  return (
    <React.Fragment>
      <div className="form-group horizontal">
        <label className="switch">
          <input type="checkbox" checked={value.callbackStrategy.onLocationHash} onChange={handleCallbackChange} /><i />
        </label>
        <label className="control-label">{translate('LABEL_OAUTH2_CALLBACKONLOCATIONHASH')}</label>
      </div>

      <Switch<OAuth2Config>
        name="nullClaimValues"
        label="Allow null claim values"
        off="remove"
        on="allow"
        value={value.nullClaimValues}
        onChange={value => handleValue('nullClaimValues', value)}
      />

      <div className="form-group">
        <label className="control-label">{translate('LABEL_CHOOSE_USERINFO_RESPONSE_STRATEGY')}</label>
        <select value={value.userInfoResponseStrategy} onChange={(event) => handleChange('userInfoResponseStrategy', event)}>
          {userInfoResponseStrategies.map(value => (
            <option value={value} key={value}>{value}</option>
          ))}
        </select>
      </div>

      <div className="form-group">
        <label className="control-label">{translate('LABEL_CHOOSE_JWT_PROPERTY_FORMAT')}</label>
        <select value={value.claimPropertyFormat} onChange={(event) => handleChange('claimPropertyFormat', event)}>
          {claimPropertyFormats.map(value => (
            <option value={value} key={value}>{value}</option>
          ))}
        </select>
      </div>
    </React.Fragment>
  )
}