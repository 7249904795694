import React, {useState} from 'react';
import {omit} from 'lodash';

import { Application } from '@app/models';
import Button, {ButtonProps} from '@app/components/Button';
import { Modal, ConfirmModal} from '@app/components/Modal';
import { translate } from '@app/i18n';
import { deleteApplication } from '@app/redux/tenantSlice';
import { useDispatch } from '@app/redux';

interface Props extends Omit<ButtonProps, 'variant' | 'children'> {
  application: Application,
  onDelete?: () => void
}
export default function DeleteApplicationButton(props : Props) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAuth0, setShowAuth0] = useState(false);
  const dispatch = useDispatch();
  const isAuth0 = props.application.hasTag('auth0');

  const handleDelete = async () => {
    setShowConfirm(false);
    setShowAuth0(false);

    await dispatch(deleteApplication(props.application));
    if (props.onDelete) props.onDelete();
  }

  const handleConfirm = () => {
    if (isAuth0) {
      setShowConfirm(false);
      setShowAuth0(true);
    } else {
      handleDelete();
    }
  }

  const handleClick = (event : React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setShowConfirm(true);
  }

  return (
    <React.Fragment>
      <Button {...omit(props, 'onDelete')} variant="danger" type="button" onClick={handleClick} >
        <i className="fa fa-trash-alt" />
      </Button>
      <ConfirmModal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        title={translate('DELETE_APPLICATION')}
        confirmText={translate('DELETE_APPLICATION')}
        confirmVariant="danger"
      >
        {translate('INFO_DELETE_APPLICATION', {application: props.application.name})}
      </ConfirmModal>
      <Modal show={showAuth0} onHide={handleDelete} title={translate('DELETE_APPLICATION')}>
        {translate('INFO_DELETE_APPLICATION_AUTH0', {application: props.application.name})}
      </Modal>
    </React.Fragment>
  )
}