import React, { useEffect, useState } from 'react';

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import Modal, { ModalProps } from "@app/components/Modal";
import { useCustomerQuery, useUpdateCardMutation } from '@app/api/subscription';
import { Tenant } from '@app/models';

import './PaymentMethodModal.css';
import Button from '@app/components/Button/Button';
import { getErrorString } from '@app/api/helper';

interface Props {
  tenant: Tenant,
  show: ModalProps["show"]
  onHide: ModalProps["onHide"]
  onSuccess: () => void
}

export default function PaymentMethodModal(props: Props) {
  const [name, setName] = useState('');
  const {tenant} = props;
  const stripe = useStripe();
  const elements = useElements();
  const [working, setWorking] = useState(false);
  const [error, setError] = useState<string | null>('');

  const customer = useCustomerQuery(tenant);
  const [update, updateResult] = useUpdateCardMutation();

  useEffect(() => {
    if (!customer.data) return;
    setName(customer.data.nameOnCard || customer.data.name || name);
  }, [customer.data]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!elements || !stripe) return;
    if (working || updateResult.isLoading) return;

    setWorking(true);
    setError(null);

    const element = elements.getElement(CardElement)!;
    const {error, token} = await stripe.createToken(element, {
      name,
      address_line1: customer.data!.street,
      address_city: customer.data!.city,
      address_zip: customer.data!.zip,
      address_country: customer.data!.country
    });

    if (error) {
      setWorking(false);
      setError(error.message || 'An error occurred');
      return;
    }

    await update({
      tenant: tenant!,
      request: {
        ...customer.data,
        name,
        paymentToken: token!.id
      }
    }).unwrap();

    props.onSuccess();
  }

  return (
    <Modal show={props.show} onHide={props.onHide} title="Update payment method">
      <Modal.Body>
        <form onSubmit={handleSubmit} className="payment-method-form flex flex-col gap-[16px]">
          <div className="form-group">
            <label className="control-label">Credit card</label>
            <div className="stripe-card-input">
              <CardElement options={{hidePostalCode: true}} />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label">Name on card</label>
            <input type="text" className="form-control" value={name} onChange={event => setName(event.target.value)} />
          </div>

          {error ? (
            <div className="alert alert-danger">{error}</div>
          ) : null}
          {updateResult.error ? (
            <div className="alert alert-danger">{getErrorString(updateResult.error)}</div>
          ) : null}

          <div className="flex flex-row align-items-end">
            <Button variant="primary" type="submit" working={working || updateResult.isLoading}>Save payment method</Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}