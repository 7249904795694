import React from 'react';
import { Provider as ReduxProvider, useSelector } from 'react-redux';

import {default as reduxStore, RootState} from '@app/redux/store';
import {SlackModal} from '@components/Slack';
import './Footer.scss';
import useEnvironment from '@app/hooks/useEnvironment';

export function Footer(props: {left?: boolean}) {
  const statuspage = useSelector((state : RootState) => state.statuspage);
  const environment = useEnvironment();

  return (
    <footer id="footer" className={`environment-${environment}`}>
      <div className={`container ${props.left ? 'left' : ''}`}>
        <div className="logo">
          <a href="https://www.criipto.com" target="_blank"><img src="/assets/images/footer-logo.png" /></a>
        </div>
        <div className="links">
          <ul>
            <li>Criipto ApS</li>
            <li>Dronninggårds Allé 136</li>
            <li>2840 Holte</li>
            <li>Denmark</li>
          </ul>
          <ul>
            <li><a href="mailto:hello@criipto.com">hello@criipto.com</a></li>
            <li><a href="mailto:sales@criipto.com">sales@criipto.com</a></li>
            <li><a href="mailto:support@criipto.com">support@criipto.com</a></li>
          </ul>
          <ul>
            <li><a href="https://criipto.com/legal/tos" target="_blank">Terms of Service</a></li>
            <li><a href="https://criipto.com/legal/privacy" target="_blank">Data Protection Policy</a></li>
            <li className="operation-status-wrapper" title={statuspage.tooltip}>
              <i className={`operation-status ${statuspage.cssClass}`} title={statuspage.tooltip}></i>
              <a href="https://status.criipto.com/" target="_blank" title={statuspage.tooltip}>Operations Status</a>
            </li>
          </ul>
          <ul className="social">
            <li><a href="https://twitter.com/criipto" className="twitter" target="_blank"><img src="/assets/images/twitter-logo.svg"/></a></li>
            <li><a href="https://www.linkedin.com/company/3078112" className="linkedin" target="_blank"><img src="/assets/images/linkedin-logo.svg"/></a></li>
          </ul>
        </div>
      </div>

      <SlackModal />
    </footer>
  )
}

export default Footer;