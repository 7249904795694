import { Application } from '@app/models';

export type OidcDiscovery = {
  authorization_endpoint: string
}
export function authorizeUrl(application: Application, acr_values: string | string[] | undefined, discovery?: OidcDiscovery) {
  const redirectUri = application?.returnUrls.find(search => search.includes('jwt.io')) || application?.returnUrls[0];
  const base = discovery?.authorization_endpoint ?? `https://${application.domain.name}/oauth2/authorize`;

  const nonce = (window?.crypto as any)?.randomUUID !== undefined ? (window.crypto as any).randomUUID() : Math.random().toString();

  const url = new URL(`${base}?scope=openid&response_type=id_token&response_mode=fragment`);
  url.searchParams.append('nonce', `ecnon-${nonce}`);
  url.searchParams.append('redirect_uri', redirectUri);
  url.searchParams.append('client_id', application.realm);
  if (acr_values) {
    if (Array.isArray(acr_values)) {
      if (acr_values.length) {
        url.searchParams.append('acr_values', acr_values.join(' '));
      }
    } else {
      url.searchParams.append('acr_values', acr_values);
    }
  }

  return url.toString();
}

export const trimDomainPrefix = (input: string) => input.trim().toLowerCase();
export function validateDomainPrefix(value: string, suffix: string) {
  value = trimDomainPrefix(value);
  if (value.match(/^\d+$/)) {
    return 'A host name (label) MUST NOT consist of all numeric values';
  }
  if (value.substr(0, 1) === '-' || value.substr(-1, 1) === '-') {
    return 'A host name (label) MUST NOT start or end with a \'-\' (dash)';
  }
  if (value.length < 2) {
    return 'A host name (label) MUST be at least 2 characters';
  }
  if ((value + suffix).length > 63) {
    return 'A host name (label) can be up to 63 characters';
  }
  if (value.includes('.')) {
    return 'A host name (label) MUST NOT include a \'.\' (dot)';
  }
  if (!value.match(/^[\w\d][\w\d\-]*[\w\d]$/)) {
    return 'A host name (label) can start or end with a letter or a number';
  }
}